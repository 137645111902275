const FETCH_REQUEST = 'highlightedProtocols/FETCH_REQUEST';
const FETCH_SUCCESS = 'highlightedProtocols/FETCH_SUCCESS';
const FETCH_FAILURE = 'highlightedProtocols/FETCH_FAILURE';
const INITIAL_STATE = {
  data: [],
  error: null,
  fetching: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
  case FETCH_REQUEST:
    return {
      ...state,
      error: null,
      fetching: true,
    };
  case FETCH_SUCCESS:
    return {
      ...state,
      fetching: false,
      data: action.payload,
    };
  case FETCH_FAILURE:
    return {
      ...state,
      fetching: false,
      error: action.payload,
    };
  default:
    return state;
  }
}

function fetchRequest() {
  return {
    type: FETCH_REQUEST,
  };
}

function fetchSuccess(data) {
  return {
    type: FETCH_SUCCESS,
    payload: data,
  };
}

function fetchFailure(error) {
  return {
    type: FETCH_FAILURE,
    payload: error,
  };
}

export function getHighlightedProtocols(idLanguage) {
  return (dispatch) => {
    dispatch(fetchRequest());
    fetch(`${process.env.REACT_APP_API_BASE_URL}/protocols?fields=category,language,number_campaigns,number_active_campaigns&idLanguage=${idLanguage}&isHighlighted=true`, {
      method: 'GET',
      headers: new Headers({ 'app_secret_key': process.env.REACT_APP_API_APP_KEY })
    }).then((response) => {
      if (response.ok) return response.json();
      const result = response.json();
      throw new Error(result && result.msg ? result.msg : 'It was impossible to load protocols');
    }).then((data) => {
      // await (new Promise((res) => { setTimeout(res, 5000); }));
      dispatch(fetchSuccess(data));
    }).catch((error) => dispatch(fetchFailure(error)));
  };
}
