import React from 'react';
import dayjs from 'dayjs';
import qs from 'qs';
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';
import { getGroups } from '../../reducers/Groups';
import { getAllLanguages } from '../../reducers/Languages';
import { formatBytes, withRouter } from '../../utils';
import { ClockLoader, PuffLoader } from 'react-spinners';
import {
  Alert, Badge, Button, Card, DatePicker, Divider, Drawer, Form, Input, Popconfirm, Result,
  Select, Space, Steps, Switch, Table, Tabs, Tag, Upload, message, notification,
} from 'antd';

import { styles } from '../../styles';
import 'react-quill/dist/quill.snow.css';

import no_photo from '../../images/no_photo.png';

const { Meta } = Card;
const { Option } = Select;
const { TextArea } = Input;

/**
 * ManageAdvantages component represents the page template that allows to manage the application advantages.
 * It displays a table with all active advantages and a table with all inactive advantages.
 * It allows tools to create, edit, deactivate, reactivate and delete advantages.
 */
class ManageAdvantages extends React.Component {
  /**
   * The constructor for a React component is called before it is mounted.
   * When implementing the constructor, `super(props)` must be called before any
   * other statement. Otherwise, this.props will be undefined in the constructor,
   * which can lead to bugs.
   *
   * A React constructor is only used for two purposes:
   * - Initializing local state by assigning an object to this.state.
   * - Binding event handler methods to an instance.
   *
   * Instead of calling `setState()` in the `constructor(), the initial state must be
   * directly assigned to `this.state` in the constructor
   *
   * @param {*} props - Arbitrary component inputs.
   */
  constructor() {
    super();
    this.state = {
      activeLanguage: process.env.REACT_APP_LANGUAGE_ID,
      addCurrentStep: 0,
      addDescriptionValue: null,
      addError: null,
      addFetching: false,
      addImageUpload: null,
      addImageUrl: null,
      addNewAdvantage: null,
      advantages: [],
      advantagesError: null,
      archiveFileLoading: false,
      categories: [],
      categoriesFetching: false,
      categoriesError: null,
      defaultLanguage: process.env.REACT_APP_LANGUAGE_ID,
      editCurrentStep: 0,
      editDescriptionValue: null,
      editError: null,
      editFetching: false,
      editedAdvantage: null,
      editImageUpload: null,
      editImageUrl: null,
      fileSubmissionError: null,
      fileSubmissionLink: null,
      fileSubmissionLoading: false,
      fileSubmissionSuccess: false,
      loading: false,
      pageWidth: 0,
      selectedItem: null,
      showAddAdvantageModal: false,
      showEditAdvantageModal: false,
      showFileSubmissionModal: false,
      showUploadInputError: false,
      submitFileCurrentStep: 0,
      titleSearch: null,
      uploadedFile: null,

      tableRef: React.createRef(null),
    };
  }

  /**
   * Allows to execute the React code when the component is already placed in the DOM (Document Object Model).
   * This method is called during the Mounting phase of the React Life-cycle i.e after the component is rendered.
   */
  componentDidMount() {
    // Get data
    this.props.getLanguages(window.localStorage.getItem('token'));
    this.fetchData();
    // Scroll to top
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Add event on resize
    this.handlePageWidth();
    window.addEventListener('resize', this.handlePageWidth);
  }

  /**
   * Allows React to call this function immediately after this component has been re-rendered with
   * updated `props` and/or `state`. This method is not called for the initial render.
   * 
   * This allows to manipulate the DOM after an update. It is also a common place to do network requests
   * as long as it compares the current `props` to previous `props` (e.g. a network request may not be necessary
   * if the `props` have not changed).
   * 
   * @param {any} prevProps - Props before the update. Compare prevProps to this.props to determine what changed.
   * @param {any} prevState - State before the update. Compare prevState to this.state to determine what changed.
   * @param {any} snapshot - Used when function getSnapshotBeforeUpdate is implemented. It will contain the value returned from that function. Otherwise, it will be undefined.
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.activeLanguage !== this.state.activeLanguage) this.fetchData(this.state.activeLanguage);
    if (prevProps.menuCollapsed !== this.props.menuCollapsed) setTimeout(() => this.handlePageWidth(), 500);
  }

  handlePageWidth = () => {
    const cmsLayoutPageWidth = document.getElementById('cms-layout-content');
    this.setState({ pageWidth: cmsLayoutPageWidth ? cmsLayoutPageWidth.clientWidth : window.innerWidth });
  };

  /**
   * Sets the array of advantages to manage. 
   * 
   * @param {any[]} data - Original data of advantages. 
   * @returns {any[]} the modified dataset of advantages.
   */
  setAdvantagesData = (data) => {
    return data.map((advantage) => {
      return {
        key: advantage.idProtocol,
        idLanguage: advantage.language.idLanguage,
        idCategory: advantage.category.idCategory,
        idGroup: advantage.group ? advantage.group.idGroup : null,
        groupName: advantage.group ? advantage.group.name : null,
        groupImage: advantage.group ? advantage.group.image : null,
        title: advantage.title,
        image: advantage.image,
        summary: advantage.summary,
        description: advantage.description,
        searchFields: advantage.searchFields,
        categoryName: advantage.category.name,
        categoryColor: advantage.category.color,
        discountValue: advantage.discountValue,
        location: advantage.location,
        isInDistrict: advantage.isInDistrict,
        hasManyLocations: advantage.hasManyLocations,
        isHighlighted: advantage.isHighlighted,
        activationDate: advantage.activationDate ? dayjs(advantage.activationDate).subtract(1, 'h') : null,
        deactivationDate: advantage.deactivationDate ? dayjs(advantage.deactivationDate).subtract(1, 'h') : null,
        documents: advantage.documents,
        numberOfDocuments: advantage.documents.length,
        isActive: this.advantageIsActive(advantage),
      };
    });
  };

  /**
   * Checks if an advantage is active, analysing its activation and deactivation dates and comparing it to the
   * actual date.
   * 
   * @param {any} record - Advantages object.
   * @returns {boolean} - true if advantages is active and false otherwise.
   */
  advantageIsActive = (record) => {
    const activation = record.activationDate ? dayjs(record.activationDate).subtract(1, 'h') : undefined;
    const deactivation = record.deactivationDate ? dayjs(record.deactivationDate).subtract(1, 'h') : undefined;
    const now = dayjs();
    return activation && (activation.isBefore(now) || activation.isSame(now)) &&
      (!deactivation || (deactivation && deactivation.isAfter(now))) ? true : false;
  };

  /**
   * Fetches all advantages from database through an available API. Calls `setAdvantagesData` to modify the
   * data, regarding this component.
   */
  fetchData = () => {
    this.setLoading(true);
    const { selectedItem } = this.state;
    // Get parameters to fetch
    const fetchParams = {
      fields: 'category,language,group,number_campaigns,number_active_campaigns,active_campaigns,documents',
      idLanguage: this.state.activeLanguage,
    };
    // Get options to fetch
    const fetchOptions = {
      method: 'GET',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      })
    };
    // Fetch data
    fetch(`${process.env.REACT_APP_API_BASE_URL}/protocols?${qs.stringify(fetchParams)}`, fetchOptions)
      .then(async (response) => { 
        if (response.ok) return response.json();
        const result = await response.json();
        throw new Error(result ? JSON.stringify(result) : 'Não é possível obter todas as vantagens.');
      })
      .then(async (data) => {
        // await (new Promise((res) => {setTimeout(res, 5000); }));
        const advantages = this.setAdvantagesData(data);
        this.setState({ advantages: advantages, advantagesError: null });
        if (selectedItem) this.setState({ selectedItem: advantages.find((option) => option.key === selectedItem.key) });
        this.setLoading(false);
      }).catch((error) => {
        this.setState({ advantages: [], advantagesError: error.message, selectedItem: null });
        this.setLoading(false);
      });
  };

  /**
   * Sets loading state from `this.state.loading`.
   * This state is relationated with the data fetching from database.
   *
   * @param {boolean} loading - Sets the loading state to true or false.
   */
  setLoading = (loading) => {
    this.setState({ loading: loading });
  };

  /**
   * Fetch all categories of the system (only active categories).
   */
  fetchCategories = () => {
    this.setState({ categoriesFetching: true });
    // Get parameters to fetch
    const fetchParams = { idLanguage: this.state.activeLanguage };
    // Get options to fetch
    const fetchOptions = {
      method: 'GET',
      headers: new Headers({ 'app_secret_key': process.env.REACT_APP_API_APP_KEY })
    };
    // Fetch data
    fetch(`${process.env.REACT_APP_API_BASE_URL}/categories?${qs.stringify(fetchParams)}`, fetchOptions)
      .then(async (response) => {
        if (response.ok) return response.json();
        const result = await response.json();
        throw new Error(result && result.msg ? result.msg : 'It was impossible to load categories');
      }).then(async (data) => {
        // await (new Promise((res) => {setTimeout(res, 5000); }));
        this.setState({ categories: data, categoriesError: null, categoriesFetching: false });
      }).catch((error) => {
        this.setState({ categories: [], categoriesError: error.message, categoriesFetching: false });
      });
  };

  /**
   * Handles the visibility state of the add advantage modal, using the state `this.state.showAddAdvantageModal`.
   */
  handleAdvantageModal = () => {
    const { showAddAdvantageModal } = this.state;
    this.setState({
      addDescriptionValue: null,
      addNewAdvantage: null,
      addImageUpload: null,
      addImageUrl: null,
      showAddAdvantageModal: !showAddAdvantageModal, 
      addCurrentStep: 0,
      addError: null,
      addFetching: false,
    });
  };
  
  /**
   * Handles the add modal current step, when user goes back.
   */
  handleAddBackStep = () => {
    const { addCurrentStep } = this.state;
    this.setState({ addCurrentStep: addCurrentStep - 1 });
  };

  /**
   * Handles the image file upload on advantage creation.
   */
  handleAddFileList = (e) => {
    if (Array.isArray(e)) return e;
    if (e) return e.fileList;
    return undefined;
  };

  /**
   * Adds a new advantage to the system, using the API for the effect.
   *
   * @param {any} values - New advantage data.
   */
  addAdvantage = (newObject) => {
    this.setState({ addFetching: true });
    const formdata = new FormData();
    formdata.append('idCategory', newObject.idCategory);
    formdata.append('title', newObject.title);
    formdata.append('description', newObject.description);
    formdata.append('isInDistrict', newObject.isInDistrict);
    formdata.append('hasManyLocations', newObject.hasManyLocations);
    formdata.append('isHighlighted', newObject.isHighlighted);
    formdata.append('activationDate', newObject.activationDate);
    if (newObject.idGroup) formdata.append('idGroup', newObject.idGroup);
    if (newObject.summary) formdata.append('summary', newObject.summary);
    if (newObject.searchFields) formdata.append('searchFields', newObject.searchFields);
    if (newObject.discountValue) formdata.append('discountValue', newObject.discountValue);
    if (newObject.location) formdata.append('location', newObject.location);
    // Handle image
    if (newObject.image && typeof newObject.image !== 'string') formdata.append('image', newObject.image.originFileObj);
    else formdata.append('image', newObject.image);
    // Get options to fetch
    const fetchOptions = {
      method: 'POST',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      }),
      body: formdata,
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/protocols`, fetchOptions).then(async (response) => {
      if (response.ok) return response.json();
      const result = await response.json();
      throw new Error(result ? JSON.stringify(result) : 'Não é possível adicionar a nova vantagem');
    }).then(async () => {
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check'></i>
            <span className='cms-notification-icon-text-span'>Vantagem &ldquo;{newObject.title}&rdquo; criada com sucesso</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
            <span>A vantagem foi adicionada com sucesso. Consulte a lista de vantagens para verificar a mesma.</span>
          </div>
        ),
      });
      setTimeout(() => {
        this.setState({ addError: null, addFetching: false });
        this.handleAdvantageModal();
        this.fetchData();
      }, 1500);
    }).catch((error) => {
      let message = 'Não é possível adicionar uma nova vantagem';
      let extendedMessage = 'Por favor, reveja os dados inseridos e/ou tente novamente.';
      if (error && error.message) {
        if (error.message.includes('Your token is not valid!'))  {
          extendedMessage = 'Não tem autorização para adicionar vantagens. Por favor, volte a entrar na sua conta e, se o erro persistir, contacte o administrador da aplicação.';
        }
        if (error.message.includes('You have no permission to access this resource.'))  {
          extendedMessage = 'Não tem autorização para adicionar vantagens. Por favor, contacte o administrador da aplicação.';
        }
        if (error.message.includes('Category is required.'))  {
          message = 'Categoria selecionada inválida';
          extendedMessage = 'Por favor, selecione uma categoria válida.';
        }
        if (error.message.includes('Invalid category identification.'))  {
          message = 'Categoria selecionada inválida';
          extendedMessage = 'Por favor, selecione uma categoria válida.';
        }
        if (error.message.includes('Category not found.'))  {
          message = 'Categoria selecionada inválida';
          extendedMessage = 'A categoria selecionada não foi encontrada no sistema. Por favor, selecione uma categoria válida.';
        }
        if (error.message.includes('Category is deactivated.'))  {
          message = 'Categoria selecionada inválida';
          extendedMessage = 'A categoria selecionada foi desativada. Por favor, selecione uma categoria válida.';
        }
        if (error.message.includes('Invalid group identification.'))  {
          message = 'Grupo selecionado inválido';
          extendedMessage = 'Por favor, selecione um grupo válido. Este campo é opcional, por isso poderá deixá-lo vazio.';
        }
        if (error.message.includes('Group not found.'))  {
          message = 'Grupo selecionado inválido';
          extendedMessage = 'O grupo selecionado não foi encontrado no sistema. Por favor, selecione um grupo válido. Este campo é opcional, por isso poderá deixá-lo vazio.';
        }
        if (error.message.includes('Group is deactivated.'))  {
          message = 'Grupo selecionado inválido';
          extendedMessage = 'O grupo selecionado foi desativado. Por favor, selecione um grupo válido. Este campo é opcional, por isso poderá deixá-lo vazio.';
        }
        if (error.message.includes('Invalid title. Must have more than 3 characters.'))  {
          message = 'Título inválido';
          extendedMessage = 'O título da vantagem é inválido. Por favor, insira um valor válido.';
        }
        if (error.message.includes('Invalid url.'))  {
          message = 'URL inválido';
          extendedMessage = 'O URL da vantagem é inválido. Por favor, insira um valor válido.';
        }
        if (error.message.includes('Invalid image.'))  {
          message = 'Imagem inválida';
          extendedMessage = 'A imagem da vantagem é inválida. Por favor, insira uma imagem válida.';
        }
        if (error.message.includes('Invalid target audience.'))  {
          message = 'Público-alvo inválido';
          extendedMessage = 'O público-alvo da vantagem é inválido. Por favor, insira um valor válido.';
        }
      }
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'></i>
            <span className='cms-notification-icon-text-span'>{message}</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
            <span>{extendedMessage}</span>
          </div>
        ),
      });
      this.setState({ addError: message, addFetching: false });
    });
  };

  /**
   * Handles the visibility state of the edit advantage modal, using the state `this.state.showEditAdvantageModal`.
   */
  handleEditAdvantageModal = () => {
    const { showEditAdvantageModal } = this.state;
    this.setState({ 
      editDescriptionValue: null,
      editImageUpload: null,
      editedAdvantage: null,
      editImageUrl: null,
      showEditAdvantageModal: !showEditAdvantageModal,
      editCurrentStep: 0,
      editError: null,
      editFetching: false,
    });
  };
  
  /**
   * Handles the edit modal current step, when user goes back.
   */
  handleEditBackStep = () => {
    const { editCurrentStep } = this.state;
    this.setState({ editCurrentStep: editCurrentStep - 1 });
  };

  /**
   * Edits an existing advantages in the system, using the API for the effect.
   *
   * @param {any} values - New data of the existing advantage.
   */
  editAdvantage = (newObject) => {
    this.setState({ editFetching: true });
    const { selectedItem } = this.state;
    const formdata = new FormData();
    formdata.append('isInDistrict', newObject.isInDistrict);
    formdata.append('hasManyLocations', newObject.hasManyLocations);
    formdata.append('isHighlighted', newObject.isHighlighted);
    formdata.append('activationDate', newObject.activationDate);
    if (newObject.idCategory) formdata.append('idCategory', newObject.idCategory);
    if (newObject.title) formdata.append('title', newObject.title);
    if (newObject.description) formdata.append('description', newObject.description);
    if (newObject.idGroup !== undefined) formdata.append('idGroup', newObject.idGroup);
    if (newObject.summary !== undefined) formdata.append('summary', newObject.summary);
    if (newObject.searchFields !== undefined) formdata.append('searchFields', newObject.searchFields);
    if (newObject.discountValue !== undefined) formdata.append('discountValue', newObject.discountValue);
    if (newObject.location !== undefined) formdata.append('location', newObject.location);
    // Handle image
    if (newObject.image && typeof newObject.image !== 'string') formdata.append('image', newObject.image.originFileObj);
    else formdata.append('image', newObject.image);
    // Get options to fetch
    const fetchOptions = {
      method: 'PATCH',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      }),
      body: formdata,
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/protocols/${selectedItem.key}`, fetchOptions).then(async (response) => {
      if (response.ok) return response.json();
      const result = await response.json();
      throw new Error(result ? JSON.stringify(result) : `Não é possível editar a vantagem '${selectedItem.title}'`);
    }).then(async (data) => {
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check'></i>
            <span className='cms-notification-icon-text-span'>Alterações guardadas com sucesso</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
            <span>A vantagem <u>{newObject.title}</u> foi atualizada com sucesso. Consulte a lista de vantagens para verificar a alteração.</span>
          </div>
        ),
      });
      setTimeout(() => {
        this.setState({ editError: null, editFetching: false });
        this.handleEditAdvantageModal();
        this.fetchData();
      }, 1500);
    }).catch((error) => {
      let message = `Não é possível editar a vantagem '${selectedItem.title}'`;
      let extendedMessage = 'Por favor, reveja os dados inseridos e/ou tente novamente.';
      if (error && error.message) {
        if (error.message.includes('Your token is not valid!'))  {
          extendedMessage = 'Não tem autorização para adicionar vantagens. Por favor, volte a entrar na sua conta e, se o erro persistir, contacte o administrador da aplicação.';
        }
        if (error.message.includes('You have no permission to access this resource.'))  {
          extendedMessage = 'Não tem autorização para adicionar vantagens. Por favor, contacte o administrador da aplicação.';
        }
        if (error.message.includes('Invalid category identification.'))  {
          message = 'Categoria selecionada inválida';
          extendedMessage = 'Por favor, selecione uma categoria válida.';
        }
        if (error.message.includes('Category not found.'))  {
          message = 'Categoria selecionada inválida';
          extendedMessage = 'A categoria selecionada não foi encontrada no sistema. Por favor, selecione uma categoria válida.';
        }
        if (error.message.includes('Category is deactivated.'))  {
          message = 'Categoria selecionada inválida';
          extendedMessage = 'A categoria selecionada foi desativada. Por favor, selecione uma categoria válida.';
        }
        if (error.message.includes('Invalid group identification.'))  {
          message = 'Grupo selecionado inválido';
          extendedMessage = 'Por favor, selecione um grupo válido. Este campo é opcional, por isso poderá deixá-lo vazio.';
        }
        if (error.message.includes('Group not found.'))  {
          message = 'Grupo selecionado inválido';
          extendedMessage = 'O grupo selecionado não foi encontrado no sistema. Por favor, selecione um grupo válido. Este campo é opcional, por isso poderá deixá-lo vazio.';
        }
        if (error.message.includes('Group is deactivated.'))  {
          message = 'Grupo selecionado inválido';
          extendedMessage = 'O grupo selecionado foi desativado. Por favor, selecione um grupo válido. Este campo é opcional, por isso poderá deixá-lo vazio.';
        }
        if (error.message.includes('Invalid title. Must have more than 3 characters.'))  {
          message = 'Título inválido';
          extendedMessage = 'O título da vantagem é inválido. Por favor, insira um valor válido.';
        }
        if (error.message.includes('Invalid location.'))  {
          message = 'Localização inválida';
          extendedMessage = 'A localização da vantagem é inválida. Por favor, insira um valor válido.';
        }
        if (error.message.includes('Invalid url.'))  {
          message = 'URL inválido';
          extendedMessage = 'O URL da vantagem é inválido. Por favor, insira um valor válido.';
        }
        if (error.message.includes('Invalid target audience.'))  {
          message = 'Público-alvo inválido';
          extendedMessage = 'O público-alvo da vantagem é inválido. Por favor, insira um valor válido.';
        }
        if (error.message.includes('Resource not found.'))  {
          message = 'Vantagem não encontrada';
          extendedMessage = 'O vantagem selecionada não foi encontrada no sistema. Por favor, tente novamente mais tarde.';
        }
      }
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'></i>
            <span className='cms-notification-icon-text-span'>{message}</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
            <span>{extendedMessage}</span>
          </div>
        ),
      });
      this.setState({ editError: message, editFetching: false });
    });
  };

  /**
   * Deactivates an existing advantage in the system, using the API for the effect.
   */
  deactivateAdvantage = () => {
    this.setState({ editFetching: true });
    const { selectedItem } = this.state;
    const formdata = new FormData();
    formdata.append('deactivationDate', new Date());
    // Get options to fetch
    const fetchOptions = {
      method: 'PATCH',
      body: formdata,
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      }),
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/protocols/${selectedItem.key}`, fetchOptions).then(async (response) => {
      if (response.ok) return response.json();
      const result = await response.json();
      throw new Error(result ? JSON.stringify(result) : `Não é possível desativar a vantagem '${selectedItem.title}'`);
    }).then(async (data) => {
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check'></i>
            <span className='cms-notification-icon-text-span'>Vantagem desativada com sucesso</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
            <span>A vantagem <u>{selectedItem.title}</u> foi desativada com sucesso. 
              Consulte a lista de vantagens desativadas para verificar a alteração. 
              A vantagem pode ser reativada através do mesmo processo de desativação. </span>
          </div>
        ),
      });
      setTimeout(() => {
        this.setState({ editFetching: false });
        this.handleEditAdvantageModal();
        this.fetchData();
      }, 1000);
    }).catch((error) => {
      let message = `Não é possível desativar a vantagem '${selectedItem.title}'`;
      let extendedMessage = 'Por favor, tente novamente mais tarde. Se o erro persistir, contacte o administrador da aplicação.';

      if (error && error.message) {
        if (error.message.includes('Your token is not valid!'))  {
          extendedMessage = 'Não tem autorização para desativar vantagens. Por favor, volte a entrar na sua conta e, se o erro persistir, contacte o administrador da aplicação.';
        }
        if (error.message.includes('You have no permission to access this resource.'))  {
          extendedMessage = 'Não tem autorização para desativar vantagens. Por favor, contacte o administrador da aplicação.';
        }
        if (error.message.includes('Resource not found.'))  {
          message = 'Vantagem não encontrada';
          extendedMessage = 'O vantagem selecionada não foi encontrada no sistema. Por favor, tente novamente mais tarde.';
        }
      }
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'></i>
            <span className='cms-notification-icon-text-span'>{message}</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
            <span>{extendedMessage}</span>
          </div>
        ),
      });
      this.setState({ editFetching: false });
    });
  };

  /**
   * Reactivates an existing advantage in the system, using the API for the effect.
   */
  reactivateAdvantage = () => {
    this.setState({ editFetching: true });
    const { selectedItem } = this.state;
    // Get options to fetch
    const fetchOptions = {
      method: 'PATCH',
      body: JSON.stringify({ deactivationDate: null }),
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      }),
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/protocols/${selectedItem.key}`, fetchOptions).then(async (response) => {
      if (response.ok) return response.json();
      const result = await response.json();
      throw new Error(result ? JSON.stringify(result) : `Não é possível reativar a vantagem '${selectedItem.title}'`);
    }).then(async (data) => {
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check'></i>
            <span className='cms-notification-icon-text-span'>Vantagem reativada com sucesso</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
            <span>A vantagem <u>{selectedItem.title}</u> foi reativada com sucesso. 
              Consulte a lista de vantagens ativas para verificar a alteração. 
              A vantagem pode ser desativada através do mesmo processo de reativação. </span>
          </div>
        ),
      });
      setTimeout(() => {
        this.setState({ editFetching: false });
        this.handleEditAdvantageModal();
        this.fetchData();
      }, 1000);
    }).catch((error) => {
      let message = `Não é possível reativar a vantagem '${selectedItem.title}'`;
      let extendedMessage = 'Por favor, tente novamente mais tarde. Se o erro persistir, contacte o administrador da aplicação.';
      if (error && error.message) {
        if (error.message.includes('Your token is not valid!'))  {
          extendedMessage = 'Não tem autorização para reativar vantagens. Por favor, volte a entrar na sua conta e, se o erro persistir, contacte o administrador da aplicação.';
        }
        if (error.message.includes('You have no permission to access this resource.'))  {
          extendedMessage = 'Não tem autorização para reativar vantagens. Por favor, contacte o administrador da aplicação.';
        }
        if (error.message.includes('Resource not found.'))  {
          message = 'Vantagem não encontrada';
          extendedMessage = 'O vantagem selecionada não foi encontrada no sistema. Por favor, tente novamente mais tarde.';
        }
      }
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'></i>
            <span className='cms-notification-icon-text-span'>{message}</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
            <span>{extendedMessage}</span>
          </div>
        ),
      });
      this.setState({ editFetching: false });
    });
  };

  /**
   * Handles the visibility state of the file submission modal, using the state `this.state.showFileSubmissionModal`.
   */
  handleFileSubmissionModal = () => {
    const { showFileSubmissionModal } = this.state;
    this.setState({ 
      showFileSubmissionModal: !showFileSubmissionModal,
      showUploadInputError: false,
      submitFileCurrentStep: 0,
      uploadedFile: null,
      fileSubmissionLoading: false,
      fileSubmissionError: null,
      fileSubmissionSuccess: false,
      fileSubmissionLink: null,
    });
  };
  
  /**
   * Handles the file submission modal current step, when user goes back.
   */
  handleFileSubmissionBackStep = () => {
    const { submitFileCurrentStep } = this.state;
    this.setState({ submitFileCurrentStep: submitFileCurrentStep - 1 });
  };

  /**
   * Uploads a file to the system, matching it to an advantage.
   */
  submitFile = () => {
    const { submitFileCurrentStep, uploadedFile, selectedItem } = this.state;
    if (!selectedItem) this.handleFileSubmissionModal();
    if (!uploadedFile) this.setState({ showUploadInputError: true, submitFileCurrentStep: submitFileCurrentStep - 1 });
    this.setState({ fileSubmissionLoading: true });
    const formdata = new FormData();
    formdata.append('idDocumentContext', '6E71538E-1F26-EE11-A314-00155D08E85F');
    formdata.append('idInstance', selectedItem.key);
    formdata.append('file', uploadedFile);
    // Get options to fetch
    const fetchOptions = {
      method: 'POST',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      }),
      body: formdata,
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/documents`, fetchOptions).then(async (response) => {
      // await (new Promise((res) => {setTimeout(res, 5000); }));
      if (response.ok) return response.json();
      const result = await response.json();
      throw new Error(result ? JSON.stringify(result) : 'Não é possível carregar o ficheiro no servidor');
    }).then(async (data) => {
      const document = data.document;
      this.setState({ 
        fileSubmissionLoading: false, 
        fileSubmissionError: null,
        fileSubmissionSuccess: true,
        fileSubmissionLink: `${process.env.REACT_APP_API_IMAGES_BASE_URL}${document.path}`,
        submitFileCurrentStep: submitFileCurrentStep + 1
      });
    }).catch((error) => {
      let message = 'Não é possível carregar o documento.';
      this.setState({ 
        fileSubmissionError: message,
        fileSubmissionLoading: false,
        fileSubmissionSuccess: false,
        fileSubmissionLink: null,
        submitFileCurrentStep: submitFileCurrentStep + 1,
      });
    });
  };

  /**
   * Archives a file in the system.
   * 
   * @param {any} document - Document to archive.
   */
  archiveFile = (document) => {
    if (document && document.idDocument) {
      this.setState({ archiveFileLoading: true });
      // Get options to fetch
      const fetchOptions = {
        method: 'PATCH',
        headers: new Headers({
          'app_secret_key': process.env.REACT_APP_API_APP_KEY,
          'Authorization': `Bearer ${window.localStorage.getItem('token')}`
        }),
      };
      // Fetch request
      fetch(`${process.env.REACT_APP_API_BASE_URL}/documents/${document.idDocument}`, fetchOptions)
        .then(async (response) => {
          // await (new Promise((res) => {setTimeout(res, 5000); }));
          if (response.ok) return response.json();
          const result = await response.json();
          throw new Error(result ? JSON.stringify(result) : 'Não é possível arquivar o ficheiro');
        }).then(async (data) => {
          this.setState({ archiveFileLoading: false });
          this.fetchData();
          notification.open({
            duration: 30,
            message: (
              <div className='cms-notification-icon-text'>
                <i className='fa-solid fa-circle-check'></i>
                <span className='cms-notification-icon-text-span'>Documento &ldquo;{document.name}&rdquo; arquivado com sucesso</span>
              </div>
            ),
            description: (
              <div className='cms-notification-icon-text'>
                <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
                <span>O documento foi arquivado com sucesso. Esta ação não é reversível.</span>
              </div>
            ),
          });
        }).catch((error) => {
          let message = 'Não é possível arquivar o documento.';
          let extendedMessage = 'Não foi possível arquivar o documento. Por favor, tente mais tarde ou contacte o administrador da aplicação.';
          this.setState({ archiveFileLoading: false });
          notification.open({
            duration: 30,
            message: (
              <div className='cms-notification-icon-text'>
                <i className='fa-solid fa-circle-xmark'></i>
                <span className='cms-notification-icon-text-span'>{message}</span>
              </div>
            ),
            description: (
              <div className='cms-notification-icon-text'>
                <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
                <span>{extendedMessage}</span>
              </div>
            ),
          });
        });
    }
  };

  /**
   * Make table pagination accessible.
   * 
   * @param {} node - The node reference.
   */
  onRefChange = (node) => {
    this.setState({ tableRef: node });
    if (
      node &&
      node.firstChild &&
      node.firstChild.firstChild &&
      node.firstChild.firstChild.lastChild &&
      node.firstChild.firstChild.lastChild.lastChild
    ) {
      const tablePageSizeOptions = node.firstChild.firstChild.lastChild.lastChild;
      if (
        tablePageSizeOptions &&
        tablePageSizeOptions.firstChild &&
        tablePageSizeOptions.firstChild.firstChild &&
        tablePageSizeOptions.firstChild.firstChild.firstChild &&
        tablePageSizeOptions.firstChild.firstChild.firstChild.firstChild
      ) {
        const tablePageSizeOptionsInput = tablePageSizeOptions.firstChild.firstChild.firstChild.firstChild;
        console.log('tablePageSizeOptionsInput', tablePageSizeOptionsInput);
        tablePageSizeOptionsInput.ariaLabel = 'Selecionar a quantidade de vantagens por cada página da tabela';
        tablePageSizeOptionsInput.ariaAutoComplete = 'list';
        tablePageSizeOptionsInput.ariaHasPopup = 'listbox';
        tablePageSizeOptionsInput.role = 'searchbox';
      }
    }
  };

  /**
   * Render method of React component.
   * 
   * @returns the component template.
   */
  render() {
    const { languages, languagesError, languagesFetching, groups, groupsError, groupsFetching } = this.props;
    const {
      activeLanguage, addCurrentStep, addDescriptionValue, addError, addFetching, addImageUpload, 
      addImageUrl, addNewAdvantage, advantages, advantagesError, archiveFileLoading, categories,
      categoriesError, categoriesFetching, defaultLanguage, editCurrentStep, editedAdvantage, 
      editDescriptionValue, editError, editFetching, editImageUpload, editImageUrl, fileSubmissionError,
      fileSubmissionLink, fileSubmissionLoading, fileSubmissionSuccess, loading, pageWidth, selectedItem, 
      showAddAdvantageModal,showEditAdvantageModal, showFileSubmissionModal, showUploadInputError, 
      submitFileCurrentStep, uploadedFile,
    } = this.state;

    const isActive = localStorage.getItem('isActive');
    const idRole = localStorage.getItem('idRole');
    const role = localStorage.getItem('role');
    const isAdmin = isActive === 'true' && idRole === '376DDE5B-09C4-ED11-A311-00155D08E85F' && role === 'Administrator';
    const isManager = isActive === 'true' && idRole === '386DDE5B-09C4-ED11-A311-00155D08E85F' && role === 'Manager';

    if (isActive !== 'true' || !(isAdmin || isManager) || advantagesError || languagesError || (!languagesFetching && languages.length < 1)) {
      return (
        <div className='cms-menu-actions-container'>
          {/* <h1 id='cms-menu-actions-title'>Gestão de Vantagens</h1> */}
          <div id='cms-menu-actions-tabs'>
            <div id='cms-menu-actions-error'>
              <i className='fa-duotone fa-arrow-rotate-right'></i>
              <b>Ocorreu um erro...</b>
              <Button type='text' onClick={() => window.location.reload(true)}>
                Clique&nbsp;<span className='cms-menu-actions-error-btn'>aqui</span>&nbsp;para refrescar a página.
              </Button>
              Ou tente mais tarde.
            </div>
          </div>
        </div>
      );
    }

    if (loading || languagesFetching) {
      return (
        <div className='cms-menu-actions-container'>
          <div id='cms-menu-actions-error'>
            <PuffLoader color={styles.COLORS.PrimaryColor} size={100} />
            <p>A carregar...</p>
          </div>
        </div>
      );
    }

    const distinctCategories = [...new Set(advantages.map(advantage => advantage.categoryName))];
    const distinctDiscountValues = [...new Set(advantages.map((advantage) => {
      if (advantage.discountValue) return advantage.discountValue;
      return '';
    }))];
    
    const columns = [
      {
        dataIndex: 'title',
        ellipsis: true,
        key: 'title',
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
          <div style={{ padding: 8 }}>
            <Input
              aria-label='Pesquisar descrição'
              placeholder='Pesquisar descrição'
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => {
                confirm();
                this.setState({ titleSearch: selectedKeys[0] });
              }}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type='primary'
                onClick={() => {
                  confirm();
                  this.setState({ titleSearch: selectedKeys[0] });
                }}
                icon={<i className='fa-regular fa-magnifying-glass'></i>}
                size='small'
                style={{ width: 100 }}
              >Pesquisar</Button>
              <Button
                onClick={() => {
                  clearFilters();
                  this.setState({ titleSearch: '' });
                }}
                size='small'
                style={{ width: 60 }}
              >Limpar</Button>
              <Button
                type='link'
                size='small'
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({ titleSearch: selectedKeys[0] });
                }}
              >Filtrar</Button>
              <Button
                type='link'
                size='small'
                onClick={() => close()}
              >Fechar</Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered) => (
          <i className='fa-regular fa-magnifying-glass' style={{ color: filtered ? styles.COLORS.PrimaryColor : undefined }}></i>
        ),
        onFilter: (value, record) => record.title.toLowerCase().includes((value).toLowerCase()),
        render: (_, record) => {
          return (
            <>
              <Button
                onClick={() => {
                  const newSelectedItem = !selectedItem || (selectedItem && selectedItem.key !== record.key) ? record : null;
                  this.setState({ selectedItem: newSelectedItem });
                  // if (newSelectedItem) this.handleEditAdvantageModal();
                }}
                size='small'
                title={record.title}
                type='text'
              >{record.title}</Button>
              {!record.isActive && <span> </span>}
              {!record.isActive && <Tag color={styles.COLORS.QuaternaryTextColor}>Desativada</Tag>}
            </>
          );
        },
        sorter: (a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
        title: 'Nome',
        width: pageWidth > 1400 ? 'auto' : '350px',
      },
      {
        dataIndex: 'categoryName',
        filterIcon: <i className='fa-duotone fa-filters'></i>,
        filters: distinctCategories.map((category) => { return { text: category, value: category }; }),
        key: 'categoryName',
        onFilter: (value, record) => record.categoryName.indexOf(value) === 0,
        render: (_, record) => {
          const style = { color: styles.COLORS.getTextColor(record.categoryColor) };
          return <Tag color={record.categoryColor} style={style}>{record.categoryName}</Tag>;
        },
        sorter: (a, b) => a.categoryName.toLowerCase().localeCompare(b.categoryName.toLowerCase()),
        title: 'Categoria',
        width: '160px',
      },
      {
        dataIndex: 'discountValue',
        ellipsis: true,
        filterIcon: <i className='fa-duotone fa-filters'></i>,
        filters: distinctDiscountValues
          .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
          .map((discount) => { return { text: discount, value: discount }; }),
        key: 'discountValue',
        onFilter: (value, record) => {
          if (value === '') return record.discountValue == null;
          return record.discountValue === value;
        },
        render: (_, record) => {
          const color = record.isActive ? styles.COLORS.ErrorActiveColor : styles.COLORS.QuaternaryTextColor;
          if (record.discountValue) return <Tag color={color}>{record.discountValue}</Tag>;
          return '';
        },
        title: 'Desconto',
        width: '100px',
      },
      {
        align: 'center',
        dataIndex: 'isHighlighted',
        filterIcon: <i className='fa-duotone fa-filters'></i>,
        filters: [{ text: 'Destacada', value: true }, { text: 'Não destacada', value: false }],
        key: 'isHighlighted',
        onFilter: (value, record) => record.isHighlighted === value,
        render: (value, record) => {
          const style = record.isActive ? undefined : { color : styles.COLORS.QuaternaryTextColor};
          return <i className={value ? 'fa-solid fa-check' : 'fa-solid fa-xmark'} style={style}></i>;
        },
        title: 'Em destaque',
        width: '140px',
      },
      {
        align: 'center',
        dataIndex: 'isInDistrict',
        filterIcon: <i className='fa-duotone fa-filters'></i>,
        filters: [{ text: 'No distrito de Setúbal', value: true }, { text: 'Fora do distrito de Setúbal', value: false }],
        key: 'isInDistrict',
        onFilter: (value, record) => record.isInDistrict === value,
        render: (value, record) => {
          const style = record.isActive ? undefined : { color : styles.COLORS.QuaternaryTextColor};
          return <i className={value ? 'fa-solid fa-check' : 'fa-solid fa-xmark'} style={style}></i>;
        },
        title: 'No distrito',
        width: '125px',
      },
      {
        align: 'center',
        dataIndex: 'hasManyLocations',
        filterIcon: <i className='fa-duotone fa-filters'></i>,
        filters: [{ text: 'Com cobertura nacional', value: true }, { text: 'Sem cobertura nacional', value: false }],
        key: 'hasManyLocations',
        onFilter: (value, record) => record.hasManyLocations === value,
        render: (value, record) => {
          const style = record.isActive ? undefined : { color : styles.COLORS.QuaternaryTextColor};
          return <i className={value ? 'fa-solid fa-check' : 'fa-solid fa-xmark'} style={style}></i>;
        },
        title: 'Cobertura Nacional',
        width: '180px',
      },
      {
        align: 'center',
        dataIndex: 'activationDate',
        key: 'activationDate',
        render: (value, record) => {
          const style = {};
          if (!record.isActive) style.color = styles.COLORS.QuaternaryTextColor;
          return <span style={style}>{value ? value.format('DD/MM/YYYY HH:mm') : '-'}</span>;
        },
        sorter: (a, b) => {
          const dateA = a.activationDate;
          const dateB = b.activationDate;
          return dateA ? (dateB ? (dateA.isAfter(dateB) ? 1 : dateA.isSame(dateB) ? 0 : -1) : 1) : -1;
        },
        title: 'Data de início',
        width: '155px',
      },
      {
        align: 'center',
        dataIndex: 'deactivationDate',
        key: 'deactivationDate',
        render: (value, record) => {
          const style = {};
          if (!record.isActive) style.color = styles.COLORS.QuaternaryTextColor;
          return <span style={style}>{value ? value.format('DD/MM/YYYY HH:mm') : '-'}</span>;
        },
        sorter: (a, b) => {
          const dateA = a.deactivationDate;
          const dateB = b.deactivationDate;
          return dateA ? (dateB ? (dateA.isAfter(dateB) ? 1 : dateA.isSame(dateB) ? 0 : -1) : 1) : -1;
        },
        title: 'Data de fim',
        width: '155px',
      },
    ];

    return (
      <div className='cms-menu-actions-container'>
        {/* Actions */}
        <div className='cms-menu-actions-btns'>
          <Button
            type='primary'
            icon={<i className='fa-solid fa-plus'></i>}
            onClick={this.handleAdvantageModal}
          >Nova Vantagem</Button>
          <Button
            type='default'
            disabled={selectedItem ? false : true}
            icon={<i className='fa-duotone fa-pen-to-square'></i>}
            onClick={this.handleEditAdvantageModal}
          >Editar</Button>
          <Button
            type='default'
            disabled={selectedItem ? false : true}
            icon={<i className='fa-duotone fa-folder-arrow-up'></i>}
            onClick={this.handleFileSubmissionModal}
          >Carregar ficheiro</Button>
        </div>

        {/* Table */}
        <div id='cms-menu-actions-tabs'>
          <Tabs
            activeKey={activeLanguage}
            defaultActiveKey={defaultLanguage}
            onChange={(key) => this.setState({ activeLanguage: key })}
            items={languages.map((language) => {
              return {
                languageData: language,
                key: language.idLanguage,
                label: language.name,
                children: (
                  <div className='cms-menu-actions-tabs-item'>
                    <Table
                      bordered
                      caption='Lista de vantagens para gerir'
                      columns={columns}
                      dataSource={advantages}
                      loading={loading}
                      pagination={{ size: 'default', position: ['bottomLeft'] }}
                      rowKey={(record) => record.key}
                      rowSelection={{
                        onChange: (selectedRowKeys, selectedRows) => {
                          this.setState({ selectedItem: selectedRows.length > 0 ? selectedRows[selectedRows.length - 1] : null });
                        },
                        selectedRowKeys: selectedItem ? [selectedItem.key] : [],
                        type: 'checkbox',
                        hideSelectAll: true,
                        getCheckboxProps: (record) => {
                          return {
                            'aria-label': `Selecionar a vantagem: ${record.title}`
                          };
                        }
                      }}
                      scroll={{ x: '100%' }}
                      size='small'
                      ref={this.onRefChange}
                    />
                  </div>
                )
              };
            })}
          />
        </div>

        {/* Add new advantage */}
        <Drawer
          afterOpenChange={() => {
            this.fetchCategories();
            this.props.getGroups(window.localStorage.getItem('token'));
          }}
          className='cms-menu-add-drawer'
          destroyOnClose
          extra={
            <div className='cms-menu-add-drawer-header'>
              {/* Title */}
              <div>Adicionar uma nova vantagem</div>
              {/* Close btn */}
              <Button 
                id='hamburger-menu-close'
                icon={<i className='fa-light fa-xmark'></i>}
                onClick={this.handleAdvantageModal}
                type='text'
              />
            </div>
          }
          open={showAddAdvantageModal}
          onClose={this.handleAdvantageModal}
          placement='right'
          size='large'
          title='Adicionar uma nova vantagem'
          width={window.innerWidth < 736 ? window.innerWidth : 736}
        >
          {/* Form steps */}
          <Steps
            current={addCurrentStep}
            initial={0}
            items={[
              { icon: <i className='fa-duotone fa-sliders'></i>, title: 'Idioma, categoria, título, grupo' },
              { icon: <i className='fa-duotone fa-image'></i>, title: 'Imagem da vantagem' },
              { icon: <i className='fa-duotone fa-text-size'></i>, title: 'Descrição extensa' },
              { icon: <i className='fa-duotone fa-memo-circle-info'></i>, title: 'Descrição, desconto, localização' },
              { icon: <i className='fa-duotone fa-eye'></i>, title: 'Pré-visualização' },
            ]}
            labelPlacement='vertical'
            size='small'
          />

          {/* Form */}
          <Form
            autoComplete='on'
            initialValues={{ remember: true }}
            layout='vertical'
            name='Adicionar vantagem'
            onFinish={(values) => {
              const newObject = addNewAdvantage ? addNewAdvantage : {};
              switch(addCurrentStep) {
              case 0:
                newObject.idCategory = values.idCategory;
                newObject.category = categories.find((category) => category.idCategory === values.idCategory);
                newObject.idGroup = values.idGroup;
                newObject.group = values.idGroup ? groups.find((group) => group.idGroup === values.idGroup) : undefined;
                newObject.title = values.title;
                newObject.activationDate = values.activationDate.add(1, 'h').format('YYYY-MM-DD HH:mm:ss');
                this.setState({ addNewAdvantage: newObject, addCurrentStep: addCurrentStep + 1 });
                break;
              case 1:
                if (values.imageUrl) newObject.image = values.imageUrl;
                if (values.imageUploaded && values.imageUploaded.length > 0) newObject.image = values.imageUploaded[0];
                this.setState({ addNewAdvantage: newObject, addCurrentStep: addCurrentStep + 1 });
                break;
              case 2:
                newObject.description = values.description;
                this.setState({ addNewAdvantage: newObject, addCurrentStep: addCurrentStep + 1 });
                break;
              case 3:
                newObject.searchFields = values.searchFields;
                newObject.summary = values.summary;
                newObject.discountValue = values.discountValue;
                newObject.location = values.location;
                newObject.isInDistrict = values.isInDistrict;
                newObject.hasManyLocations = values.hasManyLocations;
                newObject.isHighlighted = values.isHighlighted;
                this.setState({ addNewAdvantage: newObject, addCurrentStep: addCurrentStep + 1 });
                break;
              case 4:
                this.addAdvantage(newObject);
                break;
              default:
                break;
              }
            }}
          >
            {addCurrentStep === 0 ?
              <div>
                {/* Language */}
                <Form.Item
                  hasFeedback
                  label='Idioma:'
                  name='idLanguage'
                  rules={[{ required: true, message: 'Por favor, selecione um idioma.' }]}
                  initialValue={activeLanguage}
                >
                  <Select
                    placeholder='Selecione um idioma'
                    allowClear={false}
                    aria-autocomplete="list"
                    aria-haspopup='listbox'
                    aria-label='Selecione um idioma'
                    role="searchbox"
                    // disabled={true}
                  >
                    {languages.map((language) => {
                      return (
                        <Option key={language.idLanguage} value={language.idLanguage}>{language.name} ({language.code})</Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                {/* Category */}
                <Form.Item
                  hasFeedback
                  label='Categoria:'
                  name='idCategory'
                  rules={[{ required: true, message: 'Por favor, selecione uma categoria.' }]}
                >
                  <Select
                    allowClear={false}
                    disabled={categoriesError}
                    filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
                    loading={categoriesFetching}
                    placeholder='Selecione uma categoria'
                    showSearch
                    aria-autocomplete="list"
                    aria-haspopup='listbox'
                    aria-label='Selecione uma categoria'
                    role="searchbox"
                  >
                    {categories.map((category) => {
                      return (
                        <Option key={category.name} value={category.idCategory}>
                          <div className='form-item-category-select-option'>
                            <div className='form-item-category-color' style={{ backgroundColor: category.color }}></div>
                            <div>{category.name}</div>
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                {/* Group */}
                <Form.Item
                  hasFeedback
                  label='Grupo:'
                  name='idGroup'
                  tooltip='Associa um grupo de empresas à vantagem. Um exemplo é o caso do grupo Accor.'
                >
                  <Select
                    allowClear={true}
                    aria-autocomplete="list"
                    aria-haspopup='listbox'
                    aria-label='Selecione um grupo (opcional)'
                    role="searchbox"
                    disabled={groupsError}
                    loading={groupsFetching}
                    placeholder='Selecione um grupo (opcional)'
                    filterOption={(input, option) => {
                      const group = groups.find((group) => group.idGroup === option.key);
                      return group && group.name.toLowerCase().includes(input.toLowerCase());
                    }}
                    showSearch
                  >
                    {groups.map((group) => <Option key={group.idGroup} value={group.idGroup}>{group.name}</Option>)}
                  </Select>
                </Form.Item>
                {/* Title */}
                <Form.Item
                  hasFeedback
                  label='Título:'
                  name='title'
                  rules={[
                    { required: true, message: 'Por favor, introduza um título.' },
                    { max: 200, message: 'Por favor, introduza um título com um máximo de 200 caracteres.' },
                    { min: 3, message: 'Por favor, introduza um título com mais de 3 caracteres.' }
                  ]}
                >
                  <Input
                    allowClear
                    maxLength={200}
                    minLength={3}
                    placeholder='Introduzir o título da vantagem'
                    type='text'
                  />
                </Form.Item>
                {/* Activation Date */}
                <Form.Item
                  hasFeedback
                  label='Data de Início:'
                  name='activationDate'
                  required
                  rules={[{ type: 'object', required: true, message: 'Por favor, selectione uma data de início' }]}
                >
                  <DatePicker
                    allowClear={false}
                    format="DD-MM-YYYY HH:mm:ss"
                    showTime
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </div>
              : addCurrentStep === 1 ?
                <div>
                  {/* Image */}
                  <Form.Item label='Imagem:' required>
                    {/* Image URL */}
                    <Form.Item name='imageUrl' rules={[{ type: 'url', message: 'Por favor, introduza um caminho válido.' },]}>
                      <Input
                        allowClear
                        disabled={addImageUpload ? true : false}
                        name='imageUrl'
                        onChange={(e) => this.setState({ addImageUrl: e.target.value })}
                        placeholder='Introduzir o caminho da imagem da vantagem'
                        type='url'
                        value={addImageUrl}
                      />
                    </Form.Item>

                    {/* Divider */}
                    <Divider orientation='center' plain type='horizontal'>ou</Divider>

                    {/* Image Upload */}
                    <Form.Item
                      getValueFromEvent={this.handleAddFileList}
                      name='imageUploaded'
                      noStyle
                      rules={[{ required: !addImageUrl, message: 'Por favor, introduza uma imagem.' }]}
                      valuePropName='fileList'
                    >
                      <Upload.Dragger
                        accept='.png, .jpg, .jpeg'
                        beforeUpload={(file) => {
                          this.setState({ addImageUpload: file });
                          return false;
                        }}
                        disabled={addImageUrl ? true : false}
                        listType='picture'
                        maxCount={1}
                        name='imageUploaded'
                        onChange={({ file, fileList }) => this.setState({ addImageUpload: fileList.length < 1 ? null : file })}
                      >
                        <p className='ant-upload-drag-icon'><i className='fa-duotone fa-cloud-arrow-up'></i></p>
                        <p className="ant-upload-text">Clique ou arraste um ficheiro para esta área para carregá-lo</p>
                        <p className="ant-upload-hint">Suporta apenas o carregamento de um único ficheiro do tipo .jpg, .jpeg ou .png</p>
                      </Upload.Dragger>
                    </Form.Item>
                  </Form.Item>
                </div>
                : addCurrentStep === 2 ? 
                  <div>
                    {/* Description */}
                    <Form.Item
                      label='Descrição extensa:'
                      name='description'
                      required
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!addDescriptionValue || addDescriptionValue.trim() < 1) {
                              return Promise.reject(new Error('Por favor, introduza uma descrição da vantagem.'));
                            }
                            return Promise.resolve();
                          }
                        }),
                      ]}
                    >
                      <ReactQuill
                        onChange={(value) => this.setState({ addDescriptionValue: value })}
                        placeholder='Introduzir uma descrição completa da vantagem...'
                        theme='snow'
                        value={addDescriptionValue}
                        modules={{
                          toolbar: [
                            [{ 'header': [1, 2, 3, 4, 5, 6, false] },
                              'bold', 'italic', 'underline', 'strike',
                              {
                                'color': [
                                  styles.COLORS.TextColor,
                                  styles.COLORS.SecondaryTextColor,
                                  styles.COLORS.TertiaryTextColor,
                                  styles.COLORS.QuaternaryTextColor,
                                  styles.COLORS.FillColor,
                                  styles.COLORS.SecondaryFillColor,
                                  styles.COLORS.BgContainerColor,
                                  styles.COLORS.PrimaryActiveColor,
                                  styles.COLORS.PrimaryColor,
                                  styles.COLORS.PrimaryHoverColor,
                                  styles.COLORS.PrimaryBorderHoverColor,
                                  styles.COLORS.PrimaryBorderColor,
                                  styles.COLORS.PrimaryBgHoverColor,
                                  styles.COLORS.PrimaryBgColor,
                                  styles.COLORS.SecondaryActiveColor,
                                  styles.COLORS.SecondaryColor,
                                  styles.COLORS.SecondaryHoverColor,
                                  styles.COLORS.SecondaryBorderHoverColor,
                                  styles.COLORS.SecondaryBgHoverColor,
                                  styles.COLORS.SecondaryBgColor,
                                  styles.COLORS.SecondaryBorderColor,
                                  styles.COLORS.SuccessActiveColor,
                                  styles.COLORS.SuccessColor,
                                  styles.COLORS.SuccessTextHoverColor,
                                  styles.COLORS.SuccessBorderHoverColor,
                                  styles.COLORS.SuccessBorderColor,
                                  styles.COLORS.SuccessBgHoverColor,
                                  styles.COLORS.SuccessBgColor,
                                  styles.COLORS.WarningActiveColor,
                                  styles.COLORS.WarningColor,
                                  styles.COLORS.WarningTextHoverColor,
                                  styles.COLORS.WarningBorderHoverColor,
                                  styles.COLORS.WarningBorderColor,
                                  styles.COLORS.WarningBgHoverColor,
                                  styles.COLORS.WarningBgColor,
                                  styles.COLORS.ErrorActiveColor,
                                  styles.COLORS.ErrorColor,
                                  styles.COLORS.ErrorTextHoverColor,
                                  styles.COLORS.ErrorBorderHoverColor,
                                  styles.COLORS.ErrorBorderColor,
                                  styles.COLORS.ErrorBgHoverColor,
                                  styles.COLORS.ErrorBgColor,
                                ]
                              },
                              {
                                'background': [
                                  styles.COLORS.TextColor,
                                  styles.COLORS.SecondaryTextColor,
                                  styles.COLORS.TertiaryTextColor,
                                  styles.COLORS.QuaternaryTextColor,
                                  styles.COLORS.FillColor,
                                  styles.COLORS.SecondaryFillColor,
                                  styles.COLORS.BgContainerColor,
                                  styles.COLORS.PrimaryActiveColor,
                                  styles.COLORS.PrimaryColor,
                                  styles.COLORS.PrimaryHoverColor,
                                  styles.COLORS.PrimaryBorderHoverColor,
                                  styles.COLORS.PrimaryBorderColor,
                                  styles.COLORS.PrimaryBgHoverColor,
                                  styles.COLORS.PrimaryBgColor,
                                  styles.COLORS.SecondaryActiveColor,
                                  styles.COLORS.SecondaryColor,
                                  styles.COLORS.SecondaryHoverColor,
                                  styles.COLORS.SecondaryBorderHoverColor,
                                  styles.COLORS.SecondaryBgHoverColor,
                                  styles.COLORS.SecondaryBgColor,
                                  styles.COLORS.SecondaryBorderColor,
                                  styles.COLORS.SuccessActiveColor,
                                  styles.COLORS.SuccessColor,
                                  styles.COLORS.SuccessTextHoverColor,
                                  styles.COLORS.SuccessBorderHoverColor,
                                  styles.COLORS.SuccessBorderColor,
                                  styles.COLORS.SuccessBgHoverColor,
                                  styles.COLORS.SuccessBgColor,
                                  styles.COLORS.WarningActiveColor,
                                  styles.COLORS.WarningColor,
                                  styles.COLORS.WarningTextHoverColor,
                                  styles.COLORS.WarningBorderHoverColor,
                                  styles.COLORS.WarningBorderColor,
                                  styles.COLORS.WarningBgHoverColor,
                                  styles.COLORS.WarningBgColor,
                                  styles.COLORS.ErrorActiveColor,
                                  styles.COLORS.ErrorColor,
                                  styles.COLORS.ErrorTextHoverColor,
                                  styles.COLORS.ErrorBorderHoverColor,
                                  styles.COLORS.ErrorBorderColor,
                                  styles.COLORS.ErrorBgHoverColor,
                                  styles.COLORS.ErrorBgColor,
                                ]
                              },
                              { 'script': 'sub' },
                              { 'script': 'super' },
                              { 'align': [] },
                              { 'indent': '-1' },
                              { 'indent': '+1' },
                              { 'list': 'ordered' },
                              { 'list': 'bullet' },
                              'link', 'image', 'video', 'blockquote', 'code', 'code-block', 'formula', 'clean'
                            ],
                          ]
                        }}
                      />
                    </Form.Item>
                  </div>
                  : addCurrentStep === 3 ? 
                    <div>
                      {/* Search Fields */}
                      <Form.Item
                        hasFeedback
                        label='Campos de Pesquisa:'
                        name='searchFields'
                        tooltip='Associa etiquetas/palavras-chave à vantagem. Estão ocultas para o utilizador, mas permitem facultar abranger a pesquisa das vantagens.'
                      >
                        <Input
                          allowClear
                          placeholder='Introduzir os campos de pesquisa. Ex.: carro, lavagem, veículo, automóvel (opcional)'
                          type='text'
                        />
                      </Form.Item>
                      {/* Summary */}
                      <Form.Item
                        hasFeedback
                        label='Descrição breve:'
                        name='summary'
                        rules={[
                          { max: 200, message: 'Por favor, introduza uma descrição com um máximo de 200 caracteres.' },
                          { min: 3, message: 'Por favor, introduza uma descrição com mais de 3 caracteres.' }
                        ]}
                        tooltip='Associa uma breve descrição à vantagem. Esta é apresentada quando uma vantagem está em destaque na página principal.'
                      >
                        <TextArea
                          allowClear
                          autoSize
                          maxLength={200}
                          minLength={3}
                          placeholder='Introduzir uma descrição simples para a vantagem. (opcional)'
                          rows={4}
                          showCount
                        />
                      </Form.Item>
                      {/* Discount value */}
                      <Form.Item
                        hasFeedback
                        label='Desconto:'
                        name='discountValue'
                        tooltip='Associa um valor de desconto à vantagem. Pode ser por exemplo, um valor "Até 25%" ou "10%"'
                      >
                        <Input allowClear placeholder='Introduzir o desconto da vantagem. (opcional)' type='text' />
                      </Form.Item>
                      {/* Location */}
                      <Form.Item
                        hasFeedback
                        label='Localização:'
                        name='location'
                        tooltip='Associa uma localização à vantagem. Pode ser, por exemplo, "Setúbal" ou "Av. Liberdade Nº2"'
                      >
                        <Input
                          allowClear
                          placeholder='Introduzir a localização da vantagem. (opcional)'
                          type='text' />
                      </Form.Item>
                      {/* In district */}
                      <Form.Item
                        hasFeedback
                        initialValue={true}
                        label='Localiza-se no distrito de Setúbal:'
                        name='isInDistrict'
                        tooltip='Permite associar a vantagem ao distrito de Setúbal. Necessário para fins de estatística.'
                        valuePropName='checked'
                      >
                        <Switch checkedChildren='Sim' unCheckedChildren='Não' />
                      </Form.Item>
                      {/* Many locations */}
                      <Form.Item
                        hasFeedback
                        initialValue={false}
                        label='Tem várias localizações em Portugal Continental e Ilhas:'
                        name='hasManyLocations'
                        tooltip='Permite associar a vantagem a várias localizações. Necessário para fins de estatística.'
                        valuePropName='checked'
                      >
                        <Switch checkedChildren='Sim' unCheckedChildren='Não' />
                      </Form.Item>
                      {/* Highlighted */}
                      <Form.Item
                        hasFeedback
                        initialValue={false}
                        label='Destacar:'
                        name='isHighlighted'
                        tooltip='Permite destacar a vantagem na página inicial'
                        valuePropName='checked'
                      >
                        <Switch checkedChildren='Sim' unCheckedChildren='Não' />
                      </Form.Item>
                    </div>
                    :
                    <div>
                      <div className='cms-add-advantage-preview'>
                        <div className='advantage-wrapper'>
                          {addNewAdvantage && addNewAdvantage.discountValue ?
                            <Badge.Ribbon text={addNewAdvantage.discountValue} color={styles.COLORS.ErrorActiveColor}>
                              <div className='advantage-header-wrapper'>
                                {addNewAdvantage.image &&
                                  <div
                                    className='advantage-img'
                                    style={{ backgroundImage: addImageUrl ? `url(${addNewAdvantage.image})` : `url(${addNewAdvantage.image.thumbUrl})` }}
                                  ></div>
                                }
                                <div className='advantage-header'>
                                  { addNewAdvantage.title && <h1 className='advantage-title'>{addNewAdvantage.title}</h1> }
                                  { addNewAdvantage.category &&
                                    <Tag
                                      className='advantage-category'
                                      color={addNewAdvantage.category.color}
                                      style={{ color: styles.COLORS.getTextColor(addNewAdvantage.category.color) }}
                                    >{addNewAdvantage.category.name}</Tag>
                                  }
                                  {addNewAdvantage.group && addNewAdvantage.group.name &&
                                    <Tag className='advantage-group' color={styles.COLORS.SecondaryFillColor}>{addNewAdvantage.group.name}</Tag>
                                  }
                                </div>
                              </div>
                            </Badge.Ribbon>
                            :
                            <div className='advantage-header-wrapper'>
                              {addNewAdvantage.image &&
                                  <div
                                    className='advantage-img'
                                    style={{ backgroundImage: addImageUrl ? `url(${addNewAdvantage.image})` : `url(${addNewAdvantage.image.thumbUrl})` }}
                                  ></div>
                              }
                              <div className='advantage-header'>
                                { addNewAdvantage.title && <h1 className='advantage-title'>{addNewAdvantage.title}</h1> }
                                { addNewAdvantage.category &&
                                    <Tag
                                      className='advantage-category'
                                      color={addNewAdvantage.category.color}
                                      style={{ color: styles.COLORS.getTextColor(addNewAdvantage.category.color) }}
                                    >{addNewAdvantage.category.name}</Tag>
                                }
                                {addNewAdvantage.group && addNewAdvantage.group.name &&
                                    <Tag className='advantage-group' color={styles.COLORS.SecondaryFillColor}>{addNewAdvantage.group.name}</Tag>
                                }
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                        
                      <Divider orientation='center' type='horizontal' plain>Detalhe</Divider>

                      <div className='cms-add-advantage-detail-preview'>
                        {/* Title */}
                        <div className='advantage-content-title-wrap'>
                          {/* Title */}
                          { addNewAdvantage.title && <h4>{addNewAdvantage.title}</h4>}
                          <div>
                            {addNewAdvantage.category &&
                              <Tag
                                color={addNewAdvantage.category.color}
                                style={{ color: styles.COLORS.getTextColor(addNewAdvantage.category.color) }}
                              >{addNewAdvantage.category.name}</Tag>
                            }
                            {/* Discount value */}
                            {addNewAdvantage.discountValue && <Tag color={styles.COLORS.ErrorActiveColor}>{addNewAdvantage.discountValue}</Tag> }
                          </div>
                        </div>
                        {/* Content */}
                        <div className='advantage-content'>
                          {/* Validade */}
                          {addNewAdvantage.activationDate && <span className='advantage-content-validity'>Válido a partir de {dayjs(addNewAdvantage.activationDate).locale('en').format('DD/MM/YYYY')}</span>}
                          {/* Description */}
                          {addNewAdvantage.description && <div dangerouslySetInnerHTML={{ __html: addNewAdvantage.description }}></div>}
                          {/* Localization */}
                          {addNewAdvantage.localization && <span className='advantage-content-title'>Localização:</span>}
                          {addNewAdvantage.localization && <span>{addNewAdvantage.localization}</span>}
                        </div>
                      </div>
                    </div>
            }
            <br></br>
            {/* Buttons */}
            <Form.Item style={{ width: '100%' }}>
              <div className='cms-add-modal-btns'>
                <Button type='default' onClick={this.handleAdvantageModal}>Cancelar</Button>
                {addCurrentStep > 0 && <Button type='default' onClick={this.handleAddBackStep}>Voltar</Button>}
                {addCurrentStep === 4 ?
                  <Button
                    type={addError ? 'default' : 'primary'}
                    danger={addError ? true : false}
                    htmlType='submit'
                    disabled={addFetching}>{addError ? 'Tentar novamente' : addFetching ? 'A carregar...' : 'Criar'}
                  </Button>
                  :
                  <Button type='primary' htmlType='submit'>Seguinte</Button>
                }
              </div>
            </Form.Item>
          </Form>
        </Drawer>

        {/* Edit advantage */}
        {selectedItem &&
          <Drawer
            afterOpenChange={() => {
              this.fetchCategories();
              this.props.getGroups(window.localStorage.getItem('token'));
            }}
            className='cms-menu-add-drawer'
            destroyOnClose
            extra={
              <div className='cms-menu-add-drawer-header'>
                {/* Title */}
                <div>Editar vantagem</div>
                {/* Close btn */}
                <Button 
                  id='hamburger-menu-close'
                  icon={<i className='fa-light fa-xmark'></i>}
                  onClick={this.handleEditAdvantageModal}
                  type='text'
                />
              </div>
            }
            open={showEditAdvantageModal}
            onClose={this.handleEditAdvantageModal}
            placement='right'
            size='large'
            title='Editar vantagem'
            width={window.innerWidth < 736 ? window.innerWidth : 736}
          >
            {/* Form steps */}
            <Steps
              current={editCurrentStep}
              initial={0}
              items={[
                { icon: <i className='fa-duotone fa-sliders'></i>, title: 'Idioma, categoria, título, grupo' },
                { icon: <i className='fa-duotone fa-image'></i>, title: 'Imagem da vantagem' },
                { icon: <i className='fa-duotone fa-text-size'></i>, title: 'Descrição extensa' },
                { icon: <i className='fa-duotone fa-memo-circle-info'></i>, title: 'Descrição, desconto, localização' },
                { icon: <i className='fa-duotone fa-eye'></i>, title: 'Pré-visualização' },
              ]}
              labelPlacement='vertical'
              size='small'
            />

            {/* Form */}
            <Form
              autoComplete='on'
              initialValues={{ remember: true }}
              layout='vertical'
              name='Editar vantagem'
              onFinish={(values) => {
                const newObject = editedAdvantage ? editedAdvantage : {};
                switch(editCurrentStep) {
                case 0:
                  newObject.idCategory = values.idCategory;
                  newObject.category = categories.find((category) => category.idCategory === values.idCategory);
                  newObject.idGroup = selectedItem && !values.idGroup ? null : values.idGroup;
                  newObject.group = values.idGroup ? groups.find((group) => group.idGroup === values.idGroup) : undefined;
                  newObject.title = values.title;
                  newObject.activationDate = values.activationDate.add(1, 'h').format('YYYY-MM-DD HH:mm:ss');
                  this.setState({ editedAdvantage: newObject, editCurrentStep: editCurrentStep + 1 });
                  break;
                case 1:
                  if (values.imageUrl) newObject.image = values.imageUrl.replace(process.env.REACT_APP_API_IMAGES_BASE_URL, '') === selectedItem.image ? selectedItem.image : values.imageUrl;
                  if (values.imageUploaded && values.imageUploaded.length > 0) newObject.image = values.imageUploaded[0];
                  this.setState({ editedAdvantage: newObject, editCurrentStep: editCurrentStep + 1 });
                  break;
                case 2:
                  newObject.description = values.description;
                  this.setState({ editedAdvantage: newObject, editCurrentStep: editCurrentStep + 1 });
                  break;
                case 3:
                  newObject.searchFields = selectedItem && !values.searchFields ? null : values.searchFields;
                  newObject.summary = selectedItem && !values.summary ? null : values.summary;
                  newObject.discountValue = selectedItem && !values.discountValue ? null : values.discountValue;
                  newObject.location = selectedItem && !values.location ? null : values.location;
                  newObject.isInDistrict = values.isInDistrict;
                  newObject.hasManyLocations = values.hasManyLocations;
                  newObject.isHighlighted = values.isHighlighted;
                  this.setState({ editedAdvantage: newObject, editCurrentStep: editCurrentStep + 1 });
                  break;
                case 4:
                  this.editAdvantage(newObject);
                  break;
                default:
                  break;
                }
              }}
            >
              {editCurrentStep === 0 ?
                <div>
                  {/* Language */}
                  <Form.Item
                    hasFeedback
                    label='Idioma:'
                    name='idLanguage'
                    rules={[{ required: true, message: 'Por favor, selecione um idioma.' }]}
                    initialValue={activeLanguage}
                  >
                    <Select
                      placeholder='Selecione um idioma'
                      allowClear={false}
                      disabled={true}
                    >
                      {languages.map((language) => {
                        return (
                          <Option key={language.idLanguage} value={language.idLanguage}>{language.name} ({language.code})</Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  {/* Category */}
                  <Form.Item
                    hasFeedback
                    initialValue={editedAdvantage && editedAdvantage.idCategory ? editedAdvantage.idCategory : selectedItem.idCategory}
                    label='Categoria:'
                    name='idCategory'
                    rules={[{ required: true, message: 'Por favor, selecione uma categoria.' }]}
                  >
                    <Select
                      allowClear={false}
                      disabled={categoriesError}
                      filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
                      loading={categoriesFetching}
                      placeholder='Selecione uma categoria'
                      showSearch
                    >
                      {categories.map((category) => {
                        return (
                          <Option key={category.name} value={category.idCategory}>
                            <div className='form-item-category-select-option'>
                              <div className='form-item-category-color' style={{ backgroundColor: category.color }}></div>
                              <div>{category.name}</div>
                            </div>
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  {/* Group */}
                  <Form.Item
                    hasFeedback
                    initialValue={editedAdvantage && editedAdvantage.idGroup ? editedAdvantage.idGroup : selectedItem.idGroup}
                    label='Grupo:'
                    name='idGroup'
                    tooltip='Associa um grupo de empresas à vantagem. Um exemplo é o caso do grupo Accor.'
                  >
                    <Select
                      allowClear={true}
                      disabled={groupsError}
                      filterOption={(input, option) => {
                        const group = groups.find((group) => group.idGroup === option.key);
                        return group && group.name.toLowerCase().includes(input.toLowerCase());
                      }}
                      loading={groupsFetching}
                      placeholder='Selecione um grupo (opcional)'
                      showSearch
                    >
                      {groups.map((group) => <Option key={group.idGroup} value={group.idGroup}>{group.name}</Option>)}
                    </Select>
                  </Form.Item>
                  {/* Title */}
                  <Form.Item
                    hasFeedback
                    initialValue={editedAdvantage && editedAdvantage.title ? editedAdvantage.title : selectedItem.title}
                    label='Título:'
                    name='title'
                    rules={[
                      { required: true, message: 'Por favor, introduza um título.' },
                      { max: 200, message: 'Por favor, introduza um título com um máximo de 200 caracteres.' },
                      { min: 3, message: 'Por favor, introduza um título com mais de 3 caracteres.' }
                    ]}
                  >
                    <Input
                      allowClear
                      maxLength={200}
                      minLength={3}
                      placeholder='Introduzir o título da vantagem'
                      type='text'
                    />
                  </Form.Item>
                  {/* Activation Date */}
                  <Form.Item
                    hasFeedback
                    initialValue={selectedItem.activationDate}
                    label='Data de Início:'
                    name='activationDate'
                    required
                    rules={[{ type: 'object', required: true, message: 'Por favor, selectione uma data de início' }]}
                  >
                    <DatePicker
                      allowClear={false}
                      format="DD-MM-YYYY HH:mm:ss"
                      showTime
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </div>
                : editCurrentStep === 1 ?
                  <div>
                    {/* Image */}
                    <Form.Item label='Imagem:' required>
                      {/* Image URL */}
                      <Form.Item
                        initialValue={editImageUrl ? editImageUrl : selectedItem.image.startsWith('/') ? `${process.env.REACT_APP_API_IMAGES_BASE_URL}${selectedItem.image}` : selectedItem.image}
                        name='imageUrl'
                        rules={[{ type: 'url', message: 'Por favor, introduza um caminho válido.' },]}
                      >
                        <Input
                          allowClear
                          disabled={editImageUpload ? true : false}
                          name='imageUrl'
                          onChange={(e) => this.setState({ editImageUrl: e.target.value })}
                          placeholder='Introduzir o caminho da imagem da vantagem'
                          type='url'
                          value={editImageUrl}
                        />
                      </Form.Item>

                      {/* Divider */}
                      <Divider orientation='center' plain type='horizontal'>ou</Divider>

                      {/* Image Upload */}
                      <Form.Item
                        getValueFromEvent={this.handleAddFileList}
                        name='imageUploaded'
                        noStyle
                        valuePropName='fileList'
                      >
                        <Upload.Dragger
                          accept='.png, .jpg, .jpeg'
                          beforeUpload={(file) => {
                            this.setState({ editImageUpload: file });
                            return false;
                          }}
                          disabled={editImageUrl ? true : false}
                          listType='picture'
                          maxCount={1}
                          name='imageUploaded'
                          onChange={({ file, fileList }) => this.setState({ editImageUpload: fileList.length < 1 ? null : file })}
                        >
                          <p className='ant-upload-drag-icon'><i className='fa-duotone fa-cloud-arrow-up'></i></p>
                          <p className="ant-upload-text">Clique ou arraste um ficheiro para esta área para carregá-lo</p>
                          <p className="ant-upload-hint">Suporta apenas o carregamento de um único ficheiro do tipo .jpg, .jpeg ou .png</p>
                        </Upload.Dragger>
                      </Form.Item>
                    </Form.Item>
                  </div>
                  : editCurrentStep === 2 ? 
                    <div>
                      {/* Description */}
                      <Form.Item
                        hasFeedback
                        initialValue={editedAdvantage && editedAdvantage.description ? editedAdvantage.description : selectedItem.description}
                        label='Descrição extensa:'
                        name='description'
                        required
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || value.trim() < 1) {
                                return Promise.reject(new Error('Por favor, introduza uma descrição da vantagem.'));
                              }
                              return Promise.resolve();
                            }
                          }),
                        ]}
                      >
                        <ReactQuill
                          onChange={(value) => this.setState({ editDescriptionValue: value })}
                          placeholder='Introduzir uma descrição completa da vantagem...'
                          theme='snow'
                          value={editDescriptionValue}
                          modules={{
                            toolbar: [
                              [{ 'header': [1, 2, 3, 4, 5, 6, false] },
                                'bold', 'italic', 'underline', 'strike',
                                {
                                  'color': [
                                    styles.COLORS.TextColor,
                                    styles.COLORS.SecondaryTextColor,
                                    styles.COLORS.TertiaryTextColor,
                                    styles.COLORS.QuaternaryTextColor,
                                    styles.COLORS.FillColor,
                                    styles.COLORS.SecondaryFillColor,
                                    styles.COLORS.BgContainerColor,
                                    styles.COLORS.PrimaryActiveColor,
                                    styles.COLORS.PrimaryColor,
                                    styles.COLORS.PrimaryHoverColor,
                                    styles.COLORS.PrimaryBorderHoverColor,
                                    styles.COLORS.PrimaryBorderColor,
                                    styles.COLORS.PrimaryBgHoverColor,
                                    styles.COLORS.PrimaryBgColor,
                                    styles.COLORS.SecondaryActiveColor,
                                    styles.COLORS.SecondaryColor,
                                    styles.COLORS.SecondaryHoverColor,
                                    styles.COLORS.SecondaryBorderHoverColor,
                                    styles.COLORS.SecondaryBgHoverColor,
                                    styles.COLORS.SecondaryBgColor,
                                    styles.COLORS.SecondaryBorderColor,
                                    styles.COLORS.SuccessActiveColor,
                                    styles.COLORS.SuccessColor,
                                    styles.COLORS.SuccessTextHoverColor,
                                    styles.COLORS.SuccessBorderHoverColor,
                                    styles.COLORS.SuccessBorderColor,
                                    styles.COLORS.SuccessBgHoverColor,
                                    styles.COLORS.SuccessBgColor,
                                    styles.COLORS.WarningActiveColor,
                                    styles.COLORS.WarningColor,
                                    styles.COLORS.WarningTextHoverColor,
                                    styles.COLORS.WarningBorderHoverColor,
                                    styles.COLORS.WarningBorderColor,
                                    styles.COLORS.WarningBgHoverColor,
                                    styles.COLORS.WarningBgColor,
                                    styles.COLORS.ErrorActiveColor,
                                    styles.COLORS.ErrorColor,
                                    styles.COLORS.ErrorTextHoverColor,
                                    styles.COLORS.ErrorBorderHoverColor,
                                    styles.COLORS.ErrorBorderColor,
                                    styles.COLORS.ErrorBgHoverColor,
                                    styles.COLORS.ErrorBgColor,
                                  ]
                                },
                                {
                                  'background': [
                                    styles.COLORS.TextColor,
                                    styles.COLORS.SecondaryTextColor,
                                    styles.COLORS.TertiaryTextColor,
                                    styles.COLORS.QuaternaryTextColor,
                                    styles.COLORS.FillColor,
                                    styles.COLORS.SecondaryFillColor,
                                    styles.COLORS.BgContainerColor,
                                    styles.COLORS.PrimaryActiveColor,
                                    styles.COLORS.PrimaryColor,
                                    styles.COLORS.PrimaryHoverColor,
                                    styles.COLORS.PrimaryBorderHoverColor,
                                    styles.COLORS.PrimaryBorderColor,
                                    styles.COLORS.PrimaryBgHoverColor,
                                    styles.COLORS.PrimaryBgColor,
                                    styles.COLORS.SecondaryActiveColor,
                                    styles.COLORS.SecondaryColor,
                                    styles.COLORS.SecondaryHoverColor,
                                    styles.COLORS.SecondaryBorderHoverColor,
                                    styles.COLORS.SecondaryBgHoverColor,
                                    styles.COLORS.SecondaryBgColor,
                                    styles.COLORS.SecondaryBorderColor,
                                    styles.COLORS.SuccessActiveColor,
                                    styles.COLORS.SuccessColor,
                                    styles.COLORS.SuccessTextHoverColor,
                                    styles.COLORS.SuccessBorderHoverColor,
                                    styles.COLORS.SuccessBorderColor,
                                    styles.COLORS.SuccessBgHoverColor,
                                    styles.COLORS.SuccessBgColor,
                                    styles.COLORS.WarningActiveColor,
                                    styles.COLORS.WarningColor,
                                    styles.COLORS.WarningTextHoverColor,
                                    styles.COLORS.WarningBorderHoverColor,
                                    styles.COLORS.WarningBorderColor,
                                    styles.COLORS.WarningBgHoverColor,
                                    styles.COLORS.WarningBgColor,
                                    styles.COLORS.ErrorActiveColor,
                                    styles.COLORS.ErrorColor,
                                    styles.COLORS.ErrorTextHoverColor,
                                    styles.COLORS.ErrorBorderHoverColor,
                                    styles.COLORS.ErrorBorderColor,
                                    styles.COLORS.ErrorBgHoverColor,
                                    styles.COLORS.ErrorBgColor,
                                  ]
                                },
                                { 'script': 'sub' },
                                { 'script': 'super' },
                                { 'align': [] },
                                { 'indent': '-1' },
                                { 'indent': '+1' },
                                { 'list': 'ordered' },
                                { 'list': 'bullet' },
                                'link', 'image', 'video', 'blockquote', 'code', 'code-block', 'formula', 'clean'
                              ],
                            ]
                          }}
                        />
                      </Form.Item>

                      {/* Available documents for the advantage */}
                      {selectedItem.numberOfDocuments > 0 &&
                        <div className='cms-add-modal-image-card-list'>
                          {selectedItem.documents.map((document) => {
                            return (
                              <Card 
                                actions={[
                                  <Button
                                    icon={<i className='fa-duotone fa-copy'></i>}
                                    key='fa-copy'
                                    title='Copiar URL'
                                    type='link'
                                    onClick={() => {
                                      navigator.clipboard.writeText(`${process.env.REACT_APP_API_IMAGES_BASE_URL}${document.path}`)
                                        .then(
                                          function() {
                                            message.success('URL copiado');
                                          }
                                        ).catch(
                                          function() {
                                            message.error('Tente novamente');
                                          }
                                        );
                                    }}
                                  />,
                                  <Popconfirm
                                    key='fa-trash-can'
                                    title='&nbsp;Arquivar documento'
                                    description={`Tem a certeza que pretende arquivar o documento ${document.name}? Esta ação não é reversível.`}
                                    onConfirm={() => this.archiveFile(document)}
                                    okText='Arquivar'
                                    okButtonProps={{ danger: true, loading: archiveFileLoading }}
                                    cancelText='Cancelar'
                                    icon={<i 
                                      className='fa-duotone fa-circle-exclamation'
                                      style={{
                                        '--fa-primary-color': styles.COLORS.ErrorActiveColor,
                                        '--fa-secondary-color': styles.COLORS.ErrorBorderColor,
                                        '--fa-secondary-opacity': 1
                                      }}
                                    ></i>}
                                  >
                                    <Button
                                      danger
                                      icon={<i className='fa-solid fa-trash-can'></i>}
                                      title='Arquivar documento'
                                      type='text'
                                    />
                                  </Popconfirm>
                                ]}
                                cover={
                                  <>
                                    { document.mimetype.includes('pdf') ?
                                      <iframe id={document.idDocument} title={document.name} src={`${process.env.REACT_APP_API_IMAGES_BASE_URL}${document.path}`} height='100%' width='100%' ></iframe>
                                      :
                                      <img
                                        alt={document.name}
                                        crossOrigin='anonymous'
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src = no_photo;
                                        }}
                                        src={`${process.env.REACT_APP_API_IMAGES_BASE_URL}${document.path}`}
                                      />
                                    }
                                  </>
                                }
                                key={document.idDocument}
                                hoverable
                                style={{ width: 150 }}
                              >
                                <Meta 
                                  title={document.name}
                                />
                              </Card>
                            );
                          })}
                        </div>
                      }
                    </div>
                    : editCurrentStep === 3 ? 
                      <div>
                        {/* Search Fields */}
                        <Form.Item
                          hasFeedback
                          initialValue={editedAdvantage && editedAdvantage.searchFields ? editedAdvantage.searchFields : selectedItem.searchFields}
                          label='Campos de Pesquisa:'
                          name='searchFields'
                          tooltip='Associa etiquetas/palavras-chave à vantagem. Estão ocultas para o utilizador, mas permitem facultar abranger a pesquisa das vantagens.'
                        >
                          <Input
                            allowClear
                            placeholder='Introduzir os campos de pesquisa. Ex.: carro, lavagem, veículo, automóvel (opcional)'
                            type='text'
                          />
                        </Form.Item>
                        {/* Summary */}
                        <Form.Item
                          hasFeedback
                          initialValue={editedAdvantage && editedAdvantage.summary ? editedAdvantage.summary : selectedItem.summary}
                          label='Descrição breve:'
                          name='summary'
                          rules={[
                            { max: 200, message: 'Por favor, introduza uma descrição com um máximo de 200 caracteres.' },
                            { min: 3, message: 'Por favor, introduza uma descrição com mais de 3 caracteres.' }
                          ]}
                          tooltip='Associa uma breve descrição à vantagem. Esta é apresentada quando uma vantagem está em destaque na página principal.'
                        >
                          <TextArea
                            allowClear
                            autoSize
                            maxLength={200}
                            minLength={3}
                            placeholder='Introduzir uma descrição simples para a vantagem. (opcional)'
                            rows={4}
                            showCount
                          />
                        </Form.Item>
                        {/* Discount value */}
                        <Form.Item
                          hasFeedback
                          initialValue={editedAdvantage && editedAdvantage.discountValue ? editedAdvantage.discountValue : selectedItem.discountValue}
                          label='Desconto:'
                          name='discountValue'
                          tooltip='Associa um valor de desconto à vantagem. Pode ser por exemplo, um valor "Até 25%" ou "10%"'
                        >
                          <Input allowClear placeholder='Introduzir o desconto da vantagem. (opcional)' type='text' />
                        </Form.Item>
                        {/* Location */}
                        <Form.Item
                          hasFeedback
                          initialValue={editedAdvantage && editedAdvantage.location ? editedAdvantage.location : selectedItem.location}
                          label='Localização:'
                          name='location'
                          tooltip='Associa uma localização à vantagem. Pode ser, por exemplo, "Setúbal" ou "Av. Liberdade Nº2"'
                        >
                          <Input
                            allowClear
                            placeholder='Introduzir a localização da vantagem. (opcional)'
                            type='text' />
                        </Form.Item>
                        {/* In district */}
                        <Form.Item
                          hasFeedback
                          initialValue={editedAdvantage && typeof editedAdvantage.isInDistrict === 'boolean' ? editedAdvantage.isInDistrict : selectedItem.isInDistrict}
                          label='Localiza-se no distrito de Setúbal:'
                          name='isInDistrict'
                          tooltip='Permite associar a vantagem ao distrito de Setúbal. Necessário para fins de estatística.'
                          valuePropName='checked'
                        >
                          <Switch checkedChildren='Sim' unCheckedChildren='Não' />
                        </Form.Item>
                        {/* Many locations */}
                        <Form.Item
                          hasFeedback
                          initialValue={editedAdvantage && typeof editedAdvantage.hasManyLocations === 'boolean' ? editedAdvantage.hasManyLocations : selectedItem.hasManyLocations}
                          label='Tem várias localizações em Portugal Continental e Ilhas:'
                          name='hasManyLocations'
                          tooltip='Permite associar a vantagem a várias localizações. Necessário para fins de estatística.'
                          valuePropName='checked'
                        >
                          <Switch checkedChildren='Sim' unCheckedChildren='Não' />
                        </Form.Item>
                        {/* Highlighted */}
                        <Form.Item
                          hasFeedback
                          initialValue={editedAdvantage && typeof editedAdvantage.isHighlighted === 'boolean' ? editedAdvantage.isHighlighted : selectedItem.isHighlighted}
                          label='Destacar:'
                          name='isHighlighted'
                          tooltip='Permite destacar a vantagem na página inicial'
                          valuePropName='checked'
                        >
                          <Switch checkedChildren='Sim' unCheckedChildren='Não' />
                        </Form.Item>
                      </div>
                      :
                      <div>
                        <div className='cms-add-advantage-preview'>
                          <div className='advantage-wrapper'>
                            {editedAdvantage && editedAdvantage.discountValue ?
                              <Badge.Ribbon 
                                text={editedAdvantage.discountValue}
                                color={styles.COLORS.ErrorActiveColor}
                              >
                                <div className='advantage-header-wrapper'>
                                  {((editedAdvantage && editedAdvantage.image) || selectedItem.image) &&
                                    <div
                                      className='advantage-img'
                                      style={{ 
                                        backgroundImage: editImageUrl ? `url(${editedAdvantage.image})`
                                          : editImageUpload ? `url(${editedAdvantage.image.thumbUrl})`
                                            : selectedItem.image.startsWith('/') ? `${process.env.REACT_APP_API_IMAGES_BASE_URL}${selectedItem.image}`
                                              : `url(${selectedItem.image})`
                                      }}
                                    ></div>
                                  }
                                  <div className='advantage-header'>
                                    <h1 className='advantage-title'>{editedAdvantage && editedAdvantage.title ? editedAdvantage.title : selectedItem.title}</h1>
                                    <Tag
                                      className='advantage-category'
                                      color={editedAdvantage && editedAdvantage.category ? editedAdvantage.category.color : selectedItem.categoryColor}
                                      style={{ color: styles.COLORS.getTextColor(
                                        editedAdvantage && editedAdvantage.category ? editedAdvantage.category.color : selectedItem.categoryColor
                                      )}}
                                    >{editedAdvantage && editedAdvantage.category ? editedAdvantage.category.name : selectedItem.categoryName}</Tag>
                                    {(editedAdvantage && editedAdvantage.group) &&
                                      <Tag className='advantage-group' color={styles.COLORS.SecondaryFillColor}>
                                        {editedAdvantage && editedAdvantage.group ? editedAdvantage.group.name : selectedItem.groupName}
                                      </Tag>
                                    }
                                  </div>
                                </div>
                              </Badge.Ribbon>
                              :
                              <div className='advantage-header-wrapper'>
                                {(editedAdvantage && editedAdvantage.image) &&
                                  <div
                                    className='advantage-img'
                                    style={{ 
                                      backgroundImage: editImageUrl ? `url(${editedAdvantage.image})`
                                        : editImageUpload ? `url(${editedAdvantage.image.thumbUrl})`
                                          : selectedItem.image.startsWith('/') ? `${process.env.REACT_APP_API_IMAGES_BASE_URL}${selectedItem.image}`
                                            : `url(${selectedItem.image})`
                                    }}
                                  ></div>
                                }
                                <div className='advantage-header'>
                                  <h1 className='advantage-title'>{editedAdvantage && editedAdvantage.title ? editedAdvantage.title : selectedItem.title}</h1>
                                  <Tag
                                    className='advantage-category'
                                    color={editedAdvantage && editedAdvantage.category ? editedAdvantage.category.color : selectedItem.categoryColor}
                                    style={{ color: styles.COLORS.getTextColor(
                                      editedAdvantage && editedAdvantage.category ? editedAdvantage.category.color : selectedItem.categoryColor
                                    )}}
                                  >{editedAdvantage && editedAdvantage.category ? editedAdvantage.category.name : selectedItem.categoryName}</Tag>
                                  {(editedAdvantage && editedAdvantage.group) &&
                                    <Tag className='advantage-group' color={styles.COLORS.SecondaryFillColor}>
                                      {editedAdvantage && editedAdvantage.group ? editedAdvantage.group.name : selectedItem.groupName}
                                    </Tag>
                                  }
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                          
                        <Divider orientation='center' type='horizontal' plain>Detalhe</Divider>

                        <div className='cms-add-advantage-detail-preview'>
                          {/* Title */}
                          <div className='advantage-content-title-wrap'>
                            <h4>{editedAdvantage && editedAdvantage.title ? editedAdvantage.title : selectedItem.title}</h4>
                            <div>
                              <Tag
                                color={editedAdvantage && editedAdvantage.category ? editedAdvantage.category.color : selectedItem.categoryColor}
                                style={{ color: styles.COLORS.getTextColor(
                                  editedAdvantage && editedAdvantage.category ? editedAdvantage.category.color : selectedItem.categoryColor
                                )}}
                              >{editedAdvantage && editedAdvantage.category ? editedAdvantage.category.name : selectedItem.categoryName}</Tag>
                              {/* Discount value */}
                              {(editedAdvantage && editedAdvantage.discountValue) &&
                                <Tag color={styles.COLORS.ErrorActiveColor}>{editedAdvantage.discountValue}</Tag>
                              }
                            </div>
                          </div>
                          {/* Content */}
                          <div className='advantage-content'>
                            {/* Validade */}
                            <span className='advantage-content-validity'>Válido a partir de {selectedItem.activationDate.locale('en').format('DD/MM/YYYY')}</span>
                            {/* Description */}
                            <div className='advantage-content-description' dangerouslySetInnerHTML={{ __html: editedAdvantage && editedAdvantage.description ? editedAdvantage.description : selectedItem.description }}></div>
                            {/* Localization */}
                            {(editedAdvantage && editedAdvantage.localization) && <span className='advantage-content-title'>Localização:</span>}
                            {(editedAdvantage && editedAdvantage.localization) && <span>{editedAdvantage.localization}</span>}
                            {/* Group */}
                            {(editedAdvantage && editedAdvantage.group) &&
                                <div className='advantage-content-group'>
                                  <div
                                    className='advantage-content-group-img'
                                    style={{ backgroundImage: `url(${editedAdvantage && editedAdvantage.group ? editedAdvantage.group.image : selectedItem.groupImage})` }}
                                  ></div>
                                  <div>
                                    <div>Grupo:</div>
                                    <div><strong>{editedAdvantage && editedAdvantage.group ? editedAdvantage.group.name : selectedItem.groupName}</strong></div>
                                  </div>
                                </div>
                            }
                          </div>
                        </div>
                      </div>
              }
              <br></br>
              {/* Buttons */}
              <Form.Item style={{ width: '100%' }}>
                <div className='cms-add-modal-btns'>
                  <Button type='default' onClick={this.handleEditAdvantageModal}>Cancelar</Button>
                  {editCurrentStep === 0 &&
                    <Popconfirm
                      title={<>{selectedItem.isActive ? 'Desativar vantagem' : 'Reativar vantagem'}</>}
                      description={
                        <>
                          <div>Tem a certeza que pretende {selectedItem.isActive ? 'desativar' : 'reativar'} esta vantagem?</div>
                          <div>Esta ação <b>é reversível</b>.</div>
                        </>
                      }
                      okText={selectedItem.isActive ? 'Desativar' : 'Reativar'}
                      okButtonProps={{ className: 'vantagens-warning-button' }}
                      okType='default'
                      cancelText='Cancelar'
                      onConfirm={selectedItem.isActive ? this.deactivateAdvantage : this.reactivateAdvantage}
                    >
                      <Button className='vantagens-warning-button' disabled={editFetching} type='default'>{selectedItem.isActive ? 'Desativar' : 'Reativar'}</Button>
                    </Popconfirm>
                  }
                  {editCurrentStep > 0 && <Button type='primary' onClick={this.handleEditBackStep}>Voltar</Button>}
                  {editCurrentStep === 4 ?
                    <Button
                      type={editError ? 'default' : 'primary'}
                      danger={editError ? true : false}
                      htmlType='submit'
                      disabled={editFetching}>{editError ? 'Tentar novamente' : editFetching ? 'A carregar...' : 'Guardar'}
                    </Button>
                    :
                    <Button type='primary' htmlType='submit'>Seguinte</Button>
                  }
                </div>
              </Form.Item>
            </Form>
          </Drawer>
        }

        {/* Submit file */}
        {selectedItem &&
          <Drawer
            className='cms-menu-add-drawer'
            destroyOnClose
            extra={
              <div className='cms-menu-add-drawer-header'>
                {/* Title */}
                <div>Carregar ficheiro</div>
                {/* Close btn */}
                <Button 
                  id='hamburger-menu-close'
                  icon={<i className='fa-light fa-xmark'></i>}
                  onClick={this.handleFileSubmissionModal}
                  type='text'
                />
              </div>
            }
            open={showFileSubmissionModal}
            onClose={this.handleFileSubmissionModal}
            placement='right'
            size='large'
            title='Carregar ficheiro'
            width={window.innerWidth < 736 ? window.innerWidth : 736}
          >
            {/* Form steps */}
            <Steps
              current={submitFileCurrentStep}
              initial={0}
              items={[
                { icon: <i className='fa-duotone fa-image'></i>, title: 'Especificar ficheiro' },
                { icon: <i className='fa-duotone fa-circle-exclamation'></i>, title: 'Confirmação' },
                { icon: <i className='fa-duotone fa-link-simple'></i>, title: 'Copiar URL' },
              ]}
              labelPlacement='vertical'
              size='small'
            />

            {/* Form */}
            <Form
              autoComplete='on'
              initialValues={{ remember: true }}
              layout='vertical'
              name='Submeter um ficheiro no sistema'
              onFinish={(values) => {
                switch(submitFileCurrentStep) {
                case 0:
                  if (!uploadedFile) {
                    this.setState({ showUploadInputError: true });
                  } else {
                    this.setState({ showUploadInputError: false, submitFileCurrentStep: submitFileCurrentStep + 1 });
                  }
                  break;
                case 1:
                  this.submitFile();
                  break;
                case 2:
                  if (fileSubmissionSuccess) {
                    this.handleFileSubmissionModal();
                    this.fetchData();
                  } else {
                    this.handleFileSubmissionModal();
                    setTimeout(() => {
                      this.handleFileSubmissionModal();
                    }, 500);
                  }
                  break;
                default:
                  break;
                }
              }}
            >
              {submitFileCurrentStep === 0 ?
                <div style={{ height: '400px' }}>
                  <Alert message='Atenção!' description='Se já existir um ficheiro no servidor com o mesmo nome, para esta vantagem, o ficheiro será substituído.' type='warning' showIcon />
                  <br></br>
                  <Form.Item
                    getValueFromEvent={this.handleAddFileList}
                    name='uploadedFile'
                    noStyle
                    valuePropName='fileList'
                  >
                    <Upload.Dragger
                      beforeUpload={(file) => {
                        this.setState({ uploadedFile: file });
                        return false;
                      }}
                      listType='picture'
                      maxCount={1}
                      name='uploadedFile'
                      onChange={({ file, fileList }) => {
                        const item = fileList.length < 1 ? null : file;
                        this.setState({ uploadedFile: item, showUploadInputError: item ? false : true });
                      }}
                    >
                      <p className='ant-upload-drag-icon'><i className='fa-duotone fa-cloud-arrow-up'></i></p>
                      <p className="ant-upload-text">Clique ou arraste um ficheiro para esta área para carregá-lo</p>
                      <p className="ant-upload-hint">Suporta apenas o carregamento de um único ficheiro do tipo .jpg, .jpeg ou .png</p>
                    </Upload.Dragger>
                  </Form.Item>
                  { showUploadInputError &&
                    <p className='cms-add-modal-form-item-input-error-text'><i className='fa-duotone fa-circle-exclamation'></i> Por favor, introduza um ficheiro.</p>
                  }
                </div>
                : submitFileCurrentStep === 1 ?
                  <div className='cms-add-modal-form-item-confirmation-step'>
                    {fileSubmissionLoading ? 
                      <>
                        <ClockLoader color={styles.COLORS.PrimaryColor} size={100} />
                        <div>A carregar...</div>
                      </>
                      :
                      <>
                        <p>Tem a certeza que pretende carregar o ficheiro</p>
                        <p className='cms-add-modal-form-item-confirmation-step-highlight'>{uploadedFile ? `"${uploadedFile.name}" (${formatBytes(uploadedFile.size)})` : '-'}</p>
                        <p>no servidor?</p>
                      </>
                    }
                  </div>
                  :
                  <div>
                    {fileSubmissionSuccess ?
                      <>
                        <Result
                          status='success'
                          subTitle='Copie o URL do ficheiro exposto na barra abaixo.'
                          title='Ficheiro carregado com sucesso'
                          extra={[
                            <Space.Compact style={{ width: '100%' }} key='file_url_success'>
                              <Input allowClear={false} id='cms_file_url_input' value={fileSubmissionLink} />
                              <Button
                                icon={<i className='fa-duotone fa-copy'></i>}
                                onClick={(event) => {
                                  const input = document.getElementById('cms_file_url_input');
                                  if (input) {
                                    input.focus();
                                    input.select();
                                    navigator.clipboard.writeText(fileSubmissionLink).then(
                                      function() {
                                        message.success('URL copiado');
                                      }
                                    ).catch(
                                      function() {
                                        message.error('Tente novamente');
                                      }
                                    );
                                  }
                                }}
                                type='default'
                              />
                            </Space.Compact>
                          ]}
                        />
                      </>
                      : fileSubmissionError ? 
                        <Result
                          status='error'
                          subTitle={fileSubmissionError}
                          title='Erro ao carregar o ficheiro'
                          extra={[
                          ]}
                        />
                        : null
                    }
                  </div>
              }
              <br></br>
              {/* Buttons */}
              <Form.Item style={{ width: '100%' }}>
                <div className='cms-add-modal-btns'>
                  {submitFileCurrentStep < 2 && 
                    <Button type='default' onClick={this.handleFileSubmissionModal}>Cancelar</Button>
                  }
                  {submitFileCurrentStep === 1 && 
                    <Button
                      disabled={fileSubmissionLoading || fileSubmissionSuccess}
                      onClick={this.handleFileSubmissionBackStep}
                      type='primary'
                    >Voltar</Button>
                  }
                  {submitFileCurrentStep === 0 && 
                    <Button type='primary' htmlType='submit'>Continuar</Button>
                  }
                  {submitFileCurrentStep === 1 && 
                    <Button 
                      disabled={fileSubmissionLoading || fileSubmissionSuccess || fileSubmissionError}
                      htmlType='submit'
                      loading={fileSubmissionLoading}
                      type='primary'
                    >Confirmar</Button>
                  }
                  {submitFileCurrentStep === 2 && 
                    <Button
                      danger={fileSubmissionError ? true : false}
                      htmlType='submit'
                      type='primary'
                    >{fileSubmissionSuccess ? 'Concluir' : 'Tentar novamente'}</Button>
                  }
                </div>
              </Form.Item>
            </Form>
          </Drawer>
        }
      </div>
    );
  }
}

/**
 * Used in the Redux pattern to reflect any updates to the Redux store and merge them into props in the current
 * component. The Redux store serves as a centralized place for the state to live in the application.
 * 
 * @param {any} state - Centralized state of the application.
 * @returns {any} the state of the application as the component props.
 */
const mapPropsToState = (state) => {
  const languages = state.languages;
  const groups = state.groups;
  const app = state.app;

  return {
    languages: languages.privilegedData,
    languagesError: languages.error,
    languagesFetching: languages.fetching,

    groups: groups.data,
    groupsError: groups.error,
    groupsFetching: groups.fetching,

    menuCollapsed: app.collapseState,
  };
};

/**
 * Used in the Redux pattern to dispatch actions to the Redux store, triggering a state change.
 * 
 * @param {any} dispatch - function of the Redux store.
 * @returns {any} the dispatch functions as components props.
 */
const mapDispatchToState = (dispatch) => {
  return {
    getLanguages: (token) => dispatch(getAllLanguages(token)),
    getGroups: (token) => dispatch(getGroups(token)),
  };
};

export default withRouter(connect(mapPropsToState, mapDispatchToState)(ManageAdvantages));