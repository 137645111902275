import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../utils';
import { getStatistics } from '../../reducers/Statistics';
import { Card, Skeleton, Statistic} from 'antd';
import CountUp from 'react-countup';
import './index.scss';
import { Link } from 'react-router-dom';

class Counters extends React.Component {
  /**
   * Allows to execute the React code when the component is already placed in the DOM (Document Object Model).
   * This method is called during the Mounting phase of the React Life-cycle i.e after the component is rendered.
   */
  componentDidMount() {
    this.props.getStatistics();
  }

  render() {
    const { statistics, statisticsError, statisticsFetching } = this.props;
    if (statisticsError) return null;
    if (statisticsFetching) {
      return (
        <div id='homepage-counters-wrapper'>
          <Skeleton.Image active={true} />

          <div id='homepage-other-counters' className='hoc-2-c'>
            <Skeleton.Image active={true} />
            <Skeleton.Image active={true} />
            <Skeleton.Image active={true} />
            <Skeleton.Image active={true} />
          </div>
        </div>
      );
    }

    const principalCounter = statistics.activeProtocols;
    if (!principalCounter) return null;
    
    const activeCampaignsCounter = statistics.activeCampaigns; // undefined; //
    const newAdvantagesCounter = statistics.newProtocols;
    const advantagesInSetubalCounter = statistics.protocolsInDistrict;
    const advantagesWithManyLocationsCounter = statistics.protocolsWithManyLocations;

    return (
      <div id='homepage-counters-wrapper'>
        <Link to={`${process.env.REACT_APP_PUBLIC_URL}/vantagens`} className='homepage-principal-counter-link'>
          <Card className='homepage-principal-counter'>
            <i className='fa-duotone fa-badge-percent'></i>
            <Statistic
            // title='Vantagens'
              value={principalCounter.total}
              formatter={(value) => {
                return (
                  <CountUp
                    enableScrollSpy={true}
                    scrollSpyDelay={500}
                    start={0}
                    end={value}
                    separator=','
                    decimal='.'
                    useEasing={true}
                    useGrouping={true}
                  >
                    {({ countUpRef }) => <span className='homepage-counter-number' ref={countUpRef}></span>}
                  </CountUp>
                );
              }}
            />
            <div className='homepage-principal-counter-title'>Vantagens</div>
            <div className='homepage-principal-counter-description'>Número total<br></br>de vantagens IPS</div>
          </Card>
        </Link>

        <div id='homepage-other-counters' className={
          activeCampaignsCounter && activeCampaignsCounter.total > 0 &&
          newAdvantagesCounter && newAdvantagesCounter.total > 0 ? 'hoc-2-c' : 'hoc-1-c'
        }>
          {/* Campaign Counter */}
          {activeCampaignsCounter && activeCampaignsCounter.total > 0 &&
            <Card bordered={false} className='homepage-other-counter'>
              <i className='fa-duotone fa-calendar-clock'></i>
              <Statistic
                title='Campanhas Ativas'
                value={activeCampaignsCounter.total}
                formatter={(value) => {
                  return (
                    <CountUp
                      enableScrollSpy={true}
                      scrollSpyDelay={500}
                      start={0}
                      end={value}
                      separator=','
                      decimal='.'
                      useEasing={true}
                      useGrouping={true}
                    >
                      {({ countUpRef }) => <span className='homepage-counter-number' ref={countUpRef}></span>}
                    </CountUp>
                  );
                }}
              />
            </Card>
          }
          
          {/* New Campaigns */}
          {newAdvantagesCounter && newAdvantagesCounter.total > 0 &&
            <Card bordered={false} className='homepage-other-counter'>
              <i className='fa-duotone fa-business-time'></i>
              <Statistic
                title='Novas Vantagens'
                value={newAdvantagesCounter.total}
                formatter={(value) => {
                  return (
                    <CountUp
                      enableScrollSpy={true}
                      scrollSpyDelay={500}
                      start={0}
                      end={value}
                      separator=','
                      decimal='.'
                      useEasing={true}
                      useGrouping={true}
                    >
                      {({ countUpRef }) => <span className='homepage-counter-number' ref={countUpRef}></span>}
                    </CountUp>
                  );
                }}
              />
            </Card>
          }

          {/* Setubal Counter */}
          {advantagesInSetubalCounter &&
            <Link to={`${process.env.REACT_APP_PUBLIC_URL}/vantagens?isInDistrict=true`} className='homepage-principal-counter-link'>
              <Card bordered={false} className='homepage-other-counter'>
                <i className='fa-duotone fa-map-location-dot'></i>
                <Statistic
                  title='Vantagens no distrito de Setúbal'
                  value={advantagesInSetubalCounter.total}
                  formatter={(value) => {
                    return (
                      <CountUp
                        enableScrollSpy={true}
                        scrollSpyDelay={500}
                        start={0}
                        end={value}
                        separator=','
                        decimal='.'
                        useEasing={true}
                        useGrouping={true}
                      >
                        {({ countUpRef }) => <span className='homepage-counter-number' ref={countUpRef}></span>}
                      </CountUp>
                    );
                  }}
                />
              </Card>
            </Link>
          }

          {/* Portugal Counter */}
          {advantagesWithManyLocationsCounter &&
            <Link to={`${process.env.REACT_APP_PUBLIC_URL}/vantagens?hasManyLocations=true`} className='homepage-principal-counter-link'>
              <Card bordered={false} className='homepage-other-counter'>
                <i className='fa-duotone fa-map-location'></i>
                <Statistic
                  title='Vantagens com cobertura nacional'
                  value={advantagesWithManyLocationsCounter.total}
                  formatter={(value) => {
                    return (
                      <CountUp
                        enableScrollSpy={true}
                        scrollSpyDelay={500}
                        start={0}
                        end={value}
                        separator=','
                        decimal='.'
                        useEasing={true}
                        useGrouping={true}
                      >
                        {({ countUpRef }) => <span className='homepage-counter-number' ref={countUpRef}></span>}
                      </CountUp>
                    );
                  }}
                />
              </Card>
            </Link>
          }
        </div>
      </div>
    );
  }
}

const mapPropsToState = (state) => {
  const statistics = state.statistics;
  return {
    statistics: statistics.data,
    statisticsError: statistics.error,
    statisticsFetching: statistics.fetching,
  };
};

const mapDispatchToState = (dispatch) => {
  return {
    getStatistics: () => dispatch(getStatistics()),
  };
};

export default withRouter(connect(mapPropsToState, mapDispatchToState)(Counters));