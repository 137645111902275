import React from 'react';
import PropTypes from 'prop-types';
import { styles } from '../../styles';

/**
 * 
 * Search class represents a search-icon, usually used for searching purposes.
 * 
 * Accepts the props:
 * * `className` - The CSS class selectors to add specific styles to the icon.
 * * `color` - The color to add to the icon.
 * * `size` - The font-size of the icon.
 */
export default class Search extends React.Component {
  render() {
    const { className, size, color } = this.props;
    return (
      <i
        className={`fa-thin fa-magnifying-glass ${className ? className : ''}`}
        style={{ color: color, fontSize: size }}
      ></i>
    );
  }
}

Search.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
};

Search.defaultProps = {
  className: undefined,
  color: styles.COLORS.PrimaryColor,
  size: styles.FONTS.FontSize,
};