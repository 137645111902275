import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { Logotype } from '../../components';
import ipsLogo from '../../images/IPS-sm-logo.png';
import './index.scss';

export default class NotFound extends React.Component {
  render() {
    return (
      <div id='not-found-page'>
        {/* Header */}
        <div id='not-found-page-header'>
          <Logotype url='/' src={ipsLogo} alt='Logótipo do IPS' height={'70px'} />
        </div>
        {/* Content */}
        <div id='content-wrap'>
          {/* First divider */}
          <div className='not-found-page-divider'></div>
          {/* Text content */}
          <div id='not-found-page-content'>
            <h1 className='not-found-page-content-title'>404 <i className='fa-light fa-message-exclamation'></i></h1>
            <p className='not-found-page-content-text'>Perdid@ com tantas vantagens?</p>
            <p className='not-found-page-content-text-transl'>transl: Lost with so many advantages?</p>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <p className='not-found-page-content-text-small'>Hmm, parece que esta página não existe...</p>
            <Link to={`${process.env.REACT_APP_PUBLIC_URL}/`}><Button type='primary'>Ir para o Início</Button></Link>
          </div>
          {/* Last divider */}
          <div className='not-found-page-divider'></div>
        </div>
        {/* Footer */}
        <div id='not-found-page-footer'>
          <p>Copyright <i className='fa-light fa-copyright'></i> <i>{(new Date()).getFullYear()} Instituto Politécnico de Setúbal</i></p>
        </div>
      </div>
    );
  }
}