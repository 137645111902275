import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from '../../utils';
import { getCategories } from '../../reducers/Categories';
import { getConfigurations } from '../../reducers/Configurations';
import { getSocialNetworks } from '../../reducers/SocialNetworks';
import { Divider, Skeleton, Tooltip } from 'antd';
import { Logotype } from '../../components';
import './index.scss';

class Footer extends React.Component {
  /**
   * Allows to execute the React code when the component is already placed in the DOM (Document Object Model).
   * This method is called during the Mounting phase of the React Life-cycle i.e after the component is rendered.
   */
  componentDidMount() {
    this.props.getCategories();
    this.props.getConfigurations();
    this.props.getSocialNetworks();
  }

  /**
   * Render method of React component.
   * 
   * @returns the component template.
   */
  render() {
    const {
      categories, categoriesError, categoriesFetching,
      configurations, configurationsError, configurationsFetching,
      socialNetworks, socialNetworksError, socialNetworksFetching
    } = this.props;

    const address = configurations.find((configuration) => configuration.name === 'Morada IPS');
    const postcode = configurations.find((configuration) => configuration.name === 'Código Postal do IPS');
    const telephone = configurations.find((configuration) => configuration.name === 'Telefone do IPS');
    const email = configurations.find((configuration) => configuration.name === 'Email das Vantagens IPS');
    const personalDataProtectionURL = configurations.find((configuration) => configuration.name === 'Política de Proteção de Dados IPS');
    const utilizationTermsURL = configurations.find((configuration) => configuration.name === 'Termos de Utilização do Portal');

    return (
      <footer id='footer'>
        {/* First section */}
        <div id='footer-first-section'>
          {/* Logotype */}
          <Logotype url='/' src={require('../../images/IPS-lg-logo.png')} alt='Logótipo do IPS' height={window.innerWidth > 325 ? '150px' : '100px'} />
          {/* Divider */}
          <div id='footer-first-section-divider'></div>
          {/* Left menu options */}
          <div id='footer-left-menu-options'>
            <div className='menu-option-wrapper'>
              <Link to={`${process.env.REACT_APP_PUBLIC_URL}/`}>INÍCIO</Link>
            </div>
            <div className='menu-option-wrapper'>
              <a href='http://ips.pt/ips_si/web_page.inicial' rel='noreferrer' target='_blank'>IPS</a>
              <div className='menu-sub-option-wrapper'>
                <a rel='noreferrer' target='_blank'
                  href='https://www.estsetubal.ips.pt/'
                  className='sub-option-link'>Escola Superior de Tecnologia de Setúbal</a>
                <a rel='noreferrer' target='_blank'
                  href='http://www.si.ips.pt/ese_si/web_page.inicial'
                  className='sub-option-link'>Escola Superior de Educação</a>
                <a rel='noreferrer' target='_blank'
                  href='https://www.esce.ips.pt/'
                  className='sub-option-link'>Escola Superior de Ciências Empresariais</a>
                <a rel='noreferrer' target='_blank'
                  href='https://www.estbarreiro.ips.pt/'
                  className='sub-option-link'>Escola Superior de Tecnologia do Barreiro</a>
                <a rel='noreferrer' target='_blank'
                  href='https://www.ess.ips.pt/'
                  className='sub-option-link'>Escola Superior de Saúde</a>
              </div>
            </div>
          </div>
          {/* Right menu options */}
          <div id='footer-right-menu-options'>
            {categoriesError ? null : categoriesFetching ?
              <div className='menu-option-wrapper'>
                <Link to={`${process.env.REACT_APP_PUBLIC_URL}/vantagens`}>VANTAGENS</Link>
                <Skeleton.Button active block />
              </div> :
              <div className='menu-option-wrapper'>
                <Link to={`${process.env.REACT_APP_PUBLIC_URL}/vantagens`}>VANTAGENS</Link>
                <div className='menu-sub-option-wrapper'>
                  {categories.map((category) => {
                    return (
                      <Link key={category.idCategory} to={`${process.env.REACT_APP_PUBLIC_URL}/vantagens?categoria=${category.idCategory}`} className='sub-option-link'>{category.name}</Link>
                    );
                  })}
                </div>
              </div>
            }
          </div>
        </div>

        <Divider />
          
        {/* Second section */}
        <div id='footer-second-section'>
          {/* Social Networks */}
          {socialNetworksError ? null :
            socialNetworksFetching ?
              <div id='footer-social-networks'>
                <div className='footer-second-section-title'>Conecta-te connosco</div>
                <Skeleton.Input active={true} />
              </div>
              : socialNetworks && socialNetworks.length > 0 ?
                <div id='footer-social-networks'>
                  <div className='footer-second-section-title'>Conecta-te connosco</div>
                  <div id='footer-social-networks-list'>
                    {socialNetworks.map(((socialNetwork) => {
                      let icon = '';
                      switch (socialNetwork.name.toLowerCase()) {
                      case 'facebook':
                        icon = 'fa-brands fa-facebook';
                        break;
                      case 'instagram':
                        icon = 'fa-brands fa-instagram';
                        break;
                      case 'linkedin':
                        icon = 'fa-brands fa-linkedin';
                        break;
                      case 'youtube':
                        icon = 'fa-brands fa-youtube';
                        break;
                      case 'twitter':
                        icon = 'fa-brands fa-twitter';
                        break;
                      case 'spotify':
                        icon = 'fa-brands fa-spotify';
                        break;
                      default:
                        break;
                      }
                      return (
                        <Tooltip key={socialNetwork.idSocialNetwork} placement='bottom' title={socialNetwork.name}>
                          <a href={socialNetwork.url} rel='noreferrer' target='_blank'>
                            <i className={`${icon} social-network-icon`}></i>
                          </a>
                        </Tooltip>
                      );
                    }))}
                  </div>
                </div> : null
          }

          {/* Contacts */}
          <div id='footer-contacts'>
            <div className='footer-second-section-title'>Contactos</div>
            <div>
              <p>{address ? address.value : null}</p>
              <p>{postcode ? postcode.value : null}</p>
              <p>Tel.: <a href={telephone ? `tel:${telephone.value}` : '/'}>{telephone ? telephone.value : null}</a></p>
              <p>Email: <a href={email ? `mailto:${email.value}` : '/'}>{email ? email.value : null}</a></p>
            </div>
          </div>
        </div>

        {/* Useful Links */}
        <div id='footer-useful-links'>
          {configurationsError ? null : configurationsFetching ? <Skeleton.Input active /> : 
            <>
              {/* Data Protection */}
              {personalDataProtectionURL && <a href={personalDataProtectionURL.value} rel='noreferrer' target='_blank'>Política de Proteção de Dados Pessoais</a>}
              {utilizationTermsURL && <a href={utilizationTermsURL.value} rel='noreferrer' target='_blank'>Termos de Utilização do Portal</a>}
              {/* Accessibility Declaration */}
              <Link to={`${process.env.REACT_APP_PUBLIC_URL}/declaracao-acessibilidade`}>Declaração de Acessibilidade</Link>
              {/* Copyright */}
              <p id='copyright'>Copyright <i className='fa-light fa-copyright'></i> <i>{(new Date()).getFullYear()} Instituto Politécnico de Setúbal</i></p>
            </>
          }
        </div>
      </footer >
    );
  }
}

const mapPropsToState = (state) => {
  const categories = state.categories;
  const configurations = state.configurations;
  const socialNetworks = state.socialNetworks;

  return {
    categoriesFetching: categories.fetching,
    categoriesError: categories.error,
    categories: categories.data,

    configurations: configurations.data,
    configurationsError: configurations.error,
    configurationsFetching: configurations.fetching,

    socialNetworksFetching: socialNetworks.fetching,
    socialNetworksError: socialNetworks.error,
    socialNetworks: socialNetworks.data,
  };
};

const mapDispatchToState = (dispatch) => {
  return {
    getCategories: () => dispatch(getCategories()),
    getConfigurations: () => dispatch(getConfigurations()),
    getSocialNetworks: () => dispatch(getSocialNetworks())
  };
};

export default withRouter(connect(mapPropsToState, mapDispatchToState)(Footer));