import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from '../../utils';
import { getCategories } from '../../reducers/Categories';
import { styles } from '../../styles';
import { Skeleton } from 'antd';
import './index.scss';

class Categories extends React.Component {
  componentDidMount() {
    this.props.getCategories(this.props.language);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.language !== this.props.language) this.props.getCategories(this.props.language);
  }

  render() {
    const { categories, categoriesFetching, categoriesError } = this.props;
    if (categoriesError) return null;
    if (categoriesFetching) {
      return (
        <div id='categories-wrapper'>
          <h1 id='categories-title'>Categorias</h1>
          <div id='categories-loading'>
            <Skeleton active />
          </div>
        </div>
      );
    }
    if (categories && categories.length < 1) return null;

    return (
      <div id='categories-wrapper'>
        <h1 id='categories-title'>Categorias</h1>
        <div id='categories'>
          {categories.map((category) => {
            return (
              <Link
                className='category'
                key={category.idCategory}
                to={`${process.env.REACT_APP_PUBLIC_URL}/vantagens?categoria=${category.idCategory}`}
                style={{
                  backgroundColor: category.color,
                  color: styles.COLORS.getTextColor(category.color)
                }}
              >
                <span className='category-name'>{category.name}<i className='fa-light fa-chevron-right'></i></span>
              </Link>
            );
          })}
          <Link
            className='category'
            to={`${process.env.REACT_APP_PUBLIC_URL}/vantagens`}
            style={{
              backgroundColor: styles.COLORS.PrimaryColor,
              color: styles.COLORS.getTextColor(styles.COLORS.PrimaryColor)
            }}
          >
            <span className='category-name'>Todas<i className='fa-light fa-chevron-right'></i></span>
          </Link>
        </div>
      </div>
    );
  }
}

const mapPropsToState = (state) => {
  const categories = state.categories;
  const languages = state.languages;
  return {
    categoriesFetching: categories.fetching,
    categoriesError: categories.error,
    categories: categories.data,
    language: languages.selected,
  };
};

const mapDispatchToState = (dispatch) => {
  return {
    getCategories: (idLanguage) => dispatch(getCategories(idLanguage)),
  };
};

export default withRouter(connect(mapPropsToState, mapDispatchToState)(Categories));