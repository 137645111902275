/* eslint-disable no-unused-vars */import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../utils';
import { login } from '../../reducers/User';
import { Logotype } from '../../components';
import { Link, Navigate } from 'react-router-dom';
import { Alert, Button, ConfigProvider, Form, Input } from 'antd';
import ipsLogo from '../../images/IPS-sm-logo.png';
import { styles } from '../../styles';
import './index.scss';

class Login extends React.Component {
  componentDidMount() {
    document.title = 'IPS - Vantagens (login)';
  }

  submitLoginForm = (values) => {
    this.props.login(values);
  };

  render() {
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username');
    const loginDate = localStorage.getItem('loginDate');
    if (token && username && loginDate && (new Date(loginDate)).getTime() > (new Date()).getTime()) 
      return <Navigate to={'/cms'} replace={true} />;

    const { error, fetching } = this.props;

    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimaryBg: styles.COLORS.PrimaryBgColor,
            colorPrimary: styles.COLORS.PrimaryColor,
            colorSuccess: styles.COLORS.SuccessColor,
            colorWarning: styles.COLORS.WarningColor,
            colorError: styles.COLORS.ErrorColor,
            colorInfo: styles.COLORS.InfoColor,
          },
        }}
      >
        <div id='login-page-container'>
          {/* header */}
          <header id='login-page-header'>
            {/* Logotype */}
            <Logotype url='/' src={ipsLogo} alt='Logótipo do IPS' height={styles.SPACING.MarginXXL} />
            {/* Title */}
            <h4 id='login-page-header-title'>VANTAGENS</h4>
            {/* Go back btn */}
            <Link to={`${process.env.REACT_APP_PUBLIC_URL}/`} title='Homepage' id='login-page-header-link'>
              <Button
                id='login-page-header-link-btn' type='text' size='large'
                icon={<i title='Home' className='fa-duotone fa-house'></i>} 
              />
            </Link>
          </header>

          {/* Content */}
          <div id='login-page-content'>
            {/* Left side */}
            <div id='login-page-content-alert'>
              {/* Icon */}
              <span id='login-page-content-alert-icon'>
                <i className='fa-duotone fa-user-lock'></i>
              </span>
              {/* Texts */}
              <span id='login-page-content-alert-text'>Acesso Reservado</span>
              <span id='login-page-content-alert-text-complement'>transl: Restricted Access</span>
            </div>

            {/* Divider */}
            <div id='login-page-content-divider'></div>

            {/* Right side */}
            <div id='login-page-content-form-container'>
              <Form
                autoComplete='on'
                disabled={fetching}
                initialValues={{ remember: true }}
                name='login'
                onFinish={this.submitLoginForm}
                scrollToFirstError={true}
              >
                <Form.Item
                  hasFeedback
                  label='Utilizador'
                  name='username'
                  rules={[{
                    pattern: /(\w+\.\w+)/g,
                    required: true,
                    message: 'Por favor, introduza um nome de utilizador válido (ex.: jane.doe).'
                  }]}
                  validateStatus={ fetching ? 'validating' : error ? 'error' : 'success' }
                >
                  <Input placeholder='Introduza o seu nome de utilizador (ex.: jane.doe)' aria-label='Nome de utilizador' />
                </Form.Item>

                <Form.Item
                  hasFeedback={fetching || error}
                  label="Password"
                  name="password"
                  rules={[{ required: true, message: 'Por favor, introduza a sua senha secreta.' }]}
                  validateStatus={ fetching ? 'validating' : error ? 'error' : 'success' }
                >
                  <Input.Password placeholder='Introduza a sua password' autoComplete='on' aria-label='Palavra-passe' />
                </Form.Item>

                { error && error.message && error.message.includes('Invalid Credentials') &&
                  <Alert message='Credenciais inválidas.' type='error' showIcon closable />
                }

                <Form.Item className='login-page-content-form-submit-btn'>
                  <Button type='primary' htmlType='submit'>Entrar</Button>
                </Form.Item>
              </Form>
            </div>
            
          </div>

          {/* Footer */}
          <div id='login-page-footer'>
            <p id='copyright'>Copyright <i className='fa-light fa-copyright'></i> <i>{(new Date()).getFullYear()} Instituto Politécnico de Setúbal</i></p>
          </div>
        </div>
      </ConfigProvider>
    );
  }
}

const mapPropsToState = (state) => {
  const user = state.user;

  return {
    error: user.error,
    fetching: user.fetching,
    user: user.data,
    username: user.username,
    token: user.token,
  };
};

const mapDispatchToState = (dispatch) => {
  return { login: (data) => dispatch(login(data)) };
};

export default withRouter(connect(mapPropsToState, mapDispatchToState)(Login));