import React from 'react';
import dayjs from 'dayjs';
import qs from 'qs';
import { connect } from 'react-redux';
import { withRouter } from '../../utils';
import { getAllLanguages } from '../../reducers/Languages';
import { Button, Collapse, Divider, Form, Input, InputNumber, Modal, Popconfirm, Select, Table, Tabs, Tag, notification } from 'antd';
import { PuffLoader } from 'react-spinners';
import { styles } from '../../styles';

const { Option } = Select;
const { Panel } = Collapse;

/**
 * ManageMenus component represents the page template that allows to manage the application menus.
 * It displays a table with all active menus and a table with all inactive menus.
 * It allows tools to create, edit, deactivate, reactivate and delete menu options.
 */
class ManageMenus extends React.Component {
  /**
   * The constructor for a React component is called before it is mounted.
   * When implementing the constructor, `super(props)` must be called before any
   * other statement. Otherwise, this.props will be undefined in the constructor,
   * which can lead to bugs.
   *
   * A React constructor is only used for two purposes:
   * - Initializing local state by assigning an object to this.state.
   * - Binding event handler methods to an instance.
   *
   * Instead of calling `setState()` in the `constructor(), the initial state must be
   * directly assigned to `this.state` in the constructor
   *
   * @param {*} props - Arbitrary component inputs.
   */
  constructor() {
    super();
    this.state = {
      activeLanguage: process.env.REACT_APP_LANGUAGE_ID,
      activeMenuOptions: [],
      activeTableParams: {
        pagination: {
          current: 1,
          pageSize: 10,
        }
      },
      addError: null,
      addFetching: false,
      menuOptions: [],
      menuOptionsError: null,
      menuOptionsFetching: false,
      defaultLanguage: process.env.REACT_APP_LANGUAGE_ID,
      editError: null,
      editFetching: false,
      inactiveMenuOptions: [],
      inactiveTableParams: {
        pagination: {
          current: 1,
          pageSize: 10,
        }
      },
      loading: false,
      pageWidth: 0,
      selectedItem: null,
      showAddMenuOptionModal: false,
      showEditMenuOptionModal: false,
    };
  }

  /**
   * Allows to execute the React code when the component is already placed in the DOM (Document Object Model).
   * This method is called during the Mounting phase of the React Life-cycle i.e after the component is rendered.
   */
  componentDidMount() {
    // Get data
    this.props.getLanguages(window.localStorage.getItem('token'));
    this.fetchData();
    // Scroll to top
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Add event on resize
    this.setState({ pageWidth: window.innerWidth });
    window.addEventListener('resize', () => this.setState({ pageWidth: window.innerWidth }));
  }

  /**
   * Allows React to call this function immediately after this component has been re-rendered with
   * updated `props` and/or `state`. This method is not called for the initial render.
   * 
   * This allows to manipulate the DOM after an update. It is also a common place to do network requests
   * as long as it compares the current `props` to previous `props` (e.g. a network request may not be necessary
   * if the `props` have not changed).
   * 
   * @param {any} prevProps - Props before the update. Compare prevProps to this.props to determine what changed.
   * @param {any} prevState - State before the update. Compare prevState to this.state to determine what changed.
   * @param {any} snapshot - Used when function getSnapshotBeforeUpdate is implemented. It will contain the value returned from that function. Otherwise, it will be undefined.
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.activeLanguage !== this.state.activeLanguage) {
      this.fetchData(this.state.activeLanguage);
      this.fetchMenuOptions();
    }
  }

  /**
   * Recursively sets the array of menu options to manage. It is useful because menu options follow a model of a Tree,
   * which means that a menu options can have 0 or N (sub-)menu options. Those sub-options are represented in the
   * `children` array.
   * 
   * @param {any[]} data - Original data of menu options. 
   * @returns {any[]} the modified dataset of menu options.
   */
  recursiveSetMenusOptions = (data) => {
    return data.map((menuOption) => {
      const isActive = this.menuOptionIsActive(menuOption);
      const menuOptionData = {
        key: menuOption.idMenuOption,
        idParent: menuOption.idParent,
        idLanguage: menuOption.language.idLanguage,
        title: menuOption.title,
        url: menuOption.page && menuOption.page.url ? menuOption.page.url : menuOption.url,
        order: menuOption.order,
        numberOfChildren: menuOption.numberOfChildren,
        activationDate: menuOption.activationDate ? dayjs(menuOption.activationDate) : null,
        deactivationDate: menuOption.deactivationDate ? dayjs(menuOption.deactivationDate) : null,
        isActive: isActive,
      };
      if (menuOption.numberOfChildren > 0) {
        menuOptionData.children = this.recursiveSetMenusOptions(menuOption.children);
      }
      return menuOptionData;
    });
  };

  /**
   * Checks if a menu option is active, analysing its activation and deactivation dates and comparing it to the
   * actual date.
   * 
   * @param {any} record - Menu option object.
   * @returns {boolean} - true if menu option is active and false otherwise.
   */
  menuOptionIsActive = (record) => {
    const activation = record.activationDate ? dayjs(record.activationDate).subtract(1, 'h') : undefined;
    const deactivation = record.deactivationDate ? dayjs(record.deactivationDate).subtract(1, 'h')  : undefined;
    const now = dayjs();
    return activation && (activation.isBefore(now) || activation.isSame(now)) && 
      (!deactivation || (deactivation && deactivation.isAfter(now))) ? true : false;
  };

  /**
   * Fetches all menu options from database through an available API.
   * Calls `recursiveSetMenusOptions` to modify the data, regarding this component.
   */
  fetchData = () => {
    this.setLoading(true);
    const { selectedItem } = this.state;
    // Get parameters to fetch
    const fetchParams = {
      idLanguage: this.state.activeLanguage,
      groupBy: 'parent',
    };
    // Get options to fetch
    const fetchOptions = {
      method: 'GET',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      })
    };
    // Fetch data
    fetch(`${process.env.REACT_APP_API_BASE_URL}/menuOptions?${qs.stringify(fetchParams)}`, fetchOptions)
      .then((res) => res.json())
      .then(async (data) => {
        // await (new Promise((res) => {setTimeout(res, 5000); }));
        const menuOptions = this.recursiveSetMenusOptions(data);
        this.setActiveMenuOptions(menuOptions);
        this.setInactiveMenuOptions(menuOptions);
        if (selectedItem) this.setState({ 
          selectedItem: menuOptions.find((option) => option.key === selectedItem.key) 
        });
        this.setLoading(false);
      });
  };

  /**
   * Sets loading state from `this.state.loading`.
   * This state is relationated with the data fetching from database.
   *
   * @param {boolean} loading - Sets the loading state to true or false.
   */
  setLoading = (loading) => {
    this.setState({ loading: loading });
  };

  /**
   * Sets the active menu options state from `this.state.activeMenuOptions`.
   * It also sets the active menu options table parameters (`this.state.activeTableParams`).
   *
   * @param {any[]} data - the list all menu options.
   */
  setActiveMenuOptions = (data) => {
    const { activeTableParams } = this.state;
    const active = data.filter((element) => element.isActive);
    this.setState({
      activeMenuOptions: active,
      activeTableParams: {
        ...activeTableParams,
        pagination: {
          ...activeTableParams.pagination,
          total: active.length,
        }
      }
    });
  };

  /**
   * Sets the inactive menu options state from `this.state.inactiveMenuOptions`.
   * It also sets the active menu options table parameters (`this.state.inactiveTableParams`).
   *
   * @param {any[]} data - the list all menu options.
   */
  setInactiveMenuOptions = (data) => {
    const { inactiveTableParams } = this.state;
    const inactive = data.filter((element) => !element.isActive);
    this.setState({
      inactiveMenuOptions: inactive,
      inactiveTableParams: {
        ...inactiveTableParams,
        pagination: {
          ...inactiveTableParams.pagination,
          total: inactive.length,
        }
      }
    });
  };

  /**
   * Sets the active menu options table parameters (`this.state.activeTableParams`).
   * 
   * @param {any} params - The parameters to update.
   */
  setActiveTableParams = (params) => {
    this.setState({ activeTableParams: params });
  };

  /**
   * Sets the inactive menu options table parameters (`this.state.inactiveTableParams`).
   * 
   * @param {any} params - The parameters to update.
   */
  setInactiveTableParams = (params) => {
    this.setState({ inactiveTableParams: params });
  };
  
  /**
   * Handles changes on active menu options table, setting its parameters.
   * 
   * @param {any} pagination - Pagination parameters.
   * @param {any} filters - Filter parameters.
   * @param {any} sorter - Sorting parameters.
   */
  handleActiveTableChange = (pagination, filters, sorter) => {
    this.setActiveTableParams({ pagination, filters, ...sorter });
    if (this.state.activeTableParams &&
      this.state.activeTableParams.pagination &&
      this.state.activeTableParams.pagination.pageSize &&
      pagination.pageSize !== this.state.activeTableParams.pagination.pageSize) {
      this.setActiveMenuOptions([]);
    }
  };

  /**
   * Handles changes on inactive menu options table, setting its parameters.
   * 
   * @param {any} pagination - Pagination parameters.
   * @param {any} filters - Filter parameters.
   * @param {any} sorter - Sorting parameters.
   */
  handleInactiveTableChange = (pagination, filters, sorter) => {
    this.setInactiveTableParams({ pagination, filters, ...sorter });
    if (this.state.inactiveTableParams &&
      this.state.inactiveTableParams.pagination &&
      this.state.inactiveTableParams.pagination.pageSize &&
      pagination.pageSize !== this.state.inactiveTableParams.pagination.pageSize) {
      this.setInactiveMenuOptions([]);
    }
  };

  /**
   * Fetch all menu options associated with the active selected language.
   */
  fetchMenuOptions = () => {
    this.setState({ menuOptionsFetching: true });
    // Get parameters to fetch
    const fetchParams = { idLanguage: this.state.activeLanguage, order: 'title' };
    // Get options to fetch
    const fetchOptions = {
      method: 'GET',
      headers: new Headers({ 'app_secret_key': process.env.REACT_APP_API_APP_KEY })
    };
    // Fetch data
    fetch(`${process.env.REACT_APP_API_BASE_URL}/menuOptions?${qs.stringify(fetchParams)}`, fetchOptions)
      .then(async (response) => {
        if (response.ok) return response.json();
        const result = await response.json();
        throw new Error(result && result.msg ? result.msg : 'It was impossible to load menuOptions.');
      }).then(async (data) => {
        // await (new Promise((res) => {setTimeout(res, 5000); }));
        this.setState({ menuOptions: data, menuOptionsError: null, menuOptionsFetching: false });
      }).catch((error) => {
        this.setState({ menuOptions: [], menuOptionsError: error.message, menuOptionsFetching: false });
      });
  };

  /**
   * Handles the visibility state of the add menu option modal, using the state `this.state.showAddMenuOptionModal`.
   */
  handleMenuOptionModal = () => {
    const { showAddMenuOptionModal } = this.state;
    this.setState({ showAddMenuOptionModal: !showAddMenuOptionModal });
  };

  /**
   * Adds a new menu option to the system, using the API for the effect.
   *
   * @param {any} values - New menu option data.
   */
  addMenuOption = (values) => {
    this.setState({ addFetching: true });
    // Get body to fetch
    const raw = JSON.stringify({
      idLanguage: values.idLanguage,
      idParent: values.idParent ? values.idParent : undefined,
      order: values.order,
      title: values.title,
      url: values.url,
    });
    // Get options to fetch
    const fetchOptions = {
      method: 'POST',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      }),
      body: raw,
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/menuOptions`, fetchOptions)
      .then(async (response) => {
        if (response.ok) return response.json();
        const result = await response.json();
        throw new Error(result ? JSON.stringify(result) : 'Não é possível adicionar uma nova opção de menu');
      })
      .then(async (data) => {
        notification.open({
          duration: 30,
          message: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-check'></i>
              <span className='cms-notification-icon-text-span'>Opção de menu &ldquo;{data.menuOption.title}&rdquo; criada com sucesso</span>
            </div>
          ),
          description: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
              <span>A opção de menu foi adicionada com sucesso. Consulte a lista de opções de menu.</span>
            </div>
          ),
        });
        setTimeout(() => {
          this.setState({ addError: null, addFetching: false });
          this.handleMenuOptionModal();
          this.fetchData();
          this.fetchMenuOptions();
        }, 1500);
      })
      .catch((error) => {
        let message = 'Não é possível adicionar uma nova opção de menu';
        let extendedMessage = 'Por favor, reveja os dados inseridos e/ou tente novamente.';
        if (error && error.message) {
          if (error.message.includes('Your token is not valid!'))  {
            extendedMessage = 'Não tem autorização para adicionar opções de menu. Por favor, contacte o administrador da aplicação.';
          }
          if (error.message.includes('You have no permission to access this resource.'))  {
            extendedMessage = 'Não tem autorização para adicionar opções de menu. Por favor, contacte o administrador da aplicação.';
          }
          if (error.message.includes('Menu option language is required.'))  {
            message = 'Idioma selecionado inválido';
            extendedMessage = 'Por favor, selecione um idioma válido.';
          }
          if (error.message.includes('Invalid language identification.'))  {
            message = 'Idioma selecionado inválido';
            extendedMessage = 'Por favor, selecione um idioma válido.';
          }
          if (error.message.includes('Language not found.'))  {
            message = 'Idioma selecionado inválido';
            extendedMessage = 'O idioma selecionado não foi encontrado no sistema. Por favor, selecione um idioma válido.';
          }
          if (error.message.includes('Language is deactivated.'))  {
            message = 'Idioma selecionado inválido';
            extendedMessage = 'O idioma selecionado foi desativado. Por favor, selecione um idioma válido.';
          }
          if (error.message.includes('Invalid parent menu option identification.'))  {
            message = 'Opção-pai selecionada inválida';
            extendedMessage = 'Por favor, selecione uma opção-pai válida. Este campo é opcional, por isso poderá deixá-lo vazio.';
          }
          if (error.message.includes('Invalid parent identification.'))  {
            message = 'Opção-pai selecionada inválida';
            extendedMessage = 'Por favor, selecione uma opção-pai válida. Este campo é opcional, por isso poderá deixá-lo vazio.';
          }
          if (error.message.includes('Parent menu option not found.'))  {
            message = 'Opção-pai selecionada inválida';
            extendedMessage = 'A opção-pai selecionada não foi encontrada no sistema. Por favor, selecione uma opção válida. Este campo é opcional, por isso poderá deixá-lo vazio.';
          }
          if (error.message.includes('Parent menu option is deactivated.'))  {
            message = 'Opção-pai selecionada inválida';
            extendedMessage = 'A opção-pai selecionada foi desativada. Por favor, selecione uma opção válida. Este campo é opcional, por isso poderá deixá-lo vazio.';
          }
          if (error.message.includes('Invalid title. Must have between 3 and 200 characters.'))  {
            message = 'Título inválido';
            extendedMessage = 'O título da opção de menu é inválido. Por favor, insira um valor válido.';
          }
          if (error.message.includes('Invalid url.'))  {
            message = 'URL inválido';
            extendedMessage = 'O URL da opção de menu é inválido. Por favor, insira um valor válido.';
          }
          if (error.message.includes('Invalid order. Must bigger than 0.'))  {
            message = 'Ordem inválida';
            extendedMessage = 'A ordem da opção de menu é inválida. Por favor, insira um valor válido, maior que 0.';
          }
        }
        notification.open({
          duration: 30,
          message: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-xmark'></i>
              <span className='cms-notification-icon-text-span'>{message}</span>
            </div>
          ),
          description: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
              <span>{extendedMessage}</span>
            </div>
          ),
        });
        this.setState({ addError: message, addFetching: false });
      });
  };

  /**
   * Handles the visibility state of the edit menu option modal, using the state `this.state.showEditMenuOptionModal`.
   */
  handleEditMenuOptionModal = () => {
    const { showEditMenuOptionModal } = this.state;
    this.setState({ showEditMenuOptionModal: !showEditMenuOptionModal });
  };

  /**
   * Edits an existing menu option in the system, using the API for the effect.
   *
   * @param {any} values - New data of the existing menu option.
   */
  editMenuOption = (values) => {
    this.setState({ editFetching: true });
    const { selectedItem } = this.state;
    // Get body to fetch
    const raw = JSON.stringify({
      idLanguage: values.idLanguage === selectedItem.idLanguage ? undefined : values.idLanguage,
      idParent: selectedItem.idParent && !values.idParent ? null : values.idParent,
      order: values.order,
      title: values.title,
      url: values.url,
    });
    // Get options to fetch
    const fetchOptions = {
      method: 'PATCH',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      }),
      body: raw,
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/menuOptions/${selectedItem.key}`, fetchOptions)
      .then(async (response) => {
        if (response.ok) return response.json();
        const result = await response.json();
        throw new Error(result ? JSON.stringify(result) : `Não é possível editar a opção de menu '${selectedItem.title}'`);
      })
      .then(async (data) => {
        notification.open({
          duration: 30,
          message: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-check'></i>
              <span className='cms-notification-icon-text-span'>Alterações guardadas com sucesso</span>
            </div>
          ),
          description: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
              <span>A opção de menu <u>{values.title}</u> foi atualizada com sucesso. Consulte a lista de opções de menu para verificar a alteração.</span>
            </div>
          ),
        });
        setTimeout(() => {
          this.setState({ editError: null, editFetching: false });
          this.handleEditMenuOptionModal();
          this.fetchData();
          this.fetchMenuOptions();
        }, 1500);
      })
      .catch((error) => {
        let message = `Não é possível editar a opção de menu '${selectedItem.title}'`;
        let extendedMessage = 'Por favor, reveja os dados inseridos e/ou tente novamente.';
        if (error && error.message) {
          if (error.message.includes('Your token is not valid!'))  {
            extendedMessage = 'Não tem autorização para editar opções de menu. Por favor, contacte o administrador da aplicação.';
          }
          if (error.message.includes('You have no permission to access this resource.'))  {
            extendedMessage = 'Não tem autorização para editar opções de menu. Por favor, contacte o administrador da aplicação.';
          }
          if (error.message.includes('Invalid language identification.'))  {
            message = 'Idioma selecionado inválido';
            extendedMessage = 'Por favor, selecione um idioma válido.';
          }
          if (error.message.includes('Language not found.'))  {
            message = 'Idioma selecionado inválido';
            extendedMessage = 'O idioma selecionado não foi encontrado no sistema. Por favor, selecione um idioma válido.';
          }
          if (error.message.includes('Language is deactivated.'))  {
            message = 'Idioma selecionado inválido';
            extendedMessage = 'O idioma selecionado foi desativado. Por favor, selecione um idioma válido.';
          }
          if (error.message.includes('Invalid parent menu option identification.'))  {
            message = 'Opção-pai selecionada inválida';
            extendedMessage = 'Por favor, selecione uma opção-pai válida. Este campo é opcional, por isso poderá deixá-lo vazio.';
          }
          if (error.message.includes('Invalid parent identification.'))  {
            message = 'Opção-pai selecionada inválida';
            extendedMessage = 'Por favor, selecione uma opção-pai válida. Este campo é opcional, por isso poderá deixá-lo vazio.';
          }
          if (error.message.includes('Parent menu option not found.'))  {
            message = 'Opção-pai selecionada inválida';
            extendedMessage = 'A opção-pai selecionada não foi encontrada no sistema. Por favor, selecione uma opção válida. Este campo é opcional, por isso poderá deixá-lo vazio.';
          }
          if (error.message.includes('Parent menu option is deactivated.'))  {
            message = 'Opção-pai selecionada inválida';
            extendedMessage = 'A opção-pai selecionada foi desativada. Por favor, selecione uma opção válida. Este campo é opcional, por isso poderá deixá-lo vazio.';
          }
          if (error.message.includes('Invalid title. Must have between 3 and 200 characters.'))  {
            message = 'Título inválido';
            extendedMessage = 'O título da opção de menu é inválido. Por favor, insira um valor válido.';
          }
          if (error.message.includes('Invalid url.'))  {
            message = 'URL inválido';
            extendedMessage = 'O URL da opção de menu é inválido. Por favor, insira um valor válido.';
          }
          if (error.message.includes('Invalid order. Must bigger than 0.'))  {
            message = 'Ordem inválida';
            extendedMessage = 'A ordem da opção de menu é inválida. Por favor, insira um valor válido, maior que 0.';
          }
        }
        notification.open({
          duration: 30,
          message: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-xmark'></i>
              <span className='cms-notification-icon-text-span'>{message}</span>
            </div>
          ),
          description: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
              <span>{extendedMessage}</span>
            </div>
          ),
        });
        this.setState({ editError: message, editFetching: false });
      });
  };

  /**
   * Deactivates an existing menu option in the system, using the API for the effect.
   */
  deactivateMenuOption = () => {
    this.setState({ editFetching: true });
    const { selectedItem } = this.state;
    // Get options to fetch
    const fetchOptions = {
      method: 'PATCH',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      }),
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/menuOptions/${selectedItem.key}/deactivate`, fetchOptions)
      .then(async (response) => {
        if (response.ok) return response.json();
        const result = await response.json();
        throw new Error(result ? JSON.stringify(result) : `Não é possível desativar a opção de menu '${selectedItem.title}'`);
      })
      .then(async (data) => {
        notification.open({
          duration: 30,
          message: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-check'></i>
              <span className='cms-notification-icon-text-span'>Opção desativada com sucesso</span>
            </div>
          ),
          description: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
              <span>A opção de menu <u>{selectedItem.title}</u> foi desativada com sucesso. 
              Consulte a lista de opções desativadas de menu para verificar a alteração. 
              A opção pode ser reativada através do mesmo processo de desativação. </span>
            </div>
          ),
        });
        setTimeout(() => {
          this.setState({ editFetching: false });
          this.handleEditMenuOptionModal();
          this.fetchData();
          this.fetchMenuOptions();
        }, 1500);
      })
      .catch((error) => {
        let message = `Não é possível desativar a opção de menu '${selectedItem.title}'`;
        let extendedMessage = 'Por favor, tente novamente mais tarde. Se o erro persistir, contacte o administrador da aplicação.';
        if (error && error.message) {
          if (error.message.includes('Your token is not valid!'))  {
            extendedMessage = 'Não tem autorização para desativar opções de menu. Por favor, contacte o administrador da aplicação.';
          }
          if (error.message.includes('You have no permission to access this resource.'))  {
            extendedMessage = 'Não tem autorização para desativar opções de menu. Por favor, contacte o administrador da aplicação.';
          }
          if (error.message.includes('Action cannot be performed. Menu option has associated menu options.'))  {
            extendedMessage = 'A opção de menu tem sub-opções associadas. Se desejar desativar esta, deve primeiro desativar as suas sub-opções.';
          }
        }
        notification.open({
          duration: 30,
          message: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-xmark'></i>
              <span className='cms-notification-icon-text-span'>{message}</span>
            </div>
          ),
          description: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
              <span>{extendedMessage}</span>
            </div>
          ),
        });
        this.setState({ editFetching: false });
      });
  };

  /**
   * Reactivates an existing menu option in the system, using the API for the effect.
   */
  reactivateMenuOption = () => {
    this.setState({ editFetching: true });
    const { selectedItem } = this.state;
    // Get options to fetch
    const fetchOptions = {
      method: 'PATCH',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      }),
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/menuOptions/${selectedItem.key}/reactivate`, fetchOptions)
      .then(async (response) => {
        if (response.ok) return response.json();
        const result = await response.json();
        throw new Error(result ? JSON.stringify(result) : `Não é possível reativar a opção de menu '${selectedItem.title}'`);
      })
      .then(async (data) => {
        notification.open({
          duration: 30,
          message: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-check'></i>
              <span className='cms-notification-icon-text-span'>Opção reativada com sucesso</span>
            </div>
          ),
          description: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
              <span>A opção de menu <u>{selectedItem.title}</u> foi reativada com sucesso. 
              Consulte a lista de opções de menu ativas para verificar a alteração. 
              A opção pode ser desativada através do mesmo processo de reativação.</span>
            </div>
          ),
        });
        setTimeout(() => {
          this.setState({ editFetching: false });
          this.handleEditMenuOptionModal();
          this.fetchData();
          this.fetchMenuOptions();
        }, 1500);
      })
      .catch((error) => {
        let message = `Não é possível reativar a opção de menu '${selectedItem.title}'`;
        let extendedMessage = 'Por favor, tente novamente mais tarde. Se o erro persistir, contacte o administrador da aplicação.';
        if (error && error.message) {
          if (error.message.includes('Your token is not valid!'))  {
            extendedMessage = 'Não tem autorização para reativar opções de menu. Por favor, contacte o administrador da aplicação.';
          }
          if (error.message.includes('You have no permission to access this resource.'))  {
            extendedMessage = 'Não tem autorização para reativar opções de menu. Por favor, contacte o administrador da aplicação.';
          }
        }
        notification.open({
          duration: 30,
          message: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-xmark'></i>
              <span className='cms-notification-icon-text-span'>{message}</span>
            </div>
          ),
          description: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
              <span>{extendedMessage}</span>
            </div>
          ),
        });
        this.setState({ editFetching: false });
      });
  };

  /**
   * Deletes an existing menu option from the system, using the API for the effect.
   */
  deleteMenuOption = () => {
    this.setState({ editFetching: true });
    const { selectedItem } = this.state;
    // Get options to fetch
    const fetchOptions = {
      method: 'DELETE',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      }),
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/menuOptions/${selectedItem.key}`, fetchOptions)
      .then(async (response) => {
        if (response.ok) return response.json();
        const result = await response.json();
        throw new Error(result ? JSON.stringify(result) : `Não é possível apagar a opção de menu '${selectedItem.title}'`);
      })
      .then(async (data) => {
        notification.open({
          duration: 30,
          message: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-check'></i>
              <span className='cms-notification-icon-text-span'>Opção eliminada com sucesso</span>
            </div>
          ),
          description: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
              <span>A opção de menu <u>{selectedItem.title}</u> foi eliminada com sucesso. 
              Consulte a lista de opções de menu para verificar o mesmo. Esta ação <b>não é reversível</b>.</span>
            </div>
          ),
        });
        setTimeout(() => {
          this.setState({ editFetching: false });
          this.handleEditMenuOptionModal();
          this.fetchData();
          this.fetchMenuOptions();
        }, 1500);
      })
      .catch((error) => {
        let message = `Não é possível eliminar a opção de menu '${selectedItem.title}'`;
        let extendedMessage = 'Por favor, tente novamente mais tarde. Se o erro persistir, contacte o administrador da aplicação.';
        if (error && error.message) {
          if (error.message.includes('Your token is not valid!'))  {
            extendedMessage = 'Não tem autorização para eliminar opções de menu. Por favor, contacte o administrador da aplicação.';
          }
          if (error.message.includes('You have no permission to access this resource.'))  {
            extendedMessage = 'Não tem autorização para eliminar opções de menu. Por favor, contacte o administrador da aplicação.';
          }
          if (error.message.includes('Action cannot be performed. Menu option has associated menu options.'))  {
            extendedMessage = 'A opção de menu tem sub-opções associadas. Se desejar eliminar esta, deve primeiro eliminar as suas sub-opções.';
          }
        }
        notification.open({
          duration: 30,
          message: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-xmark'></i>
              <span className='cms-notification-icon-text-span'>{message}</span>
            </div>
          ),
          description: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
              <span>{extendedMessage}</span>
            </div>
          ),
        });
        this.setState({ editFetching: false });
      });
  };

  /**
   * Render method of React component.
   * 
   * @returns the component template.
   */
  render() {
    const { languages, languagesError, languagesFetching } = this.props;
    const { 
      activeLanguage, activeMenuOptions, activeTableParams, addFetching, addError, 
      defaultLanguage, editError, editFetching, inactiveMenuOptions, inactiveTableParams,
      loading, menuOptions, menuOptionsError, menuOptionsFetching, pageWidth, selectedItem, 
      showAddMenuOptionModal, showEditMenuOptionModal
    } = this.state;
    
    const isActive = localStorage.getItem('isActive');
    const idRole = localStorage.getItem('idRole');
    const role = localStorage.getItem('role');
    const isAdmin = isActive === 'true' && idRole === '376DDE5B-09C4-ED11-A311-00155D08E85F' && role === 'Administrator';
    const isManager = isActive === 'true' && idRole === '386DDE5B-09C4-ED11-A311-00155D08E85F' && role === 'Manager';

    if (isActive !== 'true' || !(isAdmin || isManager) || languagesError || (!languagesFetching && languages.length < 1)) {
      return (
        <div className='cms-menu-actions-container'>
          {/* <h1 id='cms-menu-actions-title'>Gestão de Menus e Submenus</h1> */}
          <div id='cms-menu-actions-tabs'>
            <div id='cms-menu-actions-error'>
              <i className='fa-duotone fa-arrow-rotate-right'></i>
              <b>Ocorreu um erro...</b>
              <Button type='text' onClick={() => window.location.reload(true)}>
                Clique&nbsp;<span className='cms-menu-actions-error-btn'>aqui</span>&nbsp;para refrescar a página.
              </Button>
              Ou tente mais tarde.
            </div>
          </div>
        </div>
      );
    }

    if (languagesFetching) {
      return (
        <div className='cms-menu-actions-container'>
          <div id='cms-menu-actions-error'>
            <PuffLoader color={styles.COLORS.PrimaryColor} size={100} />
            <p>A carregar...</p>
          </div>
        </div>
      );
    }

    const columns = [
      {
        title: 'Título',
        dataIndex: 'title',
        key: 'title',
        render: (_, record) => {
          const style = {};
          const isActive = record.isActive;
          if (!isActive) style.color = styles.COLORS.TertiaryTextColor;
          return (
            <>
              <Button
                onClick={() => {
                  const newSelectedItem = !selectedItem || (selectedItem && selectedItem.key !== record.key) ? record : null;
                  this.setState({ selectedItem: newSelectedItem });
                  if (newSelectedItem) this.handleEditMenuOptionModal();
                }}
                // style={style}
                title={`${record.title} ${isActive ? '' : ' (Desativado)'}`}
                type={isActive ? 'text' : 'link'}
              >
                {record.title} 
              </Button>
              {!isActive && <Tag color={styles.COLORS.QuaternaryTextColor}>Desativado</Tag>}
            </>
          );
        },
        sorter: (a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
        width: pageWidth > 1060 ? 'auto' : '160px',
      },
      {
        title: 'URL',
        dataIndex: 'url',
        key: 'url',
        render: (_, record) => {
          const style = {};
          const isActive = record.isActive;
          if (!isActive) style.color = styles.COLORS.TertiaryTextColor;
          return <a style={style} href={record.url} target='_blank' rel='noreferrer'>{record.url}</a>;
        },
        sorter: (a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
        width: pageWidth > 1060 ? 'auto' : '160px'
      },
      {
        align: 'center',
        dataIndex: 'order',
        ellipsis: true,
        key: 'order',
        render: (_, record) => {
          const style = {};
          const isActive = record.isActive;
          if (!isActive) style.color = styles.COLORS.TertiaryTextColor;
          return <span style={style}>{record.order}</span>;
        },
        sorter: (a, b) => a.order > b.order ? 1 : a.order === b.order ? 0 : -1,
        title: 'Ordem',
        width: '85px',
      },
      {
        align: 'center',
        dataIndex: 'activationDate',
        ellipsis: true,
        key: 'activationDate',
        render: (value, record) => {
          const style = {};
          if (!record.isActive) style.color = styles.COLORS.QuaternaryTextColor;
          return <span style={style}>{value ? value.add(-1, 'h').format('DD/MM/YYYY HH:mm') : '-'}</span>;
        },
        sorter: (a, b) => {
          const dateA = a.activationDate;
          const dateB = b.activationDate;
          return dateA ? ( dateB ? (dateA.isAfter(dateB) ? 1 : dateA.isSame(dateB) ? 0 : -1) : 1) : -1;
        },
        title: 'Data de início',
        width: '152px',
      },
      {
        align: 'center',
        dataIndex: 'deactivationDate',
        ellipsis: true,
        key: 'deactivationDate',
        render: (value, record) => {
          const style = {};
          if (!record.isActive) style.color = styles.COLORS.QuaternaryTextColor;
          return <span style={style}>{value ? value.add(-1, 'h').format('DD/MM/YYYY HH:mm') : '-'}</span>;
        },
        title: 'Data de fim',
        width: '160px',
      },
    ];
    
    return (
      <div className='cms-menu-actions-container'>
        {/* Actions */}
        <div className='cms-menu-actions-btns'>
          <Button
            type='primary'
            // disabled={selectedItems.length > 0}
            icon={<i className='fa-solid fa-plus'></i>}
            onClick={this.handleMenuOptionModal}
          >Nova Opção de Menu</Button>
          <Button
            type='default'
            disabled={selectedItem ? false : true}
            icon={<i className='fa-duotone fa-pen-to-square'></i>}
            onClick={this.handleEditMenuOptionModal}
          >Editar</Button>
        </div>

        {/* Tables */}
        <div id='cms-menu-actions-tabs'>
          <Tabs
            activeKey={activeLanguage}
            defaultActiveKey={defaultLanguage}
            onChange={(key) => this.setState({ activeLanguage: key })}
            items={languages.map((language) => {
              return {
                languageData: language,
                key: language.idLanguage,
                label: language.name,
                children: (
                  <div className='cms-menu-actions-tabs-item'>
                    {/* Data table */}
                    <Table
                      bordered
                      columns={columns}
                      dataSource={activeMenuOptions}
                      loading={loading}
                      onChange={this.handleActiveTableChange}
                      pagination={activeTableParams.pagination}
                      rowKey={(record) => record.key}
                      rowSelection={{
                        onChange: (selectedRowKeys, selectedRows) => {
                          this.setState({ selectedItem: selectedRows.length > 0 ? selectedRows[selectedRows.length-1] : null });
                        },
                        selectedRowKeys: selectedItem ? [selectedItem.key] : [],
                        type: 'checkbox',
                        hideSelectAll: true,
                      }}
                      scroll={{ x: 240 }}
                      size='small'
                    />

                    {inactiveMenuOptions.length > 0 &&
                      <>
                        <Divider />

                        <Collapse 
                          defaultActiveKey={[]} 
                          collapsible={inactiveMenuOptions.length > 0} 
                          ghost
                          // bordered={false}
                        >
                          <Panel header={<span className='panel-header'>Menus e submenus desativados</span>} key='deactivated_menus'>
                            <Table
                              bordered
                              columns={columns}
                              dataSource={inactiveMenuOptions}
                              loading={loading}
                              onChange={this.handleInactiveTableChange}
                              pagination={inactiveTableParams.pagination}
                              rowKey={(record) => record.key}
                              rowSelection={{
                                onChange: (selectedRowKeys, selectedRows) => this.setState({ selectedItems: selectedRows }),
                                type: 'checkbox',
                                hideSelectAll: true,
                              }}
                              scroll={{ x: 240 }}
                              size='small'
                            />
                          </Panel>
                        </Collapse>
                      </>
                    }                    
                  </div>
                )
              };
            })}
          />
        </div>

        {/* New Menu Option Modal */}
        <Modal
          afterOpenChange={() => this.fetchMenuOptions()}
          closable
          destroyOnClose
          footer={null}
          keyboard
          onCancel={this.handleMenuOptionModal}
          open={showAddMenuOptionModal}
          title={<h5 style={{marginTop: 0}}>Criar uma nova opção de menu</h5>}
        >
          <Form
            name='Add menu option'
            labelCol={{ span: 5 }}
            initialValues={{ remember: true }}
            onFinish={this.addMenuOption}
            autoComplete='on'
          >
            {/* Language */}
            <Form.Item
              hasFeedback
              label='Idioma'
              name='idLanguage'
              rules={[{ required: true, message: 'Por favor, selecione um idioma.' }]}
              initialValue={activeLanguage}
            >
              <Select
                placeholder='Selecione um idioma'
                allowClear={false}
                onChange={(value) => this.setState({ activeLanguage: value })}
              >
                {languages.map((language) => {
                  return (
                    <Option key={language.idLanguage} value={language.idLanguage}>{language.name} ({language.code})</Option>
                  );
                })}
              </Select>
            </Form.Item>

            {/* Title */}
            <Form.Item
              hasFeedback
              label='Título'
              name='title'
              rules={[
                { required: true, message: 'Por favor, introduza um título.' },
                { max: 200, message: 'Por favor, introduza um título com um máximo de 200 caracteres.' },
                { min: 3, message: 'Por favor, introduza um título com mais de 3 caracteres.' }
              ]}
            >
              <Input
                allowClear
                maxLength={200}
                minLength={3}
                placeholder='Introduzir o título da opção de menu'
                type='text'
              />
            </Form.Item>

            {/* Url */}
            <Form.Item
              hasFeedback
              label='URL'
              name='url'
              rules={[
                { required: true, message: 'Por favor, introduza um URL.' },
                { type: 'url', message: 'Por favor, introduza um URL válido.' }
              ]}
            >
              <Input placeholder='Introduzir o url para o qual a opção de menu redireciona' allowClear type='url' />
            </Form.Item>

            {/* Ordem */}
            <Form.Item
              hasFeedback
              label='Ordem'
              name='order'
              rules={[{ required: true, message: 'Por favor, introduza um valor de ordenação.' }]}
            >
              <InputNumber placeholder='Introduzir a ordem da opção' min={1} style={{ width: '100%' }} />
            </Form.Item>

            <Divider orientation='left' plain><b>Opcional</b></Divider>

            {/* idParent - optional */}
            <Form.Item
              hasFeedback
              label='Opção-pai'
              name='idParent'
              help='Ao especificar uma opção-pai, a opção de menu a criar será uma sub-opção da opção-pai selecionada.'
            >
              <Select
                allowClear
                disabled={menuOptionsError || menuOptionsFetching || menuOptions.length < 1}
                loading={menuOptionsFetching}
                placeholder='Selecione uma opção-pai (opcional)'
              >
                {menuOptions.map((menuOption) => {
                  const url = menuOption.page && menuOption.page.url ? menuOption.page.url : menuOption.url;

                  return (
                    <Option
                      key={menuOption.idMenuOption}
                      value={menuOption.idMenuOption}
                    >
                      {menuOption.title} (<span style={{ color: styles.COLORS.PrimaryColor }}>{url}</span>)
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <br></br>
            <br></br>

            <Form.Item >
              <div className='cms-add-modal-btns'>
                <Button type='default' onClick={this.handleMenuOptionModal}>Cancelar</Button>
                <Button 
                  type={addError ? 'default' : 'primary'}
                  danger={addError ? true : false}
                  htmlType='submit'
                  disabled={addFetching}>{addError ? 'Tentar novamente' : addFetching ? 'A carregar...' : 'Criar'}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>

        {/* Edit Menu Option Modal */}
        { selectedItem &&
          <Modal
            afterOpenChange={() => this.fetchMenuOptions()}
            closable
            destroyOnClose
            footer={null}
            keyboard
            onCancel={this.handleEditMenuOptionModal}
            open={showEditMenuOptionModal }
            title={
              <h5 style={{marginTop: 0}}>
                Editar opção <span style={{ color: styles.COLORS.PrimaryColor }}>{selectedItem.title}</span>
              </h5>
            }
          >
            <Form
              name='Add menu option'
              labelCol={{ span: 5 }}
              initialValues={{ remember: true }}
              onFinish={this.editMenuOption}
              autoComplete='on'
            >
              {/* Language */}
              <Form.Item
                hasFeedback
                label='Idioma'
                name='idLanguage'
                rules={[{ required: true, message: 'Por favor, selecione um idioma.' }]}
                initialValue={selectedItem.idLanguage}
              >
                <Select
                  placeholder='Selecione um idioma'
                  allowClear={false}
                >
                  {languages.map((language) => {
                    return (
                      <Option key={language.idLanguage} value={language.idLanguage}>{language.name} ({language.code})</Option>
                    );
                  })}
                </Select>
              </Form.Item>

              {/* Title */}
              <Form.Item
                hasFeedback
                initialValue={selectedItem.title}
                label='Título'
                name='title'
                rules={[
                  { required: true, message: 'Por favor, introduza um título.' },
                  { max: 200, message: 'Por favor, introduza um título com um máximo de 200 caracteres.' },
                  { min: 3, message: 'Por favor, introduza um título com mais de 3 caracteres.' }
                ]}
              >
                <Input
                  allowClear
                  maxLength={200}
                  minLength={3}
                  placeholder='Introduzir o título da opção de menu'
                  type='text'
                />
              </Form.Item>

              {/* Url */}
              <Form.Item
                hasFeedback
                initialValue={selectedItem.url}
                label='URL'
                name='url'
                rules={[
                  { required: true, message: 'Por favor, introduza um URL.' },
                  { type: 'url', message: 'Por favor, introduza um URL válido.' }
                ]}
              >
                <Input placeholder='Introduzir o url para o qual a opção de menu redireciona' allowClear type='url' />
              </Form.Item>

              {/* Ordem */}
              <Form.Item
                hasFeedback
                initialValue={selectedItem.order}
                label='Ordem'
                name='order'
                rules={[{ required: true, message: 'Por favor, introduza um valor de ordenação.' }]}
              >
                <InputNumber placeholder='Introduzir a ordem da opção' min={1} style={{ width: '100%' }} />
              </Form.Item>

              <Divider orientation='left' plain><b>Opcional</b></Divider>

              {/* idParent - optional */}
              <Form.Item
                hasFeedback
                initialValue={selectedItem.idParent}
                label='Opção-pai'
                name='idParent'
                help='Ao especificar uma opção-pai, a opção de menu a criar será uma sub-opção da opção-pai selecionada.'
              >
                <Select
                  allowClear
                  disabled={menuOptionsError || menuOptionsFetching || menuOptions.length < 1}
                  loading={menuOptionsFetching}
                  placeholder='Selecione uma opção-pai (opcional)'
                >
                  {menuOptions
                    .filter((menuOption) => menuOption.idMenuOption !== selectedItem.key)
                    .map((menuOption) => {
                      const url = menuOption.page && menuOption.page.url ? menuOption.page.url : menuOption.url;
                      return (
                        <Option
                          key={menuOption.idMenuOption}
                          value={menuOption.idMenuOption}
                        >
                          {menuOption.title} (<span style={{ color: styles.COLORS.PrimaryColor }}>{url}</span>)
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
    
              <br></br>
              <br></br>

              <Form.Item >
                <div className='cms-add-modal-btns'>
                  <Button type='default' onClick={this.handleEditMenuOptionModal} disabled={editFetching}>Cancelar</Button>
                  <Popconfirm
                    title='&nbsp;&nbsp;Apagar opção de menu'
                    description={
                      <>
                        <div>Tem a certeza que pretende apagar esta opção de menu?</div>
                        <div>Esta ação <b>não é reversível</b>.</div>
                      </>
                    }
                    okText='Apagar'
                    okButtonProps={{ danger: true }}
                    okType='primary'
                    cancelText='Cancelar'
                    onConfirm={this.deleteMenuOption}
                    icon={<i className='fa-duotone fa-circle-exclamation' style={{ color: styles.COLORS.ErrorColor }}></i>}
                  >
                    <Button danger={true} disabled={editFetching || (selectedItem.children && selectedItem.children.length > 0)} type='default'>Apagar</Button>
                  </Popconfirm>
                  {selectedItem.isActive ?
                    <Popconfirm
                      title='&nbsp;Desativar opção de menu'
                      description={
                        <>
                          <div>Tem a certeza que pretende desativar esta opção de menu?</div>
                          <div>Esta ação <b>é reversível</b>.</div>
                        </>
                      }
                      okText='Desativar'
                      okButtonProps={{ className: 'vantagens-warning-button' }}
                      okType='default'
                      cancelText='Cancelar'
                      onConfirm={this.deactivateMenuOption}
                    >
                      <Button 
                        className='vantagens-warning-button'
                        disabled={editFetching || (selectedItem.children && selectedItem.children.filter((child) => child.isActive).length > 0)}
                        type='default'
                      >Desativar</Button>
                    </Popconfirm>
                    :
                    <Popconfirm
                      title='&nbsp;Reativar opção de menu'
                      description={
                        <>
                          <div>Tem a certeza que pretende reativar esta opção de menu?</div>
                          <div>Esta ação <b>é reversível</b>.</div>
                        </>
                      }
                      okText='Reativar'
                      okButtonProps={{ className: 'vantagens-warning-button' }}
                      okType='default'
                      cancelText='Cancelar'
                      onConfirm={this.reactivateMenuOption}
                    >
                      <Button 
                        className='vantagens-warning-button'
                        disabled={editFetching}
                        type='default'
                      >Reativar</Button>
                    </Popconfirm>
                  }
                  <Button 
                    type={editError ? 'default' : 'primary'}
                    danger={editError ? true : false}
                    htmlType='submit'
                    disabled={editFetching}
                  >{editError ? 'Tentar novamente' : editFetching ? 'A carregar...' : 'Guardar'}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Modal>
        }
      </div>
    );
  }
}

/**
 * Used in the Redux pattern to reflect any updates to the Redux store and merge them into props in the current
 * component. The Redux store serves as a centralized place for the state to live in the application.
 * 
 * @param {any} state - Centralized state of the application.
 * @returns {any} the state of the application as the component props.
 */
const mapPropsToState = (state) => {
  const languages = state.languages;
  // const menuOptions = state.menuOptions;

  return {
    languages: languages.privilegedData,
    languagesError: languages.error,
    languagesFetching: languages.fetching,
  };
};

/**
 * Used in the Redux pattern to dispatch actions to the Redux store, triggering a state change.
 * 
 * @param {any} dispatch - function of the Redux store.
 * @returns {any} the dispatch functions as components props.
 */
const mapDispatchToState = (dispatch) => {
  return {
    getLanguages: (token) => dispatch(getAllLanguages(token)),
  };
};

export default withRouter(connect(mapPropsToState, mapDispatchToState)(ManageMenus));