export const FONTS = {
  FontSize: '14px',
  FontSizeSM: '12px',
  FontSizeLG: '16px',
  FontSizeXL: '20px',

  FontSizeH1: '38px',
  FontSizeH2: '30px',
  FontSizeH3: '24px',
  FontSizeH4: '20px',
  FontSizeH5: '18px',
};