import React from 'react';
import dayjs from 'dayjs';
import qs from 'qs';
import { connect } from 'react-redux';
import { withRouter } from '../../utils';
import { getAllLanguages } from '../../reducers/Languages';
import { Button, ColorPicker, Form, Input, Modal, Popconfirm, Select, Table, Tabs, Tag, notification } from 'antd';
import { PuffLoader } from 'react-spinners';
import { styles } from '../../styles';
import { ColorFactory } from 'antd/es/color-picker/color';

const { Option } = Select;

/**
 * ManageCategories component represents the page template that allows to manage the application categories.
 * It displays a table with all active categories and a table with all inactive categories.
 * It allows tools to create, edit, deactivate, reactivate and delete categories.
 */
class ManageCategories extends React.Component {
  /**
   * The constructor for a React component is called before it is mounted.
   * When implementing the constructor, `super(props)` must be called before any
   * other statement. Otherwise, this.props will be undefined in the constructor,
   * which can lead to bugs.
   *
   * A React constructor is only used for two purposes:
   * - Initializing local state by assigning an object to this.state.
   * - Binding event handler methods to an instance.
   *
   * Instead of calling `setState()` in the `constructor(), the initial state must be
   * directly assigned to `this.state` in the constructor
   *
   * @param {*} props - Arbitrary component inputs.
   */
  constructor() {
    super();
    this.state = {
      activeLanguage: process.env.REACT_APP_LANGUAGE_ID,
      addError: null,
      addFetching: false,
      categories: [],
      categoriesError: null,
      defaultLanguage: process.env.REACT_APP_LANGUAGE_ID,
      editError: null,
      editFetching: false,
      loading: false,
      pageWidth: 0,
      selectedItem: null,
      showAddCategoryModal: false,
      showEditCategoryModal: false,
    };
  }

  /**
   * Allows to execute the React code when the component is already placed in the DOM (Document Object Model).
   * This method is called during the Mounting phase of the React Life-cycle i.e after the component is rendered.
   */
  componentDidMount() {
    // Get data
    this.props.getLanguages(window.localStorage.getItem('token'));
    this.fetchData();
    // Scroll to top
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Add event on resize
    this.setState({ pageWidth: window.innerWidth });
    window.addEventListener('resize', () => this.setState({ pageWidth: window.innerWidth }));
  }

  /**
   * Allows React to call this function immediately after this component has been re-rendered with
   * updated `props` and/or `state`. This method is not called for the initial render.
   * 
   * This allows to manipulate the DOM after an update. It is also a common place to do network requests
   * as long as it compares the current `props` to previous `props` (e.g. a network request may not be necessary
   * if the `props` have not changed).
   * 
   * @param {any} prevProps - Props before the update. Compare prevProps to this.props to determine what changed.
   * @param {any} prevState - State before the update. Compare prevState to this.state to determine what changed.
   * @param {any} snapshot - Used when function getSnapshotBeforeUpdate is implemented. It will contain the value returned from that function. Otherwise, it will be undefined.
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.activeLanguage !== this.state.activeLanguage) this.fetchData(this.state.activeLanguage);
  }

  /**
   * Recursively sets the array of categories to manage. It is useful because categories follow a model of a Tree,
   * which means that a category can have 0 or N (sub-)categories. Those sub-categories are represented in the
   * `children` array.
   * 
   * @param {any[]} data - Original data of categories. 
   * @returns {any[]} the modified dataset of categories.
   */
  setCategories = (data) => {
    return data.map((category) => {
      return {
        key: category.idCategory,
        idParent: category.idParent,
        idLanguage: category.language.idLanguage,
        name: category.name,
        color: category.color,
        image: category.image,
        numberOfProtocols: category.numberOfProtocols,
        activationDate: category.activationDate ? dayjs(category.activationDate) : null,
        deactivationDate: category.deactivationDate ? dayjs(category.deactivationDate) : null,
        isActive: this.categoryIsActive(category),
      };
    });
  };

  /**
   * Checks if a category is active, analysing its activation and deactivation dates and comparing it to the
   * actual date.
   * 
   * @param {any} record - Category object.
   * @returns {boolean} - true if category is active and false otherwise.
   */
  categoryIsActive = (record) => {
    const activation = record.activationDate ? dayjs(record.activationDate).subtract(1, 'h') : undefined;
    const deactivation = record.deactivationDate ? dayjs(record.deactivationDate).subtract(1, 'h')  : undefined;
    const now = dayjs();
    return activation && (activation.isBefore(now) || activation.isSame(now)) && 
      (!deactivation || (deactivation && deactivation.isAfter(now))) ? true : false;
  };

  /**
   * Fetches all categories from database through an available API.
   * Calls `setCategories` to modify the data, regarding this component.
   */
  fetchData = () => {
    this.setLoading(true);
    const { selectedItem } = this.state;
    // Get parameters to fetch
    const fetchParams = { idLanguage: this.state.activeLanguage };
    // Get options to fetch
    const fetchOptions = {
      method: 'GET',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      })
    };
    // Fetch data
    fetch(`${process.env.REACT_APP_API_BASE_URL}/categories?${qs.stringify(fetchParams)}`, fetchOptions)
      .then(async (response) => { 
        if (response.ok) return response.json();
        const result = await response.json();
        throw new Error(result ? JSON.stringify(result) : 'Não é possível obter as categorias.');
      }).then(async (data) => {
        // await (new Promise((res) => {setTimeout(res, 5000); }));
        const categories = this.setCategories(data);
        this.setState({ categories: categories, categoriesError: null });
        if (selectedItem) this.setState({ 
          selectedItem: categories.find((category) => category.key === selectedItem.key)
        });
        this.setLoading(false);
      }).catch((error) => {
        this.setState({ categories: [], categoriesError: error.message, selectedItem: null });
        this.setLoading(false);
      });
  };

  /**
   * Sets loading state from `this.state.loading`.
   * This state is relationated with the data fetching from database.
   *
   * @param {boolean} loading - Sets the loading state to true or false.
   */
  setLoading = (loading) => {
    this.setState({ loading: loading });
  };
  
  /**
   * Handles the visibility state of the add category modal, using the state `this.state.showAddCategoryModal`.
   */
  handleAddCategoryModal = () => {
    const { showAddCategoryModal } = this.state;
    this.setState({ showAddCategoryModal: !showAddCategoryModal });
  };

  /**
   * Adds a new category to the system, using the API for the effect.
   *
   * @param {any} values - New category data.
   */
  addCategory = (values) => {
    this.setState({ addFetching: true });
    // Get body to fetch
    const raw = JSON.stringify({
      idLanguage: values.idLanguage,
      name: values.name,
      color: `#${values.color.toHex()}`,
    });
    // Get options to fetch
    const fetchOptions = {
      method: 'POST',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      }),
      body: raw,
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/categories`, fetchOptions).then(async (response) => {
      if (response.ok) return response.json();
      const result = await response.json();
      throw new Error(result ? JSON.stringify(result) : 'Não é possível adicionar uma nova categoria');
    }).then(async (data) => {
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check'></i>
            <span className='cms-notification-icon-text-span'>Categoria &ldquo;{data.category.name}&rdquo; criada com sucesso</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
            <span>A categoria foi adicionada com sucesso. Consulte a lista de categorias.</span>
          </div>
        ),
      });
      setTimeout(() => {
        this.setState({ addError: null, addFetching: false });
        this.handleAddCategoryModal();
        this.fetchData();
      }, 1500);
    }).catch((error) => {
      let message = 'Não é possível adicionar uma nova categoria';
      let extendedMessage = 'Por favor, reveja os dados inseridos e/ou tente novamente.';
      if (error && error.message) {
        if (error.message.includes('Your token is not valid!')) {
          extendedMessage = 'Não tem autorização para adicionar categorias. Por favor, contacte o administrador da aplicação.';
        }
        if (error.message.includes('You have no permission to access this resource.')) {
          extendedMessage = 'Não tem autorização para adicionar categorias. Por favor, contacte o administrador da aplicação.';
        }
        if (error.message.includes('Category language is required.')) {
          message = 'Idioma selecionado inválido';
          extendedMessage = 'Por favor, selecione um idioma válido.';
        }
        if (error.message.includes('Invalid language identification.')) {
          message = 'Idioma selecionado inválido';
          extendedMessage = 'Por favor, selecione um idioma válido.';
        }
        if (error.message.includes('Language not found.')) {
          message = 'Idioma selecionado inválido';
          extendedMessage = 'O idioma selecionado não foi encontrado no sistema. Por favor, selecione um idioma válido.';
        }
        if (error.message.includes('Language is deactivated.')) {
          message = 'Idioma selecionado inválido';
          extendedMessage = 'O idioma selecionado foi desativado. Por favor, selecione um idioma válido.';
        }
        if (error.message.includes('Invalid parent category identification.')) {
          message = 'Categoria-pai selecionada inválida';
          extendedMessage = 'Por favor, selecione uma categoria-pai válida. Este campo é opcional, por isso poderá deixá-lo vazio.';
        }
        if (error.message.includes('Parent category not found.')) {
          message = 'Categoria-pai selecionada inválida';
          extendedMessage = 'A categoria-pai selecionada não foi encontrada no sistema. Por favor, selecione uma categoria válida. Este campo é opcional, por isso poderá deixá-lo vazio.';
        }
        if (error.message.includes('Parent category is deactivated.')) {
          message = 'Categoria-pai selecionada inválida';
          extendedMessage = 'A categoria-pai selecionada foi desativada. Por favor, selecione uma categoria válida. Este campo é opcional, por isso poderá deixá-lo vazio.';
        }
        if (error.message.includes('Invalid name. Must have between 3 and 100 characters.')) {
          message = 'Nome inválido';
          extendedMessage = 'O nome da categoria é inválido. Por favor, insira um valor válido.';
        }
        if (error.message.includes('Invalid color. Must have between 3 and 10 characters. Must be of type HEX.')) {
          message = 'Cor inválida';
          extendedMessage = 'A cor da categoria é inválida. Por favor, insira um valor válido.';
        }
        if (error.message.includes('Category name already exists for the specified language')) {
          extendedMessage = 'Esta categoria já existe, no idioma selecionado. Altere o nome da categoria ou o seu idioma.';
        }
        // if (error.message.includes('Invalid image URL.')) { }
      }
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'></i>
            <span className='cms-notification-icon-text-span'>{message}</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
            <span>{extendedMessage}</span>
          </div>
        ),
      });
      this.setState({ addError: message, addFetching: false });
    });
  };

  /**
   * Handles the visibility state of the edit category modal, using the state `this.state.showEditCategoryModal`.
   */
  handleEditCategoryModal = () => {
    const { showEditCategoryModal } = this.state;
    this.setState({ showEditCategoryModal: !showEditCategoryModal });
  };

  /**
   * Edits an existing category in the system, using the API for the effect.
   *
   * @param {any} values - New data of the existing category.
   */
  editCategory = (values) => {
    this.setState({ editFetching: true });
    const { selectedItem } = this.state;
    // Get body to fetch
    const raw = JSON.stringify({ idLanguage: values.idLanguage, name: values.name, color: `#${values.color.toHex()}` });
    // Get options to fetch
    const fetchOptions = {
      method: 'PATCH',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      }),
      body: raw,
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/categories/${selectedItem.key}`, fetchOptions).then(async (response) => {
      if (response.ok) return response.json();
      const result = await response.json();
      throw new Error(result ? JSON.stringify(result) : `Não é possível editar a categoria '${selectedItem.name}'`);
    }).then(async (data) => {
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check'></i>
            <span className='cms-notification-icon-text-span'>Alterações guardadas com sucesso</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
            <span>A categoria <u>{values.name}</u> foi atualizada com sucesso. Consulte a lista de categorias para verificar a alteração.</span>
          </div>
        ),
      });
      setTimeout(() => {
        this.setState({ editError: null, editFetching: false });
        this.handleEditCategoryModal();
        this.fetchData();
      }, 1500);
    }).catch((error) => {
      let message = `Não é possível editar a categoria '${selectedItem.name}'`;
      let extendedMessage = 'Por favor, reveja os dados inseridos e/ou tente novamente.';
      if (error && error.message) {
        if (error.message.includes('Your token is not valid!')) {
          extendedMessage = 'Não tem autorização para editar categorias. Tente reentrar na sua conta. Caso o erro persista, por favor, contacte o administrador da aplicação.';
        }
        if (error.message.includes('You have no permission to access this resource.')) {
          extendedMessage = 'Não tem autorização para editar categorias. Por favor, contacte o administrador da aplicação.';
        }
        if (error.message.includes('Category language is required.')) {
          message = 'Idioma selecionado inválido';
          extendedMessage = 'Por favor, selecione um idioma válido.';
        }
        if (error.message.includes('Invalid language identification.')) {
          message = 'Idioma selecionado inválido';
          extendedMessage = 'Por favor, selecione um idioma válido.';
        }
        if (error.message.includes('Language not found.')) {
          message = 'Idioma selecionado inválido';
          extendedMessage = 'O idioma selecionado não foi encontrado no sistema. Por favor, selecione um idioma válido.';
        }
        if (error.message.includes('Language is deactivated.')) {
          message = 'Idioma selecionado inválido';
          extendedMessage = 'O idioma selecionado foi desativado. Por favor, selecione um idioma válido.';
        }
        if (error.message.includes('Invalid parent category identification.')) {
          message = 'Categoria-pai selecionada inválida';
          extendedMessage = 'Por favor, selecione uma categoria-pai válida. Este campo é opcional, por isso poderá deixá-lo vazio.';
        }
        if (error.message.includes('Parent category not found.')) {
          message = 'Categoria-pai selecionada inválida';
          extendedMessage = 'A categoria-pai selecionada não foi encontrada no sistema. Por favor, selecione uma categoria válida. Este campo é opcional, por isso poderá deixá-lo vazio.';
        }
        if (error.message.includes('Parent category is deactivated.')) {
          message = 'Categoria-pai selecionada inválida';
          extendedMessage = 'A categoria-pai selecionada foi desativada. Por favor, selecione uma categoria válida. Este campo é opcional, por isso poderá deixá-lo vazio.';
        }
        if (error.message.includes('Invalid name. Must have between 3 and 100 characters.')) {
          message = 'Nome inválido';
          extendedMessage = 'O nome da categoria é inválido. Por favor, insira um valor válido.';
        }
        if (error.message.includes('Invalid color. Must have between 3 and 10 characters. Must be of type HEX.')) {
          message = 'Cor inválida';
          extendedMessage = 'A cor da categoria é inválida. Por favor, insira um valor válido.';
        }
        // if (error.message.includes('Invalid image URL.')) { }
      }
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'></i>
            <span className='cms-notification-icon-text-span'>{message}</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
            <span>{extendedMessage}</span>
          </div>
        ),
      });
      this.setState({ editError: message, editFetching: false });
    });
  };

  /**
   * Deactivates an existing category in the system, using the API for the effect.
   */
  deactivateCategory = () => {
    this.setState({ editFetching: true });
    const { selectedItem } = this.state;
    // Get options to fetch
    const fetchOptions = {
      method: 'PATCH',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      }),
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/categories/${selectedItem.key}/deactivate`, fetchOptions).then(async (response) => {
      if (response.ok) return response.json();
      const result = await response.json();
      throw new Error(result ? JSON.stringify(result) : `Não é possível desativar a categoria '${selectedItem.name}'`);
    }).then(async (data) => {
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check'></i>
            <span className='cms-notification-icon-text-span'>Categoria desativada com sucesso</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
            <span>A categoria <u>{selectedItem.name}</u> foi desativada com sucesso. 
              Consulte a lista de categorias desativadas para verificar a alteração. 
              A categoria pode ser reativada através do mesmo processo de desativação.</span>
          </div>
        ),
      });
      setTimeout(() => {
        this.setState({ editFetching: false });
        this.handleEditCategoryModal();
        this.fetchData();
      }, 1500);
    }).catch((error) => {
      let message = `Não é possível desativar a categoria '${selectedItem.name}'`;
      let extendedMessage = 'Por favor, tente novamente mais tarde. Se o erro persistir, contacte o administrador da aplicação.';
      if (error && error.message) {
        if (error.message.includes('Your token is not valid!'))  {
          extendedMessage = 'Não tem autorização para desativar categorias. Por favor, contacte o administrador da aplicação.';
        }
        if (error.message.includes('You have no permission to access this resource.'))  {
          extendedMessage = 'Não tem autorização para desativar categorias. Por favor, contacte o administrador da aplicação.';
        }
        if (error.message.includes('Action cannot be performed. Category has associated protocols.'))  {
          extendedMessage = 'A categoria tem vantagens associadas. Se desejar desativar esta, deve primeiro retirar as vantagens desta categoria.';
        }
        if (error.message.includes('Action cannot be performed. Category has associated subcategories.'))  {
          extendedMessage = 'A categoria tem sub-categorias associadas. Se desejar desativar esta, deve primeiro eliminar as suas sub-categorias.';
        }
      }
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'></i>
            <span className='cms-notification-icon-text-span'>{message}</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
            <span>{extendedMessage}</span>
          </div>
        ),
      });
      this.setState({ editFetching: false });
    });
  };

  /**
   * Reactivates an existing category in the system, using the API for the effect.
   */
  reactivateCategory = () => {
    this.setState({ editFetching: true });
    const { selectedItem } = this.state;
    // Get options to fetch
    const fetchOptions = {
      method: 'PATCH',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      }),
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/categories/${selectedItem.key}/reactivate`, fetchOptions).then(async (response) => {
      if (response.ok) return response.json();
      const result = await response.json();
      throw new Error(result ? JSON.stringify(result) : `Não é possível reativar a categoria '${selectedItem.name}'`);
    }).then(async (data) => {
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check'></i>
            <span className='cms-notification-icon-text-span'>Categoria reativada com sucesso</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
            <span>A categoria <u>{selectedItem.name}</u> foi reativada com sucesso. 
              Consulte a lista de categorias ativas para verificar a alteração. 
              A categoria pode ser reativada através do mesmo processo de reativação.</span>
          </div>
        ),
      });
      setTimeout(() => {
        this.setState({ editFetching: false });
        this.handleEditCategoryModal();
        this.fetchData();
      }, 1500);
    }).catch((error) => {
      let message = `Não é possível reativar a categoria '${selectedItem.name}'`;
      let extendedMessage = 'Por favor, tente novamente mais tarde. Se o erro persistir, contacte o administrador da aplicação.';
      if (error && error.message) {
        if (error.message.includes('Your token is not valid!'))  {
          extendedMessage = 'Não tem autorização para reativar categorias. Por favor, contacte o administrador da aplicação.';
        }
        if (error.message.includes('You have no permission to access this resource.'))  {
          extendedMessage = 'Não tem autorização para reativar categorias. Por favor, contacte o administrador da aplicação.';
        }
      }
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'></i>
            <span className='cms-notification-icon-text-span'>{message}</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
            <span>{extendedMessage}</span>
          </div>
        ),
      });
      this.setState({ editFetching: false });
    });
  };

  /**
   * Deletes an existing category from the system, using the API for the effect.
   */
  deleteCategory = () => {
    this.setState({ editFetching: true });
    const { selectedItem } = this.state;
    // Get options to fetch
    const fetchOptions = {
      method: 'DELETE',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      }),
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/categories/${selectedItem.key}`, fetchOptions).then(async (response) => {
      if (response.ok) return response.json();
      const result = await response.json();
      throw new Error(result ? JSON.stringify(result) : `Não é possível apagar a categoria '${selectedItem.name}'`);
    }).then(async (data) => {
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check'></i>
            <span className='cms-notification-icon-text-span'>Categoria eliminada com sucesso</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
            <span>A categoria <u>{selectedItem.name}</u> foi eliminada com sucesso. Consulte a lista de categorias 
              para verificar a atualização da mesma. Esta ação <b>não é reversível</b>.</span>
          </div>
        ),
      });
      setTimeout(() => {
        this.setState({ editFetching: false });
        this.handleEditCategoryModal();
        this.fetchData();
      }, 1500);
    }).catch((error) => {
      let message = `Não é possível eliminar a categoria '${selectedItem.name}'`;
      let extendedMessage = 'Por favor, tente novamente mais tarde. Se o erro persistir, contacte o administrador da aplicação.';
      if (error && error.message) {
        if (error.message.includes('Your token is not valid!'))  {
          extendedMessage = 'Não tem autorização para eliminar categorias. Tente reentrar na sua conta. Se o erro persistir, por favor, contacte o administrador da aplicação.';
        }
        if (error.message.includes('You have no permission to access this resource.'))  {
          extendedMessage = 'Não tem autorização para eliminar categorias. Por favor, contacte o administrador da aplicação.';
        }
        if (error.message.includes('Action cannot be performed. Category has associated protocols.'))  {
          extendedMessage = 'A categoria tem vantagens associadas. Se desejar eliminar esta, deve primeiro eliminar as vantagens associadas, ou associá-las a outra categoria.';
        }
        if (error.message.includes('Action cannot be performed. Category has associated subcategories.'))  {
          extendedMessage = 'A categoria tem sub-categorias associadas. Se desejar eliminar esta, deve primeiro eliminar as suas sub-categorias.';
        }
      }
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'></i>
            <span className='cms-notification-icon-text-span'>{message}</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
            <span>{extendedMessage}</span>
          </div>
        ),
      });
      this.setState({ editFetching: false });
    });
  };

  /**
   * Render method of React component.
   * 
   * @returns the component template.
   */
  render() {
    const { languages, languagesError, languagesFetching } = this.props;
    const { 
      activeLanguage,addFetching, addError, categories, categoriesError, defaultLanguage, editError, 
      editFetching, loading, pageWidth, selectedItem, showAddCategoryModal, showEditCategoryModal,
    } = this.state;

    const isActive = localStorage.getItem('isActive');
    const idRole = localStorage.getItem('idRole');
    const role = localStorage.getItem('role');
    const isAdmin = isActive === 'true' && idRole === '376DDE5B-09C4-ED11-A311-00155D08E85F' && role === 'Administrator';
    const isManager = isActive === 'true' && idRole === '386DDE5B-09C4-ED11-A311-00155D08E85F' && role === 'Manager';
    
    if (isActive !== 'true' || !(isAdmin || isManager) || categoriesError || languagesError || (!languagesFetching && languages.length < 1)) {
      return (
        <div className='cms-menu-actions-container'>
          {/* <h1 id='cms-menu-actions-title'>Gestão de Categorias e Sub-Categorias</h1> */}
          <div id='cms-menu-actions-tabs'>
            <div id='cms-menu-actions-error'>
              <i className='fa-duotone fa-arrow-rotate-right'></i>
              <b>Ocorreu um erro...</b>
              <Button type='text' onClick={() => window.location.reload(true)}>
                Clique&nbsp;<span className='cms-menu-actions-error-btn'>aqui</span>&nbsp;para refrescar a página.
              </Button>
              Ou tente mais tarde.
            </div>
          </div>
        </div>
      );
    }

    if (languagesFetching) {
      return (
        <div className='cms-menu-actions-container'>
          <div id='cms-menu-actions-error'>
            <PuffLoader color={styles.COLORS.PrimaryColor} size={100} />
            <p>A carregar...</p>
          </div>
        </div>
      );
    }

    const columns = [
      {
        title: 'Cor',
        dataIndex: 'color',
        key: 'color',
        render: (_, record) => <ColorPicker open={false} value={record.color} />,
        width: '50px'
      },
      {
        title: 'Nome',
        dataIndex: 'name',
        key: 'name',
        render: (_, record) => {
          const style = {};
          const isActive = record.isActive;
          if (!isActive) style.color = styles.COLORS.TertiaryTextColor;
          return (
            <>
              <Button
                onClick={() => {
                  const newSelectedItem = !selectedItem || (selectedItem && selectedItem.key !== record.key) ? record : null;
                  this.setState({ selectedItem: newSelectedItem });
                  if (newSelectedItem) this.handleEditCategoryModal();
                }}
                title={`${record.name} ${isActive ? '' : ' (Desativada)'}`}
                type={isActive ? 'text' : 'link'}
              >
                {record.name} 
              </Button>
              {!isActive && <Tag color={styles.COLORS.QuaternaryTextColor}>Desativada</Tag>}
            </>
          );
        },
        sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        width: pageWidth > 1000 ? 'auto' : '160px'
      },
      {
        align: 'center',
        dataIndex: 'numberOfProtocols',
        ellipsis: true,
        key: 'numberOfProtocols',
        render: (_, record) => {
          const style = {};
          if (!record.isActive) style.color = styles.COLORS.TertiaryTextColor;
          return <span style={style}>{record.numberOfProtocols}</span>;
        },
        sorter: (a, b) => a.numberOfProtocols > b.numberOfProtocols ? 1 : a.numberOfProtocols === b.numberOfProtocols ? 0 : -1,
        title: 'Vantagens',
        width: '120px',
      },
      {
        align: 'center',
        dataIndex: 'activationDate',
        ellipsis: true,
        key: 'activationDate',
        render: (value, record) => {
          const style = {};
          if (!record.isActive) style.color = styles.COLORS.QuaternaryTextColor;
          return <span style={style}>{value ? value.add(-1, 'h').format('DD/MM/YYYY HH:mm') : '-'}</span>;
        },
        sorter: (a, b) => {
          const dateA = a.activationDate;
          const dateB = b.activationDate;
          return dateA ? ( dateB ? (dateA.isAfter(dateB) ? 1 : dateA.isSame(dateB) ? 0 : -1) : 1) : -1;
        },
        title: 'Data de início',
        width: '152px',
      },
      {
        align: 'center',
        dataIndex: 'deactivationDate',
        ellipsis: true,
        key: 'deactivationDate',
        render: (value, record) => {
          const style = {};
          if (!record.isActive) style.color = styles.COLORS.QuaternaryTextColor;
          return <span style={style}>{value ? value.add(-1, 'h').format('DD/MM/YYYY HH:mm') : '-'}</span>;
        },
        title: 'Data de fim',
        width: '160px',
      },
    ];

    return (
      <div className='cms-menu-actions-container'>
        {/* Actions */}
        <div className='cms-menu-actions-btns'>
          <Button
            type='primary'
            icon={<i className='fa-solid fa-plus'></i>}
            onClick={this.handleAddCategoryModal}
          >Adicionar Categoria</Button>
          <Button
            type='default'
            disabled={selectedItem ? false : true}
            icon={<i className='fa-duotone fa-pen-to-square'></i>}
            onClick={this.handleEditCategoryModal}
          >Editar Categoria</Button>
        </div>
        
        {/* Tables */}
        <div id='cms-menu-actions-tabs'>
          <Tabs
            activeKey={activeLanguage}
            defaultActiveKey={defaultLanguage}
            onChange={(key) => this.setState({ activeLanguage: key })}
            items={languages.map((language) => {
              return {
                languageData: language,
                key: language.idLanguage,
                label: language.name,
                children: (
                  <div className='cms-menu-actions-tabs-item'>
                    {/* Data table */}
                    <Table
                      bordered
                      columns={columns}
                      dataSource={categories}
                      loading={loading}
                      pagination={{ size: 'default' }}
                      rowKey={(record) => record.key}
                      rowSelection={{
                        onChange: (selectedRowKeys, selectedRows) => {
                          this.setState({ selectedItem: selectedRows.length > 0 ? selectedRows[selectedRows.length-1] : null });
                        },
                        selectedRowKeys: selectedItem ? [selectedItem.key] : [],
                        type: 'checkbox',
                        hideSelectAll: true,
                      }}
                      scroll={{ x: 240 }}
                      size='small'
                    />                 
                  </div>
                )
              };
            })}
          />
        </div>

        {/* New Category Modal */}
        <Modal closable destroyOnClose footer={null} keyboard
          onCancel={this.handleAddCategoryModal}
          open={showAddCategoryModal}
          title={<h5 style={{marginTop: 0}}>Criar uma nova categoria</h5>}
        >
          <Form
            name='Add category'
            labelCol={{ span: 4 }}
            initialValues={{ remember: true }}
            onFinish={this.addCategory}
            autoComplete='on'
          >
            {/* Language */}
            <Form.Item
              hasFeedback
              label='Idioma'
              name='idLanguage'
              rules={[{ required: true, message: 'Por favor, selecione um idioma.' }]}
              initialValue={activeLanguage}
            >
              <Select
                allowClear={false}
                filterOption={(input, option) => {
                  const language = languages.find((language) => language.idLanguage === option.value);
                  return language && `${language.name} (${language.code})`.toLowerCase().includes(input.toLowerCase());
                }}
                placeholder='Selecione um idioma'
                showSearch
              >
                {languages.map((language) => {
                  return (
                    <Option key={language.idLanguage} value={language.idLanguage}>{language.name} ({language.code})</Option>
                  );
                })}
              </Select>
            </Form.Item>

            {/* Name */}
            <Form.Item
              hasFeedback
              label='Nome'
              name='name'
              rules={[
                { required: true, message: 'Por favor, introduza um nome.' },
                { max: 100, message: 'Por favor, introduza um nome com um máximo de 100 caracteres.' },
                { min: 3, message: 'Por favor, introduza um nome com mais de 3 caracteres.' }
              ]}
            >
              <Input allowClear maxLength={100} minLength={3} placeholder='Introduzir o nome da categoria' type='text' />
            </Form.Item>

            {/* Color */}
            <Form.Item
              hasFeedback
              label='Cor'
              name='color'
              rules={[{ required: true, message: 'Por favor, selecione uma cor.' }]}
            >
              <ColorPicker allowClear arrow presets={[
                {
                  label: 'Cor Primária',
                  colors: [
                    styles.COLORS.PrimaryActiveColor,
                    styles.COLORS.PrimaryColor,
                    styles.COLORS.PrimaryHoverColor,
                    styles.COLORS.PrimaryBorderHoverColor,
                    styles.COLORS.PrimaryBorderColor,
                    styles.COLORS.PrimaryBgHoverColor,
                    styles.COLORS.PrimaryBgColor,
                  ]
                },
                {
                  label: 'Cor Secondária',
                  colors: [
                    styles.COLORS.SecondaryActiveColor,
                    styles.COLORS.SecondaryColor,
                    styles.COLORS.SecondaryHoverColor,
                    styles.COLORS.SecondaryBorderHoverColor,
                    styles.COLORS.SecondaryBgHoverColor,
                    styles.COLORS.SecondaryBgColor,
                    styles.COLORS.SecondaryBorderColor,
                  ]
                },
                {
                  label: 'Cores Netras',
                  colors: [
                    styles.COLORS.TextColor,
                    styles.COLORS.SecondaryTextColor,
                    styles.COLORS.TertiaryTextColor,
                    styles.COLORS.QuaternaryTextColor,
                    styles.COLORS.FillColor,
                    styles.COLORS.SecondaryFillColor,
                    styles.COLORS.BgContainerColor,
                  ]
                }
              ]} />
            </Form.Item>

            {/* Buttons */}
            <Form.Item >
              <div className='cms-add-modal-btns'>
                <Button type='default' onClick={this.handleAddCategoryModal}>Cancelar</Button>
                <Button 
                  type={addError ? 'default' : 'primary'}
                  danger={addError ? true : false}
                  htmlType='submit'
                  disabled={addFetching}>{addError ? 'Tentar novamente' : addFetching ? 'A carregar...' : 'Criar'}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>

        {/* Edit Category Modal */}
        { selectedItem &&
          <Modal closable destroyOnClose footer={null} keyboard
            onCancel={this.handleEditCategoryModal}
            open={showEditCategoryModal}
            title={<h5 style={{marginTop: 0}}>Editar categoria <span style={{ color: styles.COLORS.PrimaryColor }}>{selectedItem.name}</span></h5>}
          >
            <Form
              name='Edit category'
              labelCol={{ span: 4 }}
              initialValues={{ remember: true }}
              onFinish={this.editCategory}
              autoComplete='on'
            >
              {/* Language */}
              <Form.Item
                hasFeedback
                label='Idioma'
                name='idLanguage'
                rules={[{ required: true, message: 'Por favor, selecione um idioma.' }]}
                initialValue={selectedItem.idLanguage}
              >
                <Select
                  allowClear={false}
                  filterOption={(input, option) => {
                    const language = languages.find((language) => language.idLanguage === option.value);
                    return language && `${language.name} (${language.code})`.toLowerCase().includes(input.toLowerCase());
                  }}
                  placeholder='Selecione um idioma'
                  showSearch
                >
                  {languages.map((language) => {
                    return <Option key={language.idLanguage} value={language.idLanguage}>{language.name} ({language.code})</Option>;
                  })}
                </Select>
              </Form.Item>

              {/* Name */}
              <Form.Item
                hasFeedback
                initialValue={selectedItem.name}
                label='Nome'
                name='name'
                rules={[
                  { required: true, message: 'Por favor, introduza um nome.' },
                  { max: 100, message: 'Por favor, introduza um nome com um máximo de 100 caracteres.' },
                  { min: 3, message: 'Por favor, introduza um nome com mais de 3 caracteres.' }
                ]}
              >
                <Input allowClear maxLength={100} minLength={3} placeholder='Introduzir o nome da categoria' type='text' />
              </Form.Item>

              {/* Color */}
              <Form.Item
                hasFeedback
                initialValue={new ColorFactory(selectedItem.color.substring(1))}
                label='Cor'
                name='color'
                rules={[{ required: true, message: 'Por favor, selecione uma cor.' }]}
              >
                <ColorPicker allowClear arrow presets={[
                  {
                    label: 'Cor Primária',
                    colors: [
                      styles.COLORS.PrimaryActiveColor,
                      styles.COLORS.PrimaryColor,
                      styles.COLORS.PrimaryHoverColor,
                      styles.COLORS.PrimaryBorderHoverColor,
                      styles.COLORS.PrimaryBorderColor,
                      styles.COLORS.PrimaryBgHoverColor,
                      styles.COLORS.PrimaryBgColor,
                    ]
                  },
                  {
                    label: 'Cor Secondária',
                    colors: [
                      styles.COLORS.SecondaryActiveColor,
                      styles.COLORS.SecondaryColor,
                      styles.COLORS.SecondaryHoverColor,
                      styles.COLORS.SecondaryBorderHoverColor,
                      styles.COLORS.SecondaryBgHoverColor,
                      styles.COLORS.SecondaryBgColor,
                      styles.COLORS.SecondaryBorderColor,
                    ]
                  },
                  {
                    label: 'Cores Netras',
                    colors: [
                      styles.COLORS.TextColor,
                      styles.COLORS.SecondaryTextColor,
                      styles.COLORS.TertiaryTextColor,
                      styles.COLORS.QuaternaryTextColor,
                      styles.COLORS.FillColor,
                      styles.COLORS.SecondaryFillColor,
                      styles.COLORS.BgContainerColor,
                    ]
                  }
                ]} />
              </Form.Item>

              {/* Buttons */}
              <Form.Item >
                <div className='cms-add-modal-btns'>
                  <Button type='default' onClick={this.handleEditCategoryModal} disabled={editFetching}>Cancelar</Button>
                  <Popconfirm
                    title='&nbsp;&nbsp;Apagar categoria'
                    description={
                      <>
                        <div>Tem a certeza que pretende apagar esta categoria?</div>
                        <div>Esta ação <b>não é reversível</b>.</div>
                      </>
                    }
                    okText='Apagar'
                    okButtonProps={{ danger: true }}
                    okType='primary'
                    cancelText='Cancelar'
                    onConfirm={this.deleteCategory}
                    icon={<i className='fa-duotone fa-circle-exclamation' style={{ color: styles.COLORS.ErrorColor }}></i>}
                  >
                    <Button danger={true} disabled={editFetching} type='default'>Apagar</Button>
                  </Popconfirm>
                  {selectedItem.isActive ?
                    <Popconfirm
                      title='&nbsp;Desativar categoria'
                      description={
                        <>
                          <div>Tem a certeza que pretende desativar esta categoria?</div>
                          <div>Esta ação <b>é reversível</b>.</div>
                        </>
                      }
                      okText='Desativar'
                      okButtonProps={{ className: 'vantagens-warning-button' }}
                      okType='default'
                      cancelText='Cancelar'
                      onConfirm={this.deactivateCategory}
                    >
                      <Button className='vantagens-warning-button' disabled={editFetching} type='default'>Desativar</Button>
                    </Popconfirm>
                    :
                    <Popconfirm
                      title='&nbsp;Reativar categoria'
                      description={
                        <>
                          <div>Tem a certeza que pretende reativar esta categoria?</div>
                          <div>Esta ação <b>é reversível</b>.</div>
                        </>
                      }
                      okText='Reativar'
                      okButtonProps={{ className: 'vantagens-warning-button' }}
                      okType='default'
                      cancelText='Cancelar'
                      onConfirm={this.reactivateCategory}
                    >
                      <Button className='vantagens-warning-button' disabled={editFetching} type='default'>Reativar</Button>
                    </Popconfirm>
                  }
                  <Button 
                    type={editError ? 'default' : 'primary'}
                    danger={editError ? true : false}
                    htmlType='submit'
                    disabled={editFetching}
                  >{editError ? 'Tentar novamente' : editFetching ? 'A carregar...' : 'Guardar'}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Modal>
        }
      </div>
    );
  }
}

/**
 * Used in the Redux pattern to reflect any updates to the Redux store and merge them into props in the current
 * component. The Redux store serves as a centralized place for the state to live in the application.
 * 
 * @param {any} state - Centralized state of the application.
 * @returns {any} the state of the application as the component props.
 */
const mapPropsToState = (state) => {
  const languages = state.languages;

  return {
    languages: languages.privilegedData,
    languagesError: languages.error,
    languagesFetching: languages.fetching,
  };
};

/**
 * Used in the Redux pattern to dispatch actions to the Redux store, triggering a state change.
 * 
 * @param {any} dispatch - function of the Redux store.
 * @returns {any} the dispatch functions as components props.
 */
const mapDispatchToState = (dispatch) => {
  return {
    getLanguages: (token) => dispatch(getAllLanguages(token)),
  };
};

export default withRouter(connect(mapPropsToState, mapDispatchToState)(ManageCategories));