import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from '../../utils';
import { Button, ConfigProvider, Input } from 'antd';
import { LanguageSelect, Logotype, MobileMenuOptions, TopMenuOptions, VerticalDivider } from '../../components';
import { Lock, Search } from '../../components/Icons';
import ipsLogo from '../../images/IPS-sm-logo.png';
import { styles } from '../../styles';
import './index.scss';

/**
 * Header class represents the application header, with the logotype,
 * the search bar, the menu options of the application, the language selection
 * and the login button.
 */
class Header extends React.Component {
  /**
   * The constructor for a React component is called before it is mounted.
   * When implementing the constructor, `super(props)` must be called before any
   * other statement. Otherwise, this.props will be undefined in the constructor,
   * which can lead to bugs.
   *
   * A React constructor is only used for two purposes:
   * - Initializing local state by assigning an object to this.state.
   * - Binding event handler methods to an instance.
   *
   * Instead of calling `setState()` in the `constructor(), the initial state must be
   * directly assigned to `this.state` in the constructor
   *
   * @param {*} props - Arbitrary component inputs.
   */
  constructor(props) {
    super(props);
    this.state = {
      openMenuDrawer: false,
      searchValue: null,
    };
  }

  /**
   * Handles menu drawer open state (`this.state.openMenuDrawer`)
   *
   * @param {Boolean} open - open state.
   */
  openMenu = (open) => {
    this.setState({ openMenuDrawer: open });
  };

  /**
   * Searchs an advantage, redirecting to `/vantagens?search={SEARCH_VALUE}`
   */
  searchAdvantages = () => {
    const { searchValue } = this.state;
    if (searchValue && this.props && this.props.router) {
      this.setState({ searchValue: null });
      this.props.router.navigate(`/vantagens?pesquisa=${searchValue}`);
    }
  };

  render() {
    // TODO: Select current page url on header navbar

    return (
      <ConfigProvider
        theme={{
          token: {
            controlOutline: styles.COLORS.PrimaryActiveColor,
            controlItemBgHover: styles.COLORS.PrimaryBgHoverColor,
            controlItemBgActive: styles.COLORS.PrimaryColor,
            controlItemBgActiveHover: styles.COLORS.PrimaryActiveColor,
          },
        }}>
        <header id='header'>
          <div className='uk-position-relative'>
            <nav className='uk-navbar-container uk-navbar-transparent'>
              <div className='uk-container'>
                {/* eslint-disable-next-line react/no-unknown-property */}
                <div className='uk-navbar' uk-navbar='dropbar: true; dropbar-anchor: !.uk-navbar-container; target-y: !.uk-navbar-container'>

                  <div className='uk-navbar-left'>
                    {/* Logotype */}
                    <Logotype url='/' src={ipsLogo} alt='Logótipo do IPS' height={styles.SPACING.MarginXXL} />
                    
                    {window.innerWidth > 600 ?
                      <>
                        {/* Divider */}
                        <VerticalDivider />

                        {/* Search bar */}
                        <div id='header-search-bar'>
                          <Input
                            aria-label='Pesquisar vantagem'
                            placeholder='Nome de uma empresa, instituição ou entidade; grupo; palavra-chave; etc...'
                            suffix={
                              <Button type='text' size='small' icon={<Search />} onClick={this.searchAdvantages}/>
                            }
                            size='middle'
                            type='search'
                            value={this.state.searchValue}
                            onChange={(e) => this.setState({ searchValue: e.target.value })}
                            onPressEnter={this.searchAdvantages}
                          />
                        </div>
                      </>
                      : null
                    }
                  </div>

                  {window.innerWidth > 1060 ?
                    <div className='uk-navbar-right'>
                      {/* Menu Options */}
                      <TopMenuOptions />

                      {/* Divider */}
                      <VerticalDivider />

                      {/* Language */}
                      <LanguageSelect />

                      {/* Login */}
                      <Link to={`${process.env.REACT_APP_PUBLIC_URL}/cms`} title='CMS'><Button type='text' icon={<Lock size={styles.FONTS.FontSizeXL} />} /></Link>
                    </div>
                    :
                    <div className='uk-navbar-right'>
                      {/* Login */}
                      <Link to={`${process.env.REACT_APP_PUBLIC_URL}/cms`} title='CMS'><Button type='text' icon={<Lock size={styles.FONTS.FontSizeXL} />} /></Link>

                      {/* Menu */}
                      <Button id='hamburger-menu-open' type='text' onClick={() => { this.openMenu(true); }}>
                        <i className='fa-sharp fa-regular fa-bars'></i>
                      </Button>
                    </div>
                  }
                </div>
              </div>
            </nav>
          </div>

          <MobileMenuOptions openMenu={this.openMenu} openMenuDrawer={this.state.openMenuDrawer} />
        </header>
      </ConfigProvider>
    );
  }
}

const mapPropsToState = (state) => {
  return {};
};

const mapDispatchToState = (dispatch) => {
  return {};
};

export default withRouter(connect(mapPropsToState, mapDispatchToState)(Header));