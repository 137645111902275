import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import './index.scss';

/**
 * BackTop class represents a back to top button, which is composed by a button and 
 * an arrow-up-icon.
 * 
 * Accepts the props:
 * * `action` - The action to perform when the button is clicked.
 */
export default class BackTop extends React.Component {
  render() {
    const { action } = this.props;
    return (
      <Button
        aria-label='Go to top'
        id='back-top-btn'
        onClick={action}
        shape='circle'
        size='large'
        type='primary'
      >
        <i className="fa-light fa-arrow-up-to-line"></i>
      </Button>
    );
  }
}

BackTop.propTypes = {
  action: PropTypes.func,
};