import React from 'react';
import './index.scss';

/**
 * Accessibility component represents the Accessibility Declaration of the application.
 */
export default class Accessibility extends React.Component {
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  render() {
    return (
      <div id='accessibility-declaration-content'>

        {/*         
        <div  id='accessibility-declaration-content-wrapper'>
          <h1>Declaração de Acessibilidade e Usabilidade</h1>
          <p>
            O Politécnico de Setúbal compromete-se a disponibilizar o sítio Web <a href="https://vantagens.ips.pt/">Portal Vantagens IPS</a>, 
            em conformidade com o Decreto-Lei n.º 83/2018, de 19 de outubro, que transpõe a Diretiva (UE) 2016/2102 do Parlamento Europeu e do 
            Conselho, relativa à acessibilidade dos sítios Web e das aplicações móveis.
          </p>
        
          <h2 id="estado-de-conformidade">I. Estado de conformidade</h2>
          <p>
            O sítio Web <a href="https://vantagens.ips.pt/">Portal Vantagens IPS</a> do Politécnico de Setúbal está parcialmente conforme para 
            com o Decreto-Lei n.º 83/2018 de 19 de outubro.
          </p>
          <p>As não conformidades e/ou isenções são indicadas abaixo.</p>
        
          <h3>Não conformidades e/ou alvo de isenções</h3>
          <ol type='A'>
            <li>
              <p>Enumeração <strong>das secções / conteúdos / funções</strong> do sítio Web que não estão conformes e/ou alvo de isenção:</p>
              <ul>
                <li>A primeira hiperligação das páginas não permite saltar diretamente para a área do conteúdo principal.</li>
                <li>Pontuais combinações de cor não respeitam os rácios mínimos de contraste permitido pelas WCAG.</li>
              </ul>
            </li>

            <li>
              <p><strong>Razões</strong> que estão na base da não conformidade dos conteúdos indicados acima:</p>
              <ul>
                <li>A primeira hiperligação das páginas redireciona os utilizadores para a página inicial do Portal Vantagens IPS.</li>
                <li>As cores utilizadas são uniformizadas com as cores do Politécnico de Setúbal.</li>
              </ul>
            </li>
          </ol>
        


          <h2 id="elaboracao-declaracao">II. Elaboração da presente declaração de acessibilidade e usabilidade</h2>
          <p>Esta declaração foi atualizada a 2023-12-18.</p>
          <p>
            De acordo com o artigo 9º do Decreto-Lei n.º 83/2018, as entidades devem adotar os procedimentos de monitorização a seguir 
            apresentados. Os procedimentos A) e B) são obrigatórios. O procedimento C) é recomendado.
          </p>
          <ol type='A'>
            <li>
              <p><strong>Avaliações automáticas levadas a efeito:</strong></p>
              <ol type='1'>
                <li>
                  <p>(2023-12-18). Relatório: <a href="https://api.vantagens.ips.pt/public/files/accessibility_report.pdf">Relatório de Acessibilidade do Portal Vantagens IPS</a></p>
                  <ul>
                    <li>Ferramenta utilizada: https://accessmonitor.acessibilidade.gov.pt/</li>
                    <li>Amostra: 7 páginas.</li>
                    <li>Principais resultados (sumário): Num total de 7 páginas, foi obtido um score de 9.4 na escala do AccessMonitor (1-10).</li>
                  </ul>
                </li>
              </ol>
            </li>

            <li>
              <p><strong>Avaliações manuais levadas a efeito:</strong></p>
              <p>O sítio Web ainda não foi alvo de uma avaliação manual às práticas de acessibilidade.</p>
              <p>Razões que levaram à não realização de avaliações manuais: </p>
              <ul>
                <li>
                  O portal foi desenvolvido e publicado há relativamente pouco tempo e, dada a sua urgência, não foram efetuadas avaliações 
                  manuais sobre a acessibilidade. Estas serão efetuadas com a maior brevidade possível.
                </li>
              </ul>
            </li>

            <li>
              <p><strong>C. Testes de usabilidade com pessoas com deficiência:</strong></p>
              <p>O sítio Web ainda não foi alvo de testes com utilizadores com deficiência.</p>
            </li>
          </ol>



          <h2 id="contactos">III. Contacto e solicitação de informação relativa ao sítio Web</h2>
          <p>
            Para contactar, enviar sugestões, efetuar reclamações ou solicitar informação adicional relativamente aos 
            conteúdos e/ou funcionalidades presentes no sítio Web do Politécnico de Setúbal, utilize, por favor, os seguintes meios:
          </p>
          <ul>
            <li>Correio Eletrónico: <a href="mailto:apoio.informatico@ips.pt">apoio.informatico@ips.pt</a></li>
          </ul>
        


          <h2 id="outras-evidencias">IV. Outras evidências</h2>
          <p>
            O Politécnico de Setúbal não apresentou, aquando do preenchimento da presente Declaração, outras evidências ou esforços para tornar 
            o seu sítio Web conforme para com os requisitos de acessibilidade constantes do Decreto-Lei n.º 83/2018, de 19 de outubro.
          </p>



          <h2 id="denuncias">V. Denúncia de situações de discriminação</h2>
          <p>
            De acordo com o n.º 1 do artigo 13.º do Decreto-Lei n.º 83/2018, de 19 de outubro, sempre que uma pessoa com deficiência seja 
            objeto de um tratamento menos favorável do que aquele que é, tenha sido ou venha a ser dado a outra pessoa em situação 
            comparável, que consubstancie uma prática discriminatória contra pessoas com deficiência, prevista e punida nos termos 
            do <a href="http://data.dre.pt/eli/lei/46/2006/08/28/p/dre/pt/html">artigo 4.º da Lei n.º 46/2006, de 28 de agosto</a>, pode, 
            essa pessoa, apresentar queixa, de acordo com o disposto no <a href="https://data.dre.pt/eli/dec-lei/34/2007/02/15/p/dre/pt/html">Decreto-Lei 
            n.º 34/2007, de 15 de fevereiro</a>.
          </p>
          <p>
            O Instituto Nacional para a Reabilitação (INR, I.P.), disponibiliza 
            um <a href="http://www.inr.pt/resultados-de-pesquisa/-/journal_content/56/11309/45065?p_p_auth=wje2GjQi">formulário para denunciar 
            situações de discriminação</a>, encaminhando as queixas apresentadas às entidades competentes. Anualmente, o INR, I.P. elabora um 
            relatório anual sobre a aplicação da lei que proíbe e pune a discriminação em razão da deficiência e da existência de risco agravado 
            de saúde (Lei n.o 46/2006, de 28 de agosto).
          </p>
          <hr aria-hidden="true"/>
          <p>
            A presente Declaração de Acessibilidade e Usabilidade foi criada com o auxílio do <a href="https://www.acessibilidade.gov.pt/gerador">Gerador 
            WAI-Tools PT v1.5</a>, desenvolvido no âmbito do projeto <a href="https://w3.org/WAI/Tools">WAI-Tools</a>, de cujo consórcio a AMA 
            é parte integrante. A Declaração foi concebida em conformidade com o Decreto-Lei n.º 83/2018, de 19 de outubro.
          </p>
        </div>
        */}

        <div id='accessibility-declaration-content-wrapper'>
          <h1>Declaração de Acessibilidade e Usabilidade</h1>
          <p>
            <span className="mr mr-e-intro"><span>O</span></span> <span className="mr mr-e-name"><span>Politécnico de Setúbal</span></span> compromete-se a 
            disponibilizar <span className="mr mr-t-type"><span className="target-type">o sítio Web</span></span> <a href="https://vantagens.ips.pt/" className="siteurl"><span className="mr mr-t-desc"><span className="target-name">Portal Vantagens 
            IPS</span></span></a>, em conformidade com o Decreto-Lei n.º 83/2018, de 19 de outubro, que transpõe a 
            Diretiva (UE) 2016/2102 do Parlamento Europeu e do Conselho, relativa à acessibilidade dos sítios Web e 
            das aplicações móveis.
          </p>
          <h2 id="n1">I. Estado de conformidade</h2>
          <p className="capFL">
            <span className="target-type">O sítio Web</span> <a href="https://vantagens.ips.pt/" className="siteurl"><span className="target-name">Portal Vantagens 
            IPS</span></a> d<span data-printfilter="lowerfirst" className="lowFL">o</span> <span>Politécnico de Setúbal</span> está <span className="mr mr-conformance-status"><span className="conformance-output">parcialmente 
            conforme</span></span> para com o Decreto-Lei n.º 83/2018 de 19 de outubro.
          </p>
          <p>
            As não conformidades e/ou isenções são indicadas abaixo.
          </p>
          <h3>Não conformidades e/ou alvo de isenções</h3>
          <p>
            A. Enumeração <strong>das secções / conteúdos / funções</strong> d<span className="target-type">o sítio 
            Web</span> que não estão conformes e/ou alvo de isenção:
          </p>
          <ul className="mr mr-nonconf-sections">
            <li>A primeira hiperligação das páginas não permite saltar diretamente para a área do conteúdo principal.</li>
            <li>Pontuais combinações de cor não respeitam os rácios mínimos de contraste permitido pelas WCAG.</li>
          </ul>
          <p>
            B. <strong>Razões</strong> que estão na base da não conformidade dos conteúdos indicados acima:
          </p>
          <ul className="mr mr-nonconf-reasons">
            <li>A primeira hiperligação das páginas redireciona os utilizadores para a página inicial do Portal Vantagens IPS.</li>
            <li>As cores utilizadas são uniformizadas com as cores do Politécnico de Setúbal.</li>
          </ul>
          <h2 id="n2">
            II. Elaboração da presente declaração de acessibilidade e usabilidade
          </h2>
          <p>
            Esta declaração foi atualizada a <span className="mr mr-date"><span>2023-12-18</span></span>.
          </p>
          <p>
            De acordo com o artigo 9º do Decreto-Lei n.º 83/2018, as entidades devem adotar os procedimentos de 
            monitorização a seguir apresentados. Os procedimentos A) e B) são obrigatórios. O procedimento C) é 
            recomendado.
          </p>
          <p>
            <strong>A. Avaliações automáticas levadas a efeito</strong>
          </p>
          <div className="mr mr-automatic-summary">
            <ol>
              <li>
                (2023-12-18). Relatório: <a href="https://api.vantagens.ips.pt/public/files/accessibility_report.pdf">Relatório de 
                Acessibilidade do Portal Vantagens IPS</a>
                <ul>
                  <li>Ferramenta utilizada: https://accessmonitor.acessibilidade.gov.pt/</li>
                  <li>Amostra: 7 páginas.</li>
                  <li>Principais resultados (sumário): Num total de 7 páginas, foi obtido um score de 9.4 na escala do AccessMonitor (1-10).</li>
                </ul>
              </li>
            </ol>
          </div>
          <p>
            <strong>B. Avaliações manuais levadas a efeito:</strong>
          </p>
          <p className="capFL">
            <span className="target-type">O sítio Web</span> ainda não foi alvo de uma avaliação manual às práticas de acessibilidade.
          </p>
          <p>
            Razões que levaram à não realização de avaliações manuais:
          </p>
          <ul className="mr mr-no-manual-reasons">
            <li>O portal foi desenvolvido e publicado há relativamente pouco tempo e, dada a sua urgência, não foram efetuadas avaliações manuais sobre a acessibilidade. Estas serão efetuadas com a maior brevidade possível.</li>
          </ul>
          <p>
            <strong>C. Testes de usabilidade com pessoas com deficiência:</strong>
          </p>
          <p className="capFL"><span className="target-type">O sítio Web</span> ainda não foi alvo de testes com utilizadores com deficiência.
          </p>
          <h2 id="n3">
            III. Contacto e solicitação de informação relativa <span className="target-type nType">ao sítio Web</span>
          </h2>
          <p>
            Para contactar, enviar sugestões, efetuar reclamações ou solicitar informação adicional relativamente aos 
            conteúdos e/ou funcionalidades presentes n<span className="target-type">o sítio 
            Web</span> d<span data-printfilter="lowerfirst" className="lowFL">o</span> <span>Politécnico de Setúbal</span>, 
            utilize, por favor, os seguintes meios:
          </p>
          <div className="mr mr-contacts">
            <dl>
              <dt>Correio Eletrónico</dt>
              <dd>apoio.informatico@ips.pt</dd>
            </dl>
          </div>
          <h2 id="n4">IV. Outras evidências</h2>
          <div></div>
          <p><span id="accstmnt_seal_summary_empty">
            <span>O</span> <span>Politécnico de Setúbal</span> não apresentou, aquando do preenchimento da presente 
            Declaração, outras evidências ou esforços para tornar o seu sítio Web conforme para com os requisitos de 
            acessibilidade constantes do Decreto-Lei n.º 83/2018, de 19 de outubro.
          </span></p>
          <h2 id="n5">V. Denúncia de situações de discriminação</h2>
          <p>
            De acordo com o n.º 1 do artigo 13.º do Decreto-Lei n.º 83/2018, de 19 de outubro, sempre que uma pessoa 
            com deficiência seja objeto de um tratamento menos favorável do que aquele que é, tenha sido ou venha a ser 
            dado a outra pessoa em situação comparável, que consubstancie uma prática discriminatória contra pessoas 
            com deficiência, prevista e punida nos termos do <a href="http://data.dre.pt/eli/lei/46/2006/08/28/p/dre/pt/html">artigo 4.º da Lei n.º 46/2006, 
            de 28 de agosto</a>, pode, essa pessoa, apresentar queixa, de acordo com o disposto no <a href="https://data.dre.pt/eli/dec-lei/34/2007/02/15/p/dre/pt/html">Decreto-Lei n.º 34/2007, 
            de 15 de fevereiro</a>.
          </p>
          <p>
            O Instituto Nacional para a Reabilitação (INR, I.P.), disponibiliza um <a href="http://www.inr.pt/resultados-de-pesquisa/-/journal_content/56/11309/45065?p_p_auth=wje2GjQi">formulário para 
            denunciar situações de discriminação</a>, encaminhando as queixas apresentadas às entidades competentes. 
            Anualmente, o INR, I.P. elabora um relatório anual sobre a aplicação da lei que proíbe e pune a discriminação 
            em razão da deficiência e da existência de risco agravado de saúde (Lei n.o 46/2006, de 28 de agosto).
          </p>
          <hr aria-hidden="true" />
          <p>
            A presente Declaração de Acessibilidade e Usabilidade foi criada com o auxílio do <a href="https://www.acessibilidade.gov.pt/gerador">Gerador WAI-Tools 
            PT v1.5</a>, desenvolvido no âmbito do projeto <a href="https://w3.org/WAI/Tools">WAI-Tools</a>, de cujo consórcio a 
            AMA é parte integrante. A Declaração foi concebida em conformidade com o Decreto-Lei n.º 83/2018, de 19 de outubro.
          </p>
        </div>
      </div>
    );
  }
}