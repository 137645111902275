import React from 'react';
import { withRouter } from '../../utils';
import { connect } from 'react-redux';
import { getActiveBanners } from '../../reducers/Banners';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Navigation,
  Pagination,
  A11y,
  Mousewheel,
  Autoplay,
  EffectFade,
  Parallax,
} from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-flip';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './index.scss';

/**
 * Slideshow class represents a slideshow that presents automatically 
 */
class Slideshow extends React.Component {
  /**
   * The constructor for a React component is called before it is mounted.
   * When implementing the constructor, `super(props)` must be called before any
   * other statement. Otherwise, this.props will be undefined in the constructor,
   * which can lead to bugs.
   *
   * A React constructor is only used for two purposes:
   * - Initializing local state by assigning an object to this.state.
   * - Binding event handler methods to an instance.
   *
   * Instead of calling `setState()` in the `constructor(), the initial state must be
   * directly assigned to `this.state` in the constructor
   *
   * @param {*} props - Arbitrary component inputs.
   */
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
    };
  }

  /**
   * Allows to execute the React code when the component is already placed in the DOM (Document Object Model).
   * This method is called during the Mounting phase of the React Life-cycle i.e after the component is rendered.
   */
  componentDidMount() {
    // Load data
    this.props.getBanners();
    // Get window width to display things conditionally
    this.handleWindowWidth();
    // Add evennt on resize to show the slideshow banners' content
    window.addEventListener('resize', this.handleWindowWidth);
  }

  /**
   * Helps to handle the window inner width.
   *
   * Updates the state property `windowWidth`.
   */
  handleWindowWidth = () => {
    this.setState({ windowWidth: window.innerWidth });
    this.props.getBanners();
  };

  render() {
    const { banners, bannersError, bannersFetching } = this.props;
    if (bannersFetching || bannersError || (!bannersFetching && banners.length < 1)) return null;
    const isMobile = this.state.windowWidth <= 900;

    return (
      <Swiper
        modules={[
          A11y,
          Autoplay,
          EffectFade,
          Mousewheel,
          Navigation,
          Pagination,
          Parallax
        ]}
        spaceBetween={0}
        slidesPerView={1}
        autoplay={{ delay: 4000 }}
        effect='fade'
        loop
        mousewheel
        navigation
        pagination={{ clickable: true }}
        parallax={isMobile}
      >
        {banners.filter((banner) => {
          if (isMobile) return banner.isMobile;
          return banner.isMobile === false;
        }).map((banner, index) => {
          const path = `${banner.path.startsWith('/') ? process.env.REACT_APP_API_IMAGES_BASE_URL : ''}${banner.path}`;
          return (
            <SwiperSlide key={index}>
              {banner.redirectTo ?
                <a href={banner.redirectTo} target='_blank' rel='noreferrer'>
                  <img
                    crossOrigin={banner.path.startsWith('/') ? 'anonymous' : undefined}
                    src={path}
                    alt={banner.description}
                  />
                </a>
                :
                <img
                  crossOrigin={banner.path.startsWith('/') ? 'anonymous' : undefined}
                  src={path}
                  alt={banner.description}
                />
              }
            </SwiperSlide>
          );
        })}
      </Swiper>
    );
  }
}

const mapPropsToState = (state) => {
  const banners = state.banners;
  return {
    banners: banners.data,
    bannersError: banners.error,
    bannersFetching: banners.fetching,
  };
};

const mapDispatchToState = (dispatch) => {
  return { getBanners: () => dispatch(getActiveBanners()) };
};

export default withRouter(connect(mapPropsToState, mapDispatchToState)(Slideshow));