const RGBToHSL = (r, g, b) => {
  r /= 255;
  g /= 255;
  b /= 255;
  const l = Math.max(r, g, b);
  const s = l - Math.min(r, g, b);
  const h = s
    ? l === r
      ? (g - b) / s
      : l === g
        ? 2 + (b - r) / s
        : 4 + (r - g) / s
    : 0;
  return [
    60 * h < 0 ? 60 * h + 360 : 60 * h,
    100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0),
    (100 * (2 * l - s)) / 2,
  ];
};


export const COLORS = {

  // PRIMARY APP COLORS
  PrimaryColor: '#2EC1BA',
  PrimaryBgColor: '#F9FAFD',
  PrimaryBgHoverColor: '#D7F5EF',
  PrimaryBorderColor: '#A7E8DE',
  PrimaryBorderHoverColor: '#7BDBD0',
  PrimaryHoverColor: '#53CFC4',
  PrimaryActiveColor: '#1E9C99',
  PrimaryTextHoverColor: '#53CFC4',
  PrimaryTextColor: '#2EC1BA',
  PrimaryTextActiveColor: '#1E9C99',

  // SECONDARY APP COLORS
  SecondaryColor: '#008485',
  SecondaryBgColor: '#b1c4c1',//'#D3E0DE',
  SecondaryBgHoverColor: '#76b8af',
  SecondaryAppBorderColor: '#52aba2',//'#BBC7C5',
  SecondaryBorderHoverColor: '#339e97',
  SecondaryHoverColor: '#17918d',
  SecondaryActiveColor: '#005b5e',
  SecondaryTextHoverColor: '#17918d',
  SecondaryAppTextColor: '#008485',
  SecondaryTextActiveColor: '#005b5e',

  // SUCCESS COLORS
  SuccessBgColor: '#F6FFED',
  SuccessBgHoverColor: '#D9F7BE',
  SuccessBorderColor: '#B7EB8F',
  SuccessBorderHoverColor: '#95DE64',
  SuccessHoverColor: '#95DE64',
  SuccessColor: '#52C41A',
  SuccessActiveColor: '#389E0D',
  SuccessTextHoverColor: '#73D13D',
  SuccessTextColor: '#52C41A',
  SuccessTextActiveColor: '#389E0D',

  // WARNING COLORS
  WarningColor: '#FAAD14',
  WarningHoverColor: '#FFD666',
  WarningActiveColor: '#D48806',
  WarningTextColor: '#FAAD14',
  WarningTextHoverColor: '#FFC53D',
  WarningTextActiveColor: '#D48806',
  WarningBgColor: '#FFFBE6',
  WarningBgHoverColor: '#FFF1B8',
  WarningBorderColor: '#FFE58F',
  WarningBorderHoverColor: '#FFD666',

  // ERROR COLORS
  ErrorColor: '#FF4D4F',
  ErrorBgColor: '#FFF2F0',
  ErrorBgHoverColor: '#FFF1F0',
  ErrorBorderColor: '#FFCCC7',
  ErrorBorderHoverColor: '#FFA39E',
  ErrorHoverColor: '#FF7875',
  ErrorActiveColor: '#D9363E',
  ErrorTextColor: '#FF4D4F',
  ErrorTextHoverColor: '#FF7875',
  ErrorTextActiveColor: '#D9363E',

  // INFO COLORS
  InfoColor: '#22bdb6',
  InfoLinkHoverColor: '#6bd6ca',
  InfoLinkActiveColor: '#149694',


  // NEUTRAL COLORS
  NeutralTextColor: '#000000',
  NeutralBgColor: '#FFFFFF',

  TextColor: '#000000E0',
  SecondaryTextColor: '#000000A6',
  TertiaryTextColor: '#00000073',
  QuaternaryTextColor: '#00000040',

  BorderColor: '#D9D9D9',
  SecondaryBorderColor: '#F0F0F0',

  FillColor: '#00000026',
  SecondaryFillColor: '#0000000F',
  TertiaryFillColor: '#0000000A',
  QuaternaryFillColor: '#00000005',

  BgContainerColor: '#FFFFFF',
  BgElevatedColor: '#FFFFFF',
  BgLayoutColor: '#F5F5F5',
  BgSpotlightColor: '#000000D9',
  BgMaskColor: '#00000073',


  // HELP FUNCTIONS
  getTextColor: (color) => {
    // Variables for red, green, blue values
    let r, g, b;
    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
      // If RGB --> store the red, green, blue values in separate variables
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
      r = color[1];
      g = color[2];
      b = color[3];
    } else {
      // If hex --> Convert it to RGB: http://gist.github.com/983661
      color = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
      r = parseInt(color[1], 16);
      g = parseInt(color[2], 16);
      b = parseInt(color[3], 16);
    }
    // Get color
    if (r && g && b) {
      const newColor = Math.round(((parseInt(r) * 299) + (parseInt(g) * 587) + (parseInt(b) * 114)) / 1000);
      return newColor > 186 ? '#000000E0' : '#FFFFFF'; // 125 ou 128 ou 186
    }
    return '#000000E0';
  },
  getAverageImageRgb: (img, imgURL) => {
    var context = document.createElement('canvas').getContext('2d');
    if (typeof img == 'string') {
      var src = img;
      img = new Image();
      img.setAttribute('crossOrigin', imgURL.startsWith('/') ? 'anonymous' : undefined); 
      img.src = src;
    }
    context.imageSmoothingEnabled = true;
    context.drawImage(img, 0, 0, 1, 1);

    const rgb = context.getImageData(0, 0, 1, 1).data.slice(0,3);
    const hsl = RGBToHSL(rgb[0], rgb[1], rgb[2]);
    let color = '';

    if (hsl[2] > 10 && hsl[2] < 90) {
      color = `rgb(${rgb})`;
    } else {
      color = '#F9FAFD';
    }
    return color;
  },
  getRandomHexColor: () => {
    const randomColor = Math.floor(Math.random()*16777215).toString(16);
    console.log(randomColor);
    return '#' + randomColor;
  }
};