import React from 'react';
import PropTypes from 'prop-types';
import { styles } from '../../styles';

/**
 * Lock class represents a lock-icon, usually used for login purposes.
 * 
 * Accepts the props:
 * * `className` - The CSS class selectors to add specific styles to the icon.
 * * `color` - The color to add to the icon.
 * * `size` - The font-size of the icon.
 */
export default class Lock extends React.Component {
  render() {
    const { className, color, size } = this.props;
    return (
      <i className={`fa-sharp fa-solid fa-lock-keyhole ${className ? className : ''}`} style={{ color: color, fontSize: size }}></i>
    );
  }
}

Lock.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
};

Lock.defaultProps = {
  className: undefined,
  color: styles.COLORS.PrimaryColor,
  size: styles.FONTS.FontSize,
};