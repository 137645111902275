import React from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { withRouter } from '../../utils';
import { getRoles } from '../../reducers/Roles';
import { Alert, Button, Drawer, Form, Input, Modal, Popconfirm, Select, Table, notification } from 'antd';
import { PuffLoader } from 'react-spinners';
import { styles } from '../../styles';

const { Option } = Select;

/**
 * ManageUsers component represents the page template that allows to manage the application users.
 * It displays a table with all users. It allows to create, edit, deactivate, reactivate and 
 * delete users.
 */
class ManageUsers extends React.Component {
  /**
   * The constructor for a React component is called before it is mounted.
   * When implementing the constructor, `super(props)` must be called before any
   * other statement. Otherwise, this.props will be undefined in the constructor,
   * which can lead to bugs.
   *
   * A React constructor is only used for two purposes:
   * - Initializing local state by assigning an object to this.state.
   * - Binding event handler methods to an instance.
   *
   * Instead of calling `setState()` in the `constructor(), the initial state must be
   * directly assigned to `this.state` in the constructor
   *
   * @param {*} props - Arbitrary component inputs.
   */
  constructor() {
    super();
    this.state = {
      tableParams: {
        pagination: {
          current: 1,
          pageSize: 10,
        }
      },
      addError: null,
      addFetching: false,
      deactivateError: null,
      deactivateFetching: false,
      editError: null,
      editFetching: false,
      loading: false,
      pageWidth: 0,
      selectedItem: null,
      showAddUserModal: false,
      showEditUserModal: false,
      showDeactivateUserModal: false,
      users: [],
      usersError: null,
    };
  }

  /**
   * Allows to execute the React code when the component is already placed in the DOM (Document Object Model).
   * This method is called during the Mounting phase of the React Life-cycle i.e after the component is rendered.
   */
  componentDidMount() {
    // Get data
    this.fetchData();
    this.props.getRoles();
    // Scroll to top
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Add event on resize
    this.setState({ pageWidth: window.innerWidth });
    window.addEventListener('resize', () => this.setState({ pageWidth: window.innerWidth }));
  }

  /**
   * Sets the array of users to manage.
   *
   * @param {any[]} data - Original data of users. 
   * @returns {any[]} the modified dataset of users.
   */
  setUsers = (data) => {
    return data.map((user) => {
      return {
        key: user.idUser,
        idRole: user.role.idRole,
        roleName: user.role.name,
        name: user.name,
        email: user.email,
        username: user.username,
        lastLoginDate: user.lastLoginDate ? dayjs(user.lastLoginDate) : null,
        activationDate: user.activationDate ? dayjs(user.activationDate) : null,
        deactivationDate: user.deactivationDate ? dayjs(user.deactivationDate) : null,
        deactivationReason: user.deactivationReason,
        deactivatedBy: user.deactivatedBy,
        isActive: this.userIsActive(user),
      };
    });
  };

  /**
   * Checks if a user is active, analysing its activation and deactivation dates and comparing it to the
   * actual date.
   * 
   * @param {any} record - User object.
   * @returns {boolean} - true if user is active and false otherwise.
   */
  userIsActive = (record) => {
    const activation = record.activationDate ? dayjs(record.activationDate).subtract(1, 'h') : undefined;
    const deactivation = record.deactivationDate ? dayjs(record.deactivationDate).subtract(1, 'h')  : undefined;
    const now = dayjs();
    return activation && (activation.isBefore(now) || activation.isSame(now)) && 
      (!deactivation || (deactivation && deactivation.isAfter(now))) ? true : false;
  };

  /**
   * Fetches all users from database through an available API.
   * Calls `setUsers` to modify the data, regarding this component.
   */
  fetchData = () => {
    this.setLoading(true);
    const { selectedItem, tableParams } = this.state;
    // Get options to fetch
    const fetchOptions = {
      method: 'GET',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      })
    };
    // Fetch data
    fetch(`${process.env.REACT_APP_API_BASE_URL}/users`, fetchOptions)
      .then(async (response) => { 
        if (response.ok) return response.json();
        const result = await response.json();
        throw new Error(result ? JSON.stringify(result) : 'Não é possível obter os utilizadores.');
      }).then(async (data) => {
        // await (new Promise((res) => {setTimeout(res, 5000); }));
        const users = this.setUsers(data);
        if (selectedItem) this.setState({ selectedItem: users.find((user) => user.key === selectedItem.key)});
        this.setState({ 
          tableParams: {
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              total: users.length,
            }
          },
          users: users,
          usersError: null
        });
        this.setLoading(false);
      }).catch((error) => {
        this.setState({ users: [], usersError: error.message, selectedItem: null });
        this.setLoading(false);
      });
  };

  /**
   * Sets loading state from `this.state.loading`.
   * This state is relationated with the data fetching from database.
   *
   * @param {boolean} loading - Sets the loading state to true or false.
   */
  setLoading = (loading) => {
    this.setState({ loading: loading });
  };

  /**
   * Sets the users table parameters (`this.state.tableParams`).
   * 
   * @param {any} params - The parameters to update.
   */
  setTableParams = (params) => {
    this.setState({ tableParams: params });
  };
  
  /**
   * Handles changes on users table, setting its parameters.
   * 
   * @param {any} pagination - Pagination parameters.
   * @param {any} filters - Filter parameters.
   * @param {any} sorter - Sorting parameters.
   */
  handleTableChange = (pagination, filters, sorter) => {
    this.setTableParams({ pagination, filters, ...sorter });
    if (this.state.tableParams &&
      this.state.tableParams.pagination &&
      this.state.tableParams.pagination.pageSize &&
      pagination.pageSize !== this.state.tableParams.pagination.pageSize) {
      this.setState({ users: [] });
    }
  };

  /**
   * Handles the visibility state of the add user modal, using the state `this.state.showAddUserModal`.
   */
  handleAddUserModal = () => {
    const { showAddUserModal } = this.state;
    this.setState({ showAddUserModal: !showAddUserModal });
  };

  /**
   * Adds a new user to the system, using the API for the effect.
   *
   * @param {any} values - New user data.
   */
  addUser = (values) => {
    this.setState({ addFetching: true });
    // Get options to fetch
    const fetchOptions = {
      method: 'POST',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      }),
      body: JSON.stringify({ email: values.email }),
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/users`, fetchOptions).then(async (response) => {
      if (response.ok) return response.json();
      const result = await response.json();
      throw new Error(result ? JSON.stringify(result) : 'Não é possível adicionar um novo utilizador');
    }).then(async (data) => {
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check'></i>
            <span className='cms-notification-icon-text-span'>Utilizador(a) &ldquo;{data.user.name}&rdquo; adicionado(a) com sucesso</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
            <span>O(a) utilizador(a) foi adicionado(a) com sucesso. Consulte a lista de utilizadores para verificar a sua atualização.</span>
          </div>
        ),
      });
      this.setState({ addError: null, addFetching: false });
      this.handleAddUserModal();
      this.fetchData();
    }).catch((error) => {
      let message = 'Não é possível adicionar um novo utilizador';
      let extendedMessage = 'Por favor, reveja os dados inseridos e/ou tente novamente.';
      if (error && error.message) {
        if (error.message.includes('Your token is not valid!')) {
          extendedMessage = 'Não tem autorização para adicionar utilizadores. Tente reentrar na sua conta e, se o erro persistir, por favor, contacte o administrador da aplicação.';
        }
        if (error.message.includes('You have no permission to access this resource.')) {
          extendedMessage = 'Não tem autorização para adicionar utilizadores. Por favor, contacte o administrador da aplicação.';
        }
        if (error.message.includes('Please enter a valid email.')) {
          message = 'Email introduzido inválido';
          extendedMessage = 'Por favor, introduza um email válido.';
        }
        if (error.message.includes('User is already registered.')) {
          message = 'Utilizador já existe no sistema';
          extendedMessage = 'O utilizador já existe no sistema. Verifique a lista de utilizador desativados e reative-o, caso esse seja o efeito pretendido.';
        }
        if (error.message.includes('User is not regitered in active directory.')) {
          message = 'Utilizador não encontrado';
          extendedMessage = 'O utilizador não foi encontrado na active directory. Verifique o email introduzido.';
        }
      }
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'></i>
            <span className='cms-notification-icon-text-span'>{message}</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
            <span>{extendedMessage}</span>
          </div>
        ),
      });
      this.setState({ addError: message, addFetching: false });
    });
  };

  /**
   * Handles the visibility state of the edit user modal, using the state `this.state.showEditUserModal`.
   */
  handleEditUserModal = () => {
    const { showEditUserModal } = this.state;
    this.setState({ showEditUserModal: !showEditUserModal });
  };

  /**
   * Edits an existing user in the system, using the API for the effect.
   *
   * @param {any} values - New data of the existing user.
   */
  editUser = (values) => {
    this.setState({ editFetching: true });
    const { selectedItem } = this.state;
    // Get options to fetch
    const fetchOptions = {
      method: 'PATCH',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      }),
      body: JSON.stringify({ idRole: values.idRole }),
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/users/${selectedItem.key}`, fetchOptions).then(async (response) => {
      if (response.ok) return response.json();
      const result = await response.json();
      throw new Error(result ? JSON.stringify(result) : `Não é possível editar o utilizador '${selectedItem.name}'`);
    }).then(async (data) => {
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check'></i>
            <span className='cms-notification-icon-text-span'>Alterações guardadas com sucesso</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
            <span>O(a) utilizador(a) <u>{values.name}</u> foi atualizado(a) com sucesso. Consulte a lista de utilizadores para verificar a alteração.</span>
          </div>
        ),
      });
      this.setState({ editError: null, editFetching: false });
      this.handleEditUserModal();
      this.fetchData();
    }).catch((error) => {
      let message = `Não é possível editar o grupo de permissões do(a) utilizador(a) '${selectedItem.name}'`;
      let extendedMessage = 'Não é possível atualizar os dados do próprio utilizador. Tente novamente mais tarde ou contacte outro administrador.';
      if (error && error.message) {
        if (error.message.includes('Your token is not valid!')) {
          extendedMessage = 'Não tem autorização para editar grupos de permissões de utilizadores. Tente reentrar na sua conta. Caso o erro persista, por favor, contacte outro administrador da aplicação.';
        }
        if (error.message.includes('You have no permission to access this resource.')) {
          extendedMessage = 'Não tem autorização para editar grupos de permissões de utilizadores.';
        }
        if (error.message.includes('Resource not found.')) {
          message = 'Utilizador selecionado inválido';
          extendedMessage = 'O utilizador selecionado não foi encontrado no sistema.';
        }
        if (error.message.includes('User is not active.')) {
          message = 'Utilizador selecionado inativo';
          extendedMessage = 'O utilizador selecionado está inativo. Caso deseje alterar o seu grupo de permissão, este terá de ser reativado.';
        }
        if (error.message.includes('Role is deactivated.')) {
          message = 'Grupo de permissões inativo';
          extendedMessage = 'O grupo de permissões selecionado foi desativado. Por favor, verifique os dados inseridos e/ou tente novamente mais tarde.';
        }
      }
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'></i>
            <span className='cms-notification-icon-text-span'>{message}</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
            <span>{extendedMessage}</span>
          </div>
        ),
      });
      this.setState({ editError: message, editFetching: false });
    });
  };

  /**
   * Handles the visibility state of the deactivate user modal, using the state `this.state.showDeactivateUserModal`.
   */
  handleDeactivateModal = () => {
    const { showDeactivateUserModal } = this.state;
    this.setState({ showDeactivateUserModal: !showDeactivateUserModal });
  };
  
  /**
   * Deactivates an existing user in the system, using the API for the effect.
   */
  deactivateUser = (values) => {
    const { selectedItem } = this.state;
    // Get options to fetch
    const fetchOptions = {
      method: 'PATCH',
      body: JSON.stringify({ reason: values.deactivationReason }),
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      }),
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/users/${selectedItem.key}/deactivate`, fetchOptions).then(async (response) => {
      if (response.ok) return response.json();
      const result = await response.json();
      throw new Error(result ? JSON.stringify(result) : `Não é possível desativar o utilizador '${selectedItem.name}'`);
    }).then(async (data) => {
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check'></i>
            <span className='cms-notification-icon-text-span'>Utilizador(a) desativado(a) com sucesso</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
            <span>O(a) utilizador(a) <u>{selectedItem.name}</u> foi desativado(a) com sucesso. 
              Consulte a lista de utilizadores inativos para verificar a alteração. 
              O(a) utilizador(a) pode ser reativado(a) através do mesmo processo de desativação.</span>
          </div>
        ),
      });
      this.handleDeactivateModal();
      this.fetchData();
    }).catch((error) => {
      let message = `Não é possível desativar o(a) utilizador(a) '${selectedItem.name}'`;
      let extendedMessage = 'Não é possível desativar o próprio utilizador. Tente novamente mais tarde ou contacte outro administrador.';
      if (error && error.message) {
        if (error.message.includes('Your token is not valid!'))  {
          extendedMessage = 'Não tem autorização para desativar utilizadores. Reentre na sua conta e tente novamente.';
        }
        if (error.message.includes('You have no permission to access this resource.'))  {
          extendedMessage = 'Não tem autorização para desativar utilizadores. Por favor, contacte o administrador da aplicação.';
        }
        if (error.message.includes('User is already deactivated.'))  {
          extendedMessage = 'O(a) utilizador(a) já foi desativado(a).';
        }
      }
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'></i>
            <span className='cms-notification-icon-text-span'>{message}</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
            <span>{extendedMessage}</span>
          </div>
        ),
      });
    });
  };

  /**
   * Reactivates an existing user in the system, using the API for the effect.
   */
  reactivateUser = () => {
    const { selectedItem } = this.state;
    // Get options to fetch
    const fetchOptions = {
      method: 'PATCH',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      }),
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/users/${selectedItem.key}/reactivate`, fetchOptions).then(async (response) => {
      if (response.ok) return response.json();
      const result = await response.json();
      throw new Error(result ? JSON.stringify(result) : `Não é possível reativar o utilizador '${selectedItem.name}'`);
    }).then(async (data) => {
      this.fetchData();
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check'></i>
            <span className='cms-notification-icon-text-span'>Utilizador(a) reativado(a) com sucesso</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
            <span>O(a) utilizador(a) <u>{selectedItem.name}</u> foi reativado(a) com sucesso. 
              Consulte a lista de utilizadores ativos para verificar a alteração. 
              O(a) utilizador(a) pode ser desativado(a) através do mesmo processo de reativação.</span>
          </div>
        ),
      });
    }).catch((error) => {
      let message = `Não é possível reativar o(a) utilizador(a) '${selectedItem.name}'`;
      let extendedMessage = 'Não é possível reativar o próprio utilizador. Tente novamente mais tarde ou contacte outro administrador.';
      if (error && error.message) {
        if (error.message.includes('Your token is not valid!'))  {
          extendedMessage = 'Não tem autorização para reativar utilizadores. Reentre na sua conta e tente novamente.';
        }
        if (error.message.includes('You have no permission to access this resource.'))  {
          extendedMessage = 'Não tem autorização para reativar utilizadores. Por favor, contacte o administrador da aplicação.';
        }
        if (error.message.includes('User is already active.'))  {
          extendedMessage = 'O(a) utilizador(a) já foi reativo.';
        }
      }
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'></i>
            <span className='cms-notification-icon-text-span'>{message}</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
            <span>{extendedMessage}</span>
          </div>
        ),
      });
    });
  };

  /**
   * Render method of React component.
   * 
   * @returns the component template.
   */
  render() {
    const { roles, rolesError, rolesFetching } = this.props;
    const { 
      addError, addFetching, deactivateError, deactivateFetching, editError, editFetching, loading, pageWidth, 
      selectedItem, showAddUserModal, showDeactivateUserModal, showEditUserModal, tableParams, users, usersError,
    } = this.state;

    if (usersError || rolesError || (!loading && users.length < 1) || (!rolesFetching && roles.length < 1)) {
      return (
        <div className='cms-menu-actions-container'>
          {/* <h1 id='cms-menu-actions-title'>Gestão de Utilizadores</h1> */}
          <div id='cms-menu-actions-tabs'>
            <div id='cms-menu-actions-error'>
              <i className='fa-duotone fa-arrow-rotate-right'></i>
              <b>Ocorreu um erro...</b>
              <Button type='text' onClick={() => window.location.reload(true)}>
                Clique&nbsp;<span className='cms-menu-actions-error-btn'>aqui</span>&nbsp;para refrescar a página.
              </Button>
              Ou tente mais tarde.
            </div>
          </div>
        </div>
      );
    }

    if (loading) {
      return (
        <div className='cms-menu-actions-container'>
          <div id='cms-menu-actions-error'>
            <PuffLoader color={styles.COLORS.PrimaryColor} size={100} />
            <p>A carregar...</p>
          </div>
        </div>
      );
    }

    const columns = [
      {
        title: 'Nome',
        dataIndex: 'name',
        ellipsis: true,
        key: 'name',
        render: (_, record) => {
          return (
            <Button type='text' onClick={() => {
              const newSelectedItem = !selectedItem || (selectedItem && selectedItem.key !== record.key) ? record : null;
              this.setState({ selectedItem: newSelectedItem });
              // if (newSelectedItem) this.handleEditUserModal();
            }}>{record.name}</Button>
          );
        },
        sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        width: pageWidth > 1000 ? 'auto' : '200px'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        ellipsis: true,
        key: 'email',
        render: (_, record) => {
          return (
            <Button type='text' onClick={() => {
              const newSelectedItem = !selectedItem || (selectedItem && selectedItem.key !== record.key) ? record : null;
              this.setState({ selectedItem: newSelectedItem });
              // if (newSelectedItem) this.handleEditUserModal();
            }}>{record.email}</Button>
          );
        },
        sorter: (a, b) => a.email.toLowerCase().localeCompare(b.email.toLowerCase()),
        width: '300px'
      },
      {
        dataIndex: 'username',
        ellipsis: true,
        key: 'username',
        render: (_, record) => {
          return (
            <Button type='text' onClick={() => {
              const newSelectedItem = !selectedItem || (selectedItem && selectedItem.key !== record.key) ? record : null;
              this.setState({ selectedItem: newSelectedItem });
              // if (newSelectedItem) this.handleEditUserModal();
            }}>{record.username}</Button>
          );
        },
        sorter: (a, b) => a.username.toLowerCase().localeCompare(b.username.toLowerCase()),
        title: 'Username',
        width: '200px'
      },
      {
        align: 'center',
        dataIndex: 'roleName',
        ellipsis: true,
        filterIcon: <i className='fa-duotone fa-filters'></i>,
        filters: roles.map((role) => { return { 
          text: role.name === 'Administrator' ? 'Administrador' : 
            role.name === 'Manager' ? 'Gestor' : 
              'Sem permissões', 
          value: role.idRole 
        }; }),
        key: 'roleName',
        onFilter: (value, record) => record.idRole === value,
        render: (value) => <span>{value === 'Administrator' ? 'Administrador' : value === 'Manager' ? 'Gestor' : 'Sem permissões'}</span>,
        sorter: (a, b) => a.roleName.toLowerCase().localeCompare(b.roleName.toLowerCase()),
        title: 'Grupo',
        width: '140px'
      },
      {
        align: 'center',
        dataIndex: 'isActive',
        ellipsis: true,
        filterIcon: <i className='fa-duotone fa-filters'></i>,
        filters: [{ text: 'Ativo', value: true }, { text: 'Inativo', value: false }],
        key: 'isActive',
        onFilter: (value, record) => record.isActive === value,
        render: (value, record) => <span>{value ? 'Ativo' : 'Inativo'}</span>,
        title: 'Estado',
        width: '120px',
      },
    ];

    return (
      <div className='cms-menu-actions-container'>
        {/* Actions */}
        <div className='cms-menu-actions-btns'>
          <Button
            icon={<i className='fa-solid fa-plus'></i>}
            onClick={this.handleAddUserModal}
            type='primary'
          >Novo Utilizador</Button>
          <Button
            disabled={selectedItem ? false : true}
            icon={<i className='fa-duotone fa-pen-to-square'></i>}
            onClick={this.handleEditUserModal}
            type='default'
          >Editar</Button>
          {selectedItem && selectedItem.isActive &&
            <Button
              danger
              icon={<i className='fa-duotone fa-toggle-off'></i>}
              onClick={this.handleDeactivateModal}
              type='default'
            >{selectedItem.isActive ? 'Desativar' : 'Reativar'}</Button>
          }
          {selectedItem && !(selectedItem.isActive) &&
            <Popconfirm
              title={<>Reativar utilizador(a)</>}
              description={
                <>
                  <div>Tem a certeza que pretende reativar o(a) utilizador(a) {selectedItem.name}?</div>
                  <div>Esta ação <b>é reversível</b>.</div>
                </>
              }
              okText='Reativar'
              okButtonProps={{ className: 'vantagens-warning-button' }}
              okType='default'
              cancelText='Cancelar'
              onConfirm={this.reactivateUser}
            >
              <Button className='vantagens-warning-button' icon={<i className='fa-duotone fa-toggle-on'></i>} type='default'>Reativar</Button>
            </Popconfirm>
          }
        </div>

        {/* Tables */}
        <div id='cms-menu-actions-tabs'>
          <div className='cms-menu-actions-tabs-item'>
            {/* Data table */}
            <Table
              bordered
              columns={columns}
              dataSource={users}
              loading={loading || rolesFetching}
              onChange={this.handleTableChange}
              pagination={tableParams.pagination}
              rowKey={(record) => record.key}
              rowSelection={{
                onChange: (selectedRowKeys, selectedRows) => {
                  this.setState({ selectedItem: selectedRows.length > 0 ? selectedRows[selectedRows.length-1] : null });
                },
                selectedRowKeys: selectedItem ? [selectedItem.key] : [],
                type: 'checkbox',
                hideSelectAll: true,
              }}
              scroll={{ x: 240 }}
              size='small'
            />                  
          </div>
        </div>

        {/* Add new user */}
        <Drawer
          className='cms-menu-add-drawer'
          destroyOnClose
          extra={
            <div className='cms-menu-add-drawer-header'>
              {/* Title */}
              <div>Adicionar um novo utilizador</div>
              {/* Close btn */}
              <Button 
                id='hamburger-menu-close'
                icon={<i className='fa-light fa-xmark'></i>}
                onClick={this.handleAddUserModal}
                type='text'
              />
            </div>
          }
          open={showAddUserModal}
          onClose={this.handleAddUserModal}
          placement='right'
          size='large'
          title='Adicionar um novo utilizador'
          width={window.innerWidth < 736 ? window.innerWidth : 736}
        >
          <div></div>
          <Form
            autoComplete='off'
            layout='vertical'
            name='Adicionar utilizador'
            onFinish={this.addUser}
          >
            <div>
              {/* Email */}
              <Form.Item
                hasFeedback
                label='Email'
                name='email'
                rules={[
                  { required: true, message: 'Por favor, introduza o email do utilizador a adicionar.' },
                  { type: 'email', message: 'Por favor, introduza um email válido.' },
                  { pattern: /^[\w-.]+@ips.pt/g , message: 'Por favor, introduza um email válido. O email deverá pertencer ao domínio do IPS.' }
                ]}
              >
                <Input allowClear placeholder='Introduzir o email do utilizador a adicionar' type='email' />
              </Form.Item>
            </div>

            {/* Buttons */}
            <Form.Item style={{ width: '100%' }}>
              <div className='cms-add-modal-btns'>
                <Button type='default' onClick={this.handleAddUserModal}>Cancelar</Button>
                <Button 
                  type={addError ? 'default' : 'primary'}
                  danger={addError ? true : false}
                  htmlType='submit'
                  disabled={addFetching}>{addError ? 'Tentar novamente' : addFetching ? 'A carregar...' : 'Adicionar'}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Drawer>
        

        {/* Edit User */}
        { selectedItem &&
          <Drawer
            afterOpenChange={() => this.props.getRoles()}
            className='cms-menu-add-drawer'
            destroyOnClose
            extra={
              <div className='cms-menu-add-drawer-header'>
                {/* Title */}
                <div>Editar utilizador</div>
                {/* Close btn */}
                <Button 
                  id='hamburger-menu-close'
                  icon={<i className='fa-light fa-xmark'></i>}
                  onClick={this.handleEditUserModal}
                  type='text'
                />
              </div>
            }
            open={showEditUserModal}
            onClose={this.handleEditUserModal}
            placement='right'
            size='large'
            title='Editar utilizador'
            width={window.innerWidth < 736 ? window.innerWidth : 736}
          >
            <div></div>
            <Form
              name='Edit user'
              initialValues={{ remember: true }}
              onFinish={this.editUser}
              autoComplete='on'
            >
              <div>
                {/* Name */}
                <Form.Item initialValue={selectedItem.name} label='Nome' name='name'>
                  <Input type='text' disabled />
                </Form.Item>

                {/* Email */}
                <Form.Item initialValue={selectedItem.email} label='Email' name='email'>
                  <Input type='email' disabled />
                </Form.Item>

                {/* Username */}
                <Form.Item initialValue={selectedItem.username} label='Nome de Utilizador' name='username'>
                  <Input type='text' disabled />
                </Form.Item>

                {/* Role */}
                <Form.Item
                  hasFeedback
                  label='Grupo de Permissões'
                  name='idRole'
                  rules={[{ required: true, message: 'Por favor, selecione um grupo de permissões.' }]}
                  initialValue={selectedItem.idRole}
                >
                  <Select
                    allowClear={false}
                    filterOption={(input, option) => {
                      const role = roles.find((role) => role.idRole === option.value);
                      return role && role.name && (
                        (role.name === 'Administrator' && 'administrator'.includes(input.toLowerCase())) ||
                      (role.name === 'Manager' && 'gestor'.includes(input.toLowerCase())) ||
                      (role.name === 'Visitor' && 'sem permissões'.includes(input.toLowerCase()))
                      );
                    }}
                    loading={rolesFetching}
                    placeholder='Selecione um grupo de permissões'
                    showSearch
                  >
                    {roles.map((role) => {
                      return <Option key={role.idRole} value={role.idRole}>{
                        role.name === 'Administrator' ? 'Administrador' :
                          role.name === 'Manager' ? 'Gestor' :
                            'Sem permissões'
                      }</Option>;
                    })}
                  </Select>
                </Form.Item>

                {/* Data de ativação e do último login*/}
                <Form.Item >
                  <Alert message={
                    <>
                      <p><b>Data de ativação:</b> {selectedItem.activationDate ? dayjs(selectedItem.activationDate).subtract(1, 'h').format('DD/MM/YYYY HH:mm:ss') : '-'}</p>
                      <p><b>Data do último login:</b> {selectedItem.lastLoginDate ? dayjs(selectedItem.lastLoginDate).subtract(1, 'h').format('DD/MM/YYYY HH:mm:ss') : '-'}</p>
                    </>
                  } type='info' />
                </Form.Item>

                {/* Data de desativação */}
                { selectedItem.deactivationDate && selectedItem.deactivationReason && selectedItem.deactivatedBy &&
                  <Form.Item>
                    <Alert message={
                      <>
                        <p><b>Data de desativação:</b> {selectedItem.deactivationDate ? selectedItem.deactivationDate.format('DD/MM/YYYY HH:mm:ss') : '-'}</p>
                        <p><b>Motivo:</b> {selectedItem.deactivationReason ? selectedItem.deactivationReason : '-'}</p>
                        <p><b>Desativado(a) por:</b> {selectedItem.deactivatedBy ? selectedItem.deactivatedBy : '-'}</p>
                      </>
                    } type='error' />
                  </Form.Item>
                }
              </div>
              <br></br>

              {/* Buttons */}
              <Form.Item style={{ width: '100%' }}>
                <div className='cms-add-modal-btns'>
                  <Button type='default' onClick={this.handleEditUserModal} disabled={editFetching}>Cancelar</Button>
                  <Button 
                    type={editError ? 'default' : 'primary'}
                    danger={editError ? true : false}
                    htmlType='submit'
                    disabled={editFetching}
                  >{editError ? 'Tentar novamente' : editFetching ? 'A carregar...' : 'Guardar'}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Drawer>
        }

        {/* Deactivate User Modal */}
        { selectedItem &&
          <Modal
            afterOpenChange={() => this.props.getRoles()}
            closable
            destroyOnClose
            footer={null}
            keyboard
            onCancel={this.handleDeactivateModal}
            open={showDeactivateUserModal}
            title={<h5 style={{marginTop: 0}}>Desativar <span style={{ color: styles.COLORS.PrimaryColor }}>{selectedItem.name}</span></h5>}
          >
            <Form
              name='Deactivate user'
              initialValues={{ remember: true }}
              onFinish={this.deactivateUser}
              autoComplete='on'
            >
              {/* Name */}
              <Form.Item initialValue={selectedItem.name} label='Nome' name='name'>
                <Input type='text' disabled />
              </Form.Item>

              {/* Email */}
              <Form.Item initialValue={selectedItem.email} label='Email' name='email'>
                <Input type='email' disabled />
              </Form.Item>

              {/* Username */}
              <Form.Item initialValue={selectedItem.username} label='Nome de Utilizador' name='username'>
                <Input type='text' disabled />
              </Form.Item>

              {/* Role */}
              <Form.Item label='Grupo de Permissões'  name='idRole' initialValue={selectedItem.idRole}>
                <Select disabled loading={rolesFetching}>
                  {roles.map((role) => {
                    return <Option key={role.idRole} value={role.idRole}>{
                      role.name === 'Administrator' ? 'Administrador' :
                        role.name === 'Manager' ? 'Gestor' :
                          'Sem permissões'
                    }</Option>;
                  })}
                </Select>
              </Form.Item>

              {/* Deactivation Reason */}
              <Form.Item
                hasFeedback
                label='Motivo'
                name='deactivationReason'
                rules={[
                  { required: true, message: 'Por favor, introduza a razão da desativação.' },
                  { min: 5, message: 'Por favor, introduza uma descrição maior.' }
                ]}
              >
                <Input
                  allowClear
                  placeholder='Introduzir a razão da desativação do(a) utilizador(a)'
                  type='text'
                />
              </Form.Item>

              <br></br>

              {/* Buttons */}
              <Form.Item >
                <div className='cms-add-modal-btns'>
                  <Button type='default' onClick={this.handleDeactivateModal} disabled={deactivateFetching}>Cancelar</Button>
                  <Button 
                    type={deactivateError ? 'default' : 'primary'}
                    danger={deactivateError ? true : false}
                    htmlType='submit'
                    disabled={deactivateFetching}
                  >{deactivateError ? 'Tentar novamente' : deactivateFetching ? 'Aguarde...' : 'Desativar'}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Modal>
        }
      </div>
    );
  }
}

/**
 * Used in the Redux pattern to reflect any updates to the Redux store and merge them into props in the current
 * component. The Redux store serves as a centralized place for the state to live in the application.
 * 
 * @param {any} state - Centralized state of the application.
 * @returns {any} the state of the application as the component props.
 */
const mapPropsToState = (state) => {
  const roles = state.roles;

  return {
    roles: roles.data,
    rolesError: roles.error,
    rolesFetching: roles.fetching,
  };
};

/**
 * Used in the Redux pattern to dispatch actions to the Redux store, triggering a state change.
 * 
 * @param {any} dispatch - function of the Redux store.
 * @returns {any} the dispatch functions as components props.
 */
const mapDispatchToState = (dispatch) => {
  return {
    getRoles: () => dispatch(getRoles()),
  };
};

export default withRouter(connect(mapPropsToState, mapDispatchToState)(ManageUsers));