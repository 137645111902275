import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { styles } from '../../styles';
import './index.scss';

/**
 * Logotype class component displays a logotype, adding specific properties and styles.
 * 
 * Accepts the props:
 * * `alt` - The alternative text of the image.
 * * `height` - The height of the image.
 * * `padding` - The padding of the image.
 * * `src` - The image SRC.
 * * `url` - The image URL.
 */
export default class Logotype extends React.Component {
  render() {
    const { alt, height, padding, src, url } = this.props;
    return (
      <Link to={`${process.env.REACT_APP_PUBLIC_URL}${url ? url : ''}`} className='logotype' style={{ padding }}>
        <img src={src} alt={alt} style={{ height: height }} />
      </Link>
    );
  }
}

Logotype.propTypes = {
  alt: PropTypes.string,
  height: PropTypes.string,
  padding: PropTypes.string,
  src: PropTypes.string,
  url: PropTypes.string,
};

Logotype.defaultProps = {
  alt: undefined,
  height: styles.SPACING.MarginXXL,
  padding: styles.SPACING.Padding,
  src: undefined,
  url: undefined,
};