import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Skeleton } from 'antd';
import { withRouter } from '../../utils';
import { getCategories } from '../../reducers/Categories';
import { getMenuOptions } from '../../reducers/MenuOptions';
import './index.scss';

/**
 * TopMenuOptions class represents the required template to display a simple menu.
 */
class TopMenuOptions extends React.Component {
  /**
   * Allows to execute the React code when the component is already placed in the DOM (Document Object Model).
   * This method is called during the Mounting phase of the React Life-cycle i.e after the component is rendered.
   */
  componentDidMount() {
    this.props.getCategories();
    this.props.getMenuOptions();
  }

  render() {
    const { 
      categories, categoriesError, categoriesFetching,
      menuOptions, menuOptionsError, menuOptionsFetching
    } = this.props;
    if (categoriesError || menuOptionsError) return null;
    if (categoriesFetching || menuOptionsFetching) return <Skeleton.Button active={true} block={true} />;
    if (!categories || !menuOptions) return null;
    
    const completeMenuOptions = [
      {
        idMenuOption: '1',
        idParent: null,
        title: 'Vantagens',
        url: '/vantagens',
        order: -1,
        numberOfChildren: categories.length,
        children: categories.sort((categoryA, categoryB) => {
          return categoryA.name < categoryB.name ? -1 : 1;
        }).map((category, index) => {
          return {
            idMenuOption: category.idCategory,
            idParent: '1',
            title: category.name,
            url: `/vantagens?categoria=${category.idCategory}`,
            order: index,
            numberOfChildren: 0,
            children:[],
          };
        })
      },
      ...menuOptions,
    ];

    return (
      <ul className='uk-navbar-nav'>
        {completeMenuOptions.map((option) => {
          const url = option.url ? option.url : option.page && option.page.url ? option.page.url : '/';
          return (
            <li key={option.idMenuOption}>
              <Link className='header-menu-option' to={`${url.startsWith('/') ? process.env.REACT_APP_PUBLIC_URL : ''}${url}`}>
                <Button className='uk-navbar-nav-menu-option-btn' role='link' type='primary'>{option.title}</Button>
              </Link>
              {option.children && option.children.length > 0 ?
                <div className='uk-navbar-dropdown'>
                  <ul className='uk-nav uk-navbar-dropdown-nav'>
                    {option.children.sort((optionA, optionB) => {
                      return optionA.order - optionB.order;
                    }).map((child) => {
                      const childUrl = child.url ? child.url : child.page && child.page.url ? child.page.url : '/';
                      return (
                        <li key={child.idMenuOption}>
                          <Link to={`${childUrl.startsWith('/') ? process.env.REACT_APP_PUBLIC_URL : ''}${childUrl}`} className='logotype'
                          >{child.title}</Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                : null
              }
            </li>
          );
        })}
      </ul>
    );
  }
}

const mapPropsToState = (state) => {
  const menuOptions = state.menuOptions;
  const categories = state.categories;

  return {
    menuOptionsFetching: menuOptions.fetching,
    menuOptionsError: menuOptions.error,
    menuOptions: menuOptions.data,

    categoriesFetching: categories.fetching,
    categoriesError: categories.error,
    categories: categories.data,
  };
};

const mapDispatchToState = (dispatch) => {
  return {
    getCategories: (idLanguage) => dispatch(getCategories(idLanguage)),
    getMenuOptions: () => dispatch(getMenuOptions('order', 'parent')),
  };
};

export default withRouter(connect(mapPropsToState, mapDispatchToState)(TopMenuOptions));