import React from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { withRouter } from '../../utils';
import { Button, Divider, Drawer, Form, Input, Popconfirm, Table, Tag, Upload, notification } from 'antd';
import { PuffLoader } from 'react-spinners';
import { styles } from '../../styles';
import no_photo from '../../images/no_photo.png';

/**
 * ManageGroups component represents the page template that allows to manage the application groups of advantages.
 * It displays a table with all active and inactive groups.
 * It allows tools to create, edit, deactivate, reactivate and delete groups.
 */
class ManageGroups extends React.Component {
  /**
   * The constructor for a React component is called before it is mounted.
   * When implementing the constructor, `super(props)` must be called before any
   * other statement. Otherwise, this.props will be undefined in the constructor,
   * which can lead to bugs.
   *
   * A React constructor is only used for two purposes:
   * - Initializing local state by assigning an object to this.state.
   * - Binding event handler methods to an instance.
   *
   * Instead of calling `setState()` in the `constructor(), the initial state must be
   * directly assigned to `this.state` in the constructor
   *
   * @param {*} props - Arbitrary component inputs.
   */
  constructor() {
    super();
    this.state = {
      addError: null,
      addFetching: false,
      addImageUpload: null,
      addImageUrl: null,
      groups: [],
      groupsError: null,
      editError: null,
      editFetching: false,
      loading: false,
      pageWidth: 0,
      selectedItem: null,
      showAddGroup: false,
      showEditGroup: false,
    };
  }

  /**
   * Allows to execute the React code when the component is already placed in the DOM (Document Object Model).
   * This method is called during the Mounting phase of the React Life-cycle i.e after the component is rendered.
   */
  componentDidMount() {
    // Get data
    this.fetchData();
    // Scroll to top
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Add event on resize
    this.setState({ pageWidth: window.innerWidth });
    window.addEventListener('resize', () => this.setState({ pageWidth: window.innerWidth }));
  }

  /**
   * Recursively sets the array of groups to manage. 
   * 
   * @param {any[]} data - Original data of groups. 
   * @returns {any[]} the modified dataset of groups.
   */
  setGroups = (data) => {
    return data.map((group) => {
      return {
        key: group.idGroup,
        name: group.name,
        image: group.image,
        numberOfProtocols: group.numberOfProtocols,
        activationDate: group.activationDate ? dayjs(group.activationDate) : null,
        deactivationDate: group.deactivationDate ? dayjs(group.deactivationDate) : null,
        isActive: this.groupIsActive(group),
      };
    });
  };

  /**
   * Checks if a group is active, analysing its activation and deactivation dates and comparing it to the
   * actual date.
   * 
   * @param {any} record - Group object.
   * @returns {boolean} - true if group is active and false otherwise.
   */
  groupIsActive = (record) => {
    const activation = record.activationDate ? dayjs(record.activationDate).subtract(1, 'h') : undefined;
    const deactivation = record.deactivationDate ? dayjs(record.deactivationDate).subtract(1, 'h')  : undefined;
    const now = dayjs();
    return activation && (activation.isBefore(now) || activation.isSame(now)) && 
      (!deactivation || (deactivation && deactivation.isAfter(now))) ? true : false;
  };

  /**
   * Fetches all groups from database through an available API.
   * Calls `setGroups` to modify the data, regarding this component.
   */
  fetchData = () => {
    this.setLoading(true);
    const { selectedItem } = this.state;
    // Get options to fetch
    const fetchOptions = {
      method: 'GET',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      })
    };
    // Fetch data
    fetch(`${process.env.REACT_APP_API_BASE_URL}/groups`, fetchOptions).then(async (response) => { 
      if (response.ok) return response.json();
      const result = await response.json();
      throw new Error(result ? JSON.stringify(result) : 'Não é possível obter os grupos.');
    }).then(async (data) => {
      // await (new Promise((res) => {setTimeout(res, 5000); }));
      const groups = this.setGroups(data);
      this.setState({ groups: groups, groupsError: null });
      if (selectedItem) this.setState({ selectedItem: groups.find((group) => group.key === selectedItem.key) });
      this.setLoading(false);
    }).catch((error) => {
      this.setState({ groups: [], groupsError: error.message, selectedItem: null });
      this.setLoading(false);
    });
  };

  /**
   * Sets loading state from `this.state.loading`.
   * This state is relationated with the data fetching from database.
   *
   * @param {boolean} loading - Sets the loading state to true or false.
   */
  setLoading = (loading) => {
    this.setState({ loading: loading });
  };
  
  /**
   * Handles the visibility state of the add group drawer, using the state `this.state.showAddGroup`.
   */
  handleAddGroupDrawer = () => {
    const { showAddGroup } = this.state;
    this.setState({ showAddGroup: !showAddGroup, addImageUpload: null, addImageUrl: null });
  };

  /**
   * Handles the image file upload on banner creation.
   */
  handleAddFileList = (e) => {
    if (Array.isArray(e)) return e;
    if (e) return e.fileList;
    return undefined;
  };

  /**
   * Adds a new group to the system, using the API for the effect.
   *
   * @param {any} values - New group data.
   */
  addGroup = (values) => {
    this.setState({ addFetching: true });
    // Get body to fetch 
    const formData = new FormData();
    formData.append('name', values.name);
    if (values.imageUrl) formData.append('path', values.imageUrl);
    if (values.imageUploaded && values.imageUploaded.length > 0) formData.append('image', values.imageUploaded[0].originFileObj);
    // Get options to fetch
    const fetchOptions = {
      method: 'POST',
      body: formData,
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      }),
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/groups`, fetchOptions).then(async (response) => {
      if (response.ok) return response.json();
      const result = await response.json();
      throw new Error(result ? JSON.stringify(result) : 'Não é possível adicionar um novo grupo');
    }).then(async (data) => {
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check'></i>
            <span className='cms-notification-icon-text-span'>Grupo &ldquo;{data.group.name}&rdquo; adicionado com sucesso</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
            <span>O grupo foi adicionado com sucesso. Consulte-o na lista de grupos e comece-o a associar a protocolos.</span>
          </div>
        ),
      });
      setTimeout(() => {
        this.setState({ addError: null, addFetching: false });
        this.handleAddGroupDrawer();
        this.fetchData();
      }, 1500);
    }).catch((error) => {
      let message = 'Não é possível adicionar um novo grupo';
      let extendedMessage = 'Por favor, reveja os dados inseridos e/ou tente novamente.';
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'></i>
            <span className='cms-notification-icon-text-span'>{message}</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
            <span>{extendedMessage}</span>
          </div>
        ),
      });
      this.setState({ addError: message, addFetching: false });
    });
  };

  /**
   * Handles the visibility state of the edit category modal, using the state `this.state.showEditGroup`.
   */
  handleEditGroupDrawer = () => {
    const { showEditGroup } = this.state;
    this.setState({ showEditGroup: !showEditGroup, addImageUpload: null, addImageUrl: null });
  };

  /**
   * Edits an existing group in the system, using the API for the effect.
   *
   * @param {any} values - New data of the existing group.
   */
  editGroup = (values) => {
    this.setState({ editFetching: true });
    const { selectedItem } = this.state;
    if (selectedItem) {
      // Get body to fetch 
      const formData = new FormData();
      if (values.name) formData.append('name', values.name);
      if (values.imageUrl && !values.imageUrl.includes(selectedItem.image)) formData.append('path', values.imageUrl);
      if (values.imageUploaded && values.imageUploaded.length > 0) formData.append('image', values.imageUploaded[0].originFileObj);
      // Get options to fetch
      const fetchOptions = {
        method: 'PATCH',
        body: formData,
        headers: new Headers({
          'app_secret_key': process.env.REACT_APP_API_APP_KEY,
          'Authorization': `Bearer ${window.localStorage.getItem('token')}`
        }),
      };
      // Fetch request
      fetch(`${process.env.REACT_APP_API_BASE_URL}/groups/${selectedItem.key}`, fetchOptions).then(async (response) => {
        if (response.ok) return response.json();
        const result = await response.json();
        throw new Error(result ? JSON.stringify(result) : `Não é possível editar o grupo '${selectedItem.name}'`);
      }).then(async (data) => {
        notification.open({
          duration: 30,
          message: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-check'></i>
              <span className='cms-notification-icon-text-span'>Alterações guardadas com sucesso</span>
            </div>
          ),
          description: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
              <span>O grupo <u>{values.name}</u> foi atualizado com sucesso. Consulte a lista de grupos para verificar a alteração.</span>
            </div>
          ),
        });
        setTimeout(() => {
          this.setState({ editError: null, editFetching: false });
          this.handleEditGroupDrawer();
          this.fetchData();
        }, 1500);
      }).catch((error) => {
        let message = `Não é possível editar o grupo '${selectedItem.name}'`;
        let extendedMessage = 'Por favor, reveja os dados inseridos e/ou tente novamente.';
        notification.open({
          duration: 30,
          message: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-xmark'></i>
              <span className='cms-notification-icon-text-span'>{message}</span>
            </div>
          ),
          description: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
              <span>{extendedMessage}</span>
            </div>
          ),
        });
        this.setState({ editError: message, editFetching: false });
      });
    }
  };

  /**
   * Deactivates an existing group in the system, using the API for the effect.
   */
  deactivateGroup = () => {
    this.setState({ editFetching: true });
    const { selectedItem } = this.state;
    if (selectedItem) {
      // Get options to fetch
      const fetchOptions = {
        method: 'PATCH',
        headers: new Headers({
          'app_secret_key': process.env.REACT_APP_API_APP_KEY,
          'Authorization': `Bearer ${window.localStorage.getItem('token')}`
        }),
      };
      // Fetch request
      fetch(`${process.env.REACT_APP_API_BASE_URL}/groups/${selectedItem.key}/deactivate`, fetchOptions).then(async (response) => {
        if (response.ok) return response.json();
        const result = await response.json();
        throw new Error(result ? JSON.stringify(result) : `Não é possível desativar o grupo '${selectedItem.name}'`);
      }).then(async (data) => {
        notification.open({
          duration: 30,
          message: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-check'></i>
              <span className='cms-notification-icon-text-span'>Grupo desativado com sucesso</span>
            </div>
          ),
          description: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
              <span>O grupo <u>{selectedItem.name}</u> foi desativado com sucesso. 
              Consulte a lista de grupos para verificar a alteração. O grupo pode ser reativado através 
              do mesmo processo de desativação.</span>
            </div>
          ),
        });
        setTimeout(() => {
          this.setState({ editFetching: false });
          this.handleEditGroupDrawer();
          this.fetchData();
        }, 1500);
      }).catch((error) => {
        let message = `Não é possível desativar o grupo '${selectedItem.name}'`;
        let extendedMessage = 'Por favor, tente novamente mais tarde. Se o erro persistir, contacte o administrador da aplicação.';
        if (error && error.message) {
          if (error.message.includes('Your token is not valid!'))  {
            extendedMessage = 'Não tem autorização para desativar grupos. Por favor, contacte o administrador da aplicação.';
          }
          if (error.message.includes('You have no permission to access this resource.'))  {
            extendedMessage = 'Não tem autorização para desativar grupos. Por favor, contacte o administrador da aplicação.';
          }
          if (error.message.includes('Group has associated protocols.'))  {
            extendedMessage = 'O grupo tem vantagens associadas. Se desejar desativar este, deve primeiro eliminar ou desassociar as vantagens deste grupo.';
          }
        }
        notification.open({
          duration: 30,
          message: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-xmark'></i>
              <span className='cms-notification-icon-text-span'>{message}</span>
            </div>
          ),
          description: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
              <span>{extendedMessage}</span>
            </div>
          ),
        });
        this.setState({ editFetching: false });
      });
    }
  };

  /**
   * Reactivates an existing group in the system, using the API for the effect.
   */
  reactivateGroup = () => {
    this.setState({ editFetching: true });
    const { selectedItem } = this.state;
    if (selectedItem) {
      // Get options to fetch
      const fetchOptions = {
        method: 'PATCH',
        headers: new Headers({
          'app_secret_key': process.env.REACT_APP_API_APP_KEY,
          'Authorization': `Bearer ${window.localStorage.getItem('token')}`
        }),
      };
      // Fetch request
      fetch(`${process.env.REACT_APP_API_BASE_URL}/groups/${selectedItem.key}/reactivate`, fetchOptions).then(async (response) => {
        if (response.ok) return response.json();
        const result = await response.json();
        throw new Error(result ? JSON.stringify(result) : `Não é possível reativar o grupo '${selectedItem.name}'`);
      }).then(async (data) => {
        notification.open({
          duration: 30,
          message: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-check'></i>
              <span className='cms-notification-icon-text-span'>Grupo reativado com sucesso</span>
            </div>
          ),
          description: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
              <span>O grupo <u>{selectedItem.name}</u> foi reativado com sucesso. 
                Consulte a lista de grupos para verificar a alteração. 
                O grupo pode ser desativado através do mesmo processo de reativação.</span>
            </div>
          ),
        });
        setTimeout(() => {
          this.setState({ editFetching: false });
          this.handleEditGroupDrawer();
          this.fetchData();
        }, 1500);
      }).catch((error) => {
        let message = `Não é possível reativar o grupo '${selectedItem.name}'`;
        let extendedMessage = 'Por favor, tente novamente mais tarde. Se o erro persistir, contacte o administrador da aplicação.';
        notification.open({
          duration: 30,
          message: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-xmark'></i>
              <span className='cms-notification-icon-text-span'>{message}</span>
            </div>
          ),
          description: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
              <span>{extendedMessage}</span>
            </div>
          ),
        });
        this.setState({ editFetching: false });
      });
    }
  };

  /**
   * Deletes an existing group from the system, using the API for the effect.
   */
  deleteGroup = () => {
    this.setState({ editFetching: true });
    const { selectedItem } = this.state;
    if (selectedItem) {
      // Get options to fetch
      const fetchOptions = {
        method: 'DELETE',
        headers: new Headers({
          'app_secret_key': process.env.REACT_APP_API_APP_KEY,
          'Authorization': `Bearer ${window.localStorage.getItem('token')}`
        }),
      };
      // Fetch request
      fetch(`${process.env.REACT_APP_API_BASE_URL}/groups/${selectedItem.key}`, fetchOptions).then(async (response) => {
        if (response.ok) return response.json();
        const result = await response.json();
        throw new Error(result ? JSON.stringify(result) : `Não é possível apagar o grupo '${selectedItem.name}'`);
      }).then(async (data) => {
        notification.open({
          duration: 30,
          message: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-check'></i>
              <span className='cms-notification-icon-text-span'>Grupo eliminado com sucesso</span>
            </div>
          ),
          description: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
              <span>O grupo <u>{selectedItem.name}</u> foi eliminado com sucesso. Consulte a lista de grupos 
                para verificar a atualização do mesmo. Esta ação <b>não é reversível</b>.</span>
            </div>
          ),
        });
        setTimeout(() => {
          this.setState({ editFetching: false });
          this.handleEditGroupDrawer();
          this.fetchData();
        }, 1500);
      }).catch((error) => {
        let message = `Não é possível eliminar o grupo '${selectedItem.name}'`;
        let extendedMessage = 'Por favor, tente novamente mais tarde. Se o erro persistir, contacte o administrador da aplicação.';
        notification.open({
          duration: 30,
          message: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-xmark'></i>
              <span className='cms-notification-icon-text-span'>{message}</span>
            </div>
          ),
          description: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
              <span>{extendedMessage}</span>
            </div>
          ),
        });
        this.setState({ editFetching: false });
      });
    }
  };

  /**
   * Render method of React component.
   * 
   * @returns the component template.
   */
  render() {
    const {
      addError, addFetching, addImageUpload, addImageUrl, groups, groupsError, editError, editFetching, 
      loading, pageWidth, selectedItem, showAddGroup, showEditGroup
    } = this.state;

    const isActive = localStorage.getItem('isActive');
    const idRole = localStorage.getItem('idRole');
    const role = localStorage.getItem('role');
    const isAdmin = isActive === 'true' && idRole === '376DDE5B-09C4-ED11-A311-00155D08E85F' && role === 'Administrator';
    const isManager = isActive === 'true' && idRole === '386DDE5B-09C4-ED11-A311-00155D08E85F' && role === 'Manager';
    
    if (isActive !== 'true' || !(isAdmin || isManager) || groupsError) {
      return (
        <div className='cms-menu-actions-container'>
          <div id='cms-menu-actions-tabs'>
            <div id='cms-menu-actions-error'>
              <i className='fa-duotone fa-arrow-rotate-right'></i>
              <b>Ocorreu um erro...</b>
              <Button type='text' onClick={() => window.location.reload(true)}>
                Clique&nbsp;<span className='cms-menu-actions-error-btn'>aqui</span>&nbsp;para refrescar a página.
              </Button>
              Ou tente mais tarde.
            </div>
          </div>
        </div>
      );
    }

    if (loading) {
      return (
        <div className='cms-menu-actions-container'>
          <div id='cms-menu-actions-error'>
            <PuffLoader color={styles.COLORS.PrimaryColor} size={100} />
            <p>A carregar...</p>
          </div>
        </div>
      );
    }

    const columns = [
      {
        align: 'center',
        dataIndex: 'image',
        key: 'image',
        render: (_, record) => {
          const path = record.image.startsWith('/') ? `${process.env.REACT_APP_API_IMAGES_BASE_URL}${record.image}` : record.image;
          return (
            <Button
              onClick={() => {
                const newSelectedItem = !selectedItem || (selectedItem && selectedItem.key !== record.key) ? record : null;
                this.setState({ selectedItem: newSelectedItem });
                if (newSelectedItem) this.handleEditGroupDrawer();
              }}
              type='text'
              style={{ height: 'min-content' }}
            >
              <img
                alt={record.name}
                crossOrigin={record.image.startsWith('/') ? 'anonymous' : undefined}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = no_photo;
                }}
                src={path}
                style={{ width: '50px' }}
              />  
            </Button>
            
          );
        },
        title: 'Imagem',
        width: '50px'
      },
      {
        title: 'Nome',
        dataIndex: 'name',
        key: 'name',
        render: (_, record) => {
          const style = {};
          const isActive = record.isActive;
          if (!isActive) style.color = styles.COLORS.TertiaryTextColor;
          return (
            <>
              <Button
                onClick={() => {
                  const newSelectedItem = !selectedItem || (selectedItem && selectedItem.key !== record.key) ? record : null;
                  this.setState({ selectedItem: newSelectedItem });
                  if (newSelectedItem) this.handleEditGroupDrawer();
                }}
                title={`${record.name} ${isActive ? '' : ' (Desativado)'}`}
                type='text'
              >
                {record.name} 
              </Button>
              {!isActive && <Tag color={styles.COLORS.QuaternaryTextColor}>Desativado</Tag>}
            </>
          );
        },
        sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        width: pageWidth > 1000 ? 'auto' : '200px'
      },
      {
        align: 'center',
        dataIndex: 'numberOfProtocols',
        key: 'numberOfProtocols',
        render: (_, record) => {
          const style = {};
          if (!record.isActive) style.color = styles.COLORS.TertiaryTextColor;
          return <span style={style}>{record.numberOfProtocols}</span>;
        },
        sorter: (a, b) => a.numberOfProtocols > b.numberOfProtocols ? 1 : a.numberOfProtocols === b.numberOfProtocols ? 0 : -1,
        title: 'Vantagens',
        width: '120px',
      },
      {
        align: 'center',
        dataIndex: 'activationDate',
        key: 'activationDate',
        render: (value, record) => {
          const style = {};
          if (!record.isActive) style.color = styles.COLORS.QuaternaryTextColor;
          return <span style={style}>{value ? value.add(-1, 'h').format('DD/MM/YYYY HH:mm') : '-'}</span>;
        },
        sorter: (a, b) => {
          const dateA = a.activationDate;
          const dateB = b.activationDate;
          return dateA ? ( dateB ? (dateA.isAfter(dateB) ? 1 : dateA.isSame(dateB) ? 0 : -1) : 1) : -1;
        },
        title: 'Data de início',
        width: '152px',
      },
      {
        align: 'center',
        dataIndex: 'deactivationDate',
        key: 'deactivationDate',
        render: (value, record) => {
          const style = {};
          if (!record.isActive) style.color = styles.COLORS.QuaternaryTextColor;
          return <span style={style}>{value ? value.add(-1, 'h').format('DD/MM/YYYY HH:mm') : '-'}</span>;
        },
        title: 'Data de fim',
        width: '160px',
      },
    ];

    return (
      <div className='cms-menu-actions-container'>
        {/* Actions */}
        <div className='cms-menu-actions-btns'>
          <Button
            type='primary'
            icon={<i className='fa-solid fa-plus'></i>}
            onClick={this.handleAddGroupDrawer}
          >Adicionar Grupo</Button>
          <Button
            type='default'
            disabled={selectedItem ? false : true}
            icon={<i className='fa-duotone fa-pen-to-square'></i>}
            onClick={this.handleEditGroupDrawer}
          >Editar</Button>
        </div>
        
        {/* Tables */}
        <div id='cms-menu-actions-tabs'>
          <div className='cms-menu-actions-tabs-item'>
            {/* Data table */}
            <Table
              bordered
              columns={columns}
              dataSource={groups}
              loading={loading}
              pagination={{ size: 'default' }}
              rowKey={(record) => record.key}
              rowSelection={{
                onChange: (selectedRowKeys, selectedRows) => {
                  this.setState({ selectedItem: selectedRows.length > 0 ? selectedRows[selectedRows.length-1] : null });
                },
                selectedRowKeys: selectedItem ? [selectedItem.key] : [],
                type: 'checkbox',
                hideSelectAll: true,
              }}
              scroll={{ x: 240 }}
              size='small'
            />                 
          </div>
        </div>

        {/* Add new group */}
        <Drawer
          className='cms-menu-add-drawer'
          destroyOnClose
          extra={
            <div className='cms-menu-add-drawer-header'>
              {/* Title */}
              <div>Adicionar um novo grupo</div>
              {/* Close btn */}
              <Button 
                id='hamburger-menu-close'
                icon={<i className='fa-light fa-xmark'></i>}
                onClick={this.handleAddGroupDrawer}
                type='text'
              />
            </div>
          }
          open={showAddGroup}
          onClose={this.handleAddGroupDrawer}
          placement='right'
          size='large'
          title='Adicionar um novo grupo'
          width={window.innerWidth < 736 ? window.innerWidth : 736}
        >
          <div></div>
          <Form
            autoComplete='on'
            initialValues={{ remember: true }}
            layout='vertical'
            name='Adicionar grupo'
            onFinish={this.addGroup}
          >
            <div>
              {/* Name */}
              <Form.Item
                hasFeedback
                label='Nome'
                name='name'
                rules={[
                  { required: true, message: 'Por favor, introduza um nome.' },
                  { max: 100, message: 'Por favor, introduza um nome com um máximo de 100 caracteres.' },
                  { min: 3, message: 'Por favor, introduza um nome com mais de 3 caracteres.' }
                ]}
              >
                <Input allowClear maxLength={100} minLength={3} placeholder='Introduzir o nome do grupo' type='text' />
              </Form.Item>

              {/* Image */}
              <Form.Item label='Imagem' required>
                {/* Image URL */}
                <Form.Item name='imageUrl' rules={[{ type: 'url', message: 'Por favor, introduza um caminho válido.' },]}>
                  <Input
                    allowClear
                    disabled={addImageUpload ? true : false}
                    name='imageUrl'
                    onChange={(e) => this.setState({ addImageUrl: e.target.value })}
                    placeholder='Introduzir o caminho da imagem do grupo'
                    type='url'
                    value={addImageUrl}
                  />
                </Form.Item>

                {/* Divider */}
                <Divider orientation='center' plain type='horizontal'>ou</Divider>

                {/* Image Upload */}
                <Form.Item
                  getValueFromEvent={this.handleAddFileList}
                  name='imageUploaded'
                  noStyle
                  rules={[{ required: !addImageUrl, message: 'Por favor, introduza uma imagem.' }]}
                  valuePropName='fileList'
                >
                  <Upload.Dragger
                    accept='.png, .jpg, .jpeg'
                    beforeUpload={(file) => {
                      this.setState({ addImageUpload: file });
                      return false;
                    }}
                    disabled={addImageUrl ? true : false}
                    listType='picture'
                    maxCount={1}
                    name='imageUploaded'
                    onChange={({ file, fileList }) => this.setState({ addImageUpload: fileList.length < 1 ? null : file })}
                  >
                    <p className='ant-upload-drag-icon'><i className='fa-duotone fa-cloud-arrow-up'></i></p>
                    <p className="ant-upload-text">Clique ou arraste um ficheiro para esta área para carregá-lo</p>
                    <p className="ant-upload-hint">Suporta apenas o carregamento de um único ficheiro do tipo .jpg, .jpeg ou .png</p>
                  </Upload.Dragger>
                </Form.Item>
              </Form.Item>
            </div>
            <br></br>
            {/* Buttons */}
            <Form.Item style={{ width: '100%' }}>
              <div className='cms-add-modal-btns'>
                <Button type='default' onClick={this.handleAddGroupDrawer}>Cancelar</Button>
                <Button 
                  type={addError ? 'default' : 'primary'}
                  danger={addError ? true : false}
                  htmlType='submit'
                  disabled={addFetching}>{addError ? 'Tentar novamente' : addFetching ? 'A carregar...' : 'Criar'}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Drawer>

        {/* Edit group */}
        { selectedItem &&
          <Drawer
            className='cms-menu-add-drawer'
            destroyOnClose
            extra={
              <div className='cms-menu-add-drawer-header'>
                {/* Title */}
                <div>Editar grupo {selectedItem.name}</div>
                {/* Close btn */}
                <Button 
                  id='hamburger-menu-close'
                  icon={<i className='fa-light fa-xmark'></i>}
                  onClick={this.handleEditGroupDrawer}
                  type='text'
                />
              </div>
            }
            open={showEditGroup}
            onClose={this.handleEditGroupDrawer}
            placement='right'
            size='large'
            title='Editar grupo'
            width={window.innerWidth < 736 ? window.innerWidth : 736}
          >
            <div></div>
            <Form
              autoComplete='on'
              initialValues={{ remember: true }}
              layout='vertical'
              name='Editar grupo'
              onFinish={this.editGroup}
            >
              <div>
                {/* Name */}
                <Form.Item
                  hasFeedback
                  initialValue={selectedItem.name}
                  label='Nome'
                  name='name'
                  rules={[
                    { required: true, message: 'Por favor, introduza um nome.' },
                    { max: 100, message: 'Por favor, introduza um nome com um máximo de 100 caracteres.' },
                    { min: 3, message: 'Por favor, introduza um nome com mais de 3 caracteres.' }
                  ]}
                >
                  <Input allowClear maxLength={100} minLength={3} placeholder='Introduzir o nome do grupo' type='text' />
                </Form.Item>

                {/* Image */}
                <Form.Item label='Imagem' required>
                  {/* Image URL */}
                  <Form.Item 
                    initialValue={ addImageUrl ? addImageUrl : selectedItem.image.startsWith('/') ? `${process.env.REACT_APP_API_IMAGES_BASE_URL}${selectedItem.image}` : selectedItem.image}
                    name='imageUrl' 
                    rules={[{ type: 'url', message: 'Por favor, introduza um caminho válido.' },]}
                  >
                    <Input
                      allowClear
                      disabled={addImageUpload ? true : false}
                      name='imageUrl'
                      onChange={(e) => this.setState({ addImageUrl: e.target.value })}
                      placeholder='Introduzir o caminho da imagem do grupo'
                      type='url'
                      value={addImageUrl}
                    />
                  </Form.Item>

                  {/* Divider */}
                  <Divider orientation='center' plain type='horizontal'>ou</Divider>

                  {/* Image Upload */}
                  <Form.Item
                    getValueFromEvent={this.handleAddFileList}
                    name='imageUploaded'
                    noStyle
                    valuePropName='fileList'
                  >
                    <Upload.Dragger
                      accept='.png, .jpg, .jpeg'
                      beforeUpload={(file) => {
                        this.setState({ addImageUpload: file });
                        return false;
                      }}
                      disabled={addImageUrl ? true : false}
                      listType='picture'
                      maxCount={1}
                      name='imageUploaded'
                      onChange={({ file, fileList }) => this.setState({ addImageUpload: fileList.length < 1 ? null : file })}
                    >
                      <p className='ant-upload-drag-icon'><i className='fa-duotone fa-cloud-arrow-up'></i></p>
                      <p className="ant-upload-text">Clique ou arraste um ficheiro para esta área para carregá-lo</p>
                      <p className="ant-upload-hint">Suporta apenas o carregamento de um único ficheiro do tipo .jpg, .jpeg ou .png</p>
                    </Upload.Dragger>
                  </Form.Item>
                </Form.Item>

              </div>
              <br></br>
              {/* Buttons */}
              <Form.Item style={{ width: '100%' }}>
                <div className='cms-add-modal-btns'>
                  <Button type='default' onClick={this.handleEditGroupDrawer} disabled={editFetching}>Cancelar</Button>
                  <Popconfirm
                    title='&nbsp;&nbsp;Eliminar grupo'
                    description={
                      <>
                        <div>Tem a certeza que pretende eliminar este grupo?</div>
                        <div>Esta ação <b>não é reversível</b>.</div>
                      </>
                    }
                    disabled={selectedItem.numberOfProtocols > 0}
                    okText='Eliminar'
                    okButtonProps={{ danger: true }}
                    okType='primary'
                    cancelText='Cancelar'
                    onConfirm={this.deleteGroup}
                    icon={<i className='fa-duotone fa-circle-exclamation' style={{ color: styles.COLORS.ErrorColor }}></i>}
                  >
                    <Button danger={true} disabled={selectedItem.numberOfProtocols > 0 || editFetching} type='default'>Eliminar</Button>
                  </Popconfirm>
                  {selectedItem.isActive ?
                    <Popconfirm
                      title='&nbsp;Desativar grupo'
                      description={
                        <>
                          <div>Tem a certeza que pretende desativar este grupo?</div>
                          <div>Esta ação <b>é reversível</b>.</div>
                        </>
                      }
                      okText='Desativar'
                      okButtonProps={{ className: 'vantagens-warning-button' }}
                      okType='default'
                      cancelText='Cancelar'
                      onConfirm={this.deactivateGroup}
                    >
                      <Button className='vantagens-warning-button' disabled={editFetching} type='default'>Desativar</Button>
                    </Popconfirm>
                    :
                    <Popconfirm
                      title='&nbsp;Reativar grupo'
                      description={
                        <>
                          <div>Tem a certeza que pretende reativar este grupo?</div>
                          <div>Esta ação <b>é reversível</b>.</div>
                        </>
                      }
                      okText='Reativar'
                      okButtonProps={{ className: 'vantagens-warning-button' }}
                      okType='default'
                      cancelText='Cancelar'
                      onConfirm={this.reactivateGroup}
                    >
                      <Button className='vantagens-warning-button' disabled={editFetching} type='default'>Reativar</Button>
                    </Popconfirm>
                  }
                  <Button 
                    type={editError ? 'default' : 'primary'}
                    danger={editError ? true : false}
                    htmlType='submit'
                    disabled={editFetching}
                  >{editError ? 'Tentar novamente' : editFetching ? 'A carregar...' : 'Guardar'}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Drawer>
        }
      </div>
    );
  }
}

/**
 * Used in the Redux pattern to reflect any updates to the Redux store and merge them into props in the current
 * component. The Redux store serves as a centralized place for the state to live in the application.
 * 
 * @param {any} state - Centralized state of the application.
 * @returns {any} the state of the application as the component props.
 */
const mapPropsToState = (state) => {
  return {};
};

/**
 * Used in the Redux pattern to dispatch actions to the Redux store, triggering a state change.
 * 
 * @param {any} dispatch - function of the Redux store.
 * @returns {any} the dispatch functions as components props.
 */
const mapDispatchToState = (dispatch) => {
  return {};
};

export default withRouter(connect(mapPropsToState, mapDispatchToState)(ManageGroups));