import React from 'react';
import dayjs from 'dayjs';
import qs from 'qs';
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';
import { getGroups } from '../../reducers/Groups';
import { getAllLanguages } from '../../reducers/Languages';
import { formatBytes, withRouter } from '../../utils';
import { ClockLoader, PuffLoader } from 'react-spinners';
import {
  Alert, Button, Card, DatePicker, Drawer, Form, Input, Popconfirm, Result,
  Select, Space, Steps, Table, Tabs, Tag, Upload, message, notification,
} from 'antd';

import { styles } from '../../styles';
import 'react-quill/dist/quill.snow.css';

import no_photo from '../../images/no_photo.png';

const { Meta } = Card;
const { Option } = Select;
const { RangePicker } = DatePicker;

/**
 * ManageAdvantages component represents the page template that allows to manage the application campaigns.
 * It displays a table with all active campaigns and a table with all inactive campaigns.
 * It allows tools to create, edit, deactivate, reactivate and delete campaigns.
 */
class ManageCampaigns extends React.Component {
  /**
   * The constructor for a React component is called before it is mounted.
   * When implementing the constructor, `super(props)` must be called before any
   * other statement. Otherwise, this.props will be undefined in the constructor,
   * which can lead to bugs.
   *
   * A React constructor is only used for two purposes:
   * - Initializing local state by assigning an object to this.state.
   * - Binding event handler methods to an instance.
   *
   * Instead of calling `setState()` in the `constructor(), the initial state must be
   * directly assigned to `this.state` in the constructor
   *
   * @param {*} props - Arbitrary component inputs.
   */
  constructor() {
    super();
    this.state = {
      activeLanguage: process.env.REACT_APP_LANGUAGE_ID,
      addDescriptionValue: null,
      addError: null,
      addFetching: false,
      archiveFileLoading: false,
      campaigns: [],
      campaignsError: null,
      defaultLanguage: process.env.REACT_APP_LANGUAGE_ID,
      editDescriptionValue: null,
      editError: null,
      editFetching: false,
      fileSubmissionError: null,
      fileSubmissionLink: null,
      fileSubmissionLoading: false,
      fileSubmissionSuccess: false,
      loading: false,
      pageWidth: 0,
      protocols: [],
      protocolsFetching: false,
      protocolsError: null,
      selectedItem: null,
      showAddCampaignDrawer: false,
      showEditCampaignDrawer: false,
      showFileSubmissionModal: false,
      showUploadInputError: false,
      submitFileCurrentStep: 0,
      uploadedFile: null,
    };
  }

  /**
   * Allows to execute the React code when the component is already placed in the DOM (Document Object Model).
   * This method is called during the Mounting phase of the React Life-cycle i.e after the component is rendered.
   */
  componentDidMount() {
    // Get data
    this.props.getLanguages(window.localStorage.getItem('token'));
    this.fetchData();
    // Scroll to top
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Add event on resize
    this.handlePageWidth();
    window.addEventListener('resize', this.handlePageWidth);
  }

  /**
   * Allows React to call this function immediately after this component has been re-rendered with
   * updated `props` and/or `state`. This method is not called for the initial render.
   * 
   * This allows to manipulate the DOM after an update. It is also a common place to do network requests
   * as long as it compares the current `props` to previous `props` (e.g. a network request may not be necessary
   * if the `props` have not changed).
   * 
   * @param {any} prevProps - Props before the update. Compare prevProps to this.props to determine what changed.
   * @param {any} prevState - State before the update. Compare prevState to this.state to determine what changed.
   * @param {any} snapshot - Used when function getSnapshotBeforeUpdate is implemented. It will contain the value returned from that function. Otherwise, it will be undefined.
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.activeLanguage !== this.state.activeLanguage) this.fetchData(this.state.activeLanguage);
    if (prevProps.menuCollapsed !== this.props.menuCollapsed) setTimeout(() => this.handlePageWidth(), 500);
  }

  handlePageWidth = () => {
    const cmsLayoutPageWidth = document.getElementById('cms-layout-content');
    this.setState({ pageWidth: cmsLayoutPageWidth ? cmsLayoutPageWidth.clientWidth : window.innerWidth });
  };

  /**
   * Sets the array of campaigns to manage. 
   * 
   * @param {any[]} data - Original data of campaigns. 
   * @returns {any[]} the modified dataset of campaigns.
   */
  setCampaignsData = (data) => {
    return data.map((campaign) => {
      return {
        key: campaign.idCampaign,
        title: campaign.title,
        image: campaign.image,
        description: campaign.description,
        url: campaign.url,
        activationDate: campaign.activationDate ? dayjs(campaign.activationDate).subtract(1, 'h') : null,
        deactivationDate: campaign.deactivationDate ? dayjs(campaign.deactivationDate).subtract(1, 'h') : null,

        documents: campaign.documents,
        numberOfDocuments: campaign.documents.length,
        isActive: this.campaignIsActive(campaign),

        idProtocol: campaign.protocol.idProtocol,
        protocolTitle: campaign.protocol.title,
        protocolImage: campaign.protocol.image,
        protocol: campaign.protocol,

        idCategory: campaign.category.idCategory,
        categoryName: campaign.category.name,
        categoryColor: campaign.category.color,
        category: campaign.category,

        idLanguage: campaign.language.idLanguage,
        language: campaign.language,

        idGroup: campaign.group ? campaign.group.idGroup : null,
        groupName: campaign.group ? campaign.group.name : null,
        groupImage: campaign.group ? campaign.group.image : null,
        group: campaign.group,
      };
    });
  };

  /**
   * Checks if an campaign is active, analysing its activation and deactivation dates and comparing it to the
   * actual date.
   * 
   * @param {any} record - Campaigns object.
   * @returns {boolean} - true if campaigns is active and false otherwise.
   */
  campaignIsActive = (record) => {
    const activation = record.activationDate ? dayjs(record.activationDate).subtract(1, 'h') : undefined;
    const deactivation = record.deactivationDate ? dayjs(record.deactivationDate).subtract(1, 'h') : undefined;
    const now = dayjs();
    return activation && (activation.isBefore(now) || activation.isSame(now)) &&
      (!deactivation || (deactivation && deactivation.isAfter(now))) ? true : false;
  };

  /**
   * Fetches all campaigns from database through an available API. Calls `setCampaignsData` to modify the
   * data, regarding this component.
   */
  fetchData = () => {
    this.setLoading(true);
    const { selectedItem } = this.state;
    // Get parameters to fetch
    const fetchParams = {
      fields: 'protocol,category,language,group,documents',
      idLanguage: this.state.activeLanguage,
    };
    // Get options to fetch
    const fetchOptions = {
      method: 'GET',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      })
    };
    // Fetch data
    fetch(`${process.env.REACT_APP_API_BASE_URL}/campaigns?${qs.stringify(fetchParams)}`, fetchOptions)
      .then(async (response) => { 
        if (response.ok) return response.json();
        const result = await response.json();
        throw new Error(result ? JSON.stringify(result) : 'Não é possível obter todas as campanhas.');
      })
      .then(async (data) => {
        const campaigns = this.setCampaignsData(data);
        this.setState({ campaigns: campaigns, campaignsError: null });
        if (selectedItem) this.setState({ selectedItem: campaigns.find((option) => option.key === selectedItem.key) });
        this.setLoading(false);
      }).catch((error) => {
        this.setState({ campaigns: [], campaignsError: error.message, selectedItem: null });
        this.setLoading(false);
      });
  };

  /**
   * Sets loading state from `this.state.loading`.
   * This state is relationated with the data fetching from database.
   *
   * @param {boolean} loading - Sets the loading state to true or false.
   */
  setLoading = (loading) => {
    this.setState({ loading: loading });
  };

  /**
   * Fetch all protocols of the system (only active protocols).
   */
  fetchProtocols = () => {
    this.setState({ protocolsFetching: true });
    // Get parameters to fetch
    const fetchParams = { idLanguage: this.state.activeLanguage };
    // Get options to fetch
    const fetchOptions = {
      method: 'GET',
      headers: new Headers({ 'app_secret_key': process.env.REACT_APP_API_APP_KEY })
    };
    // Fetch data
    fetch(`${process.env.REACT_APP_API_BASE_URL}/protocols?${qs.stringify(fetchParams)}`, fetchOptions)
      .then(async (response) => {
        if (response.ok) return response.json();
        const result = await response.json();
        throw new Error(result && result.msg ? result.msg : 'It was impossible to load protocols');
      }).then(async (data) => {
        // await (new Promise((res) => {setTimeout(res, 5000); }));
        this.setState({ protocols: data, protocolsError: null, protocolsFetching: false });
      }).catch((error) => {
        this.setState({ protocols: [], protocolsError: error.message, protocolsFetching: false });
      });
  };

  /**
   * Handles the visibility state of the add campaign drawer, using the state `this.state.showAddCampaignDrawer`.
   */
  handleAddCampaignDrawer = () => {
    const { showAddCampaignDrawer } = this.state;
    this.setState({
      addDescriptionValue: null,
      showAddCampaignDrawer: !showAddCampaignDrawer,
      addError: null,
      addFetching: false,
    });
  };

  /**
   * Handles the image file upload on campaign creation.
   */
  handleAddFileList = (e) => {
    if (Array.isArray(e)) return e;
    if (e) return e.fileList;
    return undefined;
  };

  /**
   * Adds a new campaign to the system, using the API for the effect.
   *
   * @param {any} values - New campaign data.
   */
  addCampaign = (values) => {
    this.setState({ addFetching: true });
    const raw = {};
    raw.idProtocol = values.idProtocol;
    raw.title = values.title;
    raw.description = values.description;
    raw.activationDate = values.time[0].add(1, 'h').format('YYYY-MM-DD HH:mm:ss');
    raw.deactivationDate = values.time[1].add(1, 'h').format('YYYY-MM-DD HH:mm:ss');
    // Get options to fetch
    const fetchOptions = {
      method: 'POST',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      }),
      body: JSON.stringify(raw),
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/campaigns`, fetchOptions).then(async (response) => {
      if (response.ok) return response.json();
      const result = await response.json();
      throw new Error(result ? JSON.stringify(result) : 'Não é possível adicionar a nova campanha');
    }).then(async () => {
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check'></i>
            <span className='cms-notification-icon-text-span'>Campanha &ldquo;{values.title}&rdquo; criada com sucesso</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
            <span>A campanha foi adicionada com sucesso. Consulte a lista de campanhas para verificar a mesma.</span>
          </div>
        ),
      });
      setTimeout(() => {
        this.setState({ addError: null, addFetching: false });
        this.handleAddCampaignDrawer();
        this.fetchData();
      }, 1500);
    }).catch((error) => {
      const message = 'Não é possível adicionar uma nova campanha';
      const extendedMessage = 'Por favor, reveja os dados inseridos e/ou tente novamente.';
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'></i>
            <span className='cms-notification-icon-text-span'>{message}</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
            <span>{extendedMessage}</span>
          </div>
        ),
      });
      this.setState({ addError: message, addFetching: false });
    });
  };

  /**
   * Handles the visibility state of the edit campaign drawer, using the state `this.state.showEditCampaignDrawer`.
   */
  handleEditCampaignDrawer = () => {
    const { showEditCampaignDrawer } = this.state;
    this.setState({ 
      editDescriptionValue: null,
      showEditCampaignDrawer: !showEditCampaignDrawer,
      editError: null,
      editFetching: false,
    });
  };

  /**
   * Edits an existing campaigns in the system, using the API for the effect.
   *
   * @param {any} values - New data of the existing campaign.
   */
  editCampaign = (values) => {
    this.setState({ editFetching: true });
    const { selectedItem } = this.state;
    const raw = JSON.stringify({
      idProtocol: values.idProtocol,
      title: values.title,
      description: values.description,
      activationDate: values.time[0].add(1, 'h').format('YYYY-MM-DD HH:mm:ss'),
      deactivationDate: values.time[1].add(1, 'h').format('YYYY-MM-DD HH:mm:ss'),
    });
    // Get options to fetch
    const fetchOptions = {
      body: raw,
      method: 'PATCH',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      }),
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/campaigns/${selectedItem.key}`, fetchOptions).then(async (response) => {
      if (response.ok) return response.json();
      const result = await response.json();
      throw new Error(result ? JSON.stringify(result) : `Não é possível editar a campanha '${selectedItem.title}'`);
    }).then(async (data) => {
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check'></i>
            <span className='cms-notification-icon-text-span'>Alterações guardadas com sucesso</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
            <span>A campanha <u>{values.title}</u> foi atualizada com sucesso. Consulte a lista de campanhas para verificar a alteração.</span>
          </div>
        ),
      });
      setTimeout(() => {
        this.setState({ editError: null, editFetching: false });
        this.handleEditCampaignDrawer();
        this.fetchData();
      }, 1500);
    }).catch((error) => {
      const message = `Não é possível editar a campanha '${selectedItem.title}'`;
      const extendedMessage = 'Por favor, reveja os dados inseridos e/ou tente novamente.';
      // if (error && error.message) {
      //   if (error.message.includes('Your token is not valid!'))  {
      //     extendedMessage = 'Não tem autorização para adicionar campanhas. Por favor, volte a entrar na sua conta e, se o erro persistir, contacte o administrador da aplicação.';
      //   }
      //   if (error.message.includes('You have no permission to access this resource.'))  {
      //     extendedMessage = 'Não tem autorização para adicionar campanhas. Por favor, contacte o administrador da aplicação.';
      //   }
      //   if (error.message.includes('Invalid category identification.'))  {
      //     message = 'Categoria selecionada inválida';
      //     extendedMessage = 'Por favor, selecione uma categoria válida.';
      //   }
      //   if (error.message.includes('Category not found.'))  {
      //     message = 'Categoria selecionada inválida';
      //     extendedMessage = 'A categoria selecionada não foi encontrada no sistema. Por favor, selecione uma categoria válida.';
      //   }
      //   if (error.message.includes('Category is deactivated.'))  {
      //     message = 'Categoria selecionada inválida';
      //     extendedMessage = 'A categoria selecionada foi desativada. Por favor, selecione uma categoria válida.';
      //   }
      //   if (error.message.includes('Invalid group identification.'))  {
      //     message = 'Grupo selecionado inválido';
      //     extendedMessage = 'Por favor, selecione um grupo válido. Este campo é opcional, por isso poderá deixá-lo vazio.';
      //   }
      //   if (error.message.includes('Group not found.'))  {
      //     message = 'Grupo selecionado inválido';
      //     extendedMessage = 'O grupo selecionado não foi encontrado no sistema. Por favor, selecione um grupo válido. Este campo é opcional, por isso poderá deixá-lo vazio.';
      //   }
      //   if (error.message.includes('Group is deactivated.'))  {
      //     message = 'Grupo selecionado inválido';
      //     extendedMessage = 'O grupo selecionado foi desativado. Por favor, selecione um grupo válido. Este campo é opcional, por isso poderá deixá-lo vazio.';
      //   }
      //   if (error.message.includes('Invalid title. Must have more than 3 characters.'))  {
      //     message = 'Título inválido';
      //     extendedMessage = 'O título da campanha é inválido. Por favor, insira um valor válido.';
      //   }
      //   if (error.message.includes('Invalid location.'))  {
      //     message = 'Localização inválida';
      //     extendedMessage = 'A localização da campanha é inválida. Por favor, insira um valor válido.';
      //   }
      //   if (error.message.includes('Invalid url.'))  {
      //     message = 'URL inválido';
      //     extendedMessage = 'O URL da campanha é inválido. Por favor, insira um valor válido.';
      //   }
      //   if (error.message.includes('Invalid target audience.'))  {
      //     message = 'Público-alvo inválido';
      //     extendedMessage = 'O público-alvo da campanha é inválido. Por favor, insira um valor válido.';
      //   }
      //   if (error.message.includes('Resource not found.'))  {
      //     message = 'Vantagem não encontrada';
      //     extendedMessage = 'O campanha selecionada não foi encontrada no sistema. Por favor, tente novamente mais tarde.';
      //   }
      // }
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'></i>
            <span className='cms-notification-icon-text-span'>{message}</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
            <span>{extendedMessage}</span>
          </div>
        ),
      });
      this.setState({ editError: message, editFetching: false });
    });
  };

  /**
   * Deactivates an existing campaign in the system, using the API for the effect.
   */
  deactivateCampaign = () => {
    this.setState({ editFetching: true });
    const { selectedItem } = this.state;
    const raw = JSON.stringify({ deactivationDate: (new dayjs()).format('YYYY-MM-DD HH:mm:ss') });
    // Get options to fetch
    const fetchOptions = {
      body: raw,
      method: 'PATCH',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      }),
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/campaigns/${selectedItem.key}`, fetchOptions).then(async (response) => {
      if (response.ok) return response.json();
      const result = await response.json();
      throw new Error(result ? JSON.stringify(result) : `Não é possível desativar a campanha '${selectedItem.title}'`);
    }).then(async (data) => {
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check'></i>
            <span className='cms-notification-icon-text-span'>Campanha desativada com sucesso</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
            <span>A campanha <u>{selectedItem.title}</u> foi desativada com sucesso. Consulte a lista de campanhas para verificar a alteração.</span>
          </div>
        ),
      });
      setTimeout(() => {
        this.setState({ editError: null, editFetching: false });
        this.handleEditCampaignDrawer();
        this.fetchData();
      }, 1500);
    }).catch(() => {
      const message = `Não é possível desativar a campanha '${selectedItem.title}'`;
      const extendedMessage = 'Por favor, reveja os dados inseridos e/ou tente novamente.';
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'></i>
            <span className='cms-notification-icon-text-span'>{message}</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
            <span>{extendedMessage}</span>
          </div>
        ),
      });
      this.setState({ editError: message, editFetching: false });
    });
  };

  /**
   * Handles the visibility state of the file submission modal, using the state `this.state.showFileSubmissionModal`.
   */
  handleFileSubmissionModal = () => {
    const { showFileSubmissionModal } = this.state;
    this.setState({ 
      showFileSubmissionModal: !showFileSubmissionModal,
      showUploadInputError: false,
      submitFileCurrentStep: 0,
      uploadedFile: null,
      fileSubmissionLoading: false,
      fileSubmissionError: null,
      fileSubmissionSuccess: false,
      fileSubmissionLink: null,
    });
  };
  
  /**
   * Handles the file submission modal current step, when user goes back.
   */
  handleFileSubmissionBackStep = () => {
    const { submitFileCurrentStep } = this.state;
    this.setState({ submitFileCurrentStep: submitFileCurrentStep - 1 });
  };

  /**
   * Uploads a file to the system, matching it to an campaign.
   */
  submitFile = () => {
    const { submitFileCurrentStep, uploadedFile, selectedItem } = this.state;
    if (!selectedItem) this.handleFileSubmissionModal();
    if (!uploadedFile) this.setState({ showUploadInputError: true, submitFileCurrentStep: submitFileCurrentStep - 1 });
    this.setState({ fileSubmissionLoading: true });
    const formdata = new FormData();
    formdata.append('idDocumentContext', '6F71538E-1F26-EE11-A314-00155D08E85F');
    formdata.append('idInstance', selectedItem.key);
    formdata.append('file', uploadedFile);
    // Get options to fetch
    const fetchOptions = {
      method: 'POST',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      }),
      body: formdata,
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/documents`, fetchOptions).then(async (response) => {
      // await (new Promise((res) => {setTimeout(res, 5000); }));
      if (response.ok) return response.json();
      const result = await response.json();
      throw new Error(result ? JSON.stringify(result) : 'Não é possível carregar o ficheiro no servidor');
    }).then(async (data) => {
      const document = data.document;
      this.setState({ 
        fileSubmissionLoading: false, 
        fileSubmissionError: null,
        fileSubmissionSuccess: true,
        fileSubmissionLink: `${process.env.REACT_APP_API_IMAGES_BASE_URL}${document.path}`,
        submitFileCurrentStep: submitFileCurrentStep + 1
      });
    }).catch((error) => {
      let message = 'Não é possível carregar o documento.';
      this.setState({ 
        fileSubmissionError: message,
        fileSubmissionLoading: false,
        fileSubmissionSuccess: false,
        fileSubmissionLink: null,
        submitFileCurrentStep: submitFileCurrentStep + 1,
      });
    });
  };

  /**
   * Archives a file in the system.
   * 
   * @param {any} document - Document to archive.
   */
  archiveFile = (document) => {
    if (document && document.idDocument) {
      this.setState({ archiveFileLoading: true });
      // Get options to fetch
      const fetchOptions = {
        method: 'PATCH',
        headers: new Headers({
          'app_secret_key': process.env.REACT_APP_API_APP_KEY,
          'Authorization': `Bearer ${window.localStorage.getItem('token')}`
        }),
      };
      // Fetch request
      fetch(`${process.env.REACT_APP_API_BASE_URL}/documents/${document.idDocument}`, fetchOptions)
        .then(async (response) => {
          // await (new Promise((res) => {setTimeout(res, 5000); }));
          if (response.ok) return response.json();
          const result = await response.json();
          throw new Error(result ? JSON.stringify(result) : 'Não é possível arquivar o ficheiro');
        }).then(async (data) => {
          this.setState({ archiveFileLoading: false });
          this.fetchData();
          notification.open({
            duration: 30,
            message: (
              <div className='cms-notification-icon-text'>
                <i className='fa-solid fa-circle-check'></i>
                <span className='cms-notification-icon-text-span'>Documento &ldquo;{document.name}&rdquo; arquivado com sucesso</span>
              </div>
            ),
            description: (
              <div className='cms-notification-icon-text'>
                <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
                <span>O documento foi arquivado com sucesso. Esta ação não é reversível.</span>
              </div>
            ),
          });
        }).catch((error) => {
          this.setState({ archiveFileLoading: false });
          let message = 'Não é possível arquivar o documento.';
          let extendedMessage = 'Não foi possível arquivar o documento. Por favor, tente mais tarde ou contacte o administrador da aplicação.';
          notification.open({
            duration: 30,
            message: (
              <div className='cms-notification-icon-text'>
                <i className='fa-solid fa-circle-xmark'></i>
                <span className='cms-notification-icon-text-span'>{message}</span>
              </div>
            ),
            description: (
              <div className='cms-notification-icon-text'>
                <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
                <span>{extendedMessage}</span>
              </div>
            ),
          });
        });
    }
  };

  /**
   * Render method of React component.
   * 
   * @returns the component template.
   */
  render() {
    const { languages, languagesError, languagesFetching } = this.props;
    const {
      activeLanguage, addDescriptionValue, addError, addFetching, campaigns, campaignsError, 
      archiveFileLoading, protocols, protocolsError, protocolsFetching, defaultLanguage, 
      editDescriptionValue, editError, editFetching, fileSubmissionError, fileSubmissionLink,
      fileSubmissionLoading, fileSubmissionSuccess, loading, pageWidth, selectedItem, 
      showAddCampaignDrawer, showEditCampaignDrawer, showFileSubmissionModal, showUploadInputError, 
      submitFileCurrentStep, uploadedFile
    } = this.state;

    const isActive = localStorage.getItem('isActive');
    const idRole = localStorage.getItem('idRole');
    const role = localStorage.getItem('role');
    const isAdmin = isActive === 'true' && idRole === '376DDE5B-09C4-ED11-A311-00155D08E85F' && role === 'Administrator';
    const isManager = isActive === 'true' && idRole === '386DDE5B-09C4-ED11-A311-00155D08E85F' && role === 'Manager';

    if (isActive !== 'true' || !(isAdmin || isManager) || campaignsError || languagesError || (!languagesFetching && languages.length < 1)) {
      return (
        <div className='cms-menu-actions-container'>
          {/* <h1 id='cms-menu-actions-title'>Gestão de Campanhas</h1> */}
          <div id='cms-menu-actions-tabs'>
            <div id='cms-menu-actions-error'>
              <i className='fa-duotone fa-arrow-rotate-right'></i>
              <b>Ocorreu um erro...</b>
              <Button type='text' onClick={() => window.location.reload(true)}>
                Clique&nbsp;<span className='cms-menu-actions-error-btn'>aqui</span>&nbsp;para refrescar a página.
              </Button>
              Ou tente mais tarde.
            </div>
          </div>
        </div>
      );
    }

    if (loading || languagesFetching) {
      return (
        <div className='cms-menu-actions-container'>
          {/* <div id='cms-menu-actions-tabs'> */}
          <div id='cms-menu-actions-error'>
            <PuffLoader color={styles.COLORS.PrimaryColor} size={100} />
            <p>A carregar...</p>
          </div>
          {/* </div> */}
        </div>
      );
    }

    const distinctCategories = [...new Set(campaigns.map(campaign => campaign.categoryName))];

    const columns = [
      {
        dataIndex: 'title',
        ellipsis: true,
        key: 'title',
        render: (_, record) => {
          return (
            <>
              <Button
                onClick={() => {
                  const newSelectedItem = !selectedItem || (selectedItem && selectedItem.key !== record.key) ? record : null;
                  this.setState({ selectedItem: newSelectedItem });
                }}
                size='small'
                title={record.title}
                type='text'
              >{record.title}</Button>
              {!record.isActive && <span> </span>}
              {!record.isActive && <Tag color={styles.COLORS.QuaternaryTextColor}>Desativada</Tag>}
            </>
          );
        },
        sorter: (a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
        title: 'Nome',
        width: pageWidth > 1400 ? 'auto' : '350px',
      },
      {
        dataIndex: 'protocolTitle',
        ellipsis: true,
        key: 'protocolTitle',
        render: (_, record) => {
          const style = record.isActive ? undefined : { color : styles.COLORS.QuaternaryTextColor};
          return (
            <span style={style}>{record.protocolTitle}</span>
            // <>
            //   <Button
            //     onClick={() => {
            //       const newSelectedItem = !selectedItem || (selectedItem && selectedItem.key !== record.key) ? record : null;
            //       this.setState({ selectedItem: newSelectedItem });
            //     }}
            //     size='small'
            //     style={style}
            //     title={record.protocolTitle}
            //     type='text'
            //   >{record.protocolTitle}</Button>
            // </>
          );
        },
        sorter: (a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
        title: 'Vantagem',
        width: pageWidth > 1400 ? 'auto' : '350px',
      },
      {
        dataIndex: 'categoryName',
        filterIcon: <i className='fa-duotone fa-filters'></i>,
        filters: distinctCategories.map((category) => { return { text: category, value: category }; }),
        key: 'categoryName',
        onFilter: (value, record) => record.categoryName.indexOf(value) === 0,
        render: (_, record) => {
          const style = { color: styles.COLORS.getTextColor(record.categoryColor) };
          return <Tag color={record.categoryColor} style={style}>{record.categoryName}</Tag>;
        },
        sorter: (a, b) => a.categoryName.toLowerCase().localeCompare(b.categoryName.toLowerCase()),
        title: 'Categoria',
        width: '160px',
      },
      {
        align: 'center',
        dataIndex: 'activationDate',
        key: 'activationDate',
        render: (value, record) => {
          const style = {};
          if (!record.isActive) style.color = styles.COLORS.QuaternaryTextColor;
          return <span style={style}>{value ? value.format('DD/MM/YYYY HH:mm') : '-'}</span>;
        },
        sorter: (a, b) => {
          const dateA = a.activationDate;
          const dateB = b.activationDate;
          return dateA ? (dateB ? (dateA.isAfter(dateB) ? 1 : dateA.isSame(dateB) ? 0 : -1) : 1) : -1;
        },
        title: 'Data de início',
        width: '155px',
      },
      {
        align: 'center',
        dataIndex: 'deactivationDate',
        key: 'deactivationDate',
        render: (value, record) => {
          const style = {};
          if (!record.isActive) style.color = styles.COLORS.QuaternaryTextColor;
          return <span style={style}>{value ? value.format('DD/MM/YYYY HH:mm') : '-'}</span>;
        },
        sorter: (a, b) => {
          const dateA = a.deactivationDate;
          const dateB = b.deactivationDate;
          return dateA ? (dateB ? (dateA.isAfter(dateB) ? 1 : dateA.isSame(dateB) ? 0 : -1) : 1) : -1;
        },
        title: 'Data de fim',
        width: '155px',
      },
    ];

    return (
      <div className='cms-menu-actions-container'>
        {/* Actions */}
        <div className='cms-menu-actions-btns'>
          <Button
            type='primary'
            icon={<i className='fa-solid fa-plus'></i>}
            onClick={this.handleAddCampaignDrawer}
          >Nova Campanha</Button>
          <Button
            type='default'
            disabled={selectedItem ? false : true}
            icon={<i className='fa-duotone fa-pen-to-square'></i>}
            onClick={this.handleEditCampaignDrawer}
          >Editar</Button>
          <Button
            type='default'
            disabled={selectedItem && selectedItem.isActive ? false : true}
            icon={<i className='fa-duotone fa-folder-arrow-up'></i>}
            onClick={this.handleFileSubmissionModal}
          >Carregar ficheiro</Button>
        </div>

        {/* Table */}
        <div id='cms-menu-actions-tabs'>
          <Tabs
            activeKey={activeLanguage}
            defaultActiveKey={defaultLanguage}
            onChange={(key) => this.setState({ activeLanguage: key })}
            items={languages.map((language) => {
              return {
                languageData: language,
                key: language.idLanguage,
                label: language.name,
                children: (
                  <div className='cms-menu-actions-tabs-item'>
                    <Table
                      bordered
                      columns={columns}
                      dataSource={campaigns}
                      loading={loading}
                      pagination={{ size: 'default' }}
                      rowKey={(record) => record.key}
                      rowSelection={{
                        onChange: (selectedRowKeys, selectedRows) => {
                          this.setState({ selectedItem: selectedRows.length > 0 ? selectedRows[selectedRows.length - 1] : null });
                        },
                        selectedRowKeys: selectedItem ? [selectedItem.key] : [],
                        type: 'checkbox',
                        hideSelectAll: true,
                      }}
                      scroll={{ x: 240 }}
                      size='small'
                    />
                  </div>
                )
              };
            })}
          />
        </div>

        {/* Add new campaign */}
        <Drawer
          afterOpenChange={() => { this.fetchProtocols(); }}
          className='cms-menu-add-drawer'
          destroyOnClose
          extra={
            <div className='cms-menu-add-drawer-header'>
              {/* Title */}
              <div>Adicionar uma nova campanha</div>
              {/* Close btn */}
              <Button 
                id='hamburger-menu-close'
                icon={<i className='fa-light fa-xmark'></i>}
                onClick={this.handleAddCampaignDrawer}
                type='text'
              />
            </div>
          }
          open={showAddCampaignDrawer}
          onClose={this.handleAddCampaignDrawer}
          placement='right'
          size='large'
          title='Adicionar uma nova campanha'
          width={window.innerWidth < 736 ? window.innerWidth : 736}
        >
          <div></div>

          {/* Form */}
          <Form
            autoComplete='on'
            initialValues={{ remember: true }}
            layout='vertical'
            name='Adicionar campanha'
            onFinish={this.addCampaign}
          >
            <div>
              {/* Language */}
              <Form.Item
                hasFeedback
                label='Idioma:'
                name='idLanguage'
                rules={[{ required: true, message: 'Por favor, selecione um idioma.' }]}
                initialValue={activeLanguage}
              >
                <Select
                  placeholder='Selecione um idioma'
                  allowClear={false}
                  disabled={true}
                >
                  {languages.map((language) => {
                    return (
                      <Option key={language.idLanguage} value={language.idLanguage}>{language.name} ({language.code})</Option>
                    );
                  })}
                </Select>
              </Form.Item>

              {/* Protocol */}
              <Form.Item
                hasFeedback
                label='Vantagem associada:'
                name='idProtocol'
                rules={[{ required: true, message: 'Por favor, selecione uma vantagem.' }]}
              >
                <Select
                  allowClear={false}
                  disabled={protocolsError}
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                  loading={protocolsFetching}
                  placeholder='Selecione uma vantagem'
                  showSearch
                >
                  {protocols.map((protocol) => {
                    return (
                      <Option key={protocol.idProtocol} value={protocol.idProtocol}>
                        {protocol.title}  
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              {/* Title */}
              <Form.Item
                hasFeedback
                label='Título:'
                name='title'
                rules={[
                  { required: true, message: 'Por favor, introduza um título.' },
                  { max: 200, message: 'Por favor, introduza um título com um máximo de 200 caracteres.' },
                  { min: 3, message: 'Por favor, introduza um título com mais de 3 caracteres.' }
                ]}
              >
                <Input
                  allowClear
                  maxLength={200}
                  minLength={3}
                  placeholder='Introduzir o título da campanha'
                  type='text'
                />
              </Form.Item>

              {/* Activation / deactivation date */}
              <Form.Item
                hasFeedback
                label='Período em vigor:'
                name='time'
                rules={[{ type: 'array', required: true, message: 'Por favor, especifique um período de tempo' }]} 
                required
              >
                <RangePicker
                  allowClear
                  allowEmpty={[false, false]}
                  format="DD-MM-YYYY HH:mm:ss"
                  showTime
                />
              </Form.Item>

              {/* Description */}
              <Form.Item
                label='Descrição:'
                name='description'
              >
                <ReactQuill
                  onChange={(value) => this.setState({ addDescriptionValue: value })}
                  placeholder='Introduzir a descrição da campanha...'
                  theme='snow'
                  value={addDescriptionValue}
                  modules={{
                    toolbar: [
                      [{ 'header': [1, 2, 3, 4, 5, 6, false] },
                        'bold', 'italic', 'underline', 'strike',
                        {
                          'color': [
                            styles.COLORS.TextColor,
                            styles.COLORS.SecondaryTextColor,
                            styles.COLORS.TertiaryTextColor,
                            styles.COLORS.QuaternaryTextColor,
                            styles.COLORS.FillColor,
                            styles.COLORS.SecondaryFillColor,
                            styles.COLORS.BgContainerColor,
                            styles.COLORS.PrimaryActiveColor,
                            styles.COLORS.PrimaryColor,
                            styles.COLORS.PrimaryHoverColor,
                            styles.COLORS.PrimaryBorderHoverColor,
                            styles.COLORS.PrimaryBorderColor,
                            styles.COLORS.PrimaryBgHoverColor,
                            styles.COLORS.PrimaryBgColor,
                            styles.COLORS.SecondaryActiveColor,
                            styles.COLORS.SecondaryColor,
                            styles.COLORS.SecondaryHoverColor,
                            styles.COLORS.SecondaryBorderHoverColor,
                            styles.COLORS.SecondaryBgHoverColor,
                            styles.COLORS.SecondaryBgColor,
                            styles.COLORS.SecondaryBorderColor,
                            styles.COLORS.SuccessActiveColor,
                            styles.COLORS.SuccessColor,
                            styles.COLORS.SuccessTextHoverColor,
                            styles.COLORS.SuccessBorderHoverColor,
                            styles.COLORS.SuccessBorderColor,
                            styles.COLORS.SuccessBgHoverColor,
                            styles.COLORS.SuccessBgColor,
                            styles.COLORS.WarningActiveColor,
                            styles.COLORS.WarningColor,
                            styles.COLORS.WarningTextHoverColor,
                            styles.COLORS.WarningBorderHoverColor,
                            styles.COLORS.WarningBorderColor,
                            styles.COLORS.WarningBgHoverColor,
                            styles.COLORS.WarningBgColor,
                            styles.COLORS.ErrorActiveColor,
                            styles.COLORS.ErrorColor,
                            styles.COLORS.ErrorTextHoverColor,
                            styles.COLORS.ErrorBorderHoverColor,
                            styles.COLORS.ErrorBorderColor,
                            styles.COLORS.ErrorBgHoverColor,
                            styles.COLORS.ErrorBgColor,
                          ]
                        },
                        {
                          'background': [
                            styles.COLORS.TextColor,
                            styles.COLORS.SecondaryTextColor,
                            styles.COLORS.TertiaryTextColor,
                            styles.COLORS.QuaternaryTextColor,
                            styles.COLORS.FillColor,
                            styles.COLORS.SecondaryFillColor,
                            styles.COLORS.BgContainerColor,
                            styles.COLORS.PrimaryActiveColor,
                            styles.COLORS.PrimaryColor,
                            styles.COLORS.PrimaryHoverColor,
                            styles.COLORS.PrimaryBorderHoverColor,
                            styles.COLORS.PrimaryBorderColor,
                            styles.COLORS.PrimaryBgHoverColor,
                            styles.COLORS.PrimaryBgColor,
                            styles.COLORS.SecondaryActiveColor,
                            styles.COLORS.SecondaryColor,
                            styles.COLORS.SecondaryHoverColor,
                            styles.COLORS.SecondaryBorderHoverColor,
                            styles.COLORS.SecondaryBgHoverColor,
                            styles.COLORS.SecondaryBgColor,
                            styles.COLORS.SecondaryBorderColor,
                            styles.COLORS.SuccessActiveColor,
                            styles.COLORS.SuccessColor,
                            styles.COLORS.SuccessTextHoverColor,
                            styles.COLORS.SuccessBorderHoverColor,
                            styles.COLORS.SuccessBorderColor,
                            styles.COLORS.SuccessBgHoverColor,
                            styles.COLORS.SuccessBgColor,
                            styles.COLORS.WarningActiveColor,
                            styles.COLORS.WarningColor,
                            styles.COLORS.WarningTextHoverColor,
                            styles.COLORS.WarningBorderHoverColor,
                            styles.COLORS.WarningBorderColor,
                            styles.COLORS.WarningBgHoverColor,
                            styles.COLORS.WarningBgColor,
                            styles.COLORS.ErrorActiveColor,
                            styles.COLORS.ErrorColor,
                            styles.COLORS.ErrorTextHoverColor,
                            styles.COLORS.ErrorBorderHoverColor,
                            styles.COLORS.ErrorBorderColor,
                            styles.COLORS.ErrorBgHoverColor,
                            styles.COLORS.ErrorBgColor,
                          ]
                        },
                        { 'script': 'sub' },
                        { 'script': 'super' },
                        { 'align': [] },
                        { 'indent': '-1' },
                        { 'indent': '+1' },
                        { 'list': 'ordered' },
                        { 'list': 'bullet' },
                        'link', 'image', 'video', 'blockquote', 'code', 'code-block', 'formula', 'clean'
                      ],
                    ]
                  }}
                />
              </Form.Item>
            </div>
            <br></br>
            {/* Buttons */}
            <Form.Item style={{ width: '100%' }}>
              <div className='cms-add-modal-btns'>
                <Button type='default' onClick={this.handleAddCampaignDrawer}>Cancelar</Button>
                {/* {addCurrentStep > 0 && <Button type='default' onClick={this.handleAddBackStep}>Voltar</Button>} */}
                {/* {addCurrentStep === 2 ? */}
                <Button
                  type={addError ? 'default' : 'primary'}
                  danger={addError ? true : false}
                  htmlType='submit'
                  disabled={addFetching}>{addError ? 'Tentar novamente' : addFetching ? 'A carregar...' : 'Criar'}
                </Button>
                {/* :
                  <Button type='primary' htmlType='submit'>Seguinte</Button>
                } */}
              </div>
            </Form.Item>
          </Form>
        </Drawer>

        {/* Edit campaign */}
        {selectedItem &&
          <Drawer
            afterOpenChange={() => { this.fetchProtocols(); }}
            className='cms-menu-add-drawer'
            destroyOnClose
            extra={
              <div className='cms-menu-add-drawer-header'>
                {/* Title */}
                <div>Editar campanha</div>
                {/* Close btn */}
                <Button 
                  id='hamburger-menu-close'
                  icon={<i className='fa-light fa-xmark'></i>}
                  onClick={this.handleEditCampaignDrawer}
                  type='text'
                />
              </div>
            }
            open={showEditCampaignDrawer}
            onClose={this.handleEditCampaignDrawer}
            placement='right'
            size='large'
            title='Editar campanha'
            width={window.innerWidth < 736 ? window.innerWidth : 736}
          >
            <div></div>

            {/* Form */}
            <Form
              autoComplete='on'
              initialValues={{ remember: true }}
              layout='vertical'
              name='Editar campanha'
              onFinish={this.editCampaign}
            >
              <div>
                {/* Language */}
                <Form.Item
                  hasFeedback
                  label='Idioma:'
                  name='idLanguage'
                  rules={[{ required: true, message: 'Por favor, selecione um idioma.' }]}
                  initialValue={activeLanguage}
                >
                  <Select
                    placeholder='Selecione um idioma'
                    allowClear={false}
                    disabled={true}
                  >
                    {languages.map((language) => {
                      return (
                        <Option key={language.idLanguage} value={language.idLanguage}>{language.name} ({language.code})</Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                {/* Protocol */}
                <Form.Item
                  hasFeedback
                  initialValue={selectedItem.idProtocol}
                  label='Vantagem associada:'
                  name='idProtocol'
                  rules={[{ required: true, message: 'Por favor, selecione uma vantagem.' }]}
                >
                  <Select
                    allowClear={false}
                    disabled={true}
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    loading={protocolsFetching}
                    placeholder='Selecione uma vantagem'
                    showSearch
                  >
                    {protocols.map((protocol) => {
                      return (
                        <Option key={protocol.idProtocol} value={protocol.idProtocol}>
                          {protocol.title}  
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                {/* Title */}
                <Form.Item
                  hasFeedback
                  initialValue={selectedItem.title}
                  label='Título:'
                  name='title'
                  rules={[
                    { required: true, message: 'Por favor, introduza um título.' },
                    { max: 200, message: 'Por favor, introduza um título com um máximo de 200 caracteres.' },
                    { min: 3, message: 'Por favor, introduza um título com mais de 3 caracteres.' }
                  ]}
                >
                  <Input
                    allowClear
                    maxLength={200}
                    minLength={3}
                    placeholder='Introduzir o título da campanha'
                    type='text'
                  />
                </Form.Item>

                {/* Activation / deactivation date */}
                <Form.Item
                  hasFeedback
                  initialValue={[selectedItem.activationDate, selectedItem.deactivationDate]}
                  label='Período em vigor:'
                  name='time'
                  rules={[{ type: 'array', required: true, message: 'Por favor, especifique um período de tempo' }]} 
                  required
                >
                  <RangePicker
                    allowClear
                    allowEmpty={[false, false]}
                    format="DD-MM-YYYY HH:mm:ss"
                    showTime
                  />
                </Form.Item>

                {/* Description */}
                <Form.Item
                  initialValue={selectedItem.description}
                  label='Descrição:'
                  name='description'
                >
                  <ReactQuill
                    onChange={(value) => this.setState({ editDescriptionValue: value })}
                    placeholder='Introduzir a descrição da campanha...'
                    theme='snow'
                    value={editDescriptionValue}
                    modules={{
                      toolbar: [
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] },
                          'bold', 'italic', 'underline', 'strike',
                          {
                            'color': [
                              styles.COLORS.TextColor,
                              styles.COLORS.SecondaryTextColor,
                              styles.COLORS.TertiaryTextColor,
                              styles.COLORS.QuaternaryTextColor,
                              styles.COLORS.FillColor,
                              styles.COLORS.SecondaryFillColor,
                              styles.COLORS.BgContainerColor,
                              styles.COLORS.PrimaryActiveColor,
                              styles.COLORS.PrimaryColor,
                              styles.COLORS.PrimaryHoverColor,
                              styles.COLORS.PrimaryBorderHoverColor,
                              styles.COLORS.PrimaryBorderColor,
                              styles.COLORS.PrimaryBgHoverColor,
                              styles.COLORS.PrimaryBgColor,
                              styles.COLORS.SecondaryActiveColor,
                              styles.COLORS.SecondaryColor,
                              styles.COLORS.SecondaryHoverColor,
                              styles.COLORS.SecondaryBorderHoverColor,
                              styles.COLORS.SecondaryBgHoverColor,
                              styles.COLORS.SecondaryBgColor,
                              styles.COLORS.SecondaryBorderColor,
                              styles.COLORS.SuccessActiveColor,
                              styles.COLORS.SuccessColor,
                              styles.COLORS.SuccessTextHoverColor,
                              styles.COLORS.SuccessBorderHoverColor,
                              styles.COLORS.SuccessBorderColor,
                              styles.COLORS.SuccessBgHoverColor,
                              styles.COLORS.SuccessBgColor,
                              styles.COLORS.WarningActiveColor,
                              styles.COLORS.WarningColor,
                              styles.COLORS.WarningTextHoverColor,
                              styles.COLORS.WarningBorderHoverColor,
                              styles.COLORS.WarningBorderColor,
                              styles.COLORS.WarningBgHoverColor,
                              styles.COLORS.WarningBgColor,
                              styles.COLORS.ErrorActiveColor,
                              styles.COLORS.ErrorColor,
                              styles.COLORS.ErrorTextHoverColor,
                              styles.COLORS.ErrorBorderHoverColor,
                              styles.COLORS.ErrorBorderColor,
                              styles.COLORS.ErrorBgHoverColor,
                              styles.COLORS.ErrorBgColor,
                            ]
                          },
                          {
                            'background': [
                              styles.COLORS.TextColor,
                              styles.COLORS.SecondaryTextColor,
                              styles.COLORS.TertiaryTextColor,
                              styles.COLORS.QuaternaryTextColor,
                              styles.COLORS.FillColor,
                              styles.COLORS.SecondaryFillColor,
                              styles.COLORS.BgContainerColor,
                              styles.COLORS.PrimaryActiveColor,
                              styles.COLORS.PrimaryColor,
                              styles.COLORS.PrimaryHoverColor,
                              styles.COLORS.PrimaryBorderHoverColor,
                              styles.COLORS.PrimaryBorderColor,
                              styles.COLORS.PrimaryBgHoverColor,
                              styles.COLORS.PrimaryBgColor,
                              styles.COLORS.SecondaryActiveColor,
                              styles.COLORS.SecondaryColor,
                              styles.COLORS.SecondaryHoverColor,
                              styles.COLORS.SecondaryBorderHoverColor,
                              styles.COLORS.SecondaryBgHoverColor,
                              styles.COLORS.SecondaryBgColor,
                              styles.COLORS.SecondaryBorderColor,
                              styles.COLORS.SuccessActiveColor,
                              styles.COLORS.SuccessColor,
                              styles.COLORS.SuccessTextHoverColor,
                              styles.COLORS.SuccessBorderHoverColor,
                              styles.COLORS.SuccessBorderColor,
                              styles.COLORS.SuccessBgHoverColor,
                              styles.COLORS.SuccessBgColor,
                              styles.COLORS.WarningActiveColor,
                              styles.COLORS.WarningColor,
                              styles.COLORS.WarningTextHoverColor,
                              styles.COLORS.WarningBorderHoverColor,
                              styles.COLORS.WarningBorderColor,
                              styles.COLORS.WarningBgHoverColor,
                              styles.COLORS.WarningBgColor,
                              styles.COLORS.ErrorActiveColor,
                              styles.COLORS.ErrorColor,
                              styles.COLORS.ErrorTextHoverColor,
                              styles.COLORS.ErrorBorderHoverColor,
                              styles.COLORS.ErrorBorderColor,
                              styles.COLORS.ErrorBgHoverColor,
                              styles.COLORS.ErrorBgColor,
                            ]
                          },
                          { 'script': 'sub' },
                          { 'script': 'super' },
                          { 'align': [] },
                          { 'indent': '-1' },
                          { 'indent': '+1' },
                          { 'list': 'ordered' },
                          { 'list': 'bullet' },
                          'link', 'image', 'video', 'blockquote', 'code', 'code-block', 'formula', 'clean'
                        ],
                      ]
                    }}
                  />
                </Form.Item>

                {/* Available documents for the advantage */}
                {selectedItem.numberOfDocuments > 0 &&
                  <div className='cms-add-modal-image-card-list'>
                    {selectedItem.documents.map((document) => {
                      return (
                        <Card 
                          actions={[
                            <Button
                              icon={<i className='fa-duotone fa-copy'></i>}
                              key='fa-copy'
                              title='Copiar URL'
                              type='link'
                              onClick={() => {
                                navigator.clipboard.writeText(`${process.env.REACT_APP_API_IMAGES_BASE_URL}${document.path}`)
                                  .then(
                                    function() {
                                      message.success('URL copiado');
                                    }
                                  ).catch(
                                    function() {
                                      message.error('Tente novamente');
                                    }
                                  );
                              }}
                            />,
                            <Popconfirm
                              key='fa-trash-can'
                              title='&nbsp;Arquivar documento'
                              description={`Tem a certeza que pretende arquivar o documento ${document.name}? Esta ação não é reversível.`}
                              onConfirm={() => this.archiveFile(document)}
                              okText='Arquivar'
                              okButtonProps={{ danger: true, loading: archiveFileLoading }}
                              cancelText='Cancelar'
                              icon={<i 
                                className='fa-duotone fa-circle-exclamation'
                                style={{
                                  '--fa-primary-color': styles.COLORS.ErrorActiveColor,
                                  '--fa-secondary-color': styles.COLORS.ErrorBorderColor,
                                  '--fa-secondary-opacity': 1
                                }}
                              ></i>}
                            >
                              <Button
                                danger
                                icon={<i className='fa-solid fa-trash-can'></i>}
                                title='Arquivar documento'
                                type='text'
                              />
                            </Popconfirm>
                          ]}
                          cover={
                            <>
                              { document.mimetype.includes('pdf') ?
                                <iframe id={document.idDocument} title={document.name} src={`${process.env.REACT_APP_API_IMAGES_BASE_URL}${document.path}`} height='100%' width='100%' ></iframe>
                                :
                                <img
                                  alt={document.name}
                                  crossOrigin='anonymous'
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = no_photo;
                                  }}
                                  src={`${process.env.REACT_APP_API_IMAGES_BASE_URL}${document.path}`}
                                />
                              }
                            </>
                          }
                          key={document.idDocument}
                          hoverable
                          style={{ width: 150 }}
                        >
                          <Meta title={document.name} />
                        </Card>
                      );
                    })}
                  </div>
                }
              </div>
              <br></br>
              {/* Buttons */}
              <Form.Item style={{ width: '100%' }}>
                <div className='cms-add-modal-btns'>
                  <Button type='default' onClick={this.handleEditCampaignDrawer}>Cancelar</Button>
                  {selectedItem.isActive &&
                    <Popconfirm
                      title='Desativar campanha'
                      description={
                        <>
                          <div>Tem a certeza que pretende desativar esta campanha?</div>
                          <div>Esta ação <b>é reversível</b>.</div>
                        </>
                      }
                      okText='Desativar'
                      okButtonProps={{ className: 'vantagens-warning-button' }}
                      okType='default'
                      cancelText='Cancelar'
                      onConfirm={this.deactivateCampaign}
                    >
                      <Button className='vantagens-warning-button' disabled={editFetching} type='default'>Desativar</Button>
                    </Popconfirm>
                  }
                  <Button
                    type={editError ? 'default' : 'primary'}
                    danger={editError ? true : false}
                    htmlType='submit'
                    disabled={editFetching}>{editError ? 'Tentar novamente' : editFetching ? 'A carregar...' : 'Guardar'}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Drawer>
        }
        
        {/* Submit file */}
        {selectedItem &&
          <Drawer
            className='cms-menu-add-drawer'
            destroyOnClose
            extra={
              <div className='cms-menu-add-drawer-header'>
                {/* Title */}
                <div>Carregar ficheiro</div>
                {/* Close btn */}
                <Button 
                  id='hamburger-menu-close'
                  icon={<i className='fa-light fa-xmark'></i>}
                  onClick={this.handleFileSubmissionModal}
                  type='text'
                />
              </div>
            }
            open={showFileSubmissionModal}
            onClose={this.handleFileSubmissionModal}
            placement='right'
            size='large'
            title='Carregar ficheiro'
            width={window.innerWidth < 736 ? window.innerWidth : 736}
          >
            {/* Form steps */}
            <Steps
              current={submitFileCurrentStep}
              initial={0}
              items={[
                { icon: <i className='fa-duotone fa-image'></i>, title: 'Especificar ficheiro' },
                { icon: <i className='fa-duotone fa-circle-exclamation'></i>, title: 'Confirmação' },
                { icon: <i className='fa-duotone fa-link-simple'></i>, title: 'Copiar URL' },
              ]}
              labelPlacement='vertical'
              size='small'
            />

            {/* Form */}
            <Form
              autoComplete='on'
              initialValues={{ remember: true }}
              layout='vertical'
              name='Submeter um ficheiro no sistema'
              onFinish={(values) => {
                switch(submitFileCurrentStep) {
                case 0:
                  if (!uploadedFile) {
                    this.setState({ showUploadInputError: true });
                  } else {
                    this.setState({ showUploadInputError: false, submitFileCurrentStep: submitFileCurrentStep + 1 });
                  }
                  break;
                case 1:
                  this.submitFile();
                  break;
                case 2:
                  if (fileSubmissionSuccess) {
                    this.handleFileSubmissionModal();
                    this.fetchData();
                  } else {
                    this.handleFileSubmissionModal();
                    setTimeout(() => {
                      this.handleFileSubmissionModal();
                    }, 500);
                  }
                  break;
                default:
                  break;
                }
              }}
            >
              {submitFileCurrentStep === 0 ?
                <div style={{ height: '400px' }}>
                  <Alert message='Atenção!' description='Se já existir um ficheiro no servidor com o mesmo nome, para esta campanha, o ficheiro será substituído.' type='warning' showIcon />
                  <br></br>
                  <Form.Item
                    getValueFromEvent={this.handleAddFileList}
                    name='uploadedFile'
                    noStyle
                    valuePropName='fileList'
                  >
                    <Upload.Dragger
                      beforeUpload={(file) => {
                        this.setState({ uploadedFile: file });
                        return false;
                      }}
                      listType='picture'
                      maxCount={1}
                      name='uploadedFile'
                      onChange={({ file, fileList }) => {
                        const item = fileList.length < 1 ? null : file;
                        this.setState({ uploadedFile: item, showUploadInputError: item ? false : true });
                      }}
                    >
                      <p className='ant-upload-drag-icon'><i className='fa-duotone fa-cloud-arrow-up'></i></p>
                      <p className="ant-upload-text">Clique ou arraste um ficheiro para esta área para carregá-lo</p>
                      <p className="ant-upload-hint">Suporta apenas o carregamento de um único ficheiro do tipo .jpg, .jpeg ou .png</p>
                    </Upload.Dragger>
                  </Form.Item>
                  { showUploadInputError &&
                    <p className='cms-add-modal-form-item-input-error-text'><i className='fa-duotone fa-circle-exclamation'></i> Por favor, introduza um ficheiro.</p>
                  }
                </div>
                : submitFileCurrentStep === 1 ?
                  <div className='cms-add-modal-form-item-confirmation-step'>
                    {fileSubmissionLoading ? 
                      <>
                        <ClockLoader color={styles.COLORS.PrimaryColor} size={100} />
                        <div>A carregar...</div>
                      </>
                      :
                      <>
                        <p>Tem a certeza que pretende carregar o ficheiro</p>
                        <p className='cms-add-modal-form-item-confirmation-step-highlight'>{uploadedFile ? `"${uploadedFile.name}" (${formatBytes(uploadedFile.size)})` : '-'}</p>
                        <p>no servidor?</p>
                      </>
                    }
                  </div>
                  :
                  <div>
                    {fileSubmissionSuccess ?
                      <>
                        <Result
                          status='success'
                          subTitle='Copie o URL do ficheiro exposto na barra abaixo.'
                          title='Ficheiro carregado com sucesso'
                          extra={[
                            <Space.Compact style={{ width: '100%' }} key='file_url_success'>
                              <Input allowClear={false} id='cms_file_url_input' value={fileSubmissionLink} />
                              <Button
                                icon={<i className='fa-duotone fa-copy'></i>}
                                onClick={(event) => {
                                  const input = document.getElementById('cms_file_url_input');
                                  if (input) {
                                    input.focus();
                                    input.select();
                                    navigator.clipboard.writeText(fileSubmissionLink).then(
                                      function() {
                                        message.success('URL copiado');
                                      }
                                    ).catch(
                                      function() {
                                        message.error('Tente novamente');
                                      }
                                    );
                                  }
                                }}
                                type='default'
                              />
                            </Space.Compact>
                          ]}
                        />
                      </>
                      : fileSubmissionError ? 
                        <Result
                          status='error'
                          subTitle={fileSubmissionError}
                          title='Erro ao carregar o ficheiro'
                          extra={[
                          ]}
                        />
                        : null
                    }
                  </div>
              }
              <br></br>
              {/* Buttons */}
              <Form.Item style={{ width: '100%' }}>
                <div className='cms-add-modal-btns'>
                  {submitFileCurrentStep < 2 && 
                    <Button type='default' onClick={this.handleFileSubmissionModal}>Cancelar</Button>
                  }
                  {submitFileCurrentStep === 1 && 
                    <Button
                      disabled={fileSubmissionLoading || fileSubmissionSuccess}
                      onClick={this.handleFileSubmissionBackStep}
                      type='primary'
                    >Voltar</Button>
                  }
                  {submitFileCurrentStep === 0 && 
                    <Button type='primary' htmlType='submit'>Continuar</Button>
                  }
                  {submitFileCurrentStep === 1 && 
                    <Button 
                      disabled={fileSubmissionLoading || fileSubmissionSuccess || fileSubmissionError}
                      htmlType='submit'
                      loading={fileSubmissionLoading}
                      type='primary'
                    >Confirmar</Button>
                  }
                  {submitFileCurrentStep === 2 && 
                    <Button
                      danger={fileSubmissionError ? true : false}
                      htmlType='submit'
                      type='primary'
                    >{fileSubmissionSuccess ? 'Concluir' : 'Tentar novamente'}</Button>
                  }
                </div>
              </Form.Item>
            </Form>
          </Drawer>
        }
      </div>
    );
  }
}

/**
 * Used in the Redux pattern to reflect any updates to the Redux store and merge them into props in the current
 * component. The Redux store serves as a centralized place for the state to live in the application.
 * 
 * @param {any} state - Centralized state of the application.
 * @returns {any} the state of the application as the component props.
 */
const mapPropsToState = (state) => {
  const languages = state.languages;
  const groups = state.groups;
  const app = state.app;

  return {
    languages: languages.privilegedData,
    languagesError: languages.error,
    languagesFetching: languages.fetching,

    groups: groups.data,
    groupsError: groups.error,
    groupsFetching: groups.fetching,

    menuCollapsed: app.collapseState,
  };
};

/**
 * Used in the Redux pattern to dispatch actions to the Redux store, triggering a state change.
 * 
 * @param {any} dispatch - function of the Redux store.
 * @returns {any} the dispatch functions as components props.
 */
const mapDispatchToState = (dispatch) => {
  return {
    getLanguages: (token) => dispatch(getAllLanguages(token)),
    getGroups: (token) => dispatch(getGroups(token)),
  };
};

export default withRouter(connect(mapPropsToState, mapDispatchToState)(ManageCampaigns));