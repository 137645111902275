import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';

/**
 * MenuActions container represents the page template that displays all CMS menus to manage contents.
 */
export default class MenuActions extends React.Component {
  render() {
    const isActive = localStorage.getItem('isActive');
    const idRole = localStorage.getItem('idRole');
    const role = localStorage.getItem('role');
    const isAdmin = isActive === 'true' && idRole === '376DDE5B-09C4-ED11-A311-00155D08E85F' && role === 'Administrator';
    const isManager = isActive === 'true' && idRole === '386DDE5B-09C4-ED11-A311-00155D08E85F' && role === 'Manager';

    return (
      <>
        <div id='cms-menu-actions-list'>
          <Link to={`${process.env.REACT_APP_PUBLIC_URL}/cms/vantagens`} className={!(isAdmin || isManager) ? 'disable-link' : ''}>
            <i className='fa-regular fa-handshake'></i>
            <h2>Vantagens</h2>
          </Link>
          <Link to={`${process.env.REACT_APP_PUBLIC_URL}/cms/grupos`} className={!(isAdmin || isManager) ? 'disable-link' : ''}>
            <i className='fa-duotone fa-sitemap'></i>
            <h2>Grupos</h2>
          </Link>
          <Link to={`${process.env.REACT_APP_PUBLIC_URL}/cms/campanhas`} className={!(isAdmin || isManager) ? 'disable-link' : ''}>
            <i className='fa-duotone fa-business-time'></i>
            <h2>Campanhas</h2>
          </Link>
          <Link to={`${process.env.REACT_APP_PUBLIC_URL}/cms/categorias`} className={!(isAdmin || isManager) ? 'disable-link' : ''}>
            <i className='fa-duotone fa-tags'></i>
            <h2>Categorias</h2>
          </Link>
          <Link to={`${process.env.REACT_APP_PUBLIC_URL}/cms/menus`} className={!(isAdmin || isManager) ? 'disable-link' : ''}>
            <i className='fa-duotone fa-list-dropdown'></i>
            <h2>Menus</h2>
          </Link>
          {/* <Link to='/cms/paginas' className={!(isAdmin || isManager) ? 'disable-link' : ''}>
            <i className='fa-duotone fa-browser'></i>
            <h2>Páginas</h2>
          </Link> */}
          <Link to={`${process.env.REACT_APP_PUBLIC_URL}/cms/banners`} className={!(isAdmin || isManager) ? 'disable-link' : ''}>
            <i className='fa-duotone fa-images'></i>
            <h2>Banners</h2>
          </Link>
          <Link to={`${process.env.REACT_APP_PUBLIC_URL}/cms/redes-sociais`} className={!(isAdmin || isManager) ? 'disable-link' : ''}>
            <i className='fa-duotone fa-chart-network'></i>
            <h2>Redes Sociais</h2>
          </Link>
          <Link to={`${process.env.REACT_APP_PUBLIC_URL}/cms/utilizadores`} className={!isAdmin ? 'disable-link' : ''}>
            <i className='fa-duotone fa-users'></i>
            <h2>Utilizadores</h2>
          </Link>
          <Link to={`${process.env.REACT_APP_PUBLIC_URL}/cms/atividade`}>
            <i className='fa-duotone fa-chart-gantt'></i>
            <h2>Atividade</h2>
          </Link>
          <Link to={`${process.env.REACT_APP_PUBLIC_URL}/cms/configuracoes`} className={!(isAdmin || isManager) ? 'disable-link' : ''}>
            <i className='fa-duotone fa-gears'></i>
            <h2>Configurações</h2>
          </Link>
        </div>
      </>
    );
  }
}