export const  SPACING = {
  MarginXXS: '4px',
  MarginXS: '8px',
  MarginSM: '12px',
  Margin: '16px',
  MarginMD: '20px',
  MarginLG: '24px',
  MarginXL: '32px',
  MarginXXL: '48px',

  PaddingXXS: '4px',
  PaddingXS: '8px',
  PaddingSM: '12px',
  Padding: '16px',
  PaddingMD: '20px',
  PaddingLG: '24px',
  PaddingXL: '32px',
  PaddingXXL: '48px',
};