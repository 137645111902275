const SET_CMS_MENU_COLLAPSE_STATE = 'app/SET_CMS_MENU_COLLAPSE_STATE';
const INITIAL_STATE = {
  collapseState: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
  case SET_CMS_MENU_COLLAPSE_STATE:
    return {
      ...state,
      collapseState: action.payload,
    };
  default:
    return state;
  }
}

function setCmsMenuCollapseState(state) {
  return {
    type: SET_CMS_MENU_COLLAPSE_STATE,
    payload: state,
  };
}

export function setMenuCollapseState(state) {
  return (dispatch) => dispatch(setCmsMenuCollapseState(state));
}