const FETCH_REQUEST = 'user/FETCH_REQUEST';
const FETCH_SUCCESS = 'user/FETCH_SUCCESS';
const FETCH_FAILURE = 'user/FETCH_FAILURE';
const FETCH_LOGOUT_SUCCESS = 'user/FETCH_LOGOUT_SUCCESS';

const INITIAL_STATE = {
  data: null,
  username: null,
  token: null,
  error: null,
  fetching: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
  case FETCH_REQUEST:
    return {
      ...state,
      error: null,
      fetching: true,
    };
  case FETCH_SUCCESS:
    return {
      ...state,
      fetching: false,
      error: null,
      data: action.payload.user,
      username: action.payload.user.username,
      token: action.payload.token,
    };
  case FETCH_FAILURE:
    return {
      ...state,
      fetching: false,
      error: action.payload,
    };
  case FETCH_LOGOUT_SUCCESS:
    return {
      ...state,
      fetching: false,
      error: null,
      data: null,
      username: null,
      token: null,
    };
  default:
    return state;
  }
}

function fetchRequest() {
  return {
    type: FETCH_REQUEST,
  };
}

function fetchSuccess(data) {
  return {
    type: FETCH_SUCCESS,
    payload: data,
  };
}

function fetchFailure(error) {
  return {
    type: FETCH_FAILURE,
    payload: error,
  };
}

function fetchLogoutSuccess() {
  return {
    type: FETCH_LOGOUT_SUCCESS,
  };
}

export function login(body) {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: new Headers({
      'Content-Type': 'application/json',
      'app_secret_key': process.env.REACT_APP_API_APP_KEY
    })
  };
  return (dispatch) => {
    dispatch(fetchRequest());
    fetch(`${process.env.REACT_APP_API_BASE_URL}/users/login`, requestOptions)
      .then(async (response) => {
        if (response.ok) return response.json();
        const result = JSON.stringify(await response.json());
        throw new Error(result ? result : 'It was impossible to log user in.');
      }).then(async (data) => {
        // await (new Promise((res) => { setTimeout(res, 5000); }));
        localStorage.setItem('token', data.token);
        localStorage.setItem('username', data.user.username);
        localStorage.setItem('loginDate', new Date(Date.now() + (3600 * 1000 * 4))); //4h
        localStorage.setItem('isActive', data.user.deactivationDate ? false : true);
        localStorage.setItem('idRole', data.user.role.idRole);
        localStorage.setItem('role', data.user.role.name);
        dispatch(fetchSuccess(data));

      }).catch((error) => dispatch(fetchFailure(error)));
  };
}

export function logout() {
  return (dispatch) => {
    dispatch(fetchRequest());
    try {
      localStorage.clear();
      dispatch(fetchLogoutSuccess());
    } catch (error) {
      dispatch(fetchFailure(error));
    }
  };
}