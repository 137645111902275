import React from 'react';
import dayjs from 'dayjs';
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';
import { withRouter } from '../../utils';
import { Button, Form, Input, Modal, Table, Tag, notification } from 'antd';
import { PuffLoader } from 'react-spinners';
import { styles } from '../../styles';

/**
 * ManageConfigurations component represents the page template that allows to manage the application configurations.
 * It displays a table with all configurations. It displays tools to edit configurations.
 */
class ManageConfigurations extends React.Component {
  /**
   * The constructor for a React component is called before it is mounted.
   * When implementing the constructor, `super(props)` must be called before any
   * other statement. Otherwise, this.props will be undefined in the constructor,
   * which can lead to bugs.
   *
   * A React constructor is only used for two purposes:
   * - Initializing local state by assigning an object to this.state.
   * - Binding event handler methods to an instance.
   *
   * Instead of calling `setState()` in the `constructor(), the initial state must be
   * directly assigned to `this.state` in the constructor
   *
   * @param {*} props - Arbitrary component inputs.
   */
  constructor() {
    super();
    this.state = {
      activeConfigurations: [],
      inactiveConfigurations: [],
      configurationsError: null,
      editError: null,
      editFetching: false,
      loading: false,
      pageWidth: 0,
      selectedItem: null,
      showEditConfigurationModal: false,
    };
  }

  /**
   * Allows to execute the React code when the component is already placed in the DOM (Document Object Model).
   * This method is called during the Mounting phase of the React Life-cycle i.e after the component is rendered.
   */
  componentDidMount() {
    // Get data
    this.fetchData();
    // Scroll to top
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Add event on resize
    this.setState({ pageWidth: window.innerWidth });
    window.addEventListener('resize', () => this.setState({ pageWidth: window.innerWidth }));
  }

  /**
   * Sets the array of configurations to manage.
   * 
   * @param {any[]} data - Original data of configurations. 
   * @returns {any[]} the modified dataset of configurations.
   */
  setConfigurations = (data) => {
    return data.map((configuration) => {
      return {
        key: configuration.idConfiguration,
        idValueType: configuration.valueType ? configuration.valueType.idValueType : null,
        valueTypeName: configuration.valueType ? configuration.valueType.name : null,
        name: configuration.name,
        value: configuration.value,
        description: configuration.description,
        editable: configuration.editable,
        activationDate: configuration.activationDate ? dayjs(configuration.activationDate) : null,
        deactivationDate: configuration.deactivationDate ? dayjs(configuration.deactivationDate) : null,
        isActive: this.configurationIsActive(configuration),
      };
    });
  };

  /**
   * Checks if a configuration is active, analysing its activation and deactivation dates and comparing it to the
   * actual date.
   * 
   * @param {any} record - Configuration object.
   * @returns {boolean} - true if configuration is active and false otherwise.
   */
  configurationIsActive = (record) => {
    const activation = record.activationDate ? dayjs(record.activationDate).subtract(1, 'h') : undefined;
    const deactivation = record.deactivationDate ? dayjs(record.deactivationDate).subtract(1, 'h')  : undefined;
    const now = dayjs();
    return activation && (activation.isBefore(now) || activation.isSame(now)) && 
      (!deactivation || (deactivation && deactivation.isAfter(now))) ? true : false;
  };

  /**
   * Fetches all configurations from database through an available API.
   * Calls `setConfigurations` to modify the data, regarding this component.
   */
  fetchData = () => {
    this.setLoading(true);
    const { selectedItem } = this.state;
    // Get options to fetch
    const fetchOptions = {
      method: 'GET',
      headers: new Headers({ 'app_secret_key': process.env.REACT_APP_API_APP_KEY })
    };
    // Fetch data
    fetch(`${process.env.REACT_APP_API_BASE_URL}/configurations`, fetchOptions).then(async (response) => { 
      if (response.ok) return response.json();
      const result = await response.json();
      throw new Error(result ? JSON.stringify(result) : 'Não é possível obter as configurações do sistema.');
    }).then(async (data) => {
      const configurations = this.setConfigurations(data);
      const active = [];
      const inactive = [];
      configurations.forEach((configuration) => {
        if (configuration.isActive) active.push(configuration);
        else inactive.push(configuration); 
      });
      this.setState({ activeConfigurations: active, inactiveConfigurations: inactive });
      if (selectedItem) this.setState({ selectedItem: configurations.find((configuration) => configuration.key === selectedItem.key)});
      else this.setState({ selectedItem: null });
      this.setLoading(false);
      this.setState({ configurationsError: null });
    }).catch((error) => {
      this.setState({ activeConfigurations: [], inactiveConfigurations: [], configurationsError: error.message, selectedItem: null });
      this.setLoading(false);
    });
  };

  /**
   * Sets loading state from `this.state.loading`.
   * This state is relationated with the data fetching from database.
   *
   * @param {boolean} loading - Sets the loading state to true or false.
   */
  setLoading = (loading) => {
    this.setState({ loading: loading });
  };

  /**
   * Handles the visibility state of the edit configuration modal, using the state `this.state.showEditConfigurationModal`.
   */
  handleEditConfigurationModal = () => {
    const { showEditConfigurationModal } = this.state;
    this.setState({ showEditConfigurationModal: !showEditConfigurationModal });
  };

  /**
   * Edits an existing configuration in the system, using the API for the effect.
   *
   * @param {any} values - New data of the existing configuration.
   */
  editConfiguration = (values) => {
    this.setState({ editFetching: true });
    const { selectedItem } = this.state;
    // Get body to fetch
    const raw = JSON.stringify({ value: values.value });
    // Get options to fetch
    const fetchOptions = {
      method: 'PATCH',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      }),
      body: raw,
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/configurations/${selectedItem.key}`, fetchOptions).then(async (response) => {
      if (response.ok) return response.json();
      const result = await response.json();
      throw new Error(result ? JSON.stringify(result) : `Não é possível editar a configuração '${selectedItem.name}'`);
    }).then(async (data) => {
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check'></i>
            <span className='cms-notification-icon-text-span'>Alterações guardadas com sucesso</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
            <span>A configuração <u>{values.name}</u> foi atualizada com sucesso. Consulte a lista de configurações para verificar a alteração.</span>
          </div>
        ),
      });
      setTimeout(() => {
        this.setState({ editError: null, editFetching: false });
        this.handleEditConfigurationModal();
        this.fetchData();
      }, 1500);
    }).catch((error) => {
      let message = `Não é possível editar a configuração '${selectedItem.name}'`;
      let extendedMessage = 'Por favor, reveja os dados inseridos e/ou tente novamente.';
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'></i>
            <span className='cms-notification-icon-text-span'>{message}</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
            <span>{extendedMessage}</span>
          </div>
        ),
      });
      this.setState({ editError: message, editFetching: false });
    });
  };

  /**
   * Render method of React component.
   * 
   * @returns the component template.
   */
  render() {
    const { 
      activeConfigurations, configurationsError, editError, editFetching, loading, 
      pageWidth, selectedItem, showEditConfigurationModal,
    } = this.state;

    const isActive = localStorage.getItem('isActive');
    const idRole = localStorage.getItem('idRole');
    const role = localStorage.getItem('role');
    const isAdmin = isActive === 'true' && idRole === '376DDE5B-09C4-ED11-A311-00155D08E85F' && role === 'Administrator';
    const isManager = isActive === 'true' && idRole === '386DDE5B-09C4-ED11-A311-00155D08E85F' && role === 'Manager';
    
    if (isActive !== 'true' || !(isAdmin || isManager) || configurationsError) {
      return (
        <div className='cms-menu-actions-container'>
          {/* <h1 id='cms-menu-actions-title'>Configurações do Sistema</h1> */}
          <div id='cms-menu-actions-tabs'>
            <div id='cms-menu-actions-error'>
              <i className='fa-duotone fa-arrow-rotate-right'></i>
              <b>Ocorreu um erro...</b>
              <Button type='text' onClick={() => window.location.reload(true)}>
                Clique&nbsp;<span className='cms-menu-actions-error-btn'>aqui</span>&nbsp;para refrescar a página.
              </Button>
              Ou tente mais tarde.
            </div>
          </div>
        </div>
      );
    }

    if (loading) {
      return (
        <div className='cms-menu-actions-container'>
          <div id='cms-menu-actions-error'>
            <PuffLoader color={styles.COLORS.PrimaryColor} size={100} />
            <p>A carregar...</p>
          </div>
        </div>
      );
    }

    const columns = [
      {
        title: 'Nome',
        dataIndex: 'name',
        key: 'name',
        render: (_, record) => {
          const style = {};
          const isActive = record.isActive;
          if (!isActive) style.color = styles.COLORS.TertiaryTextColor;
          return (
            <>
              <Button
                onClick={() => {
                  const newSelectedItem = !selectedItem || (selectedItem && selectedItem.key !== record.key) ? record : null;
                  this.setState({ selectedItem: newSelectedItem });
                  if (newSelectedItem) this.handleEditConfigurationModal();
                }}
                title={`${record.name} ${isActive ? '' : ' (Desativada)'}`}
                type={isActive ? 'text' : 'link'}
              >
                {record.name} 
              </Button>
              {!isActive && <Tag color={styles.COLORS.QuaternaryTextColor}>Desativada</Tag>}
            </>
          );
        },
        sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        width: '280px',
      },
      {
        dataIndex: 'description',
        key: 'description',
        render: (_, record) => {
          const style = {};
          if (!record.isActive) style.color = styles.COLORS.TertiaryTextColor;
          return <span style={style}>{record.description}</span>;
        },
        title: 'Descrição',
        width: pageWidth > 1400 ? 'auto' : '380px',
      },
      {
        dataIndex: 'value',
        key: 'value',
        render: (_, record) => {
          const style = {};
          if (!record.isActive) style.color = styles.COLORS.QuaternaryTextColor;
          return <span style={style}>{record.value}</span>;
        },
        title: 'Valor',
        width: '600px',
      },
    ];

    return (
      <div className='cms-menu-actions-container'>
        {/* Tables */}
        <div id='cms-menu-actions-tabs'>
          <div className='cms-menu-actions-tabs-item'>
            <br></br>
            {/* Data table */}
            <Table
              bordered
              columns={columns}
              dataSource={activeConfigurations}
              loading={loading}
              pagination={{ size: 'default' }}
              rowKey={(record) => record.key}
              rowSelection={{
                onChange: (selectedRowKeys, selectedRows) => {
                  this.setState({ selectedItem: selectedRows.length > 0 ? selectedRows[selectedRows.length-1] : null });
                },
                selectedRowKeys: selectedItem ? [selectedItem.key] : [],
                type: 'checkbox',
                hideSelectAll: true,
              }}
              scroll={{ x: 240 }}
              size='small'
            />
          </div>
        </div>

        {/* Edit Configuration Modal */}
        { selectedItem &&
          <Modal closable destroyOnClose footer={null} keyboard
            onCancel={this.handleEditConfigurationModal}
            open={showEditConfigurationModal}
            title={<h5 style={{marginTop: 0}}>Editar configuração <span style={{ color: styles.COLORS.PrimaryColor }}>{selectedItem.name}</span></h5>}
          >
            <Form
              name='Edit configuration'
              layout='vertical'
              initialValues={{ remember: true }}
              onFinish={this.editConfiguration}
              autoComplete='on'
            >
              {/* Name */}
              <Form.Item
                initialValue={selectedItem.name}
                label='Nome'
                name='name'
                required
              >
                <Input disabled placeholder='Introduzir o nome da categoria' type='text' />
              </Form.Item>

              {/* Description */}
              <Form.Item
                initialValue={selectedItem.description}
                label='Descrição'
                name='description'
                required
              >
                <Input disabled placeholder='Introduzir a descrição da configuração' type='text' />
              </Form.Item>

              {/* Value */}
              {
                <Form.Item
                  initialValue={selectedItem.value}
                  label='Valor'
                  name='value'
                  required
                >
                  { selectedItem.valueTypeName === 'string' ?
                    <Input disabled={!selectedItem.editable} placeholder='Introduzir o valor da configuração' type='text' />
                    : selectedItem.valueTypeName === 'number' ?
                      <span>number</span>
                      : selectedItem.valueTypeName === 'boolean' ?
                        <span>boolean</span>
                        :
                        <ReactQuill
                          placeholder='Introduzir o valor da configuração...'
                          theme='snow'
                          modules={{
                            toolbar: [
                              [{ 'header': [1, 2, 3, 4, 5, 6, false] },
                                'bold', 'italic', 'underline', 'strike',
                                {
                                  'color': [
                                    styles.COLORS.TextColor,
                                    styles.COLORS.SecondaryTextColor,
                                    styles.COLORS.TertiaryTextColor,
                                    styles.COLORS.QuaternaryTextColor,
                                    styles.COLORS.FillColor,
                                    styles.COLORS.SecondaryFillColor,
                                    styles.COLORS.BgContainerColor,
                                    styles.COLORS.PrimaryActiveColor,
                                    styles.COLORS.PrimaryColor,
                                    styles.COLORS.PrimaryHoverColor,
                                    styles.COLORS.PrimaryBorderHoverColor,
                                    styles.COLORS.PrimaryBorderColor,
                                    styles.COLORS.PrimaryBgHoverColor,
                                    styles.COLORS.PrimaryBgColor,
                                    styles.COLORS.SecondaryActiveColor,
                                    styles.COLORS.SecondaryColor,
                                    styles.COLORS.SecondaryHoverColor,
                                    styles.COLORS.SecondaryBorderHoverColor,
                                    styles.COLORS.SecondaryBgHoverColor,
                                    styles.COLORS.SecondaryBgColor,
                                    styles.COLORS.SecondaryBorderColor,
                                    styles.COLORS.SuccessActiveColor,
                                    styles.COLORS.SuccessColor,
                                    styles.COLORS.SuccessTextHoverColor,
                                    styles.COLORS.SuccessBorderHoverColor,
                                    styles.COLORS.SuccessBorderColor,
                                    styles.COLORS.SuccessBgHoverColor,
                                    styles.COLORS.SuccessBgColor,
                                    styles.COLORS.WarningActiveColor,
                                    styles.COLORS.WarningColor,
                                    styles.COLORS.WarningTextHoverColor,
                                    styles.COLORS.WarningBorderHoverColor,
                                    styles.COLORS.WarningBorderColor,
                                    styles.COLORS.WarningBgHoverColor,
                                    styles.COLORS.WarningBgColor,
                                    styles.COLORS.ErrorActiveColor,
                                    styles.COLORS.ErrorColor,
                                    styles.COLORS.ErrorTextHoverColor,
                                    styles.COLORS.ErrorBorderHoverColor,
                                    styles.COLORS.ErrorBorderColor,
                                    styles.COLORS.ErrorBgHoverColor,
                                    styles.COLORS.ErrorBgColor,
                                  ]
                                },
                                {
                                  'background': [
                                    styles.COLORS.TextColor,
                                    styles.COLORS.SecondaryTextColor,
                                    styles.COLORS.TertiaryTextColor,
                                    styles.COLORS.QuaternaryTextColor,
                                    styles.COLORS.FillColor,
                                    styles.COLORS.SecondaryFillColor,
                                    styles.COLORS.BgContainerColor,
                                    styles.COLORS.PrimaryActiveColor,
                                    styles.COLORS.PrimaryColor,
                                    styles.COLORS.PrimaryHoverColor,
                                    styles.COLORS.PrimaryBorderHoverColor,
                                    styles.COLORS.PrimaryBorderColor,
                                    styles.COLORS.PrimaryBgHoverColor,
                                    styles.COLORS.PrimaryBgColor,
                                    styles.COLORS.SecondaryActiveColor,
                                    styles.COLORS.SecondaryColor,
                                    styles.COLORS.SecondaryHoverColor,
                                    styles.COLORS.SecondaryBorderHoverColor,
                                    styles.COLORS.SecondaryBgHoverColor,
                                    styles.COLORS.SecondaryBgColor,
                                    styles.COLORS.SecondaryBorderColor,
                                    styles.COLORS.SuccessActiveColor,
                                    styles.COLORS.SuccessColor,
                                    styles.COLORS.SuccessTextHoverColor,
                                    styles.COLORS.SuccessBorderHoverColor,
                                    styles.COLORS.SuccessBorderColor,
                                    styles.COLORS.SuccessBgHoverColor,
                                    styles.COLORS.SuccessBgColor,
                                    styles.COLORS.WarningActiveColor,
                                    styles.COLORS.WarningColor,
                                    styles.COLORS.WarningTextHoverColor,
                                    styles.COLORS.WarningBorderHoverColor,
                                    styles.COLORS.WarningBorderColor,
                                    styles.COLORS.WarningBgHoverColor,
                                    styles.COLORS.WarningBgColor,
                                    styles.COLORS.ErrorActiveColor,
                                    styles.COLORS.ErrorColor,
                                    styles.COLORS.ErrorTextHoverColor,
                                    styles.COLORS.ErrorBorderHoverColor,
                                    styles.COLORS.ErrorBorderColor,
                                    styles.COLORS.ErrorBgHoverColor,
                                    styles.COLORS.ErrorBgColor,
                                  ]
                                },
                                { 'script': 'sub' },
                                { 'script': 'super' },
                                { 'align': [] },
                                { 'indent': '-1' },
                                { 'indent': '+1' },
                                { 'list': 'ordered' },
                                { 'list': 'bullet' },
                                'link', 'image', 'video', 'blockquote', 'code', 'code-block', 'formula', 'clean'
                              ],
                            ]
                          }}
                        />
                  }
                </Form.Item>
              }
             
              {/* Buttons */}
              <Form.Item >
                <div className='cms-add-modal-btns'>
                  <Button type='default' onClick={this.handleEditConfigurationModal} disabled={editFetching}>Cancelar</Button>
                  <Button 
                    type={editError ? 'default' : 'primary'}
                    danger={editError ? true : false}
                    htmlType='submit'
                    disabled={editFetching}
                  >{editError ? 'Tentar novamente' : editFetching ? 'A guardar...' : 'Guardar'}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Modal>
        }
      </div>
    );
  }
}

/**
 * Used in the Redux pattern to reflect any updates to the Redux store and merge them into props in the current
 * component. The Redux store serves as a centralized place for the state to live in the application.
 * 
 * @param {any} state - Centralized state of the application.
 * @returns {any} the state of the application as the component props.
 */
const mapPropsToState = (state) => {
  return {};
};

/**
 * Used in the Redux pattern to dispatch actions to the Redux store, triggering a state change.
 * 
 * @param {any} dispatch - function of the Redux store.
 * @returns {any} the dispatch functions as components props.
 */
const mapDispatchToState = (dispatch) => {
  return {};
};

export default withRouter(connect(mapPropsToState, mapDispatchToState)(ManageConfigurations));