import React from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { withRouter } from '../../utils';
import { Button, Descriptions, Input, Modal, Space, Table } from 'antd';
import { PuffLoader } from 'react-spinners';
import { styles } from '../../styles';

/**
 * ManageLogs component represents the page template that allows to manage the application loggings.
 * It displays a table with all actions perfomed in the app.
 * Depending on the permission of the log, if it is an administrator, it is possible to see all actions performed
 * by all logs. The rest of the logs, can only see their own actions.
 */
class ManageLogs extends React.Component {
  /**
   * The constructor for a React component is called before it is mounted.
   * When implementing the constructor, `super(props)` must be called before any
   * other statement. Otherwise, this.props will be undefined in the constructor,
   * which can lead to bugs.
   *
   * A React constructor is only used for two purposes:
   * - Initializing local state by assigning an object to this.state.
   * - Binding event handler methods to an instance.
   *
   * Instead of calling `setState()` in the `constructor(), the initial state must be
   * directly assigned to `this.state` in the constructor
   *
   * @param {*} props - Arbitrary component inputs.
   */
  constructor() {
    super();
    this.state = {
      descriptionSearch: null,
      loading: false,
      logs: [],
      logsError: null,
      pageWidth: 0,
      selectedItem: null,
      showMoreInfoModal: false,
    };
  }

  /**
   * Allows to execute the React code when the component is already placed in the DOM (Document Object Model).
   * This method is called during the Mounting phase of the React Life-cycle i.e after the component is rendered.
   */
  componentDidMount() {
    // Get data
    this.fetchData();
    // Scroll to top
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Add event on resize
    this.setState({ pageWidth: window.innerWidth });
    window.addEventListener('resize', () => this.setState({ pageWidth: window.innerWidth }));
  }

  /**
   * Sets the array of logs to manage.
   *
   * @param {any[]} data - Original data of logs. 
   * @returns {any[]} the modified dataset of logs.
   */
  setLogs = (data) => {
    return data.map((log) => {
      return {
        key: log.idLog,
        idLogType: log.logType.idLogType,
        logTypeName: log.logType.name,
        idUser: log.user.idUser,
        userName: log.user.name,
        userEmail: log.user.email,
        userUsername: log.user.username,
        userIdRole: log.role.idRole,
        userRoleName: log.role.name,
        userIsActive: this.userIsActive(log.user),
        action: log.action,
        target: log.target,
        instance: log.instance,
        description: log.description,
        date: log.date ? dayjs(log.date) : null,
      };
    });
  };

  /**
   * Checks if a user is active, analysing its activation and deactivation dates and comparing it to the
   * actual date.
   * 
   * @param {any} record - User object.
   * @returns {boolean} - true if user is active and false otherwise.
   */
  userIsActive = (record) => {
    const activation = record.activationDate ? dayjs(record.activationDate).subtract(1, 'h') : undefined;
    const deactivation = record.deactivationDate ? dayjs(record.deactivationDate).subtract(1, 'h')  : undefined;
    const now = dayjs();
    return activation && (activation.isBefore(now) || activation.isSame(now)) && 
      (!deactivation || (deactivation && deactivation.isAfter(now))) ? true : false;
  };

  /**
   * Fetches all logs from database through an available API.
   * Calls `setLogs` to modify the data, regarding this component.
   */
  fetchData = () => {
    this.setLoading(true);
    const { selectedItem } = this.state;
    // Get options to fetch
    const fetchOptions = {
      method: 'GET',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      })
    };
    // Fetch data
    fetch(`${process.env.REACT_APP_API_BASE_URL}/logs`, fetchOptions)
      .then(async (response) => { 
        if (response.ok) return response.json();
        const result = await response.json();
        throw new Error(result ? JSON.stringify(result) : 'Não é possível obter a atividade dos utilizadores.');
      }).then((data) => {
        const logs = this.setLogs(data);
        if (selectedItem) this.setState({ selectedItem: logs.find((log) => log.key === selectedItem.key)});
        this.setState({ logs: logs, logsError: null });
        this.setLoading(false);
      }).catch((error) => {
        this.setState({ logs: [], logsError: error.message, selectedItem: null });
        this.setLoading(false);
      });
  };

  /**
   * Sets loading state from `this.state.loading`.
   * This state is relationated with the data fetching from database.
   *
   * @param {boolean} loading - Sets the loading state to true or false.
   */
  setLoading = (loading) => {
    this.setState({ loading: loading });
  };

  /**
   * Handles the visibility state of the log information modal, using the state `this.state.showMoreInfoModal`.
   */
  handleInfoModal = () => {
    const { showMoreInfoModal } = this.state;
    this.setState({ showMoreInfoModal: !showMoreInfoModal });
    if (showMoreInfoModal) this.setState({ selectedItem: null });
  };

  /**
   * Render method of React component.
   * 
   * @returns the component template.
   */
  render() {
    const { loading, logs, logsError, selectedItem, showMoreInfoModal } = this.state;

    if (logsError || (!loading && logs.length < 1)) {
      return (
        <div className='cms-menu-actions-container'>
          {/* <h1 id='cms-menu-actions-title'>Atividade na Aplicação</h1> */}
          <div id='cms-menu-actions-tabs'>
            <div id='cms-menu-actions-error'>
              <i className='fa-duotone fa-arrow-rotate-right'></i>
              <b>Ocorreu um erro...</b>
              <Button type='text' onClick={() => window.location.reload(true)}>
                Clique&nbsp;<span className='cms-menu-actions-error-btn'>aqui</span>&nbsp;para refrescar a página.
              </Button>
              Ou tente mais tarde.
            </div>
          </div>
        </div>
      );
    }

    if (loading) {
      return (
        <div className='cms-menu-actions-container'>
          <div id='cms-menu-actions-error'>
            <PuffLoader color={styles.COLORS.PrimaryColor} size={100} />
            <p>A carregar...</p>
          </div>
        </div>
      );
    }

    const distinctActions =  [...new Set(logs.map(log => log.action))];
    const distinctTargets =  [...new Set(logs.map(log => log.target))];
    const distinctUsers =  [...new Set(logs.map(log => log.userUsername))];

    const columns = [
      {
        align: 'center',
        title: 'Ação',
        dataIndex: 'action',
        filterIcon: <i className='fa-duotone fa-filters'></i>,
        filters: distinctActions.map((action) => { return { text: action, value: action }; }),
        key: 'action',
        onFilter: (value, record) => record.action.indexOf(value) === 0,
        render: (_, record) => {
          return (
            <Button type='text' onClick={() => {
              this.setState({ selectedItem: record });
              this.handleInfoModal();
            }}>{record.action}</Button>
          );
        },
        sorter: (a, b) => a.action.toLowerCase().localeCompare(b.action.toLowerCase()),
        width: '120px'
      },
      {
        align: 'center',
        title: 'Tabela',
        dataIndex: 'target',
        filterIcon: <i className='fa-duotone fa-filters'></i>,
        filters: distinctTargets.map((target) => { return { text: target, value: target }; }),
        key: 'target',
        onFilter: (value, record) => record.target.indexOf(value) === 0,
        render: (_, record) => {
          return (
            <Button type='text' onClick={() => {
              this.setState({ selectedItem: record });
              this.handleInfoModal();
            }}>{record.target}</Button>
          );
        },
        sorter: (a, b) => a.target.toLowerCase().localeCompare(b.target.toLowerCase()),
        width: '130px'
      },
      {
        align: 'center',
        title: 'Responsável',
        dataIndex: 'userUsername',
        filterIcon: <i className='fa-duotone fa-filters'></i>,
        filters: distinctUsers.map((username) => { return { text: username, value: username }; }),
        key: 'userUsername',
        onFilter: (value, record) => record.userUsername.indexOf(value) === 0,
        render: (_, record) => {
          return (
            <Button type='text' onClick={() => {
              this.setState({ selectedItem: record });
              this.handleInfoModal();
            }}>{record.userUsername}</Button>
          );
        },
        sorter: (a, b) => a.userUsername.toLowerCase().localeCompare(b.userUsername.toLowerCase()),
        width: '160px'
      },
      {
        title: 'Descrição',
        dataIndex: 'description',
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
          <div style={{ padding: 8 }}>
            <Input
              placeholder='Pesquisar descrição'
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => {
                confirm();
                this.setState({ descriptionSearch: selectedKeys[0] });
              }}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type='primary'
                onClick={() => {
                  confirm();
                  this.setState({ descriptionSearch: selectedKeys[0] });
                }}
                icon={<i className='fa-regular fa-magnifying-glass'></i>}
                size='small'
                style={{ width: 100 }}
              >Pesquisar</Button>
              <Button
                onClick={() => {
                  clearFilters();
                  this.setState({ descriptionSearch: '' });
                }}
                size='small'
                style={{ width: 60 }}
              >Limpar</Button>
              <Button
                type='link'
                size='small'
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({ descriptionSearch: selectedKeys[0] });
                }}
              >Filtrar</Button>
              <Button
                type='link'
                size='small'
                onClick={() => close()}
              >Fechar</Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered) => (
          <i className='fa-regular fa-magnifying-glass' style={{ color: filtered ? styles.COLORS.PrimaryColor : undefined }}></i>
        ),
        key: 'description',
        onFilter: (value, record) => record.description.toLowerCase().includes((value).toLowerCase()),
        render: (_, record) => <span>{record.description}</span>,
        width: '300px'
      },
      {
        align: 'center',
        dataIndex: 'date',
        key: 'date',
        render: (value, record) => {
          return (
            <Button type='text' onClick={() => {
              this.setState({ selectedItem: record });
              this.handleInfoModal();
            }}>{value ? value.add(-1, 'h').format('DD/MM/YYYY HH:mm') : '-'}</Button>
          );
        },
        sorter: (a, b) => {
          const dateA = a.date;
          const dateB = b.date;
          return dateA ? ( dateB ? (dateA.isAfter(dateB) ? 1 : dateA.isSame(dateB) ? 0 : -1) : 1) : -1;
        },
        title: 'Data',
        width: '150px',
      },
    ];

    return (
      <div className='cms-menu-actions-container'>
        <div></div>
        {/* Tables */}
        <div id='cms-menu-actions-tabs'>
          <div className='cms-menu-actions-tabs-item'>
            {/* Data table */}
            <Table
              bordered
              columns={columns}
              dataSource={logs}
              loading={loading}
              rowKey={(record) => record.key}
              pagination={{ position: ['bottomRight', 'topRight'],size: 'default' }}
              scroll={{ x: true }}
              size='small'
            />                  
          </div>
        </div>

        {/* Log Info Modal */}
        { selectedItem &&
          <Modal
            closable
            destroyOnClose
            footer={[
              <Button key='back' onClick={this.handleInfoModal}>Voltar</Button>
            ]}
            keyboard
            onCancel={this.handleInfoModal}
            open={showMoreInfoModal}
            title={<h5 style={{marginTop: 0}}>Detalhe da Atividade</h5>}
          >
            <Descriptions bordered column={1} size='small'>
              <Descriptions.Item label='Tipo de Registo'>{selectedItem.logTypeName}</Descriptions.Item>
              <Descriptions.Item label='Ação'>{selectedItem.action}</Descriptions.Item>
              <Descriptions.Item label='Tabela'>{selectedItem.target}</Descriptions.Item>
              <Descriptions.Item label='Instância'>{selectedItem.instance}</Descriptions.Item>
              <Descriptions.Item label='Descrição'>{selectedItem.description}</Descriptions.Item>
              <Descriptions.Item label='Data'>{selectedItem.date.subtract(1, 'h').format('DD/MM/YYYY HH:mm')}</Descriptions.Item>
              <Descriptions.Item label='Responsável'>
                <div>{selectedItem.userName} ({selectedItem.userUsername})</div>
                <div>{selectedItem.userEmail}</div>
                <div>{selectedItem.userRoleName}</div>
              </Descriptions.Item>
            </Descriptions>
          </Modal>
        }
      </div>
    );
  }
}

/**
 * Used in the Redux pattern to reflect any updates to the Redux store and merge them into props in the current
 * component. The Redux store serves as a centralized place for the state to live in the application.
 * 
 * @param {any} state - Centralized state of the application.
 * @returns {any} the state of the application as the component props.
 */
const mapPropsToState = (state) => {
  return {};
};

/**
 * Used in the Redux pattern to dispatch actions to the Redux store, triggering a state change.
 * 
 * @param {any} dispatch - function of the Redux store.
 * @returns {any} the dispatch functions as components props.
 */
const mapDispatchToState = (dispatch) => {
  return {};
};

export default withRouter(connect(mapPropsToState, mapDispatchToState)(ManageLogs));