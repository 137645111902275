import React from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { withRouter } from '../../utils';
import { Button, Drawer, Form, Input, InputNumber, Popconfirm, Table, Tag, notification } from 'antd';
import { PuffLoader } from 'react-spinners';
import { styles } from '../../styles';
// import no_photo from '../../images/no_photo.png';

/**
 * ManageSocialNetworks component represents the page template that allows to manage the application social networks.
 * It displays a table with all social networks.
 * It allows tools to create, edit and delete social networks.
 */
class ManageSocialNetworks extends React.Component {
  /**
   * The constructor for a React component is called before it is mounted.
   * When implementing the constructor, `super(props)` must be called before any
   * other statement. Otherwise, this.props will be undefined in the constructor,
   * which can lead to bugs.
   *
   * A React constructor is only used for two purposes:
   * - Initializing local state by assigning an object to this.state.
   * - Binding event handler methods to an instance.
   *
   * Instead of calling `setState()` in the `constructor(), the initial state must be
   * directly assigned to `this.state` in the constructor
   *
   * @param {*} props - Arbitrary component inputs.
   */
  constructor() {
    super();
    this.state = {
      addError: null,
      addFetching: false,
      editError: null,
      editFetching: false,
      loading: false,
      pageWidth: 0,
      selectedItem: null,
      showAddSocialNetwork: false,
      showEditSocialNetwork: false,
      socialNetworks: [],
      socialNetworksError: null,
    };
  }

  /**
   * Allows to execute the React code when the component is already placed in the DOM (Document Object Model).
   * This method is called during the Mounting phase of the React Life-cycle i.e after the component is rendered.
   */
  componentDidMount() {
    // Get data
    this.fetchData();
    // Scroll to top
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Add event on resize
    this.setState({ pageWidth: window.innerWidth });
    window.addEventListener('resize', () => this.setState({ pageWidth: window.innerWidth }));
  }

  /**
   * Sets the array of social networks to manage. 
   * 
   * @param {any[]} data - Original data of social networks. 
   * @returns {any[]} the modified dataset of social networks.
   */
  setSocialNetworks = (data) => {
    return data.map((socialNetwork) => {
      return {
        key: socialNetwork.idSocialNetwork,
        name: socialNetwork.name,
        url: socialNetwork.url,
        order: socialNetwork.order,
        activationDate: socialNetwork.activationDate ? dayjs(socialNetwork.activationDate) : null,
        deactivationDate: socialNetwork.deactivationDate ? dayjs(socialNetwork.deactivationDate) : null,
        isActive: this.socialNetworkIsActive(socialNetwork),
      };
    });
  };

  /**
   * Checks if a social network is active, analysing its activation and deactivation dates and comparing it to the
   * actual date.
   * 
   * @param {any} record - Social network object.
   * @returns {boolean} - true if social network is active and false otherwise.
   */
  socialNetworkIsActive = (record) => {
    const activation = record.activationDate ? dayjs(record.activationDate).subtract(1, 'h') : undefined;
    const deactivation = record.deactivationDate ? dayjs(record.deactivationDate).subtract(1, 'h') : undefined;
    const now = dayjs();
    return activation && (activation.isBefore(now) || activation.isSame(now)) &&
      (!deactivation || (deactivation && deactivation.isAfter(now))) ? true : false;
  };

  /**
   * Fetches all social network from database through an available API.
   * Calls `setSocialNetworks` to modify the data, regarding this component.
   */
  fetchData = () => {
    this.setLoading(true);
    const { selectedItem } = this.state;
    // Get options to fetch
    const fetchOptions = {
      method: 'GET',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      })
    };
    // Fetch data
    fetch(`${process.env.REACT_APP_API_BASE_URL}/socialNetworks?order=order`, fetchOptions).then(async (response) => {
      if (response.ok) return response.json();
      const result = await response.json();
      throw new Error(result ? JSON.stringify(result) : 'Não é possível obter as redes sociais.');
    }).then(async (data) => {
      // await (new Promise((res) => {setTimeout(res, 5000); }));
      const socialNetworks = this.setSocialNetworks(data);
      this.setState({ socialNetworks: socialNetworks, socialNetworksError: null });
      if (selectedItem) this.setState({ selectedItem: socialNetworks.find((socialNetwork) => socialNetwork.key === selectedItem.key) });
      this.setLoading(false);
    }).catch((error) => {
      this.setState({ socialNetworks: [], socialNetworksError: error.message, selectedItem: null });
      this.setLoading(false);
    });
  };

  /**
   * Sets loading state from `this.state.loading`.
   * This state is relationated with the data fetching from database.
   *
   * @param {boolean} loading - Sets the loading state to true or false.
   */
  setLoading = (loading) => {
    this.setState({ loading: loading });
  };

  /**
   * Handles the visibility state of the add group drawer, using the state `this.state.showAddSocialNetwork`.
   */
  handleAddSocialNetworkDrawer = () => {
    const { showAddSocialNetwork } = this.state;
    this.setState({ showAddSocialNetwork: !showAddSocialNetwork });
  };

  /**
   * Adds a new social network to the system, using the API for the effect.
   *
   * @param {any} values - New social network data.
   */
  addSocialNetwork = (values) => {
    this.setState({ addFetching: true });
    // Get body to fetch 
    const raw = JSON.stringify({
      name: values.name,
      url: values.url,
      order: values.order
    });
    // Get options to fetch
    const fetchOptions = {
      method: 'POST',
      body: raw,
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      }),
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/socialNetworks`, fetchOptions).then(async (response) => {
      if (response.ok) return response.json();
      const result = await response.json();
      throw new Error(result ? JSON.stringify(result) : 'Não é possível adicionar uma nova rede social');
    }).then(async (data) => {
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check'></i>
            <span className='cms-notification-icon-text-span'>Rede social &ldquo;{data.socialNetwork.name}&rdquo; adicionada com sucesso</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
            <span>A rede social foi adicionada com sucesso. Consulte-a na lista de rede sociais e no rodapé da aplicação.</span>
          </div>
        ),
      });
      setTimeout(() => {
        this.setState({ addError: null, addFetching: false });
        this.handleAddSocialNetworkDrawer();
        this.fetchData();
      }, 1500);
    }).catch((error) => {
      let message = 'Não é possível adicionar uma nova rede social';
      let extendedMessage = 'Por favor, reveja os dados inseridos e/ou tente novamente.';
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'></i>
            <span className='cms-notification-icon-text-span'>{message}</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark' style={{ visibility: 'hidden' }}></i>
            <span>{extendedMessage}</span>
          </div>
        ),
      });
      this.setState({ addError: message, addFetching: false });
    });
  };

  /**
   * Handles the visibility state of the edit category modal, using the state `this.state.showEditSocialNetwork`.
   */
  handleEditSocialNetworkDrawer = () => {
    const { showEditSocialNetwork } = this.state;
    this.setState({ showEditSocialNetwork: !showEditSocialNetwork });
  };

  /**
   * Edits an existing social network in the system, using the API for the effect.
   *
   * @param {any} values - New data of the existing social network.
   */
  editSocialNetwork = (values) => {
    this.setState({ editFetching: true });
    const { selectedItem } = this.state;
    if (selectedItem) {
      // Get body to fetch 
      const raw = {};
      if (values.name) raw.name = values.name;
      if (values.url) raw.url = values.url;
      if (values.order) raw.order = values.order;
      // Get options to fetch
      const fetchOptions = {
        method: 'PATCH',
        body: JSON.stringify(raw),
        headers: new Headers({
          'app_secret_key': process.env.REACT_APP_API_APP_KEY,
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${window.localStorage.getItem('token')}`
        }),
      };
      // Fetch request
      fetch(`${process.env.REACT_APP_API_BASE_URL}/socialNetworks/${selectedItem.key}`, fetchOptions).then(async (response) => {
        if (response.ok) return response.json();
        const result = await response.json();
        throw new Error(result ? JSON.stringify(result) : `Não é possível editar a rede social '${selectedItem.name}'`);
      }).then(async (data) => {
        notification.open({
          duration: 30,
          message: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-check'></i>
              <span className='cms-notification-icon-text-span'>Alterações guardadas com sucesso</span>
            </div>
          ),
          description: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
              <span>A rede social <u>{values.name}</u> foi atualizada com sucesso. Consulte a lista de redes sociais para verificar a alteração.</span>
            </div>
          ),
        });
        setTimeout(() => {
          this.setState({ editError: null, editFetching: false });
          this.handleEditSocialNetworkDrawer();
          this.fetchData();
        }, 1500);
      }).catch((error) => {
        let message = `Não é possível editar a rede social '${selectedItem.name}'`;
        let extendedMessage = 'Por favor, reveja os dados inseridos e/ou tente novamente.';
        notification.open({
          duration: 30,
          message: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-xmark'></i>
              <span className='cms-notification-icon-text-span'>{message}</span>
            </div>
          ),
          description: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-xmark' style={{ visibility: 'hidden' }}></i>
              <span>{extendedMessage}</span>
            </div>
          ),
        });
        this.setState({ editError: message, editFetching: false });
      });
    }
  };

  /**
   * Deactivates an existing social network in the system, using the API for the effect.
   */
  deactivateSocialNetwork = () => {
    this.setState({ editFetching: true });
    const { selectedItem } = this.state;
    if (selectedItem) {
      // Get options to fetch
      const fetchOptions = {
        method: 'PATCH',
        headers: new Headers({
          'app_secret_key': process.env.REACT_APP_API_APP_KEY,
          'Authorization': `Bearer ${window.localStorage.getItem('token')}`
        }),
      };
      // Fetch request
      fetch(`${process.env.REACT_APP_API_BASE_URL}/socialNetworks/${selectedItem.key}/deactivate`, fetchOptions).then(async (response) => {
        if (response.ok) return response.json();
        const result = await response.json();
        throw new Error(result ? JSON.stringify(result) : `Não é possível desativar a rede social '${selectedItem.name}'`);
      }).then(async (data) => {
        notification.open({
          duration: 30,
          message: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-check'></i>
              <span className='cms-notification-icon-text-span'>Rede social desativada com sucesso</span>
            </div>
          ),
          description: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
              <span>A rede social <u>{selectedItem.name}</u> foi desativada com sucesso.
                Consulte a lista de redes sociais para verificar a alteração. A rede social pode ser reativada através
                do mesmo processo de desativação.</span>
            </div>
          ),
        });
        setTimeout(() => {
          this.setState({ editFetching: false });
          this.handleEditSocialNetworkDrawer();
          this.fetchData();
        }, 1500);
      }).catch((error) => {
        let message = `Não é possível desativar a rede social '${selectedItem.name}'`;
        let extendedMessage = 'Por favor, tente novamente mais tarde. Se o erro persistir, contacte o administrador da aplicação.';
        if (error && error.message) {
          if (error.message.includes('Your token is not valid!')) {
            extendedMessage = 'Não tem autorização para desativar redes sociais. Por favor, contacte o administrador da aplicação.';
          }
          if (error.message.includes('You have no permission to access this resource.')) {
            extendedMessage = 'Não tem autorização para desativar redes sociais. Por favor, contacte o administrador da aplicação.';
          }
        }
        notification.open({
          duration: 30,
          message: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-xmark'></i>
              <span className='cms-notification-icon-text-span'>{message}</span>
            </div>
          ),
          description: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-xmark' style={{ visibility: 'hidden' }}></i>
              <span>{extendedMessage}</span>
            </div>
          ),
        });
        this.setState({ editFetching: false });
      });
    }
  };

  /**
   * Reactivates an existing social network in the system, using the API for the effect.
   */
  reactivateSocialNetwork = () => {
    this.setState({ editFetching: true });
    const { selectedItem } = this.state;
    if (selectedItem) {
      // Get options to fetch
      const fetchOptions = {
        method: 'PATCH',
        headers: new Headers({
          'app_secret_key': process.env.REACT_APP_API_APP_KEY,
          'Authorization': `Bearer ${window.localStorage.getItem('token')}`
        }),
      };
      // Fetch request
      fetch(`${process.env.REACT_APP_API_BASE_URL}/socialNetworks/${selectedItem.key}/reactivate`, fetchOptions).then(async (response) => {
        if (response.ok) return response.json();
        const result = await response.json();
        throw new Error(result ? JSON.stringify(result) : `Não é possível reativar a rede social '${selectedItem.name}'`);
      }).then(async (data) => {
        notification.open({
          duration: 30,
          message: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-check'></i>
              <span className='cms-notification-icon-text-span'>Rede social reativada com sucesso</span>
            </div>
          ),
          description: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
              <span>A rede social <u>{selectedItem.name}</u> foi reativada com sucesso.
                Consulte a lista de redes sociais para verificar a alteração.
                A rede social pode ser desativada através do mesmo processo de reativação.</span>
            </div>
          ),
        });
        setTimeout(() => {
          this.setState({ editFetching: false });
          this.handleEditSocialNetworkDrawer();
          this.fetchData();
        }, 1500);
      }).catch((error) => {
        let message = `Não é possível reativar a rede social '${selectedItem.name}'`;
        let extendedMessage = 'Por favor, tente novamente mais tarde. Se o erro persistir, contacte o administrador da aplicação.';
        notification.open({
          duration: 30,
          message: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-xmark'></i>
              <span className='cms-notification-icon-text-span'>{message}</span>
            </div>
          ),
          description: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-xmark' style={{ visibility: 'hidden' }}></i>
              <span>{extendedMessage}</span>
            </div>
          ),
        });
        this.setState({ editFetching: false });
      });
    }
  };

  /**
   * Deletes an existing social network from the system, using the API for the effect.
   */
  deleteSocialNetwork = () => {
    this.setState({ editFetching: true });
    const { selectedItem } = this.state;
    if (selectedItem) {
      // Get options to fetch
      const fetchOptions = {
        method: 'DELETE',
        headers: new Headers({
          'app_secret_key': process.env.REACT_APP_API_APP_KEY,
          'Authorization': `Bearer ${window.localStorage.getItem('token')}`
        }),
      };
      // Fetch request
      fetch(`${process.env.REACT_APP_API_BASE_URL}/socialNetworks/${selectedItem.key}`, fetchOptions).then(async (response) => {
        if (response.ok) return response.json();
        const result = await response.json();
        throw new Error(result ? JSON.stringify(result) : `Não é possível apagar a rede social '${selectedItem.name}'`);
      }).then(async (data) => {
        notification.open({
          duration: 30,
          message: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-check'></i>
              <span className='cms-notification-icon-text-span'>Rede social eliminada com sucesso</span>
            </div>
          ),
          description: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
              <span>A rede social <u>{selectedItem.name}</u> foi eliminada com sucesso. Consulte a lista de redes sociais
                para verificar a atualização da mesma. Esta ação <b>não é reversível</b>.</span>
            </div>
          ),
        });
        setTimeout(() => {
          this.setState({ editFetching: false });
          this.handleEditSocialNetworkDrawer();
          this.fetchData();
        }, 1500);
      }).catch((error) => {
        let message = `Não é possível eliminar a rede social '${selectedItem.name}'`;
        let extendedMessage = 'Por favor, tente novamente mais tarde. Se o erro persistir, contacte o administrador da aplicação.';
        notification.open({
          duration: 30,
          message: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-xmark'></i>
              <span className='cms-notification-icon-text-span'>{message}</span>
            </div>
          ),
          description: (
            <div className='cms-notification-icon-text'>
              <i className='fa-solid fa-circle-xmark' style={{ visibility: 'hidden' }}></i>
              <span>{extendedMessage}</span>
            </div>
          ),
        });
        this.setState({ editFetching: false });
      });
    }
  };

  /**
   * Render method of React component.
   * 
   * @returns the component template.
   */
  render() {
    const {
      addError, addFetching, socialNetworks, socialNetworksError, editError, editFetching,
      loading, pageWidth, selectedItem, showAddSocialNetwork, showEditSocialNetwork
    } = this.state;

    const isActive = localStorage.getItem('isActive');
    const idRole = localStorage.getItem('idRole');
    const role = localStorage.getItem('role');
    const isAdmin = isActive === 'true' && idRole === '376DDE5B-09C4-ED11-A311-00155D08E85F' && role === 'Administrator';
    const isManager = isActive === 'true' && idRole === '386DDE5B-09C4-ED11-A311-00155D08E85F' && role === 'Manager';

    if (isActive !== 'true' || !(isAdmin || isManager) || socialNetworksError) {
      return (
        <div className='cms-menu-actions-container'>
          <div id='cms-menu-actions-tabs'>
            <div id='cms-menu-actions-error'>
              <i className='fa-duotone fa-arrow-rotate-right'></i>
              <b>Ocorreu um erro...</b>
              <Button type='text' onClick={() => window.location.reload(true)}>
                Clique&nbsp;<span className='cms-menu-actions-error-btn'>aqui</span>&nbsp;para refrescar a página.
              </Button>
              Ou tente mais tarde.
            </div>
          </div>
        </div>
      );
    }

    if (loading) {
      return (
        <div className='cms-menu-actions-container'>
          <div id='cms-menu-actions-error'>
            <PuffLoader color={styles.COLORS.PrimaryColor} size={100} />
            <p>A carregar...</p>
          </div>
        </div>
      );
    }

    const columns = [
      {
        align: 'center',
        dataIndex: 'logotype',
        key: 'logotype',
        render: (_, record) => {
          let icon = '';
          switch (record.name.toLowerCase()) {
          case 'facebook':
            icon = 'fa-brands fa-facebook';
            break;
          case 'instagram':
            icon = 'fa-brands fa-instagram';
            break;
          case 'linkedin':
            icon = 'fa-brands fa-linkedin';
            break;
          case 'youtube':
            icon = 'fa-brands fa-youtube';
            break;
          case 'twitter':
            icon = 'fa-brands fa-twitter';
            break;
          case 'spotify':
            icon = 'fa-brands fa-spotify';
            break;
          default:
            break;
          }
          return (
            <Button
              onClick={() => {
                const newSelectedItem = !selectedItem || (selectedItem && selectedItem.key !== record.key) ? record : null;
                this.setState({ selectedItem: newSelectedItem });
              }}
              icon={<i className={`${icon} social-network-icon`}></i>}
              type='text'
              size='large'
            />
          );
        },
        title: 'Logótipo',
        width: '100px',
      },
      {
        title: 'Nome',
        dataIndex: 'name',
        key: 'name',
        render: (_, record) => {
          const style = {};
          const isActive = record.isActive;
          if (!isActive) style.color = styles.COLORS.TertiaryTextColor;
          return (
            <>
              <Button
                onClick={() => {
                  const newSelectedItem = !selectedItem || (selectedItem && selectedItem.key !== record.key) ? record : null;
                  this.setState({ selectedItem: newSelectedItem });
                }}
                title={`${record.name} ${isActive ? '' : ' (Desativada)'}`}
                type='text'
              >
                {record.name}
              </Button>
              {!isActive && <Tag color={styles.COLORS.QuaternaryTextColor}>Desativada</Tag>}
            </>
          );
        },
        sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        width: pageWidth > 1000 ? 'auto' : '200px'
      },
      {
        dataIndex: 'url',
        key: 'url',
        render: (_, record) => {
          const style = {};
          if (!record.isActive) style.color = styles.COLORS.TertiaryTextColor;
          return <span style={style}>{record.url}</span>;
        },
        title: 'URL',
        width: '400px',
      },
      {
        align: 'center',
        dataIndex: 'order',
        key: 'order',
        render: (_, record) => {
          const style = {};
          if (!record.isActive) style.color = styles.COLORS.TertiaryTextColor;
          return <span style={style}>{record.order}</span>;
        },
        sorter: (a, b) => a.order > b.order ? 1 : a.order === b.order ? 0 : -1,
        title: 'Ordem',
        width: '120px',
      },
      {
        align: 'center',
        dataIndex: 'activationDate',
        key: 'activationDate',
        render: (value, record) => {
          const style = {};
          if (!record.isActive) style.color = styles.COLORS.QuaternaryTextColor;
          return <span style={style}>{value ? value.add(-1, 'h').format('DD/MM/YYYY HH:mm') : '-'}</span>;
        },
        sorter: (a, b) => {
          const dateA = a.activationDate;
          const dateB = b.activationDate;
          return dateA ? (dateB ? (dateA.isAfter(dateB) ? 1 : dateA.isSame(dateB) ? 0 : -1) : 1) : -1;
        },
        title: 'Data de ativação',
        width: '152px',
      },
      {
        align: 'center',
        dataIndex: 'deactivationDate',
        key: 'deactivationDate',
        render: (value, record) => {
          const style = {};
          if (!record.isActive) style.color = styles.COLORS.QuaternaryTextColor;
          return <span style={style}>{value ? value.add(-1, 'h').format('DD/MM/YYYY HH:mm') : '-'}</span>;
        },
        title: 'Data de fim',
        width: '160px',
      },
    ];

    return (
      <div className='cms-menu-actions-container'>
        {/* Actions */}
        <div className='cms-menu-actions-btns'>
          <Button
            type='primary'
            icon={<i className='fa-solid fa-plus'></i>}
            onClick={this.handleAddSocialNetworkDrawer}
          >Adicionar Rede Social</Button>

          {selectedItem &&
            <Button
              type='default'
              disabled={selectedItem ? false : true}
              icon={<i className='fa-duotone fa-pen-to-square'></i>}
              onClick={this.handleEditSocialNetworkDrawer}
            >Editar</Button>
          }

          {selectedItem && selectedItem.isActive ?
            <Popconfirm
              title='&nbsp;Desativar rede social'
              description={
                <>
                  <div>Tem a certeza que pretende desativar esta rede social?</div>
                  <div>Esta ação <b>é reversível</b>.</div>
                </>
              }
              okText='Desativar'
              okButtonProps={{ className: 'vantagens-warning-button' }}
              okType='default'
              cancelText='Cancelar'
              onConfirm={this.deactivateSocialNetwork}
            >
              <Button className='vantagens-warning-button' disabled={editFetching} type='default'>Desativar</Button>
            </Popconfirm>
            : selectedItem ?
              <Popconfirm
                title='&nbsp;Reativar rede social'
                description={
                  <>
                    <div>Tem a certeza que pretende reativar esta rede social?</div>
                    <div>Esta ação <b>é reversível</b>.</div>
                  </>
                }
                okText='Reativar'
                okButtonProps={{ className: 'vantagens-warning-button' }}
                okType='default'
                cancelText='Cancelar'
                onConfirm={this.reactivateSocialNetwork}
              >
                <Button className='vantagens-warning-button' disabled={editFetching} type='default'>Reativar</Button>
              </Popconfirm>
              : null
          }

          {selectedItem &&
            <Popconfirm
              title='&nbsp;&nbsp;Eliminar rede social'
              description={
                <>
                  <div>Tem a certeza que pretende eliminar esta rede social?</div>
                  <div>Esta ação <b>não é reversível</b>.</div>
                </>
              }
              // disabled={selectedItem ? true : false}
              okText='Eliminar'
              okButtonProps={{ danger: true }}
              okType='primary'
              cancelText='Cancelar'
              onConfirm={this.deleteSocialNetwork}
              icon={<i className='fa-duotone fa-circle-exclamation' style={{ color: styles.COLORS.ErrorColor }}></i>}
            >
              <Button danger={true} disabled={editFetching} type='default'>Eliminar</Button>
            </Popconfirm>
          }
        </div>

        {/* Tables */}
        <div id='cms-menu-actions-tabs'>
          <div className='cms-menu-actions-tabs-item'>
            {/* Data table */}
            <Table
              bordered
              columns={columns}
              dataSource={socialNetworks}
              loading={loading}
              pagination={{ size: 'default' }}
              rowKey={(record) => record.key}
              rowSelection={{
                onChange: (selectedRowKeys, selectedRows) => {
                  this.setState({ selectedItem: selectedRows.length > 0 ? selectedRows[selectedRows.length - 1] : null });
                },
                selectedRowKeys: selectedItem ? [selectedItem.key] : [],
                type: 'checkbox',
                hideSelectAll: true,
              }}
              scroll={{ x: 240 }}
              size='small'
            />
          </div>
        </div>

        {/* Add new social network */}
        <Drawer
          className='cms-menu-add-drawer'
          destroyOnClose
          extra={
            <div className='cms-menu-add-drawer-header'>
              {/* Title */}
              <div>Adicionar uma nova rede social</div>
              {/* Close btn */}
              <Button
                id='hamburger-menu-close'
                icon={<i className='fa-light fa-xmark'></i>}
                onClick={this.handleAddSocialNetworkDrawer}
                type='text'
              />
            </div>
          }
          open={showAddSocialNetwork}
          onClose={this.handleAddSocialNetworkDrawer}
          placement='right'
          size='large'
          title='Adicionar uma nova rede social'
          width={window.innerWidth < 736 ? window.innerWidth : 736}
        >
          <div></div>
          <Form
            autoComplete='on'
            initialValues={{ remember: true }}
            layout='vertical'
            name='Adicionar rede social'
            onFinish={this.addSocialNetwork}
          >
            <div>
              {/* Name */}
              <Form.Item
                hasFeedback
                label='Nome'
                name='name'
                rules={[
                  { required: true, message: 'Por favor, introduza um nome.' },
                  { max: 100, message: 'Por favor, introduza um nome com um máximo de 100 caracteres.' },
                  { min: 3, message: 'Por favor, introduza um nome com mais de 3 caracteres.' }
                ]}
              >
                <Input allowClear maxLength={100} minLength={3} placeholder='Introduzir o nome da rede social' type='text' />
              </Form.Item>

              {/* Redirect to URL */}
              <Form.Item
                hasFeedback
                label='Url'
                name='url'
                rules={[{ required: true, message: 'Por favor, introduza um url.' }, { type: 'url', message: 'Por favor, introduza um url válido.' },]}
                tooltip='Página da rede social, para a qual o utilizador é reencaminhado ao clicar.'
              >
                <Input allowClear name='url' placeholder='Introduzir o url da rede social.' type='url' />
              </Form.Item>

              {/* Ordem */}
              <Form.Item
                hasFeedback
                label='Ordem'
                name='order'
                rules={[{ required: true, message: 'Por favor, introduza um valor de ordenação.' }]}
              >
                <InputNumber placeholder='Introduzir a ordem de apresentação da nova rede social' min={1} style={{ width: '100%' }} />
              </Form.Item>
            </div>
            <br></br>
            {/* Buttons */}
            <Form.Item style={{ width: '100%' }}>
              <div className='cms-add-modal-btns'>
                <Button type='default' onClick={this.handleAddSocialNetworkDrawer}>Cancelar</Button>
                <Button
                  type={addError ? 'default' : 'primary'}
                  danger={addError ? true : false}
                  htmlType='submit'
                  disabled={addFetching}>{addError ? 'Tentar novamente' : addFetching ? 'A carregar...' : 'Adicionar'}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Drawer>

        {/* Edit group */}
        {selectedItem &&
          <Drawer
            className='cms-menu-add-drawer'
            destroyOnClose
            extra={
              <div className='cms-menu-add-drawer-header'>
                {/* Title */}
                <div>Editar rede social {selectedItem.name}</div>
                {/* Close btn */}
                <Button
                  id='hamburger-menu-close'
                  icon={<i className='fa-light fa-xmark'></i>}
                  onClick={this.handleEditSocialNetworkDrawer}
                  type='text'
                />
              </div>
            }
            open={showEditSocialNetwork}
            onClose={this.handleEditSocialNetworkDrawer}
            placement='right'
            size='large'
            title='Editar rede social'
            width={window.innerWidth < 736 ? window.innerWidth : 736}
          >
            <div></div>
            <Form
              autoComplete='on'
              initialValues={{ remember: true }}
              layout='vertical'
              name='Editar rede social'
              onFinish={this.editSocialNetwork}
            >
              <div>
                {/* Name */}
                <Form.Item
                  hasFeedback
                  initialValue={selectedItem.name}
                  label='Nome'
                  name='name'
                  rules={[
                    { required: true, message: 'Por favor, introduza um nome.' },
                    { max: 100, message: 'Por favor, introduza um nome com um máximo de 100 caracteres.' },
                    { min: 3, message: 'Por favor, introduza um nome com mais de 3 caracteres.' }
                  ]}
                >
                  <Input allowClear maxLength={100} minLength={3} placeholder='Introduzir o nome do grupo' type='text' />
                </Form.Item>

                {/* Redirect to URL */}
                <Form.Item
                  hasFeedback
                  initialValue={selectedItem.url}
                  label='Url'
                  name='url'
                  rules={[{ required: true, message: 'Por favor, introduza um url.' }, { type: 'url', message: 'Por favor, introduza um url válido.' },]}
                  tooltip='Página da rede social, para a qual o utilizador é reencaminhado ao clicar.'
                >
                  <Input allowClear name='url' placeholder='Introduzir o url da rede social.' type='url' />
                </Form.Item>

                {/* Ordem */}
                <Form.Item
                  hasFeedback
                  initialValue={selectedItem.order}
                  label='Ordem'
                  name='order'
                  rules={[{ required: true, message: 'Por favor, introduza um valor de ordenação.' }]}
                >
                  <InputNumber placeholder='Introduzir a ordem de apresentação da nova rede social' min={1} style={{ width: '100%' }} />
                </Form.Item>
              </div>
              <br></br>
              {/* Buttons */}
              <Form.Item style={{ width: '100%' }}>
                <div className='cms-add-modal-btns'>
                  <Button type='default' onClick={this.handleEditSocialNetworkDrawer} disabled={editFetching}>Cancelar</Button>
                  <Button
                    type={editError ? 'default' : 'primary'}
                    danger={editError ? true : false}
                    htmlType='submit'
                    disabled={editFetching}
                  >{editError ? 'Tentar novamente' : editFetching ? 'A carregar...' : 'Guardar'}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Drawer>
        }
      </div>
    );
  }
}

/**
 * Used in the Redux pattern to reflect any updates to the Redux store and merge them into props in the current
 * component. The Redux store serves as a centralized place for the state to live in the application.
 * 
 * @param {any} state - Centralized state of the application.
 * @returns {any} the state of the application as the component props.
 */
const mapPropsToState = (state) => {
  return {};
};

/**
 * Used in the Redux pattern to dispatch actions to the Redux store, triggering a state change.
 * 
 * @param {any} dispatch - function of the Redux store.
 * @returns {any} the dispatch functions as components props.
 */
const mapDispatchToState = (dispatch) => {
  return {};
};

export default withRouter(connect(mapPropsToState, mapDispatchToState)(ManageSocialNetworks));