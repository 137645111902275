import React from 'react';
import { store } from './store';
import { Provider } from 'react-redux';
import { Routes, Route, HashRouter } from 'react-router-dom';
import { 
  Accessibility, Advantages, Home, Layout, LayoutCMS, Login, 
  ManageAdvantages, ManageBanners, ManageCampaigns, ManageCategories, 
  ManageConfigurations, ManageGroups, ManageLogs, ManageMenus, 
  ManageSocialNetworks, ManageUsers, NotFound
} from './pages';
import { MenuActions } from './containers';

/**
 * Application template.
 */
export default class App extends React.Component {
  /**
   * Render method of React component.
   * 
   * @returns the component template.
   */
  render() {
    return (
      <Provider store={store}>
        <HashRouter>
          <Routes>
            <Route path='/' element={<Layout />}>
              <Route index element={<Home />} />
              <Route path='/vantagens' element={<Advantages />} />
              <Route path='/declaracao-acessibilidade' element={<Accessibility />} />
            </Route>
            <Route path='/cms' element={<LayoutCMS />}>
              <Route index element={<MenuActions />} />
              <Route path='/cms/atividade' element={<ManageLogs />} />
              <Route path='/cms/banners' element={<ManageBanners />} />
              <Route path='/cms/campanhas' element={<ManageCampaigns />} />
              <Route path='/cms/categorias' element={<ManageCategories />} />
              <Route path='/cms/configuracoes' element={<ManageConfigurations />} />
              <Route path='/cms/grupos' element={<ManageGroups />} />
              <Route path='/cms/menus' element={<ManageMenus />} />
              <Route path='/cms/redes-sociais' element={<ManageSocialNetworks />} />
              <Route path='/cms/utilizadores' element={<ManageUsers />} />
              <Route path='/cms/vantagens' element={<ManageAdvantages />} />
              
              {/* TODO: */}
              {/* <Route path='/cms/paginas' element={<ManagePages />} /> */}

            </Route>
            <Route path='/login' element={<Login />} />

            {/* TODO: Páginas dinâmicas */}

            <Route path='*' element={<NotFound />} />
          </Routes>
        </HashRouter>
      </Provider>
    );
  }
}