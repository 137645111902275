import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../utils';
import { getConfigurations } from '../../reducers/Configurations';
import { Categories, Counters, Highlights } from '../../containers';
import { Slideshow } from '../../components';
import { Collapse, Skeleton } from 'antd';
import './index.scss';

const { Panel } = Collapse;

/**
 * Home component represents the homepage template of the application. This includes:
 * - a ```slideshow```
 * - a list of ```categories```
 * - a list of analytic ```counters```
 */
class Home extends React.Component {
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.props.getConfigurations();
  }

  render() {
    const { configurations, configurationsError, configurationsFetching } = this.props;

    const apresentation = configurations.find((configuration) => configuration.name === 'Texto "Sobre as Vantagens" (PT)');
    const beneficiaries = configurations.find((configuration) => configuration.name === 'Texto "Beneficiários" (PT)');
    const partners = configurations.find((configuration) => configuration.name === 'Texto "Ser parceiro" (PT)');

    return (
      <div>
        {/* Slideshow */}
        <Slideshow />
        {/* Content */}
        <div id='homepage-content-wrap'>
          {/* Highlights */}
          <Highlights />
          
          {/* Categories */}
          <Categories />

          {/* Counters */}
          <Counters  /> 

          {/* Advantagens */}
          {configurationsError ? null : 
            <div className='homepage-description-wrapper'>
              {configurationsFetching ? <Skeleton active /> : 
                <>
                  <h1 className='homepage-description-title'>Sobre as Vantagens</h1>
                  <div className='homepage-description-content'>
                    { apresentation && 
                      <Collapse className='homepage-accordion' defaultActiveKey={[]}>
                        <Panel header='Sobre as Vantagens' key={apresentation.idConfiguration}>
                          <div dangerouslySetInnerHTML={{ __html: apresentation.value }}></div>
                        </Panel>
                      </Collapse>
                    }
                    { beneficiaries && 
                      <Collapse className='homepage-accordion' defaultActiveKey={[]}>
                        <Panel header='Beneficiários' key={beneficiaries.idConfiguration}>
                          <div dangerouslySetInnerHTML={{ __html: beneficiaries.value }}></div>
                        </Panel>
                      </Collapse>
                    }
                    { partners && 
                      <Collapse className='homepage-accordion' defaultActiveKey={[]}>
                        <Panel header='Ser parceiro' key={partners.idConfiguration}>
                          <div dangerouslySetInnerHTML={{ __html: partners.value }}></div>
                        </Panel>
                      </Collapse>
                    }
                  </div>
                </>
              }
            </div>
          }
        </div>
      </div>
    );
  }
}

/**
 * Used in the Redux pattern to reflect any updates to the Redux store and merge them into props in the current
 * component. The Redux store serves as a centralized place for the state to live in the application.
 * 
 * @param {any} state - Centralized state of the application.
 * @returns {any} the state of the application as the component props.
 */
const mapPropsToState = (state) => {
  const configurations = state.configurations;

  return {
    configurations: configurations.data,
    configurationsError: configurations.error,
    configurationsFetching: configurations.fetching,
  };
};

/**
 * Used in the Redux pattern to dispatch actions to the Redux store, triggering a state change.
 * 
 * @param {any} dispatch - function of the Redux store.
 * @returns {any} the dispatch functions as components props.
 */
const mapDispatchToState = (dispatch) => {
  return {
    getConfigurations: () => dispatch(getConfigurations()),
  };
};

export default withRouter(connect(mapPropsToState, mapDispatchToState)(Home));