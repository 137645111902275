import { COLORS } from './colors';
import { FONTS } from './fonts';
import { SPACING } from './spacing';

/**
 * Application default styles, to create consistency.
 */
export const styles = {
  COLORS: COLORS,
  FONTS: FONTS,
  SPACING: SPACING,
};