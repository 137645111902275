import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from '../../utils';
import { getCategories } from '../../reducers/Categories';
import { getMenuOptions } from '../../reducers/MenuOptions';
import { Button, Drawer, Input, Menu, Skeleton } from 'antd';
import { Search } from '../Icons';
import LanguageSelect from '../LanguageSelect';


/**
 * MobileMenuOptions class represents the required template to display a simple mobile menu.
 */
class MobileMenuOptions extends React.Component {
  /**
   * The constructor for a React component is called before it is mounted.
   * When implementing the constructor, `super(props)` must be called before any
   * other statement. Otherwise, this.props will be undefined in the constructor,
   * which can lead to bugs.
   *
   * A React constructor is only used for two purposes:
   * - Initializing local state by assigning an object to this.state.
   * - Binding event handler methods to an instance.
   *
   * Instead of calling `setState()` in the `constructor(), the initial state must be
   * directly assigned to `this.state` in the constructor
   *
   * @param {*} props - Arbitrary component inputs.
   */
  constructor(props) {
    super(props);
    this.state = {
      searchValue: null,
    };
  }

  /**
   * Allows to execute the React code when the component is already placed in the DOM (Document Object Model).
   * This method is called during the Mounting phase of the React Life-cycle i.e after the component is rendered.
   */
  componentDidMount() {
    this.props.getCategories();
    this.props.getMenuOptions();
  }

  /**
   * Transforms a menu option to adapt to the menu drawer.
   *
   * @param {any} menuOption - The object to transform.
   * @returns {any} - The transformed menu option.
   */
  transformMenuOption = (menuOption) => {
    const url = menuOption.url ? menuOption.url : menuOption.page && menuOption.page.url ? menuOption.page.url : '/';
    const newMenuOption = {
      key: menuOption.idMenuOption,
      label: <Link style={{ width: '100%' }} to={`${url.startsWith('/') ? process.env.REACT_APP_PUBLIC_URL : ''}${url}`}>{menuOption.title}</Link>
    };
    if (menuOption.children && menuOption.children.length > 0) {
      newMenuOption.children = menuOption.children.map((child) => {
        return this.transformMenuOption(child);
      });
    }
    return newMenuOption;
  };

  /**
   * Searchs an advantage, redirecting to `/vantagens?search={SEARCH_VALUE}`
   */
  searchAdvantages = () => {
    const { searchValue } = this.state;
    if (searchValue && this.props && this.props.router) {
      this.setState({ searchValue: null });
      this.props.router.navigate(`${process.env.REACT_APP_PUBLIC_URL}/vantagens?pesquisa=${searchValue}`);
      this.props.openMenu(false);
    }
  };

  /**
   * Render method of React component.
   * 
   * @returns the component template.
   */
  render() {
    const { 
      categories, categoriesError, categoriesFetching,
      menuOptions, menuOptionsError, menuOptionsFetching,
      openMenu, openMenuDrawer
    } = this.props;
    if (categoriesError || menuOptionsError) return null;
    if (categoriesFetching || menuOptionsFetching) return <Skeleton.Button active={true} block={true} />;
    if (!categories || !menuOptions) return null;

    const completeMenuOptions = [
      {
        idMenuOption: '1',
        idParent: null,
        title: 'Vantagens',
        url: '/vantagens',
        order: -1,
        numberOfChildren: categories.length,
        children: categories.sort((categoryA, categoryB) => {
          return categoryA.name < categoryB.name ? -1 : 1;
        }).map((category, index) => {
          return {
            idMenuOption: category.idCategory,
            idParent: '1',
            title: category.name,
            url: `/vantagens?categoria=${category.idCategory}`,
            order: index,
            numberOfChildren: 0,
            children:[],
          };
        })
      },
      ...menuOptions,
    ].map(this.transformMenuOption);

    const result = [{ type: 'divider' }];
    completeMenuOptions.forEach((option) => {
      result.push(option);
      result.push({ type: 'divider' });
    });
    
    return (
      <Drawer
        className='hamburger-navigation-menu'
        placement='right'
        width={window.innerWidth < 400 ? window.innerWidth : 400}
        onClose={() => { openMenu(false); }}
        open={openMenuDrawer}
        extra={
          <div id='header-drawer-hamburger-navigation-head'>
            <div>
              {/* Search bar */}
              {window.innerWidth <= 600 &&
                <div id='header-drawer-hamburger-navigation-search-bar'>
                  <Input
                    placeholder='Pesquisar vantagens'
                    suffix={<Button type='text' size='small' icon={<Search />} onClick={this.searchAdvantages}/>}
                    size='middle'
                    type='search'
                    value={this.state.searchValue}
                    onChange={(e) => this.setState({ searchValue: e.target.value })}
                    onPressEnter={this.searchAdvantages}
                  />
                </div> 
              }
              {/* Language */}
              <LanguageSelect />
            </div>
            {/* Close btn */}
            <Button 
              icon={<i className='fa-light fa-xmark'></i>}
              id='hamburger-menu-close'
              onClick={() => { openMenu(false); }}
              type='text'
            />
          </div>
        }
      >

        {/* Menu Items */}
        <Menu
          style={{ width: 256 }}
          defaultSelectedKeys={['73F0A4FC-54E0-ED11-A312-00155D08E85F']}
          defaultOpenKeys={['8B13B61A-0EC4-ED11-A311-00155D08E85F']}
          expandIcon={<i className='fa-sharp fa-regular fa-chevron-down'></i>}
          mode='inline'
          items={result}
        />
      </Drawer>
    );
  }
}

const mapPropsToState = (state) => {
  const menuOptions = state.menuOptions;
  const categories = state.categories;

  return {
    menuOptionsFetching: menuOptions.fetching,
    menuOptionsError: menuOptions.error,
    menuOptions: menuOptions.data,

    categoriesFetching: categories.fetching,
    categoriesError: categories.error,
    categories: categories.data,
  };
};

const mapDispatchToState = (dispatch) => {
  return {
    getCategories: (idLanguage) => dispatch(getCategories(idLanguage)),
    getMenuOptions: () => dispatch(getMenuOptions('order', 'parent')),
  };
};

export default withRouter(connect(mapPropsToState, mapDispatchToState)(MobileMenuOptions));