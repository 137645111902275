/* eslint-disable no-unused-vars */
import React from 'react';
import dayjs from 'dayjs';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from '../../utils';
import { getCategories } from '../../reducers/Categories';
import { getProtocols } from '../../reducers/Protocols';
import { Alert, Badge, Button, Card, Checkbox, Drawer, FloatButton, Image, Input, Result, Select, Skeleton, Switch, Tag } from 'antd';
import { styles } from '../../styles';
import no_photo from '../../images/no_photo.png';
import './index.scss';

const { Meta } = Card;
const { Option } = Select;

/**
 * Advantages component represents the page template with all advantages, and the possibility to
 * filter/order them. 
 */
class Advantages extends React.Component {
  /**
   * The constructor for a React component is called before it is mounted.
   * When implementing the constructor, `super(props)` must be called before any
   * other statement. Otherwise, this.props will be undefined in the constructor,
   * which can lead to bugs.
   *
   * A React constructor is only used for two purposes:
   * - Initializing local state by assigning an object to this.state.
   * - Binding event handler methods to an instance.
   *
   * Instead of calling `setState()` in the `constructor(), the initial state must be
   * directly assigned to `this.state` in the constructor
   *
   * @param {*} props - Arbitrary component inputs.
   */
  constructor(props) {
    super(props);
    this.state = {
      categorySelectOpen: true,
      openModal: false,
      orderingItems: [
        {
          value: '1',
          label: 'Destaques',
        },
        {
          value: '2',
          label: 'Mais recentes',
        },
        {
          value: '3',
          label: 'Mais antigas',
        },
        {
          value: '4',
          label: 'Nome A-Z',
        },
        {
          value: '5',
          label: 'Nome Z-A',
        },
      ],
      pageWidth: 0,
      search: {
        searchValue: '',
        idCategory: [],
        order: '1',
        districtCheckbox: false,
        isInDistrict: null,
        locationsCheckbox: false,
        hasManyLocations: null,
      },
      selectedCampaign: null,
      selectedProtocol: null,
    };
  }

  componentDidMount() {
    // Get data
    this.props.getCategories();
    this.props.getProtocols();
    // Scroll to page's top
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Add event on resize
    this.handlePageWidth();
    window.addEventListener('resize', this.handlePageWidth);
    // Get selected item
    if (this.props.router && this.props.router.location && this.props.router.location.hash) {
      const idProtocol = this.props.router.location.hash.substring(1);
      const protocol = this.props.protocols.find((p) => p.idProtocol === idProtocol);
      this.setState({ selectedProtocol: protocol });
      if (protocol) this.handleInformationModal(true);
    }
    // Get search queries
    if (this.props.router && this.props.router.searchQuery) {
      const { search } = this.state;
      const newSearchObject = {};
      // Filter search value
      const searchValue = this.props.router.searchQuery.pesquisa;
      if (searchValue) newSearchObject.searchValue = searchValue;
      // Filter categories
      const categories = this.props.router.searchQuery.categoria;
      if (categories) newSearchObject.idCategory = categories.split(',');
      // Order constraint
      const order = this.props.router.searchQuery.order;
      if (order) newSearchObject.order = order;
      // Filter hasManyLocations
      const hasManyLocations = this.props.router.searchQuery.hasManyLocations;
      if (hasManyLocations) {
        newSearchObject.hasManyLocations = hasManyLocations === 'true' ? true : hasManyLocations === 'false' ? false : null;
        newSearchObject.locationsCheckbox = true;
      } else {
        newSearchObject.hasManyLocations = null;
        newSearchObject.locationsCheckbox = false;
      }
      // Filter isInDistrict
      const isInDistrict = this.props.router.searchQuery.isInDistrict;
      if (isInDistrict) {
        newSearchObject.isInDistrict = isInDistrict === 'true' ? true : isInDistrict === 'false' ? false : null;
        newSearchObject.districtCheckbox = true;
      } else {
        newSearchObject.isInDistrict = null;
        newSearchObject.districtCheckbox = false;
      }
      this.setState({ search: { ...search, ...newSearchObject }});
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Check if protocols array changed
    if (JSON.stringify(prevProps.protocols) !== JSON.stringify(this.props.protocols)) {
      // Get selected item
      if (this.props.router && this.props.router.location && this.props.router.location.hash) {
        const idProtocol = this.props.router.location.hash.substring(1);
        const protocol = this.props.protocols.find((p) => p.idProtocol === idProtocol);
        this.setState({ selectedProtocol: protocol });
        if (protocol) this.handleInformationModal(true);
      }
    }
    // Check if router path changed
    if (JSON.stringify(prevProps.router) !== JSON.stringify(this.props.router)) {
      // Get selected item
      if (this.props.router && this.props.router.location && this.props.router.location.hash) {
        const idProtocol = this.props.router.location.hash.substring(1);
        const protocol = this.props.protocols.find((p) => p.idProtocol === idProtocol);
        this.setState({ selectedProtocol: protocol });
        if (protocol) this.handleInformationModal(true);
      }
      // Check if URL query params changed
      if (this.props.router && this.props.router.searchQuery) {
        let newSearchObject = {};
        // Filter search value
        const searchValue = this.props.router.searchQuery.pesquisa;
        newSearchObject.searchValue = searchValue ? searchValue : '';
        // Filter categories
        const categories = this.props.router.searchQuery.categoria;
        newSearchObject.idCategory = categories ? categories.split(',') : [];
        // Order constraint
        const order = this.props.router.searchQuery.order;
        newSearchObject.order = order ? order : '1';
        // Filter hasManyLocations
        const hasManyLocations = this.props.router.searchQuery.hasManyLocations;
        if (hasManyLocations) {
          newSearchObject.hasManyLocations = hasManyLocations === 'true' ? true : hasManyLocations === 'false' ? false : null;
          newSearchObject.locationsCheckbox = true;
        } else {
          newSearchObject.hasManyLocations = null;
          newSearchObject.locationsCheckbox = false;
        }
        // Filter isInDistrict
        const isInDistrict = this.props.router.searchQuery.isInDistrict;
        if (isInDistrict) {
          newSearchObject.isInDistrict = isInDistrict === 'true' ? true : isInDistrict === 'false' ? false : null;
          newSearchObject.districtCheckbox = true;
        } else {
          newSearchObject.isInDistrict = null;
          newSearchObject.districtCheckbox = false;
        }
        this.setState({ search: newSearchObject});
      } else {
        this.setState({ 
          search: {
            searchValue: '',
            idCategory: [],
            order: '1',
            districtCheckbox: false,
            isInDistrict: null,
            locationsCheckbox: false,
            hasManyLocations: null,
          }
        });
      }
    }
  }

  handlePageWidth = () => {
    this.setState({ pageWidth: window.innerWidth });
  };

  handleInformationModal = (value) => {
    this.setState({ openModal: value });
    // selectedCampaign: null, selectedProtocol: null
    if (!value) this.setState({ selectedCampaign: null, selectedProtocol: null });
  };

  render() {
    const { categories, categoriesFetching, categoriesError, protocols, protocolsError, protocolsFetching } = this.props;
    const { categorySelectOpen, orderingItems, search, selectedProtocol } = this.state;

    const results = [...protocols].filter((protocol) => {
      if (search.searchValue) {
        const searchValueLC = search.searchValue.toLowerCase();
        return protocol.title.toLowerCase().includes(searchValueLC) ||
          (protocol.summary && protocol.summary.toLowerCase().includes(searchValueLC)) ||
          (protocol.searchFields && protocol.searchFields.toLowerCase().includes(searchValueLC)) ||
          (protocol.location && protocol.location.toLowerCase().includes(searchValueLC)) ||
          (protocol.group && protocol.group.name && protocol.group.name.toLowerCase().includes(searchValueLC)) ||
          (protocol.description && protocol.description.toLowerCase().includes(searchValueLC));
      }
      return true;
    }).filter((protocol) => {
      if (search.idCategory && search.idCategory.length > 0) {
        return search.idCategory.includes(protocol.category.idCategory);
      }
      return true;
    }).filter((protocol) => {
      if (search.districtCheckbox && typeof search.isInDistrict === 'boolean') {
        return protocol.isInDistrict === search.isInDistrict;
      }
      return true;
    }).filter((protocol) => {
      if (search.locationsCheckbox && typeof search.hasManyLocations === 'boolean') {
        return protocol.hasManyLocations === search.hasManyLocations;
      }
      return true;
    }).sort((a, b) => {
      switch(search.order) {
      case '1': // Destaques Primeiro
        return a.isHighlighted && !b.isHighlighted ? -1 : b.isHighlighted && !a.isHighlighted ? 1 : 0;
      case '2': { // Mais recentes 
        const dateA = dayjs(a.activationDate);
        const dateB = dayjs(b.activationDate);
        return dateA ? (dateB ? (dateA.isAfter(dateB) ? -1 : dateA.isSame(dateB) ? 0 : 1) : -1) : 1;
      }
      case '3': { // Mais antigos
        const dateA = dayjs(a.activationDate);
        const dateB = dayjs(b.activationDate);
        return dateA ? (dateB ? (dateA.isAfter(dateB) ? 1 : dateA.isSame(dateB) ? 0 : -1) : 1) : -1;
      }
      case '4': // Mais A-Z
        return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
      case '5': // Mais Z-A
        return (-1) * a.title.toLowerCase().localeCompare(b.title.toLowerCase());
      default:
        return 0;
      }
    });

    return (
      <div id='content-wrap'>
        {/* Title */}
        <h1 id='advantages-content-title'>Vantagens</h1>

        {/* Filters */}
        <div id='advantages-content-filters-form'>
          {/* Search */}
          <div className='advantages-content-filters-form-item'>
            <label className='filter-content-label' htmlFor='advantagesSearchbox'>Pesquisar vantagem:</label>
            <Input
              id='advantagesSearchbox'
              allowClear
              disabled={protocolsFetching}
              name='advantageSearch'
              onChange={(value) => this.setState({ search: {...search, searchValue: value.target.value }})}
              placeholder='Pesquisar título, grupo, descrição, etc...'
              value={search.searchValue}
            />
          </div>
          {/* Category */}
          <div className='advantages-content-filters-form-item'>
            <label className='filter-content-label' htmlFor='advantagesCategories'>Filtrar por categoria: </label>
            {categorySelectOpen &&
              <Select
                id='advantagesCategories'
                aria-autocomplete="list"
                aria-haspopup='listbox'
                aria-label='Filtrar por categoria'
                role="searchbox"
                allowClear={false}
                disabled={categoriesError}
                defaultValue={search.idCategory}
                filterOption={(input, option) => option.key.toLowerCase().includes(input.toLowerCase())}
                loading={categoriesFetching || protocolsFetching}
                maxTagCount='responsive'
                mode='multiple'
                onChange={(value) => {
                  this.setState({ categorySelectOpen: false, search: { ...search, idCategory: value }});
                  setTimeout(() => this.setState({ categorySelectOpen: true }), 50);
                }}
                placeholder='Selecione uma ou mais categorias'
                placement='bottomLeft'
                showSearch
                style={{ width: '100%' }}
                tagRender={(props) => {
                  const { value, closable, onClose } = props;
                  const onPreventMouseDown = (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                  };
                  const category = this.props.categories.find((category) => category.idCategory === value);
                  if (category)
                    return (
                      <Tag
                        className='advantages-content-filters-form-category'
                        closable={closable}
                        closeIcon={<i className='fa-sharp fa-regular fa-xmark'
                          style={{ color: styles.COLORS.getTextColor(category.color) }}></i>}
                        color={category.color}
                        onClose={onClose}
                        onMouseDown={onPreventMouseDown} 
                        style={{ color: styles.COLORS.getTextColor(category.color) }}
                      >{category.name}</Tag>
                    );
                  return null;
                }}
                value={search.idCategory}
              >
                {categories.map((category) => {
                  return (
                    <Option key={category.name} value={category.idCategory}>
                      <div className='form-item-category-select-option'>
                        <div className='form-item-category-color' style={{ backgroundColor: category.color }}></div>
                        <div>{category.name}</div>
                      </div>
                    </Option>
                  );
                })}
              </Select>
            }
          </div>
          {/* Order */}
          <div className='advantages-content-filters-form-item'>
            <label className='filter-content-label' htmlFor='advantagesSortingOptions'>Ordenar:</label>
            <Select
              id='advantagesSortingOptions'
              aria-autocomplete="list"
              aria-haspopup='listbox'
              aria-label='Ordenar vantagens'
              role="searchbox"
              allowClear={false}
              disabled={protocolsFetching}
              loading={protocolsFetching}
              options={orderingItems}
              onChange={(value) => this.setState({ search: {...search, order: value} })}
              placeholder='Selecione um valor de ordenação'
              style={{ width: '100%' }}
              value={search.order}
            />
          </div>
        </div>

        {/* More Filters */}
        <div className='advantages-content-filters-form-advanced-search'>
          {/* Is in district */}
          <div className='advantages-content-filters-form-item'>
            <Checkbox 
              aria-label='Ativar o filtro relativo aos distritos das vantagens'
              checked={search.districtCheckbox}
              onChange={(event) => this.setState({ search: {...search, districtCheckbox: event.target.checked }})} 
            >
              <label className='filter-content-label' htmlFor='advantagesDistrictCheckbox'>No distrito de Setúbal: </label>
              <Switch
                id='advantagesDistrictCheckbox'
                checkedChildren='Sim' 
                disabled={!search.districtCheckbox}
                onChange={(value) => this.setState({ search: {...search, isInDistrict: value} })}
                unCheckedChildren='Não'
                checked={search.isInDistrict}
              />
            </Checkbox>
          </div>
          {/* Has many locations */}
          <div className='advantages-content-filters-form-item'>
            <Checkbox 
              aria-label='Ativar o filtro relativo à cobertura nacional das vantagens'
              checked={search.locationsCheckbox}
              onChange={(event) => this.setState({ search: {...search, locationsCheckbox: event.target.checked }})} 
            >
              <label className='filter-content-label' htmlFor='advantagesNationalCoverCheckbox'>Com cobertura nacional: </label>
              <Switch 
                id='advantagesNationalCoverCheckbox'
                checkedChildren='Sim' 
                disabled={!search.locationsCheckbox}
                onChange={(value) => this.setState({ search: {...search, hasManyLocations: value} })}
                unCheckedChildren='Não'
                checked={search.hasManyLocations} 
              />
            </Checkbox>
          </div>
        </div>

        {/* Advantages */}
        {protocolsError ?
          <div className='advantages-content-fetch-error'>
            <i className='fa-duotone fa-arrow-rotate-right'></i>
            <b>Ocorreu um erro...</b>
            <Button type='text' onClick={() => window.location.reload(true)}>
              Clique&nbsp;<span className='cms-menu-actions-error-btn'>aqui</span>&nbsp;para refrescar a página.
            </Button>
              Ou tente mais tarde.
          </div>
          : protocolsFetching ?
            <div id='advantages-wrapper'>
              <Skeleton.Image active={true} />
              <Skeleton.Image active={true} />
              <Skeleton.Image active={true} />
              <Skeleton.Image active={true} />
              <Skeleton.Image active={true} />
              <Skeleton.Image active={true} />
              <Skeleton.Image active={true} />
              <Skeleton.Image active={true} />
              <Skeleton.Image active={true} />
              <Skeleton.Image active={true} />
            </div>
            : results.length > 0 ?
              <div id='advantages-wrapper'>
                {results.map((protocol) => {
                  const protocolImage = protocol.image.startsWith('/') ? `${process.env.REACT_APP_API_IMAGES_BASE_URL}${protocol.image}` : protocol.image;
                  return (
                    <button
                      key={protocol.idProtocol}
                      className='advantage-wrapper'
                      onClick={() => {
                        this.setState({ selectedProtocol: protocol });
                        this.handleInformationModal(true);
                      }}
                    >
                      {protocol.discountValue ?
                        <Badge.Ribbon
                          key={protocol.idProtocol}
                          text={protocol.discountValue}
                          color={styles.COLORS.ErrorActiveColor}
                        >
                          <div className='advantage-header-wrapper'>
                            <img
                              alt={protocol.title}
                              className='advantage-img'
                              crossOrigin={protocol.image.startsWith('/') ? 'anonymous' : undefined}
                              src={protocolImage}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = no_photo;
                              }}
                            />
                            <div className='advantage-header'>
                              <h2 className='advantage-title'>{protocol.title}</h2>
                              <Tag
                                className='advantage-category'
                                color={protocol.category.color}
                                style={{ color: styles.COLORS.getTextColor(protocol.category.color) }}
                              >{protocol.category.name}</Tag>
                              {protocol.group && protocol.group.name &&
                                <Tag className='advantage-group' color={styles.COLORS.SecondaryFillColor}>{protocol.group.name}</Tag>
                              }
                            </div>
                          </div>
                        </Badge.Ribbon>
                        :
                        <div className='advantage-header-wrapper'>
                          <img
                            alt={protocol.title}
                            className='advantage-img'
                            crossOrigin={protocol.image.startsWith('/') ? 'anonymous' : undefined}
                            src={protocolImage}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = no_photo;
                            }}
                          />
                          <div className='advantage-header'>
                            <h1 className='advantage-title'>{protocol.title}</h1>
                            <Tag
                              className='advantage-category'
                              color={protocol.category.color}
                              style={{ color: styles.COLORS.getTextColor(protocol.category.color) }}
                            >{protocol.category.name}</Tag>
                            {protocol.group && protocol.group.name &&
                              <Tag className='advantage-group' color={styles.COLORS.SecondaryFillColor}>{protocol.group.name}</Tag>
                            }
                          </div>
                        </div>
                      }
                    </button>
                  );
                })}
              </div>
              : 
              <Result
                icon={<i className='fa-duotone fa-face-sad-sweat' style={{ fontSize: styles.FONTS.FontSizeH1, color: styles.COLORS.SecondaryColor }}></i>}
                status='info'
                title='Não foram encontrados resultados'
              />
        }

        {/* Advantage modal */}
        {selectedProtocol &&
          <Drawer
            className='advantages-detail-drawer'
            destroyOnClose
            extra={
              <div className='advantages-detail-drawer-header'>
                {/* Overlay da imagem de fundo */}
                <div
                  className='advantage-content-img-overlay'
                  style={{
                    width: this.state.pageWidth < 736 ? this.state.pageWidth - 50 : 686,
                    backgroundColor: styles.COLORS.getAverageImageRgb(
                      selectedProtocol.image.startsWith('/') ? 
                        `${process.env.REACT_APP_API_IMAGES_BASE_URL}${selectedProtocol.image}` : 
                        selectedProtocol.image, selectedProtocol.image
                    )
                  }}
                ></div>

                {/* Imagem da vantagem */}
                <img
                  alt={selectedProtocol.title}
                  className='advantage-content-img'
                  crossOrigin={selectedProtocol.image.startsWith('/') ? 'anonymous' : undefined}
                  src={selectedProtocol.image.startsWith('/') ? 
                    `${process.env.REACT_APP_API_IMAGES_BASE_URL}${selectedProtocol.image}` : 
                    selectedProtocol.image
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = no_photo;
                  }}
                />

                {/* Close btn */}
                <FloatButton 
                  onClick={() => this.handleInformationModal(false)}
                  title='Fechar'
                  type='primary'
                  icon={
                    // <i class="fa-duotone fa-chevrons-left"></i>
                    <i  className='fa-solid fa-chevron-left'></i>
                  }
                />
              </div>
            }
            open={this.state.openModal}
            onClose={() => this.handleInformationModal(false)}
            placement='right'
            size='large'
            width={this.state.pageWidth < 736 ? this.state.pageWidth : 736}
          >
            {/* Header */}
            <div className='advantages-detail-drawer-body-header'>
              <div className='advantages-detail-drawer-body-header-title'>
                {/* Title */}
                <h3>{selectedProtocol.title}</h3>
                
                {/* Validade */}
                {selectedProtocol.activationDate && selectedProtocol.deactivationDate ?
                  <span className='advantage-content-validity'>Válido de {moment(selectedProtocol.activationDate).format('DD/MM/YYYY')} a {moment(selectedProtocol.deactivationDate).format('DD/MM/YYYY')}</span>
                  : selectedProtocol.activationDate ?
                    <span className='advantage-content-validity'>Válido a partir de {moment(selectedProtocol.activationDate).format('DD/MM/YYYY')}</span>
                    : selectedProtocol.deactivationDate ?
                      <span className='advantage-content-validity'>Válido até {moment(selectedProtocol.deactivationDate).format('DD/MM/YYYY')}</span>
                      : <span></span>
                }
              </div>

              <div className='advantages-detail-drawer-body-header-tags'>
                {/* Category */}
                <Tag
                  color={selectedProtocol.category.color}
                  style={{ 
                    color: styles.COLORS.getTextColor(selectedProtocol.category.color),
                    fontSize: styles.FONTS.FontSizeSM,
                  }}
                >{selectedProtocol.category.name}</Tag>

                {/* Discount Value */}
                {selectedProtocol.discountValue &&
                <Tag 
                  color={styles.COLORS.ErrorActiveColor}
                  style={{ fontSize: styles.FONTS.FontSizeSM }}
                >{selectedProtocol.discountValue}</Tag>
                }
              </div>
            </div>

            {/* Group */}
            <div className='advantage-content-group-wrapper'>
              {selectedProtocol.group &&
                <Alert 
                  message={
                    <span>
                      Esta vantagem está associada ao grupo <Link to={`${this.props.router.location.pathname}?pesquisa=accor`} onClick={() => this.handleInformationModal(false)}>{selectedProtocol.group.name}</Link>
                    </span>
                  }
                  type="info"
                  closable
                />
              }
            </div>

            {/* Locations alerts */}
            <div>
              {/* Multiple locations info */}
              <Alert 
                message={selectedProtocol.hasManyLocations ? 
                  <span>Esta vantagem <b>tem múltiplas</b> localizações</span>
                  :
                  <span>Esta vantagem <b>não tem</b> cobertura nacional</span>
                }
                type="info"
                showIcon
                icon={selectedProtocol.hasManyLocations ? 
                  <i className='fa-duotone fa-map-location-dot'></i>
                  :
                  <i className='fa-duotone fa-location-dot'></i>
                }
                closable
              />

              {/* Location in the district */}
              {/* <Alert 
                closable
                showIcon
                type="info"
                message={
                  <span>
                    {selectedProtocol.isInDistrict ? 
                      <span>
                        {selectedProtocol.hasManyLocations ?
                          <span>Pode usufruir desta vantagem também no distrito de Setúbal</span>
                          : 
                          <span>Usufrua desta vantagem no distrito de Setúbal</span>
                        }
                      </span>
                      :
                      <span>
                        {selectedProtocol.hasManyLocations ?
                          <span>Esta vantagem não inclui o distrito de Setúbal</span>
                          : 
                          <span>Esta vantagem não se localiza no distrito de Setúbal</span>
                        }
                      </span>
                    }
                  </span>
                }
                icon={selectedProtocol.isInDistrict ?
                  <i className={selectedProtocol.hasManyLocations ? 
                    'fa-duotone fa-location-plus' : 'fa-duotone fa-location-crosshairs'
                  }></i>
                  :
                  <i className='fa-duotone fa-location-dot-slash'></i>
                }
              />  */}
            </div>
           
            {/* Description */}
            <div className='advantage-content-detailed-description'>
              {selectedProtocol.description &&
                <div 
                  className='advantage-content-description'
                  dangerouslySetInnerHTML={{ __html: selectedProtocol.description }}
                ></div>
              }

              {/* Location */}
              {selectedProtocol.location && <span className='advantage-content-title'>Localização: </span>}
              {selectedProtocol.location && <span>{selectedProtocol.location}</span>}
            </div>

            {/* Active Campaigns */}
            <div className='advantage-content-active-campaigns-wrapper'>
              {selectedProtocol.numberOfActiveCampaigns > 0 && selectedProtocol.campaigns &&
                <div className='advantage-content-active-campaigns'>
                  {/* Title */}
                  <div className='advantage-content-active-campaigns-title'>Campanhas em Vigor:</div>

                  {/* Campaigns */}
                  <div className='advantage-content-active-campaigns-list'>
                    {selectedProtocol.campaigns.map((campaign) => {
                      return (
                        <Card
                          cover={
                            campaign.documents && campaign.numberOfDocuments > 0 ?
                              <>
                                { campaign.documents[0].mimetype.includes('pdf') ?
                                  <iframe id={campaign.documents[0].idDocument} title={campaign.documents[0].name} src={`${process.env.REACT_APP_API_IMAGES_BASE_URL}${campaign.documents[0].path}`} height='110px' width='100%' ></iframe>
                                  :
                                  <img
                                    alt={campaign.documents[0].name}
                                    crossOrigin='anonymous'
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = no_photo;
                                    }}
                                    src={`${process.env.REACT_APP_API_IMAGES_BASE_URL}${campaign.documents[0].path}`}
                                  />
                                }
                              </>
                              : undefined
                          }
                          hoverable
                          key={campaign.idCampaign}
                          style={{ width: 200 }}
                          type='text'
                        >
                          <Meta
                            title={campaign.documents && campaign.numberOfDocuments > 0 ?
                              <Link
                                target='_blank'
                                to={`${process.env.REACT_APP_API_IMAGES_BASE_URL}${campaign.documents[0].path}`}
                              >{campaign.title}</Link>
                              : campaign.title
                            }
                            description={
                              <>
                                <p className='advantage-content-validity'>Válido de {dayjs(campaign.activationDate).format('DD/MM/YYYY')} até {dayjs(campaign.deactivationDate).format('DD/MM/YYYY')}</p>
                                {campaign.description && <div dangerouslySetInnerHTML={{ __html: campaign.description }}></div>}
                              </>
                            }
                          />
                        </Card>
                      );
                    })}
                  </div>
                </div>
              }
            </div>
          </Drawer>
        }
      </div>
    );
  }
}

const mapPropsToState = (state) => {
  const categories = state.categories;
  const protocols = state.protocols;

  return {
    categoriesFetching: categories.fetching,
    categoriesError: categories.error,
    categories: categories.data,

    protocolsFetching: protocols.fetching,
    protocolsError: protocols.error,
    protocols: protocols.data,
  };
};

const mapDispatchToState = (dispatch) => {
  return {
    getCategories: () => dispatch(getCategories()),
    getProtocols: (order) => dispatch(getProtocols(order)),
  };
};

export default withRouter(connect(mapPropsToState, mapDispatchToState)(Advantages));