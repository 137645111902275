import React from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import { withRouter } from '../../utils';
import { getAllLanguages } from '../../reducers/Languages';
import { 
  Button,  Divider, Drawer, Form, Input, InputNumber, Popconfirm, 
  Select, Space, Switch, Table, Tabs, Upload, notification
} from 'antd';
import { PuffLoader } from 'react-spinners';
import { styles } from '../../styles';
import no_photo from '../../images/no_photo.png';

const { Option } = Select;
const { TextArea } = Input;

/**
 * ManageBanners component represents the page template that allows to manage the application banners.
 * It displays a table with all active banners and a table with all inactive banners.
 * It allows tools to create, edit, deactivate, reactivate and delete banners.
 */
class ManageBanners extends React.Component {
  /**
   * The constructor for a React component is called before it is mounted.
   * When implementing the constructor, `super(props)` must be called before any
   * other statement. Otherwise, this.props will be undefined in the constructor,
   * which can lead to bugs.
   *
   * A React constructor is only used for two purposes:
   * - Initializing local state by assigning an object to this.state.
   * - Binding event handler methods to an instance.
   *
   * Instead of calling `setState()` in the `constructor(), the initial state must be
   * directly assigned to `this.state` in the constructor
   *
   * @param {*} props - Arbitrary component inputs.
   */
  constructor() {
    super();
    this.state = {
      activeLanguage: process.env.REACT_APP_LANGUAGE_ID,
      addError: null,
      addFetching: false,
      addImageUpload: null,
      addImageUrl: null,
      addNewBanner: null,
      banners: [],
      bannersError: null,
      defaultLanguage: process.env.REACT_APP_LANGUAGE_ID,
      descriptionSearch: null,
      editError: null,
      editFetching: false,
      loading: false,
      pageWidth: 0,
      selectedItem: null,
      showAddBannerModal: false,
      showEditBannerModal: false,
    };
  }

  /**
   * Allows to execute the React code when the component is already placed in the DOM (Document Object Model).
   * This method is called during the Mounting phase of the React Life-cycle i.e after the component is rendered.
   */
  componentDidMount() {
    // Get data
    this.props.getLanguages(window.localStorage.getItem('token'));
    this.fetchData();
    // Scroll to top
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Add event on resize
    this.setState({ pageWidth: window.innerWidth });
    window.addEventListener('resize', () => this.setState({ pageWidth: window.innerWidth }));
  }

  /**
   * Allows React to call this function immediately after this component has been re-rendered with
   * updated `props` and/or `state`. This method is not called for the initial render.
   * 
   * This allows to manipulate the DOM after an update. It is also a common place to do network requests
   * as long as it compares the current `props` to previous `props` (e.g. a network request may not be necessary
   * if the `props` have not changed).
   * 
   * @param {any} prevProps - Props before the update. Compare prevProps to this.props to determine what changed.
   * @param {any} prevState - State before the update. Compare prevState to this.state to determine what changed.
   * @param {any} snapshot - Used when function getSnapshotBeforeUpdate is implemented. It will contain the value returned from that function. Otherwise, it will be undefined.
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.activeLanguage !== this.state.activeLanguage) this.fetchData(this.state.activeLanguage);
  }

  /**
   * Recursively sets the array of banners to manage.
   * 
   * @param {any[]} data - Original data of banners. 
   * @returns {any[]} the modified dataset of banners.
   */
  setBanners = (data) => {
    return data.map((banner) => {
      return {
        key: banner.idBanner,
        idLanguage: banner.language.idLanguage,
        path: banner.path,
        order: banner.order,
        isMobile: banner.isMobile,
        isActive: banner.isActive,
        description: banner.description,
        redirectTo: banner.redirectTo,
      };
    });
  };

  /**
   * Fetches all banners from database through an available API.
   * Calls `setBanners` to modify the data, regarding this component.
   */
  fetchData = () => {
    this.setLoading(true);
    const { selectedItem } = this.state;
    // Get parameters to fetch
    const fetchParams = { idLanguage: this.state.activeLanguage };
    // Get options to fetch
    const fetchOptions = {
      method: 'GET',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      })
    };
    // Fetch data
    fetch(`${process.env.REACT_APP_API_BASE_URL}/banners?${qs.stringify(fetchParams)}`, fetchOptions)
      .then(async (response) => { 
        if (response.ok) return response.json();
        const result = await response.json();
        throw new Error(result ? JSON.stringify(result) : 'Não é possível obter banners.');
      }).then(async (data) => {
        // await (new Promise((res) => {setTimeout(res, 5000); }));
        const banners = this.setBanners(data);
        if (selectedItem) this.setState({ selectedItem: banners.find((banner) => banner.key === selectedItem.key)});
        this.setState({ banners: banners, bannersError: null });
        this.setLoading(false);
      }).catch((error) => {
        this.setState({ banners: [], bannersError: error.message, selectedItem: null });
        this.setLoading(false);
      });
  };

  /**
   * Sets loading state from `this.state.loading`.
   * This state is relationated with the data fetching from database.
   *
   * @param {boolean} loading - Sets the loading state to true or false.
   */
  setLoading = (loading) => {
    this.setState({ loading: loading });
  };

  /**
   * Handles the visibility state of the add banner modal, using the state `this.state.showAddBannerModal`.
   */
  handleAddBannerModal = () => {
    const { showAddBannerModal } = this.state;
    this.setState({ 
      addNewBanner: null,
      addImageUpload: null,
      addImageUrl: null,
      showAddBannerModal: !showAddBannerModal,
      addError: null,
      addFetching: false,
    });
  };

  /**
   * Handles the image file upload on banner creation.
   */
  handleAddFileList = (e) => {
    if (Array.isArray(e)) return e;
    if (e) return e.fileList;
    return undefined;
  };

  /**
   * Adds a new banner to the system, using the API for the effect.
   *
   * @param {any} values - New banner data.
   */
  addBanner = (values) => {
    this.setState({ addFetching: true });
    const formdata = new FormData();
    formdata.append('idLanguage', values.idLanguage);
    formdata.append('order', values.order);
    formdata.append('isMobile', values.isMobile);
    formdata.append('isActive', values.isActive);
    formdata.append('description', values.description);
    if (values.redirectTo) formdata.append('redirectTo', values.redirectTo);
    if (values.imageUrl) formdata.append('path', values.imageUrl);
    if (values.imageUploaded && values.imageUploaded.length > 0) formdata.append('image', values.imageUploaded[0].originFileObj);
    // Get options to fetch
    const fetchOptions = {
      method: 'POST',
      body: formdata,
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      }),
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/banners`, fetchOptions).then(async (response) => {
      if (response.ok) return response.json();
      const result = await response.json();
      throw new Error(result ? JSON.stringify(result) : 'Não é possível adicionar um novo banner');
    }).then(async (data) => {
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check'></i>
            <span className='cms-notification-icon-text-span'>Banner adicionado com sucesso</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
            <span>O banner foi adicionado com sucesso. Consulte a lista de banners.</span>
          </div>
        ),
      });
      setTimeout(() => {
        this.setState({ addError: null, addFetching: false });
        this.handleAddBannerModal();
        this.fetchData();
      }, 1500);
    }).catch((error) => {
      let message = 'Não é possível adicionar uma nova categoria';
      let extendedMessage = 'Por favor, reveja os dados inseridos e/ou tente novamente.';
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'></i>
            <span className='cms-notification-icon-text-span'>{message}</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
            <span>{extendedMessage}</span>
          </div>
        ),
      });
      this.setState({ addError: message, addFetching: false });
    });
  };

  /**
   * Handles the visibility state of the edit banner modal, using the state `this.state.showEditBannerModal`.
   */
  handleEditBannerModal = () => {
    const { showEditBannerModal } = this.state;
    this.setState({ showEditBannerModal: !showEditBannerModal });
  };

  /**
   * Edits an existing banner in the system, using the API for the effect.
   *
   * @param {any} values - New data of the existing banner.
   */
  editBanner = (values) => {
    this.setState({ editFetching: true });
    const { selectedItem } = this.state;
    // Get body to fetch
    const raw = { 
      idLanguage: values.idLanguage,
      order: values.order,
      isMobile: values.isMobile,
      isActive: values.isActive,
      description: values.description,
      redirectTo: values.redirectTo ? values.redirectTo : null
    };
    // Get options to fetch
    const fetchOptions = {
      method: 'PATCH',
      body: JSON.stringify(raw),
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      }),
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/banners/${selectedItem.key}`, fetchOptions).then(async (response) => {
      if (response.ok) return response.json();
      const result = await response.json();
      throw new Error(result ? JSON.stringify(result) : 'Não é possível editar o banner selecionado');
    }).then(async (data) => {
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check'></i>
            <span className='cms-notification-icon-text-span'>Alterações guardadas com sucesso</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
            <span>O banner selecionado foi atualizado com sucesso. Consulte a lista de banners para verificar as suas alterações.</span>
          </div>
        ),
      });
      setTimeout(() => {
        this.setState({ editError: null, editFetching: false });
        this.handleEditBannerModal();
        this.fetchData();
      }, 1500);
    }).catch((error) => {
      let message = 'Não é possível editar o banner selecionado';
      let extendedMessage = 'Por favor, reveja os dados inseridos e/ou tente novamente.';
      // if (error && error.message) {
      //   if (error.message.includes('Your token is not valid!')) {
      //     extendedMessage = 'Não tem autorização para editar categorias. Tente reentrar na sua conta. Caso o erro persista, por favor, contacte o administrador da aplicação.';
      //   }
      //   if (error.message.includes('You have no permission to access this resource.')) {
      //     extendedMessage = 'Não tem autorização para editar categorias. Por favor, contacte o administrador da aplicação.';
      //   }
      //   if (error.message.includes('Banner language is required.')) {
      //     message = 'Idioma selecionado inválido';
      //     extendedMessage = 'Por favor, selecione um idioma válido.';
      //   }
      //   if (error.message.includes('Invalid language identification.')) {
      //     message = 'Idioma selecionado inválido';
      //     extendedMessage = 'Por favor, selecione um idioma válido.';
      //   }
      //   if (error.message.includes('Language not found.')) {
      //     message = 'Idioma selecionado inválido';
      //     extendedMessage = 'O idioma selecionado não foi encontrado no sistema. Por favor, selecione um idioma válido.';
      //   }
      //   if (error.message.includes('Language is deactivated.')) {
      //     message = 'Idioma selecionado inválido';
      //     extendedMessage = 'O idioma selecionado foi desativado. Por favor, selecione um idioma válido.';
      //   }
      //   if (error.message.includes('Invalid parent banner identification.')) {
      //     message = 'Categoria-pai selecionada inválida';
      //     extendedMessage = 'Por favor, selecione uma categoria-pai válida. Este campo é opcional, por isso poderá deixá-lo vazio.';
      //   }
      //   if (error.message.includes('Parent banner not found.')) {
      //     message = 'Categoria-pai selecionada inválida';
      //     extendedMessage = 'A categoria-pai selecionada não foi encontrada no sistema. Por favor, selecione uma categoria válida. Este campo é opcional, por isso poderá deixá-lo vazio.';
      //   }
      //   if (error.message.includes('Parent banner is deactivated.')) {
      //     message = 'Categoria-pai selecionada inválida';
      //     extendedMessage = 'A categoria-pai selecionada foi desativada. Por favor, selecione uma categoria válida. Este campo é opcional, por isso poderá deixá-lo vazio.';
      //   }
      //   if (error.message.includes('Invalid name. Must have between 3 and 100 characters.')) {
      //     message = 'Nome inválido';
      //     extendedMessage = 'O nome da categoria é inválido. Por favor, insira um valor válido.';
      //   }
      //   if (error.message.includes('Invalid color. Must have between 3 and 10 characters. Must be of type HEX.')) {
      //     message = 'Cor inválida';
      //     extendedMessage = 'A cor da categoria é inválida. Por favor, insira um valor válido.';
      //   }
      //   // if (error.message.includes('Invalid image URL.')) { }
      // }
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'></i>
            <span className='cms-notification-icon-text-span'>{message}</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
            <span>{extendedMessage}</span>
          </div>
        ),
      });
      this.setState({ editError: message, editFetching: false });
    });
  };

  /**
   * Deletes an existing banner from the system, using the API for the effect.
   */
  deleteBanner = () => {
    this.setState({ editFetching: true });
    const { selectedItem } = this.state;
    // Get options to fetch
    const fetchOptions = {
      method: 'DELETE',
      headers: new Headers({
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      }),
    };
    // Fetch request
    fetch(`${process.env.REACT_APP_API_BASE_URL}/banners/${selectedItem.key}`, fetchOptions).then(async (response) => {
      if (response.ok) return response.json();
      const result = await response.json();
      throw new Error(result ? JSON.stringify(result) : 'Não é possível eliminar o banner selecionado');
    }).then(async (data) => {
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check'></i>
            <span className='cms-notification-icon-text-span'>Banner eliminado com sucesso</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-check' style={{ visibility: 'hidden' }}></i>
            <span>O banner selecionado foi eliminado com sucesso. Consulte a lista de banners 
              para verificar a atualização da mesma. Esta ação <b>não é reversível</b>.</span>
          </div>
        ),
      });
      setTimeout(() => {
        this.setState({ editFetching: false });
        this.handleEditBannerModal();
        this.fetchData();
      }, 1500);
    }).catch((error) => {
      let message = 'Não é possível eliminar o banner selecionado';
      let extendedMessage = 'Por favor, tente novamente mais tarde. Se o erro persistir, contacte o administrador da aplicação.';
      // if (error && error.message) {
      //   if (error.message.includes('Your token is not valid!'))  {
      //     extendedMessage = 'Não tem autorização para eliminar categorias. Tente reentrar na sua conta. Se o erro persistir, por favor, contacte o administrador da aplicação.';
      //   }
      //   if (error.message.includes('You have no permission to access this resource.'))  {
      //     extendedMessage = 'Não tem autorização para eliminar categorias. Por favor, contacte o administrador da aplicação.';
      //   }
      //   if (error.message.includes('Action cannot be performed. Banner has associated protocols.'))  {
      //     extendedMessage = 'A categoria tem vantagens associadas. Se desejar eliminar esta, deve primeiro eliminar as vantagens associadas, ou associá-las a outra categoria.';
      //   }
      //   if (error.message.includes('Action cannot be performed. Banner has associated subcategories.'))  {
      //     extendedMessage = 'A categoria tem sub-categorias associadas. Se desejar eliminar esta, deve primeiro eliminar as suas sub-categorias.';
      //   }
      // }
      notification.open({
        duration: 30,
        message: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'></i>
            <span className='cms-notification-icon-text-span'>{message}</span>
          </div>
        ),
        description: (
          <div className='cms-notification-icon-text'>
            <i className='fa-solid fa-circle-xmark'style={{ visibility: 'hidden' }}></i>
            <span>{extendedMessage}</span>
          </div>
        ),
      });
      this.setState({ editFetching: false });
    });
  };

  /**
   * Render method of React component.
   * 
   * @returns the component template.
   */
  render() {
    const { languages, languagesError, languagesFetching } = this.props;
    const { 
      activeLanguage, addError, addFetching, addImageUpload, addImageUrl, banners, bannersError, 
      defaultLanguage, editError, editFetching, loading, pageWidth, selectedItem, 
      showAddBannerModal, showEditBannerModal,
    } = this.state;

    if (languagesError || bannersError || (!languagesFetching && languages.length < 1)) {
      return (
        <div className='cms-menu-actions-container'>
          {/* <h1 id='cms-menu-actions-title'>Gestão de Banners</h1> */}
          <div id='cms-menu-actions-tabs'>
            <div id='cms-menu-actions-error'>
              <i className='fa-duotone fa-arrow-rotate-right'></i>
              <b>Ocorreu um erro...</b>
              <Button type='text' onClick={() => window.location.reload(true)}>
                Clique&nbsp;<span className='cms-menu-actions-error-btn'>aqui</span>&nbsp;para refrescar a página.
              </Button>
              Ou tente mais tarde.
            </div>
          </div>
        </div>
      );
    }

    if (languagesFetching) {
      return (
        <div className='cms-menu-actions-container'>
          <div id='cms-menu-actions-error'>
            <PuffLoader color={styles.COLORS.PrimaryColor} size={100} />
            <p>A carregar...</p>
          </div>
        </div>
      );
    }

    const columns = [
      {
        align: 'center',
        dataIndex: 'path',
        key: 'path',
        render: (_, record) => {
          const path = record.path.startsWith('/') ? `${process.env.REACT_APP_API_IMAGES_BASE_URL}${record.path}` : record.path;
          return (
            <Button
              onClick={() => {
                const newSelectedItem = !selectedItem || (selectedItem && selectedItem.key !== record.key) ? record : null;
                this.setState({ selectedItem: newSelectedItem });
                if (newSelectedItem) this.handleEditBannerModal();
              }}
              type='text'
              style={{ height: 'min-content' }}
            >
              <img
                alt={record.description}
                crossOrigin={record.path.startsWith('/') ? 'anonymous' : undefined}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = no_photo;
                }}
                src={path}
                style={{ width: '200px' }}
              />  
            </Button>
            
          );
        },
        title: 'Imagem',
        width: '200px'
      },
      {
        align: 'center',
        dataIndex: 'isMobile',
        filterIcon: <i className='fa-duotone fa-filters'></i>,
        filters: [{ text: 'Mobile', value: true }, { text: 'Desktop', value: false }],
        key: 'isMobile',
        onFilter: (value, record) => record.isMobile === value,
        render: (_, record) => <span>{record.isMobile ? 'Mobile' : 'Desktop'}</span>,
        sorter: (a, b) => a.isMobile ? (b.isMobile ? 0 : 1) : (b.isMobile ? -1 : 0),
        title: 'Tipo de Banner',
        width:'180px'
      },
      {
        dataIndex: 'description',
        key: 'description',
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
          <div style={{ padding: 8 }}>
            <Input
              placeholder='Pesquisar descrição'
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => {
                confirm();
                this.setState({ descriptionSearch: selectedKeys[0] });
              }}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type='primary'
                onClick={() => {
                  confirm();
                  this.setState({ descriptionSearch: selectedKeys[0] });
                }}
                icon={<i className='fa-regular fa-magnifying-glass'></i>}
                size='small'
                style={{ width: 100 }}
              >Pesquisar</Button>
              <Button
                onClick={() => {
                  clearFilters();
                  this.setState({ descriptionSearch: '' });
                }}
                size='small'
                style={{ width: 60 }}
              >Limpar</Button>
              <Button
                type='link'
                size='small'
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({ descriptionSearch: selectedKeys[0] });
                }}
              >Filtrar</Button>
              <Button
                type='link'
                size='small'
                onClick={() => close()}
              >Fechar</Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered) => (
          <i className='fa-regular fa-magnifying-glass' style={{ color: filtered ? styles.COLORS.PrimaryColor : undefined }}></i>
        ),
        onFilter: (value, record) => record.description.toLowerCase().includes((value).toLowerCase()),
        render: (_, record) => <span>{record.description}</span>,
        title: 'Descrição',
        width: pageWidth > 1000 ? 'auto' : '400px'
      },
      {
        align: 'center',
        dataIndex: 'order',
        key: 'order',
        render: (_, record) => <span>{record.order}</span>,
        sorter: (a, b) => a.order > b.order ? 1 : a.order === b.order ? 0 : -1,
        title: 'Ordem',
        width:'100px'
      },
      {
        align: 'center',
        dataIndex: 'redirectTo',
        key: 'redirectTo',
        render: (_, record) => {
          if (record.redirectTo) return <a href={record.redirectTo} target='_blank' rel='noreferrer'>{record.redirectTo}</a>;
          return '-';
        },
        // Filter
        title: 'Redirecionamento',
        width: '100px'
      },
      {
        align: 'center',
        dataIndex: 'isActive',
        filterIcon: <i className='fa-duotone fa-filters'></i>,
        filters: [{ text: 'Ativo', value: true }, { text: 'Desativado', value: false }],
        key: 'isActive',
        onFilter: (value, record) => record.isActive === value,
        render: (value, record) => <i className={value ? 'fa-solid fa-check' : 'fa-solid fa-xmark'}></i>,
        title: 'Ativo',
        width: '100px'
      },
    ];

    return (
      <div className='cms-menu-actions-container'>
        {/* Actions */}
        <div className='cms-menu-actions-btns'>
          <Button
            type='primary'
            icon={<i className='fa-solid fa-plus'></i>}
            onClick={this.handleAddBannerModal}
          >Novo Banner</Button>
          <Button
            type='default'
            disabled={selectedItem ? false : true}
            icon={<i className='fa-duotone fa-pen-to-square'></i>}
            onClick={this.handleEditBannerModal}
          >Editar Banner</Button>
        </div>
        
        {/* Table */}
        <div id='cms-menu-actions-tabs'>
          <Tabs
            activeKey={activeLanguage}
            defaultActiveKey={defaultLanguage}
            onChange={(key) => this.setState({ activeLanguage: key })}
            items={languages.map((language) => {
              return {
                languageData: language,
                key: language.idLanguage,
                label: language.name,
                children: (
                  <div className='cms-menu-actions-tabs-item'>
                    {/* Data table */}
                    <Table
                      bordered
                      columns={columns}
                      dataSource={banners}
                      loading={loading}
                      pagination={{ size: 'default' }}
                      rowKey={(record) => record.key}
                      rowSelection={{
                        onChange: (selectedRowKeys, selectedRows) => {
                          this.setState({ selectedItem: selectedRows.length > 0 ? selectedRows[selectedRows.length-1] : null });
                        },
                        selectedRowKeys: selectedItem ? [selectedItem.key] : [],
                        type: 'checkbox',
                        hideSelectAll: true,
                      }}
                      scroll={{ x: 240 }}
                      size='small'
                    />
                  </div>
                )
              };
            })}
          />
        </div>

        {/* Add new banner */}
        <Drawer
          className='cms-menu-add-drawer'
          destroyOnClose
          extra={
            <div className='cms-menu-add-drawer-header'>
              {/* Title */}
              <div>Adicionar um novo banner</div>
              {/* Close btn */}
              <Button 
                id='hamburger-menu-close'
                icon={<i className='fa-light fa-xmark'></i>}
                onClick={this.handleAddBannerModal}
                type='text'
              />
            </div>
          }
          open={showAddBannerModal}
          onClose={this.handleAddBannerModal}
          placement='right'
          size='large'
          title='Adicionar um novo banner'
          width={window.innerWidth < 736 ? window.innerWidth : 736}
        >
          <div></div>
          <Form
            autoComplete='on'
            initialValues={{ remember: true }}
            layout='vertical'
            name='Adicionar banner'
            onFinish={this.addBanner}
          >
            <div>
              {/* Language */}
              <Form.Item
                hasFeedback
                label='Idioma'
                name='idLanguage'
                rules={[{ required: true, message: 'Por favor, selecione um idioma.' }]}
                initialValue={activeLanguage}
              >
                <Select
                  allowClear={false}
                  filterOption={(input, option) => {
                    const language = languages.find((language) => language.idLanguage === option.value);
                    return language && `${language.name} (${language.code})`.toLowerCase().includes(input.toLowerCase());
                  }}
                  placeholder='Selecione um idioma'
                  showSearch
                >
                  {languages.map((language) => (
                    <Option key={language.idLanguage} value={language.idLanguage}>{language.name} ({language.code})</Option>
                  ))}
                </Select>
              </Form.Item>

              {/* Image */}
              <Form.Item label='Imagem' required>
                {/* Image URL */}
                <Form.Item name='imageUrl' rules={[{ type: 'url', message: 'Por favor, introduza um caminho válido.' },]}>
                  <Input
                    allowClear
                    disabled={addImageUpload ? true : false}
                    name='imageUrl'
                    onChange={(e) => this.setState({ addImageUrl: e.target.value })}
                    placeholder='Introduzir o caminho da imagem do banner'
                    type='url'
                    value={addImageUrl}
                  />
                </Form.Item>

                {/* Divider */}
                <Divider orientation='center' plain type='horizontal'>ou</Divider>

                {/* Image Upload */}
                <Form.Item
                  getValueFromEvent={this.handleAddFileList}
                  name='imageUploaded'
                  noStyle
                  rules={[{ required: !addImageUrl, message: 'Por favor, introduza uma imagem.' }]}
                  valuePropName='fileList'
                >
                  <Upload.Dragger
                    accept='.png, .jpg, .jpeg'
                    beforeUpload={(file) => {
                      this.setState({ addImageUpload: file });
                      return false;
                    }}
                    disabled={addImageUrl ? true : false}
                    listType='picture'
                    maxCount={1}
                    name='imageUploaded'
                    onChange={({ file, fileList }) => this.setState({ addImageUpload: fileList.length < 1 ? null : file })}
                  >
                    <p className='ant-upload-drag-icon'><i className='fa-duotone fa-cloud-arrow-up'></i></p>
                    <p className="ant-upload-text">Clique ou arraste um ficheiro para esta área para carregá-lo</p>
                    <p className="ant-upload-hint">Suporta apenas o carregamento de um único ficheiro do tipo .jpg, .jpeg ou .png</p>
                  </Upload.Dragger>
                </Form.Item>
              </Form.Item>

              {/* Is Mobile */}
              <Form.Item
                hasFeedback
                initialValue={false}
                label='Versão mobile'
                name='isMobile'
                required
                tooltip='Permite distinguir entre um banner mobile e um banner do tipo desktop.'
                valuePropName='checked'
              >
                <Switch checkedChildren='Sim' unCheckedChildren='Não' />
              </Form.Item>

              {/* Is Active */}
              <Form.Item
                hasFeedback
                initialValue={false}
                label='Ativar imediatamente'
                name='isActive'
                required
                tooltip='Permite disponibilizar de imediato o banner para o público.'
                valuePropName='checked'
              >
                <Switch checkedChildren='Sim' unCheckedChildren='Não' />
              </Form.Item>

              {/* Description */}
              <Form.Item
                hasFeedback
                label='Descrição breve'
                name='description'
                rules={[
                  { required: true, message: 'Por favor, introduza uma descrição.' },
                  { min: 3, message: 'Por favor, introduza uma descrição maior.' }
                ]}
                tooltip='Associa uma breve descrição ao banner. Esta informação é necessária para questões de acessibilidade.'
              >
                <TextArea
                  allowClear
                  autoSize
                  minLength={3}
                  placeholder='Introduzir uma descrição simples do banner.'
                  rows={4}
                />
              </Form.Item>

              {/* Ordem */}
              <Form.Item
                hasFeedback
                label='Ordem'
                name='order'
                rules={[{ required: true, message: 'Por favor, introduza um valor de ordenação.' }]}
              >
                <InputNumber placeholder='Introduzir a ordem do banner no slideshow' min={1} style={{ width: '100%' }} />
              </Form.Item>
            
              {/* Redirect to URL */}
              <Form.Item
                hasFeedback
                label='URL de Destino'
                name='redirectTo'
                rules={[{ type: 'url', message: 'Por favor, introduza um URL válido.' },]}
                tooltip='Associa um link ao banner. Desta forma, ao clicar no banner, o utilizador é redirecionado para outro local.'
              >
                <Input
                  allowClear
                  name='imageUrl'
                  placeholder='Introduzir o URL de destino do banner. (opcional)'
                  type='url'
                />
              </Form.Item>
            </div>

            {/* Buttons */}
            <Form.Item style={{ width: '100%' }}>
              <div className='cms-add-modal-btns'>
                <Button type='default' onClick={this.handleAddBannerModal}>Cancelar</Button>
                <Button 
                  type={addError ? 'default' : 'primary'}
                  danger={addError ? true : false}
                  htmlType='submit'
                  disabled={addFetching}>{addError ? 'Tentar novamente' : addFetching ? 'A carregar...' : 'Adicionar'}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Drawer>

        {/* Edit banner */}
        {selectedItem &&
          <Drawer
            className='cms-menu-add-drawer'
            destroyOnClose
            extra={
              <div className='cms-menu-add-drawer-header'>
                {/* Title */}
                <div>Editar banner</div>
                {/* Close btn */}
                <Button 
                  id='hamburger-menu-close'
                  icon={<i className='fa-light fa-xmark'></i>}
                  onClick={this.handleEditBannerModal}
                  type='text'
                />
              </div>
            }
            open={showEditBannerModal}
            onClose={this.handleEditBannerModal}
            placement='right'
            size='large'
            title='Editar banner'
            width={window.innerWidth < 736 ? window.innerWidth : 736}
          >
            {/* Image */}
            <div className='cms-menu-add-drawer-form-item-image'>
              <img
                alt={selectedItem.description}
                crossOrigin={selectedItem.path.startsWith('/') ? 'anonymous' : undefined}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = no_photo;
                }}
                src={selectedItem.path.startsWith('/') ? `${process.env.REACT_APP_API_IMAGES_BASE_URL}${selectedItem.path}` : selectedItem.path}
              />
            </div>

            <Form
              autoComplete='on'
              initialValues={{ remember: true }}
              layout='vertical'
              name='Editar banner'
              onFinish={this.editBanner}
            >
              <div>
                {/* Language */}
                <Form.Item
                  label='Idioma'
                  name='idLanguage'
                  rules={[{ required: true, message: 'Por favor, selecione um idioma.' }]}
                  initialValue={selectedItem.idLanguage}
                >
                  <Select disabled>
                    {languages.map((language) => (
                      <Option key={language.idLanguage} value={language.idLanguage}>{language.name} ({language.code})</Option>
                    ))}
                  </Select>
                </Form.Item>

                {/* Is Mobile */}
                <Form.Item
                  hasFeedback
                  initialValue={selectedItem.isMobile}
                  label='Versão mobile'
                  name='isMobile'
                  required
                  tooltip='Permite distinguir entre um banner mobile e um banner do tipo desktop.'
                  valuePropName='checked'
                >
                  <Switch checkedChildren='Sim' unCheckedChildren='Não' />
                </Form.Item>

                {/* Is Active */}
                <Form.Item
                  hasFeedback
                  initialValue={selectedItem.isActive}
                  label='Ativo'
                  name='isActive'
                  required
                  tooltip='Permite ativar/desativar o banner.'
                  valuePropName='checked'
                >
                  <Switch checkedChildren='Sim' unCheckedChildren='Não' />
                </Form.Item>

                {/* Description */}
                <Form.Item
                  hasFeedback
                  initialValue={selectedItem.description}
                  label='Descrição breve'
                  name='description'
                  rules={[
                    { required: true, message: 'Por favor, introduza uma descrição.' },
                    { min: 3, message: 'Por favor, introduza uma descrição maior.' }
                  ]}
                  tooltip='Associa uma breve descrição ao banner. Esta informação é necessária para questões de acessibilidade.'
                >
                  <TextArea
                    allowClear
                    autoSize
                    minLength={3}
                    placeholder='Introduzir uma descrição simples do banner.'
                    rows={4}
                  />
                </Form.Item>

                {/* Ordem */}
                <Form.Item
                  hasFeedback
                  initialValue={selectedItem.order}
                  label='Ordem'
                  name='order'
                  rules={[{ required: true, message: 'Por favor, introduza um valor de ordenação.' }]}
                >
                  <InputNumber placeholder='Introduzir a ordem do banner no slideshow' min={1} style={{ width: '100%' }} />
                </Form.Item>
    
                {/* Redirect to URL */}
                <Form.Item
                  hasFeedback
                  initialValue={selectedItem.redirectTo}
                  label='URL de Destino'
                  name='redirectTo'
                  rules={[{ type: 'url', message: 'Por favor, introduza um URL válido.' },]}
                  tooltip='Associa um link ao banner. Desta forma, ao clicar no banner, o utilizador é redirecionado para outro local.'
                >
                  <Input
                    allowClear
                    name='imageUrl'
                    placeholder='Introduzir o URL de destino do banner. (opcional)'
                    type='url'
                  />
                </Form.Item>
              </div>
              <br></br>
              {/* Buttons */}
              <Form.Item style={{ width: '100%' }}>
                <div className='cms-add-modal-btns'>
                  <Button type='default' onClick={this.handleEditBannerModal}>Cancelar</Button>
                  <Popconfirm
                    title='&nbsp;&nbsp;Eliminar banner'
                    description={
                      <>
                        <div>Tem a certeza que pretende eliminar este banner?</div>
                        <div>Esta ação <b>não é reversível</b>.</div>
                      </>
                    }
                    okText='Eliminar'
                    okButtonProps={{ danger: true }}
                    okType='primary'
                    cancelText='Cancelar'
                    onConfirm={this.deleteBanner}
                    icon={<i className='fa-duotone fa-circle-exclamation' style={{ color: styles.COLORS.ErrorColor }}></i>}
                  >
                    <Button danger={true} disabled={editFetching} type='default'>Eliminar</Button>
                  </Popconfirm>
                  <Button 
                    type={editError ? 'default' : 'primary'}
                    danger={editError ? true : false}
                    htmlType='submit'
                    disabled={editFetching}>{editError ? 'Tentar novamente' : editFetching ? 'Aguardar...' : 'Guardar'}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Drawer>
        }
      </div>
    );
  }
}

/**
 * Used in the Redux pattern to reflect any updates to the Redux store and merge them into props in the current
 * component. The Redux store serves as a centralized place for the state to live in the application.
 * 
 * @param {any} state - Centralized state of the application.
 * @returns {any} the state of the application as the component props.
 */
const mapPropsToState = (state) => {
  const languages = state.languages;

  return {
    languages: languages.privilegedData,
    languagesError: languages.error,
    languagesFetching: languages.fetching,
  };
};

/**
 * Used in the Redux pattern to dispatch actions to the Redux store, triggering a state change.
 * 
 * @param {any} dispatch - function of the Redux store.
 * @returns {any} the dispatch functions as components props.
 */
const mapDispatchToState = (dispatch) => {
  return {
    getLanguages: (token) => dispatch(getAllLanguages(token)),
  };
};

export default withRouter(connect(mapPropsToState, mapDispatchToState)(ManageBanners));