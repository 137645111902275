import React from 'react';
import { connect } from 'react-redux';
import { Select, Skeleton } from 'antd';
import { withRouter } from '../../utils';
import { getLanguages, setSelectedLanguage } from '../../reducers/Languages';

/**
 * LanguageSelect class represents the required template to display a dropdown with the list of languages
 * available and a default selection. It manages the language selected.
 */
class LanguageSelect extends React.Component {
  /**
   * Allows to execute the React code when the component is already placed in the DOM (Document Object Model).
   * This method is called during the Mounting phase of the React Life-cycle i.e after the component is rendered.
   * 
   * Gets the list of available languages.
   */
  componentDidMount() {
    this.props.getLanguages();
  }

  /**
   * Allows to manage the language value, when a new value is selected.
   *
   * @param {String} value - The new language value.
   */
  onLanguageChange = (value) => {
    // TODO: Gerir a língua selecionada. Redirecionar para a página da língua especificada.
    this.props.setLanguage(value);
  };

  /**
   * Allows to filter the language list.
   * 
   * @param {String} input - The user value introduced.
   * @param {Object} option - Each option (language) of the selection list (languages).
   * @returns {boolean} true if tha language object includes the input inserted; false, otherwise.
   */
  onLanguageFilter = (input, option) => {
    return option && option.extendedLabel ?
      option.extendedLabel.toLowerCase().includes((input).toLowerCase()) : false;
  };

  render() {
    const { language, languages, languagesError, languagesFetching } = this.props;
    if (languagesError) return null;
    if (languagesFetching) return <Skeleton.Button active={true} />;
    if (!languages || (languages && languages.length < 1)) return null;

    return (
      <Select
        aria-label='Idioma do Portal'
        aria-autocomplete="list"
        aria-haspopup='listbox'
        role="searchbox"
        className='header-language-select'
        defaultValue={language}
        filterOption={this.onLanguageFilter}
        onChange={this.onLanguageChange}
        options={languages.map((language) => {
          return {
            value: language.idLanguage,
            label: language.code,
            extendedLabel: `${language.name} (${language.code})`
          };
        })}
        optionFilterProp='children'
        showSearch
        size='middle'
      />
    );
  }
}

const mapPropsToState = (state) => {
  const languages = state.languages;
  return {
    languagesFetching: languages.fetching,
    languagesError: languages.error,
    languages: languages.data,
    language: languages.selected,
  };
};

const mapDispatchToState = (dispatch) => {
  return {
    getLanguages: () => dispatch(getLanguages()),
    setLanguage: (languageId) => dispatch(setSelectedLanguage(languageId)),
  };
};

export default withRouter(connect(mapPropsToState, mapDispatchToState)(LanguageSelect));