import React from 'react';
import { Outlet } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { Footer, Header } from '../../containers';
import { BackTop } from '../../components';
import { styles } from '../../styles';

/**
 * Layout template, composed by a header, an outlet for the router that manages app routes, and a footer.
 * It also handles the scrolling action.
 */
export default class Layout extends React.Component {
  /**
   * The constructor for a React component is called before it is mounted.
   * When implementing the constructor, `super(props)` must be called before any
   * other statement. Otherwise, this.props will be undefined in the constructor,
   * which can lead to bugs.
   *
   * A React constructor is only used for two purposes:
   * - Initializing local state by assigning an object to this.state.
   * - Binding event handler methods to an instance.
   *
   * Instead of calling `setState()` in the `constructor(), the initial state must be
   * directly assigned to `this.state` in the constructor
   *
   * @param {*} props - Arbitrary component inputs.
   */
  constructor(props) {
    super(props);
    this.state = {
      footerHeight: 0,
      showBackTopBtn: false,
    };
  }

  /**
   * Allows to execute the React code when the component is already placed in the DOM (Document Object Model).
   * This method is called during the Mounting phase of the React Life-cycle i.e after the component is rendered.
   */
  componentDidMount() {
    // Go to pages top after component render
    this.goToTop();
    // Update the footer height after component render
    this.handleFooterHeight();
    // Add event on resize to update the footer height.
    window.addEventListener('resize', this.handleFooterHeight);
    // Add event on scroll to show the back to top button
    window.addEventListener('scroll', this.handleScroll);
    document.title = 'IPS - Vantagens';
  }

  /**
   * Helps handle the footer height.
   * This function is used to measure the necessary padding at teh bottom of the page.
   *
   * Updates the state variable: `footerHeight`
   */
  handleFooterHeight = () => {
    // Get footer height to measure the necessary padding at the bottom
    const footer = document.getElementById('footer');
    // Updates the state variable `footerHeight`
    if (footer) this.setState({ footerHeight: footer.clientHeight });
  };

  /**
   * Handles the scroll action, showing the `back to top` button, or hiding it.
   */
  handleScroll = () => {
    // Get the page scroll value (on Y axis).
    const value = window.scrollY;
    // If the value is bigger than 200, shows the back to top button.
    if (value > 200) this.setState({ showBackTopBtn: true });
    else this.setState({ showBackTopBtn: false });
  };

  /**
   * Scrolls the screen to the top, smoothly.
   */
  goToTop = () => {
    // Scroll the window location to top, smoothly.
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  /**
   * Render method of React component.
   * 
   * @returns the component template.
   */
  render() {
    return (
      <div id='page-container'>
        <ConfigProvider
          theme={{
            token: {
              colorPrimaryBg: styles.COLORS.PrimaryBgColor,
              colorPrimary: styles.COLORS.PrimaryColor,
              colorSuccess: styles.COLORS.SuccessColor,
              colorWarning: styles.COLORS.WarningColor,
              colorError: styles.COLORS.ErrorColor,
              colorInfo: styles.COLORS.InfoColor,
              fontFamily: '\'Barlow\', -apple-system, BlinkMacSystemFont, \'Segoe UI\'',
            },
          }}
        >
          {/* Page header */}
          <Header />
          
          {/* Page routes */}
          <div id='content' style={{ paddingBottom: `${this.state.footerHeight}px` }}>
            <Outlet />
          </div>

          {/* Page footer */}
          <Footer />

          {/* Back to top button */}
          {this.state.showBackTopBtn ? <BackTop action={this.goToTop}/> : null }
        </ConfigProvider>
      </div>
    );
  }
}