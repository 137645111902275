const FETCH_REQUEST = 'roles/FETCH_REQUEST';
const FETCH_SUCCESS = 'roles/FETCH_SUCCESS';
const FETCH_FAILURE = 'roles/FETCH_FAILURE';
const INITIAL_STATE = {
  data: [],
  error: null,
  fetching: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
  case FETCH_REQUEST:
    return {
      ...state,
      error: null,
      fetching: true,
    };
  case FETCH_SUCCESS:
    return {
      ...state,
      fetching: false,
      data: action.payload,
    };
  case FETCH_FAILURE:
    return {
      ...state,
      fetching: false,
      error: action.payload,
    };
  default:
    return state;
  }
}

function fetchRequest() {
  return {
    type: FETCH_REQUEST,
  };
}

function fetchSuccess(data) {
  return {
    type: FETCH_SUCCESS,
    payload: data,
  };
}

function fetchFailure(error) {
  return {
    type: FETCH_FAILURE,
    payload: error,
  };
}

export function getRoles() {
  return (dispatch) => {
    dispatch(fetchRequest());
    fetch(`${process.env.REACT_APP_API_BASE_URL}/roles`, {
      method: 'GET',
      headers: new Headers({ 
        'app_secret_key': process.env.REACT_APP_API_APP_KEY,
        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
      })
    }).then(async (response) => {
      if (response.ok) return response.json();
      const result = await response.json();
      throw new Error(result && result.msg ? result.msg : 'It was impossible to load roles');
    }).then(async (data) => {
      // await (new Promise((res) => {setTimeout(res, 5000); }));
      dispatch(fetchSuccess(data));
    }).catch((error) => dispatch(fetchFailure(error)));
  };
}