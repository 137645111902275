import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from '../../utils';
import { getHighlightedProtocols } from '../../reducers/HighlightedProtocols';
import { A11y, Autoplay, EffectCoverflow, Mousewheel, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Button, Skeleton, Tag } from 'antd';
import { styles } from '../../styles';
import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/autoplay';
import 'swiper/css/mousewheel';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import './index.scss';
import no_photo from '../../images/no_photo.png';

/**
 * Highlights class represents the highlighted protocols template of the homepage. 
 */
class Highlights extends React.Component {
  componentDidMount() {
    this.props.getProtocols(this.props.language);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.language !== this.props.language) {
      this.props.getProtocols(this.props.language);
    }
  }

  protocolIsNew = (creationDate) => {
    // Check if a protocol is new, by adding 3 months to it
    const startDate = new Date(creationDate);
    const newLimitDate = new Date(startDate.setMonth(startDate.getMonth() + 3));
    return newLimitDate.getTime() >= new Date();
  };

  render() {
    const { protocols, protocolsFetching, protocolsError } = this.props;
    if (protocolsError) return null;
    if (protocolsFetching) {
      return (
        <div id='highlights-wrapper'>
          <h1 id='highlights-title'>Destaques</h1>
          <div id='highlights-loading'>
            <Skeleton active />
          </div>
        </div>
      );
    }
    if (protocols && protocols.length < 1) return null;

    return (
      <div id='highlights-wrapper'>
        <h1 id='highlights-title'>Destaques</h1>
        <div id='highlights'>
          <Swiper
            modules={[A11y, Autoplay, EffectCoverflow, Mousewheel, Pagination, Scrollbar]}
            autoplay
            centeredSlides
            coverflowEffect={{
              rotate: 80,
              stretch: 0,
              depth: 350,
              modifier: 1,
              slideShadows: true,
            }}
            effect='coverflow'
            grabCursor
            mousewheel
            pagination={{ clickable: true }}
            slidesPerView={'auto'}
          >
            {protocols.map((protocol) => {
              const protocolImage = protocol.image.startsWith('/') ? `${process.env.REACT_APP_API_IMAGES_BASE_URL}${protocol.image}` : protocol.image;
              
              return (
                <SwiperSlide key={protocol.idProtocol}>
                  <div className='highlights-slide-wrapper'>
                    {/* Closed Highlight */}
                    <div className='highlights-slide-content-closed'>
                      <img
                        alt={protocol.title}
                        crossOrigin={protocol.image.startsWith('/') ? 'anonymous' : undefined}
                        src={protocolImage}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = no_photo;
                        }}
                      />
                    </div>
                    {/* Open Highlight */}
                    <div className='highlights-slide-content-opened'>
                      {/* Image */}
                      <div className='highlights-slide-content-image'>
                        <img
                          alt={protocol.title}
                          crossOrigin={protocol.image.startsWith('/') ? 'anonymous' : undefined}
                          src={protocolImage}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = no_photo;
                          }}
                        />
                        {/* New banner */}
                        <div className='highlights-slide-badges'>
                          {this.protocolIsNew(protocol.activationDate) ? <Tag color={styles.COLORS.PrimaryColor}>Novo</Tag> : null}
                          {protocol.numberOfActiveCampaigns > 0 ? <Tag color={styles.COLORS.ErrorColor}>Campanha</Tag> : null}
                        </div>
                      </div>
                      {/* Description */}
                      <div className='highlights-slide-content-description'>
                        {/* Title */}
                        <div className='highlights-slide-content-title'>{protocol.title}</div>
                        {/* Description/Summary */}
                        <div className='highlights-slide-content-text'>{protocol.summary ? protocol.summary : 'Clique para obter mais informações.'}</div>
                        {/* Buttons */}
                        <div className='highlights-slide-content-button'>
                          {/* Category */}
                          <Link to={`${process.env.REACT_APP_PUBLIC_URL}/vantagens?categoria=${protocol.category.idCategory}`}>
                            <Tag 
                              className='highlights-slide-content-button-tag' 
                              style={{ color: styles.COLORS.getTextColor(protocol.category.color) }}
                              color={protocol.category.color}>{protocol.category.name}</Tag>
                          </Link>
                          {/* More info */}
                          <Link to={`${process.env.REACT_APP_PUBLIC_URL}/vantagens#${protocol.idProtocol}`}>
                            <Button size='small' type={window.innerWidth < 546 ? 'primary' : 'default'}>Ver condições</Button>
                          </Link>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    );
  }
}

const mapPropsToState = (state) => {
  const protocols = state.highlightedProtocols;
  const languages = state.languages;
  return {
    protocolsFetching: protocols.fetching,
    protocolsError: protocols.error,
    protocols: protocols.data,
    language: languages.selected,
  };
};

const mapDispatchToState = (dispatch) => {
  return {
    getProtocols: (idLanguage) => dispatch(getHighlightedProtocols(idLanguage)),
  };
};

export default withRouter(connect(mapPropsToState, mapDispatchToState)(Highlights));