import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../utils';
import { logout } from '../../reducers/User';
import { Link, Navigate, Outlet } from 'react-router-dom';
import { Alert, Avatar, Button, ConfigProvider, Dropdown, Menu } from 'antd';
import ipsLogo from '../../images/IPS-sm-logo.png';
import locale from 'antd/locale/pt_PT';
import { styles } from '../../styles';
import './index.scss';
import { setMenuCollapseState } from '../../reducers/App';

/**
 * Layout template for CMS, composed by a header, an outlet for the router that manages app routes, and a footer.
 * It also handles the scrolling action.
 */
class LayoutCMS extends React.Component {
  /**
   * The constructor for a React component is called before it is mounted.
   * When implementing the constructor, `super(props)` must be called before any
   * other statement. Otherwise, this.props will be undefined in the constructor,
   * which can lead to bugs.
   *
   * A React constructor is only used for two purposes:
   * - Initializing local state by assigning an object to this.state.
   * - Binding event handler methods to an instance.
   *
   * Instead of calling `setState()` in the `constructor(), the initial state must be
   * directly assigned to `this.state` in the constructor
   *
   * @param {*} props - Arbitrary component inputs.
   */
  constructor(props) {
    super(props);
    this.state = {
      selectedMenuOption: null,
      menuCollapsed: false,
      menuOptions: [
        {
          id: 1,
          label: 'Vantagens',
          icon: 'fa-regular fa-handshake',
          url: '/cms/vantagens',
        },
        {
          id: 2,
          label: 'Grupos',
          icon: 'fa-duotone fa-sitemap',
          url: '/cms/grupos',
        },
        {
          id: 3,
          label: 'Campanhas',
          icon: 'fa-duotone fa-business-time',
          url: '/cms/campanhas',
        },
        {
          id: 4,
          label: 'Categorias',
          icon: 'fa-duotone fa-tags',
          url: '/cms/categorias',
        },
        {
          id: 5,
          label: 'Menus',
          icon: 'fa-duotone fa-list-dropdown',
          url: '/cms/menus',
        },
        // {
        //   id: 6,
        //   label: 'Páginas',
        //   icon: 'fa-duotone fa-browser',
        //   url: '/cms/paginas',
        // },
        {
          id: 6,
          label: 'Banners',
          icon: 'fa-duotone fa-images',
          url: '/cms/banners',
        },
        {
          id: 7,
          label: 'Redes Sociais',
          icon: 'fa-duotone fa-chart-network',
          url: '/cms/redes-sociais',
        },
        {
          id: 8,
          label: 'Utilizadores',
          icon: 'fa-duotone fa-users',
          url: '/cms/utilizadores',
        },
        {
          id: 9,
          label: 'Atividade',
          icon: 'fa-duotone fa-chart-gantt',
          url: '/cms/atividade',
        },
        {
          id: 10,
          label: 'Configurações',
          icon: 'fa-duotone fa-gears',
          url: '/cms/configuracoes',
        },
      ],
      pageWidth: 0,
    };
  }

  /**
   * Allows to execute the React code when the component is already placed in the DOM (Document Object Model).
   * This method is called during the Mounting phase of the React Life-cycle i.e after the component is rendered.
   */
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Add event on resize
    this.setState({ pageWidth: window.innerWidth });
    window.addEventListener('resize', () => this.setState({ pageWidth: window.innerWidth }));
    // Set selected menu option
    this.setSelectedMenuOption();
    document.title = 'Gestão das Vantagens IPS';
  }

  /**
   * Allows React to call this function immediately after this component has been re-rendered with
   * updated `props` and/or `state`. This method is not called for the initial render.
   * 
   * This allows to manipulate the DOM after an update. It is also a common place to do network requests
   * as long as it compares the current `props` to previous `props` (e.g. a network request may not be necessary
   * if the `props` have not changed).
   * 
   * @param {any} prevProps - Props before the update. Compare prevProps to this.props to determine what changed.
   * @param {any} prevState - State before the update. Compare prevState to this.state to determine what changed.
   * @param {any} snapshot - Used when function getSnapshotBeforeUpdate is implemented. It will contain the value returned from that function. Otherwise, it will be undefined.
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (JSON.stringify(prevProps.router) !== JSON.stringify(this.props.router)) {
      this.setSelectedMenuOption();
    }
  }

  setSelectedMenuOption = () => {
    const currentLocation = this.props && this.props.router && this.props.router.location ?
      this.props.router.location.pathname : null;
    if (currentLocation && currentLocation.replace('/cms').length > 1) {
      this.setState({
        selectedMenuOption: this.state.menuOptions.find((option) => {
          return option.url === currentLocation;
        })
      });
    }
  };

  toggleCollapsed = () => {
    const current = this.state.menuCollapsed;
    this.setState({ menuCollapsed: !current });
    this.props.setCollapseState(!current);
  };

  menuItemClick = (to) => {
    if (this.props && this.props.router) this.props.router.navigate(to);
  };

  /**
   * Logs user out, cleaning all local storage data.
   */
  logout = () => {
    this.props.logout();
  };

  /**
   * Render method of React component.
   * 
   * @returns the component template.
   */
  render() {
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username');
    const loginDate = localStorage.getItem('loginDate');
    if (!token || !username || !loginDate || (loginDate && new Date(loginDate).getTime() <= (new Date()).getTime()))
      return <Navigate to={'/login'} replace={true} />;

    const { menuCollapsed, menuOptions, pageWidth, selectedMenuOption } = this.state;
    const { router } = this.props;

    let avatarUsername = '';
    const usernameNames = username.split('.');
    const numberOfUsernameNames = usernameNames.length;
    if (numberOfUsernameNames === 1) {
      avatarUsername = username.toUpperCase().substring(0, 2);
    } else if (numberOfUsernameNames > 1) {
      const firstName = usernameNames[0].toUpperCase();
      const lastName = usernameNames[numberOfUsernameNames - 1].toUpperCase();
      avatarUsername = firstName.charAt(0) + lastName.charAt(0);
    }
    // const avatarRandomColor = styles.COLORS.getRandomHexColor();
    // console.log(avatarRandomColor);

    const isActive = localStorage.getItem('isActive');
    const idRole = localStorage.getItem('idRole');
    const role = localStorage.getItem('role');
    const validMenuOptions = menuOptions.map((option) => {
      // Manager
      if (isActive === 'true' && idRole === '386DDE5B-09C4-ED11-A311-00155D08E85F' && role === 'Manager') {
        if ([1, 2, 3, 4, 5, 6, 7, 9, 10].includes(option.id)) option.disabled = false;
        else option.disabled = true;
        return option;
      }
      // Administrator
      if (isActive === 'true' && idRole === '376DDE5B-09C4-ED11-A311-00155D08E85F' && role === 'Administrator') {
        option.disabled = false;
        return option;
      }
      // Visitor / Inactive / Other
      if (option.id === 9)  option.disabled = false;
      else option.disabled = true;
      return option;
    });

    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimaryBg: styles.COLORS.PrimaryBgColor,
            colorPrimary: styles.COLORS.PrimaryColor,
            colorSuccess: styles.COLORS.SuccessColor,
            colorWarning: styles.COLORS.WarningColor,
            colorError: styles.COLORS.ErrorColor,
            colorInfo: styles.COLORS.InfoColor,
            fontFamily: '\'Barlow\', -apple-system, BlinkMacSystemFont, \'Segoe UI\'',
          },
        }}
        locale={locale}
      >
        <div id='cms-layout-page'>
          {/* Side menu */}
          {pageWidth > 600 &&
            <div id='cms-layout-menu'>
              {/* Top menu */}
              <Menu
                className='cms-layout-menu-top-node'
                inlineCollapsed={menuCollapsed}
                mode='inline'
                items={[{
                  key: '0',
                  label: (
                    <Link id='cms-layout-menu-header' to={`${process.env.REACT_APP_PUBLIC_URL}/cms`} style={{ textDecoration: 'none' }}>
                      <img src={ipsLogo} alt={'Logótipo do Politécnico de Setúbal'} style={{ height: styles.SPACING.MarginXL }} />
                      {/* <Logotype url='/cms' src={ipsLogo} alt='Logótipo do IPS' height={styles.SPACING.MarginXL} /> */}
                      <h1 className='cms-layout-menu-header-title'>VANTAGENS</h1>
                    </Link>
                  ),
                  title: 'Página inicial',
                  // onClick: () => {
                  //   this.menuItemClick('/cms');
                  // }
                }]}
              />

              {/* Center menu */}
              <Menu
                className='cms-layout-menu-node'
                selectedKeys={selectedMenuOption ? [''+selectedMenuOption.id] : undefined}
                inlineCollapsed={menuCollapsed}
                mode='inline'
                items={
                  validMenuOptions.map((option) => {
                    return {
                      key: option.id,
                      label: option.label,
                      icon: <i className={option.icon}></i>,
                      disabled: option.disabled,
                      onClick: () => this.menuItemClick(option.url),
                    };
                  })
                }
              />

              {/* Bottom menu */}
              <Menu
                mode='inline'
                inlineCollapsed={menuCollapsed}
                items={[
                  {
                    key: '0',
                    icon: menuCollapsed ? <i className='fa-solid fa-arrow-right-from-line'></i> : <i className='fa-solid fa-arrow-left-from-line'></i>,
                    label: menuCollapsed ? 'Expandir' : 'Colapsar',
                    onClick: this.toggleCollapsed
                  }
                ]}
              />
            </div>
          }
          {/* Content */}
          <div id='cms-layout-content'>
            {/* Header */}
            <header>
              {/* Title */}
              <h1 id='cms-layout-content-title'>{
                router.location.pathname === '/cms/vantagens' ? 'Gestão de Vantagens' : 
                  router.location.pathname === '/cms/campanhas' ? 'Gestão de Campanhas' :
                    router.location.pathname === '/cms/grupos' ? 'Gestão de Grupos' :
                      router.location.pathname === '/cms/categorias' ? 'Gestão de Categorias' : 
                        router.location.pathname === '/cms/menus' ? 'Gestão de Menus' : 
                          // router.location.pathname === '/cms/paginas' ? 'Gestão de Páginas' : 
                          router.location.pathname === '/cms/redes-sociais' ? 'Redes Sociais' : 
                            router.location.pathname === '/cms/banners' ? 'Gestão de Banners' : 
                              router.location.pathname === '/cms/utilizadores' ? 'Gestão de Utilizadores' : 
                                router.location.pathname === '/cms/atividade' ? 'Atividade na Aplicação' : 
                                  router.location.pathname === '/cms/configuracoes' ? 'Configurações do Sistema' : 
                                    document.title
              }</h1>

              {/* Back home */}
              { router.location.pathname !== '/cms' &&
                <Link to={`${process.env.REACT_APP_PUBLIC_URL}/cms`}><Button icon={
                  <i className='fa-duotone fa-house-blank' style={{ fontSize: styles.FONTS.FontSizeH4 }}></i>
                } type='link' size='large'/></Link>
              }

              {/* Profile */}
              <Dropdown menu={{
                items: [
                  {
                    key: '0',
                    icon: <span id='cms-back-home-btn'><i className='fa-duotone fa-house'></i></span>,
                    label: 'Regressar à App',
                    onClick: () => this.menuItemClick('/'),
                  },
                  {
                    key: '1',
                    icon: <span id='cms-back-home-btn'><i className='fa-duotone fa-list-check'></i></span>,
                    label: 'Ver atividade',
                    onClick: () => this.menuItemClick('/cms/atividade'),
                  },
                  {
                    key: '2',
                    style: { color: styles.COLORS.ErrorColor },
                    icon: <span id='cms-logout-btn'><i className='fa-solid fa-right-from-bracket'></i></span>,
                    label: 'Logout',
                    onClick: this.logout,
                  }
                ]
              }}>
                <Avatar style={{ backgroundColor: styles.COLORS.SecondaryColor }} size='large'>{avatarUsername}</Avatar>
              </Dropdown>
            </header>
            {/* Content */}
            <div id='cms-layout-content-outlet'>
              {/* Inactive user alert */}
              {isActive !== 'true' && 
                <>
                  <Alert message='O seu utilizador foi desativado. Os acessos à aplicação são restritos.' type='warning' showIcon />
                  <br></br>
                </>
              }
              <Outlet />
            </div>

            <footer></footer>
          </div>
        </div>
      </ConfigProvider>
    );
  }
}

/**
 * Used in the Redux pattern to reflect any updates to the Redux store and merge them into props in the current
 * component. The Redux store serves as a centralized place for the state to live in the application.
 * 
 * @param {any} state - Centralized state of the application.
 * @returns {any} the state of the application as the component props.
 */
const mapPropsToState = (state) => {
  const user = state.user;

  return {
    error: user.error,
    fetching: user.fetching,
    user: user.data,
    username: user.username,
    token: user.token,
  };
};

/**
 * Used in the Redux pattern to dispatch actions to the Redux store, triggering a state change.
 * 
 * @param {any} dispatch - function of the Redux store.
 * @returns {any} the dispatch functions as components props.
 */
const mapDispatchToState = (dispatch) => {
  return { 
    logout: () => dispatch(logout()),
    setCollapseState: (state) => dispatch(setMenuCollapseState(state)),
  };
};

export default withRouter(connect(mapPropsToState, mapDispatchToState)(LayoutCMS));