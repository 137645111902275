import { configureStore } from '@reduxjs/toolkit';
import {
  App,
  Banners,
  Categories,
  Configurations,
  Groups,
  HighlightedProtocols,
  Languages,
  MenuOptions,
  Pages,
  Protocols,
  Roles,
  SocialNetworks,
  Statistics,
  User,
} from './reducers';

export const store = configureStore({
  reducer: {
    app: App,
    banners: Banners,
    categories: Categories,
    configurations: Configurations,
    groups: Groups,
    highlightedProtocols: HighlightedProtocols,
    languages: Languages,
    menuOptions: MenuOptions,
    pages: Pages,
    protocols: Protocols,
    roles: Roles,
    statistics: Statistics,
    socialNetworks: SocialNetworks,
    user: User,
  },
});
