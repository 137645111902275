import React from 'react';
import PropTypes from 'prop-types';
import { styles } from '../../styles';

/**
 * VerticalDivider class represents a divider vertically displayed.
 * 
 * Accepts the props:
 * * `color` - The color of the divider ( @default `#0000000A` ).
 * * `height` - The height of the divider ( @default `48px` ).
 * * `width` - The width of the divider( @default `1px` ).
 */
export default class VerticalDivider extends React.Component {
  render() {
    // Get vertical divider component props
    const { color, height, width } = this.props;
    // Set divider style, depending on passed props
    const dividerStyle = {};
    dividerStyle.borderInlineEndStyle = 'solid';
    if (color) dividerStyle.borderInlineEndColor = color;
    else dividerStyle.borderInlineEndColor = styles.COLORS.TertiaryFillColor;
    if (width) dividerStyle.borderInlineEndWidth = width;
    else dividerStyle.borderInlineEndWidth = '1px';
    if (height) dividerStyle.height = height;
    else dividerStyle.height = styles.SPACING.MarginXXL;
    return <div style={dividerStyle}></div>;
  }
}

VerticalDivider.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

VerticalDivider.defaultProps = {
  color: styles.COLORS.TertiaryFillColor,
  height: styles.SPACING.MarginXXL,
  width: '1px',
};